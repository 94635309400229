<template>
    <div class="container pb-4">
        <div class="pb-4 text-center">
            <h3>Registro Hospitalar</h3>
            <p>Realizar a configuração do Registro Hospitalar</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div class="row">

                    <div class="col-12">
                        <div class="form-row flex-nowrap align-content-center">
                            <div class="col">
                                <label class="mt-2">Registro Hospitalar Automático</label>
                            </div>
                            <div class="col-auto">
                                <div 
                                    class="font-32"
                                    :class="{
                                        'text-success':         form.state,
                                        'text-secondary':      !form.state
                                    }"
                                    @click="form.state = !form.state"
                                >
                                    <i 
                                        class="fad"
                                        :class="{
                                            'fa-toggle-on':     form.state,
                                            'fa-toggle-off':      !form.state
                                        }"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="example" class="col-12 mt-4">
                        <div class="row d-flex justify-content-center">
                            <h5 class="col-12 text-center mb-2">Exemplo de Registro Hospitalar</h5>
                            <label class="mt-3 text-uppercase">{{ example }}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="prefix">Prefixo</label>
                            <input type="text"
                                id="prefix"
                                :disabled="!form.state"
                                class="form-control text-uppercase"
                                v-model="form.prefix"
                            >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="suffix">Sufixo</label>
                            <input 
                                type="text"
                                class="form-control"
                                :disabled="!form.state"
                                id="suffix"
                                v-model="form.suffix"
                            >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="initial_number">Número Inicial</label>
                            <input 
                                type="number"
                                :disabled="!form.state"
                                class="form-control"
                                id="initial_number"
                                v-model="form.initial_number"
                            >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="initial_date">Data Inicial</label>
                            <input 
                                type="date"                                
                                :disabled="!form.state"
                                class="form-control"
                                :class="{
                                    'is-invalid': $v.form.initial_date.$error
                                }"
                                id="initial_date"
                                ref="initial_date"
                                v-model="$v.form.initial_date.$model"
                            >
                            <div class="invalid-feedback">
                                Obrigatório
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="current_number">Número atual</label>
                            <input type="text"
                                class="form-control"
                                id="current_number"
                                v-model="form.current_number"
                                readonly 
                            >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="my-4 d-flex justify-content-end">
                            <button type="text" class="btn btn-primary px-5"
                                @click="SaveEHRConfig()"
                            >
                                salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { required, requiredIf } from 'vuelidate/lib/validators'
    import helpers from '../../helpers'
    import { mapGetters, mapActions } from 'vuex'
    import Swal from 'sweetalert2'
    export default {

        name: 'EHR',

        data () {
            return {
                form:       {
                    prefix:             "",
                    suffix:             "",
                    initial_number:     0,
                    initial_date:       "",
                    current_number:     "",
                    state:              false,
                },
                rh:         null
            }
        },
        validations: {
            form: {
                initial_date: {
                    required
                }
            }
        },
        computed: {
            ...mapGetters('auth', [
                'requester_selected_id'
            ]),
            example()
            {
                const p         = this.form.prefix ? this.form.prefix : ""
                const s         = this.form.suffix ? this.form.suffix : ""
                const num       = this.form.current_number ? this.form.current_number : this.form.initial_number

                let example     = p + num + s

                return example != 0 ? example : "A12BC"
            }
        },
        mounted() 
        {
            this.GetEHRConfig()
        },
        methods: {
            ...mapActions('system', [
                'StartLoading', 'FinishLoading'
            ]),
            ...mapActions('auth', [
                'GetAuthAccount'
            ]),
            GetEHRConfig() 
            {
                this.StartLoading()

                return window.api.call('post', '/get-ehr-config', {
                        account_id: this.requester_selected_id
                    })
                    .then(({data}) => {
                        const res   = data.ehr_config

                        if((res.prefix || res.suffix) && res.initial_date && 
                            (res.initial_number || res.current_number)) 
                        {
                            this.form       = res
                        }
                    })
                    .finally(() => {
                        this.FinishLoading()
                    })
            },
            SaveEHRConfig() 
            {
                this.$v.form.$touch()

                if(!this.$v.form.$invalid) 
                {
                    this.StartLoading()
                    
                    return window.api.call('post', '/save-ehr-config', {
                            ...this.form,
                            account_id: this.requester_selected_id
                        })
                        .then(({data}) => {
                            if(data.response) 
                            {
                                this.GetEHRConfig()

                            } else {

                                Swal.fire({
                                    icon: 'error',
                                    title: 'Opa!',
                                    text: 'Houve um erro ao concluir, contate o suporte.'
                                })
                            }
                        })
                        .finally(() => {
                            this.FinishLoading()
                        })
                } else {
					window.helpers.focusInvalid(this.$v.form, this)
				}
            }
        }
    }
</script>

<style lang="css">

</style>