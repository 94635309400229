<template>
	<div class="container">
		<div class="row align-items-center justify-content-center flex-column">
			<div class="col-12" v-if="type == 'create'">
				<div class="d-block mx-auto pb-4 pb-md-5 text-md-center max-500 pr-6 pr-md-0">
					<span> Criar agenda </span>
					<h4 class="font-24 font-md-32 m-0">Configure a periodicidade</h4>
				</div>
			</div>
			<div class="col-12">
				<div class="row justify-content-center">
					<div class="col-md-10 mb-4">
						<div class="row align-items-center">

							<div class="col-md-4">
								<div class="form-group form-check mb-2">
									<input 
										type="checkbox"
										v-model="selected.temporary_schedule"
										id="temporary"
									>
									<label for="temporary">Esta agenda é temporária?</label>
								</div>					
								<p class="opacity-75 font-14">
									Se a agenda possuir data de término, marque esta opção.
								</p>			
							</div>

							<div class="col-md-4">
								<div class="form-group">
									<label>Data de início</label>
									<input 
										type="date"
										class="form-control" 
										:class="{ 'is-invalid': $v.selected.date_init.$error }"
										:min="(type == 'config' ? '2018-01-01' : new Date().toISOString().substr(0, 10))"
										v-model="$v.selected.date_init.$model"
										:disabled="type == 'config'"
										ref="date_init"
									>
									<div class="invalid-feedback">
										Deve ser menor que a data de término
									</div>
								</div>
							</div>
							<div class="col-md-4" v-if="selected.temporary_schedule">
								<div class="form-group">
									<label>Data de término</label>
									<input 
										type="date" 
										class="form-control"
										:class="{ 'is-invalid': $v.selected.date_end.$error }"
										:disabled="!selected.temporary_schedule"
										:min="selected.date_init"
										v-model="$v.selected.date_end.$model"
										ref="date_end"
									>
									<div class="invalid-feedback">
										Deve ser maior que a data de inicio
									</div>
								</div>
							</div>

							<div class="col-md-5 mt-5" v-if="!continuous_schedule">
								<div class="form-group form-check mb-2">
									<input 
										type="checkbox" 
										id="cyclic"
										v-model="selected.not_cyclic_schedule"
									>
									<label for="cyclic">Esta agenda é esporádica?</label>
								</div>					
								<p class="opacity-75 font-14">
									Se esta agenda não tiver um ciclo semanal repetitivo, marque esta opção.
								</p>			
							</div>

						</div>
					
						<template v-if="!continuous_schedule">
							<div class="row justify-content-center">
								<div class="col-12 col-md-6">
									<p class="font-24 text-center">
										Selecione os dias de funcionamento
									</p>
								</div>
							</div>
							<transition name="fade" mode="out-in">
								<div v-if="!selected.not_cyclic_schedule" key="1">
									<div class="row justify-content-center">
										<div class="col-12 col-md-6">
											<div class="card bg-light border-no overflow-hidden">
												<div class="card-body p-3 scroll-this">
													<div 
														class="select-card with-border mb-3"
														:class="{ 'active': day.selected }"
														v-for="day in week"
														:key="day.name"
														@click="day.selected = !day.selected"
													>
														<label class="p-3">
															{{day.name.toUpperCase()}}
															<i 
																class="far font-14"
																:class="{
																	'fa-plus': !day.selected,
																	'fa-times text-danger': day.selected
																}"
															></i>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div v-else key="2">
									<div class="row justify-content-center">
										<div class="col-12 col-md">
											<div class="card bg-light border-no overflow-hidden">
												<div class="card-body p-3 scroll-this">
													<div 
														v-for="year in years"
														:key="year"
														@click="selected.year = year"
														class="select-card with-border mb-3"
														:class="{ 'active': selected.year == year }"
													>
														<label class="p-3">
															{{ year }}
															<i class="far fa-chevron-right font-14"></i>
														</label>
													</div>
												</div>
											</div>
										</div>
										<div class="col-12 col-md">
											<div class="card bg-light border-no overflow-hidden">
												<div class="card-body p-3 scroll-this">		
													<template v-if="selected.year">

														<div 
															v-for="month in filteredMonths"
															:key="month.id"
															class="select-card with-border mb-3"
															:class="{ 'active': selected.month == month.id}"
															@click="GetDaysFromMonth(month.id)"
														>
															<label class="p-3">
																{{ month.name }}
																<i class="far fa-chevron-right font-14"></i>
															</label>
														</div>
													</template>
													<template v-else>
														<div class="rounded-lg border mb-3 text-center">
															<label class="p-3 m-0">
																<small class="d-block text-muted opacity-75">Selecione um ano</small>
															</label>
														</div>
													</template>	
												</div>
											</div>
										</div>
										<div class="col-12 col-md">
											<div class="card bg-light border-no overflow-hidden">
												<div class="card-body p-3 scroll-this">
													<template v-if="selected.year && selected.month">
														<div 
															v-for="day in days"
															:key="day.fulldate"
															@click="AddDate(day)"
															class="select-card with-border mb-3"
															:class="{ 'active': selected.dates.find( d => d.fulldate == day.fulldate) }"
														>
															<label class="p-3">
																{{`${day.day} - ${day.weekday}`}}
																<i class="far fa-plus font-14"></i>
															</label>
														</div>
													</template>
													<template v-else>
														<div class="rounded-lg border mb-3 text-center">
															<label class="p-3 m-0">
																<small class="d-block text-muted opacity-75">Selecione um mês</small>
															</label>
														</div>
													</template>
												</div>
											</div>
										</div>
										<div class="col-12 col-md">
											<div class="card bg-light border-no overflow-hidden">
												<div class="card-body p-3 scroll-this">		
													<template v-if="selected.dates.length > 0">
														<div 
															v-for="day in selected.dates"
															:key="day.fulldate"
															@click="RemoveDate(day)"
															class="select-card select-orange with-border mb-3"
														>
															<label class="p-3">
																{{`${day.fulldate} - ${day.weekday}`}}
																<i class="far fa-times font-14"></i>
															</label>
														</div>
													</template>
													<template v-else>
														<div class="rounded-lg border mb-3 text-center">
															<label class="p-3 m-0">
																<small class="d-block text-muted opacity-75">Selecione um dia</small>
															</label>
														</div>
													</template>
												</div>
											</div>
										</div>
									</div>
								</div>
							</transition>
						</template>
						<div class="d-flex justify-content-between  mt-4" v-if="type == 'create'">
							<button
								type="button"
								class="btn btn-light px-5"
								@click="Previous"
							>
								voltar
							</button>
							<button 
								class="btn btn-primary px-5"
								@click="Next"
							>
								concluir
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	const moment 		= require('moment')
	import { required } from 'vuelidate/lib/validators'

	const CheckDate = (param) => (value, vm) => {
		switch(param)
		{
			case 'date_init':
				return moment(value).isSameOrAfter(moment(vm[param]))

			case 'date_end':
				return moment(vm[param]).isSameOrAfter(moment(value))
		}

		return false
	}

	export default {

		name: 'PeriodicityAndDates',
		props: 	{
			continuous_schedule: {
				type: 		Boolean,
				default: 	false
			},
			type: 			{
				type: 		String,
				default: 	'create'//config
			},
			date_init: 		{
				type: 		String,
				default: 	new Date().toISOString().substr(0, 10)
			},
			date_end: 		{
				type: 		String,
				default: 	new Date().toISOString().substr(0, 10)
			},
			temporary_schedule: 	{
				type: 		Boolean,
				default: 	false
			},
			cyclic_schedule: 		{
				type: 		Boolean,
				default: 	true
			},
			dates: 				{
				type: 		Array,
				default: 	() => []
			},
			is_started:			{
				type:		Boolean,
				default:	true,
			}
		},
		data () {
			return {
				selected: 	{
					today:				new Date().toISOString().substr(0, 10),
					date_init: 			new Date().toISOString().substr(0, 10),
					date_end: 			new Date().toISOString().substr(0, 10),
					temporary_schedule: false,
					not_cyclic_schedule: false,
					year: 				null,
					month: 				null,
					dates: 				[]
				},
				week: 					[
					{ name: 'Domingo', value: 0, selected: false },
		      		{ name: 'Segunda', value: 1, selected: false },
		      		{ name: 'Terça', value: 2, selected: false },
		      		{ name: 'Quarta', value: 3, selected: false },
		      		{ name: 'Quinta', value: 4, selected: false },
		      		{ name: 'Sexta', value: 5, selected: false },
		      		{ name: 'Sabado', value: 6, selected: false }
				],
				week_arr: 						[
					'Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sabado'
				],
				months: 				[
					{id: 1, name: 'Janeiro'},
					{id: 2, name: 'Fevereiro'},
					{id: 3, name: 'Março'},
					{id: 4, name: 'Abril'},
					{id: 5, name: 'Maio'},
					{id: 6, name: 'Junho'},
					{id: 7, name: 'Julho'},
					{id: 8, name: 'Agosto'},
					{id: 9, name: 'Setembro'},
					{id: 10, name: 'Outubro'},
					{id: 11, name: 'Novembro'},
					{id: 12, name: 'Dezembro'}
				],
				days: 					[]
			}
		},
		validations: 	{
			selected: 		{
				date_init: 		{
					//GreaterThan: CheckDate('date_end')
				},
				date_end: 		{
					GreaterThan: CheckDate('date_init')
				}
			}
		},
		watch: {
			date_init: 	{
				handler(val)
				{
					this.selected.date_init 		= val
				},
				immediate: true
			},
			'selected.date_init': {
				handler(val)
				{
					this.selected.dates 	= []

					if(this.selected.temporary_schedule && moment(val).isSameOrAfter(this.selected.date_end))
					{
						this.selected.date_end 	= val
					}
				}
			},
			date_end: 	{
				handler(val)
				{
					if(this.temporary_schedule || this.selected.temporary_schedule)
					{
						this.selected.date_end 		= val
					}
				},
				immediate: true
			},
			'selected.date_end': {
				handler(val) {
					if(!this.is_started) {
						this.selected.dates 	= []
	
						if(!moment(val).isSameOrAfter(this.selected.date_init))
						{
							this.selected.date_init 	= val
						}

						this.$emit('append', {
							type: 'date_end',
							date_end: val
						})
					}
				}
			},
			'selected.year': {
				handler(val)
				{
					this.days 				= []
				}
			},
			temporary_schedule: 	{
				handler(val)
				{
					this.selected.temporary_schedule 		= val
				},
				immediate: true
			},
			'selected.temporary_schedule': {
				handler(val) {
					if(!this.is_started) {
						this.selected.dates 	= []
						this.days 				= []

						this.$emit('append', {
							type: 'temporary_schedule',
							temporary_schedule: val,
							date_end: this.selected.date_end
						})
					}
				}
			},
			cyclic_schedule: 	{
				handler(val)
				{
					this.selected.not_cyclic_schedule 		= !val
				},
				immediate: true
			},
			'selected.not_cyclic_schedule': {
				handler(val) {
					if(!this.is_started) {
						this.$emit('append', {
							type: 'cyclic',
							cyclic_schedule: !val
						})
					}
				},
				immediate: true
			},
			dates: 	{
				handler(val)
				{
					if(val.length > 0)
					{
						if(!this.continuous_schedule)
						{
							if(this.cyclic_schedule)
							{
								this.week 			= val


							}else{
								this.selected.dates 	= val
								this.selected.year 		= this.type == 'config' ? this.loaded_year : null
								// this.selected.month 	= this.type == 'config' ? this.loaded_month : null
							}
						}
					}
					
				},
				immediate:  true,
				deep: 		true
			},
			'selected.dates': {
				handler(val)
				{
					if(!this.is_started)
					{
						this.$emit('append', {
							type: 'dates',
							dates: val
						})
					}
				},
				deep: true
			},
			week: {
				handler(val)
				{
					if(!this.is_started)
					{
						this.$emit('append', {
							type: 'week',
							week: val
						})
					}
				},
				deep: true
			}
		},
		computed: {
			loaded_year()
			{
				if (!this.selected.cyclic_schedule)
				{
					return this.selected.dates[0].fulldate ? parseInt(this.selected.dates[0].fulldate.split('/')[2]) : 0
				}

				return 0
			},
			loaded_month()
			{
				if (!this.selected.cyclic_schedule)
				{
					const last_index = this.selected.dates.length - 1
					
					const month = this.selected.dates[last_index].fulldate ? parseInt(this.selected.dates[last_index].fulldate.split('/')[1]) : 0
	
					return month
				}

				return 0
			},
			years()
	      	{
	      		let years 				= []
	      		let year  				= new Date(this.selected.today).getFullYear()
	      		let final 				= parseInt(year) + 20
	      		let has_end				= false

	      		if(this.selected.temporary_schedule && this.selected.date_end)
	      		{
	      			final 		= new Date(this.selected.date_end.replace(/-/g, '/')).getFullYear()
	      		}

	      		while(year <= final)
	      		{

	      			years.push(year)

	      			year++
	      		}

	      		return years
	      	},
	      	filteredMonths()
	      	{
	      		let first_data 		= this.selected.today.split('-')
	      		first_data 			= new Date(first_data[0], (first_data[1] - 1), first_data[2])

	      		let last_data 		= this.selected.date_end.split('-')
	      		last_data 			= new Date(last_data[0], (last_data[1] - 1), last_data[2])

	      		let months 			= this.months

	      		if(this.selected.temporary_schedule)
	      		{
	      			let isFirst 	= first_data.getFullYear() == this.selected.year
	      			let isLast 		= last_data.getFullYear() == this.selected.year

	      			if(isFirst && isLast)
	      			{
	      				months 			= this.months.filter( month => {
	      					return month.id >= (first_data.getMonth() + 1) && month.id <= (last_data.getMonth() + 1)
	      				})
	      			}else if(isFirst)
	      			{
		      			months 			= this.months.filter( month => month.id >= (first_data.getMonth() + 1))

		      		}else if(isLast)
		      		{
		      			months 			= this.months.filter( month => month.id <= (last_data.getMonth() + 1))
		      		}

	      		}else{

	      			if(first_data.getFullYear() == this.selected.year)
		      		{
		      			months 			= this.months.filter( month => month.id >= (first_data.getMonth() + 1))
		      		}
	      		}

	      		this.GetDaysFromMonth()

	      		return months
	      	}
		},
		methods: {
			GetDaysFromMonth(month = null) {
				this.selected.month			= month ? month : this.selected.month

				if(this.selected.year && this.selected.month) {
					let loop 			= true

					let first_date 		= new Date(this.selected.today.replace(/-/g, '/'))
					let last_date 		= new Date(this.selected.date_end.replace(/-/g, '/'))

					let first_day 		= 1

					if(this.selected.month == (first_date.getMonth() + 1) && this.selected.year == first_date.getFullYear()) {
						first_day 			= first_date.getDate()
					}

					let date 			= new Date(this.selected.year, (this.selected.month - 1), first_day)

					let days 			= [{
						day: 			first_day.toString().padStart(2, '0'),
						weekday: 		this.week_arr[date.getDay()],
						fulldate: 		`${date.getDate().toString().padStart(2,'0')}/${(date.getMonth() + 1).toString().padStart(2,'0')}/${date.getFullYear()}`
					}]

					while(loop) {
						let obj 			= {
							day: 				null,
							weekday: 			null,
							fulldate: 			null
						}

						date 				= date.setDate(date.getDate() + 1)

						date 				= new Date(date)

						obj.weekday 		= this.week_arr[date.getDay()]
						obj.day 			= date.getDate().toString().padStart(2,'0')
						obj.fulldate 		= `${obj.day}/${(date.getMonth() + 1).toString().padStart(2,'0')}/${date.getFullYear()}`

						if(date.getMonth() !== (this.selected.month - 1)) {
							loop 	= false

							break
						} else if(this.selected.month == (last_date.getMonth() + 1) && this.selected.year == last_date.getFullYear()) {
							if(this.selected.temporary_schedule && (date.getDate() > last_date.getDate())) {
								loop 	= false

								break
							} else {
								days.push(obj)
							}
						} else {
							days.push(obj)
						}
					}

					this.days 		= days
				}
			},
			AddDate(day) {
				if(this.selected.dates.find( d => d.fulldate == day.fulldate)) {
					Swal.fire({
						toast: 		true,
						position: 	'top',
						title: 		'Esta data já foi selecionada.',
						icon: 		'error',
						timer: 		3000
					})
				} else {
					this.selected.dates.unshift(day)
				}
			},
			RemoveDate(target)
			{
				this.selected.dates 	= this.selected.dates.filter( day => day.fulldate != target.fulldate)
			},
			Next()
			{	
				this.$v.selected.$touch()

				if(!this.$v.selected.$invalid)
				{
					let obj 	= {
						...this.selected
					}

					delete obj.year
					delete obj.month

					if(this.continuous_schedule) {
						delete obj.cyclic_schedule
						delete obj.dates

						this.$emit('next', obj)

						return true
					} else {
						if(!this.selected.not_cyclic_schedule) {
							if(typeof this.week.find(x => x.selected) === 'undefined') {
								Swal.fire({
									toast: 		true,
									position: 	'top',
									title: 		'Selecione ao menos 1 dia.',
									icon: 		'error',
									timer: 		3000								
								})

								return false
							} else {
								obj 		= {
									...obj,
									week: 	this.week
								}

								delete obj.dates

								this.$emit('next', obj)

								return true
							}
						} else {
							if(this.selected.dates.length === 0) {
								Swal.fire({
									toast: 		true,
									position: 	'top',
									title: 		'Selecione ao menos 1 data.',
									icon: 		'error',
									timer: 		3000								
								})

								return false
							} else {
								obj.cyclic_schedule = !this.selected.not_cyclic_schedule;
							
								this.$emit('next', obj)

								return true
							}
						}
					}
				} else {
					window.helpers.focusInvalid(this.$v.selected, this)
				}

				return false
			},
			Previous()
			{
				this.$emit('previous')
			}
		},
	}
</script>

<style lang="css" scoped>
</style>