<template>
	<div class="container pb-4">
		<div class="col-12">
			<div class="col-12 d-flex flex-column align-items-center pb-5">
				<div class="col-12">
					<button
						class="btn btn-light"
						@click="Previous()"
					>
						<i class="far fa-chevron-left mr-3"></i> voltar
					</button>
				</div>
				<div v-show="step == 1"
					class="col-12 col-md-9"
				>
					<div class="col-12">
						<div class="mb-3 pr-6 pr-md-0 py-3 pb-md-0 text-md-center">
							<span>Organizações</span>
							<h4 class="font-24 font-md-32 m-0">Cadastrar organização</h4>
						</div>
					</div>
					<FormOrganization 
						ref="form_organization"
						:is_trial="is_trial"
						:organization_id="organization_id"
						@submit="UpdateForm"
						@next="WalkStep" />
				</div>
				<div v-show="step == 2"
					class="col-12 col-md-9"
				>
					<div class="col-12">
						<div class="mb-3 pr-6 pr-md-0 py-3 pb-md-0 text-md-center">
							<span>Perfil de pagamento</span>
							<h4 class="font-24 font-md-32 m-0">Cadastrar perfil de pagamento</h4>
						</div>
					</div>
					<Card 
						ref="credit_card"
						:prop_card="card"
						:edit="true"
						:modal_opened="true"
						@update="UpdateCard"
						class="my-5"
					/>
					<div class="d-flex justify-content-end p-1">
					<button 
						id="save-credit-card"
						class="btn btn-primary align-self-end mt-2 mb-0 mx-3"
						@click="WalkStep">
						próximo
					</button>
				</div>
				</div>
				<div v-show="step == 3"
					class="col-12"
				>
					<div class="col-12">
						<div class="mb-3 pr-6 pr-md-0 py-3 pb-md-0 text-md-center">
							<span>Assinatura</span>
							<h4 class="font-24 font-md-32 m-0">Informações da assinatura</h4>
						</div>
					</div>

					<div class="row">
						<div class="col-md-4">
							<p class="mb-4 font-18 text-uppercase font-weight-bold text-dark">Plano selecionado:</p>
							<div class="card-plan">
								<div class="card-plan-content">
									<div class="row">
										<div class="col-12">
											<h3 class="text-center text-uppercase">{{ selected_plan.name }}</h3>
										</div>
										<div class="col-12 text-center">
											<div class="d-flex flex-row justify-content-center flex-wrap">
												<div
													class="row col-12 flex-row justify-content-center">
													<div class="currency">R$</div>
													<div class="amount">
														{{ ParsePrice(selected_plan.total_price) }}
													</div>
													<div class="per-month">/mês</div>
												</div>
											</div>
											<hr class="my-4"/>
										</div>
										<div class="col-12 px-4">
											<p class="text-left">
												<i class="far fa-user text-primary mr-1"></i> {{ selected_plan.recurring_accesses > 5 ? 'permite' : 'somente' }} <b>{{ selected_plan.recurring_accesses }} usuário{{ selected_plan.recurring_accesses > 1 ? 's' : '' }} </b> por vez
											</p>
											<p class="text-left">
												<i class="fas fa-check text-success mr-1"></i> R$ <b>{{ price_per_user }}</b>/usuário
											</p>
											<p class="text-left"
												v-for="(benefit, index) in selected_plan.benefits" :key="index">
												<i class="fas mr-1"
													:class="benefit.have ? 'fa-check text-success' : 'fa-times text-danger mr-2'"></i> {{ benefit.text }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-8 pt-5">
							<div class="row">
								<div class="col-12">
									<p class="text-center font-20 mt-2">
										Você está contratando o plano <b>{{ selected_plan.name }}</b>.
									</p>
								</div>
								<div class="col-12">
									<p class="text-center font-20">
										Ao prosseguir, você estará concordando com os termos e condições abaixo descritos.
									</p>
								</div>
								<div class="col-12">
									<div class="border rounded-lg overflow-auto p-3 bg-light contract">
										{{ contract }}
									</div>
								</div>
								<div class="col-12">
									<div class="form-group mr-4 mb-0 form-check mt-4">
										<input 
											type="checkbox" 
											id="agree_contract"
											v-model="agree_contract"
										>
										<label for="agree_contract" class="font-18">
											<b>Li e concordo com os termos e condições apresentados.</b> 
										</label>	
									</div>
								</div>
								<div class="col-12 text-right">
									<button
										type="button"
										class="btn btn-primary px-5 mt-3"
										:disabled="!agree_contract"
										@click="WalkStep"
									>
										assinar plano
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const _ 		= require('lodash')
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	import FormOrganization from './components/Form'
	import Card from './components/Card'
	export default {

		name: 'New',
		components: {
			FormOrganization,
			Card
		},
		props:	{
			pre_plan:	{
				type:		Object,
				default:	() => {}
			}
		},
		data () {
			return {
				step:				1,
				contract:		`What is Lorem Ipsum?
								Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

								Why do we use it?
								It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).


								Where does it come from?
								Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.`,
				organization:		{
					account_type: 			1, // 1 - CPF OU 2 - CNPJ
					name: 					'',
					social_name: 			'',
					document_number:		'',
					state_registration: 	'',
					municipal_registration: '',
					address: 				'',
					number: 				'',
					district: 				'',
					complement: 			'',
					city: 					'',
					state: 					'',
					zipcode: 				'',
					email: 					'',
					phone_1: 				'',
					phone_2: 				''
				},
				card:				{
					number:				'',
					holder_name:		'',
					due_date:			'',
					cvv:				'',
					brand:		        null,
				},
				organization_id:	null,
				selected_plan:		{},
				checklist:			{
					save_organization: 	false,
					save_customer:		false,
					set_payment_profile:false,
					subscribe_pre_plan:	false,
					remove_pre_plan:	false
				},
				is_subscribed:		false,
				is_trial:			false,
				agree_contract:		false,
				states: window.statesBR
			}
		},
		computed: {
			...mapGetters('auth', [
				'account', 'requester_selected_id', 'all_roles'
			]),
			requester_in_roles()
			{
				return Object.keys(this.all_roles).indexOf(this.requester_selected_id) > -1
			},
			price_per_user()
			{
				if(this.selected_plan.total_price)
				{
					let val = this.selected_plan.total_price / this.selected_plan.recurring_accesses
	
					return this.ParsePrice(val.toFixed(2))
				}

				return 0
			},
		},
		watch: {
			pre_plan: {
				immediate: true,
				deep: true,
				handler(val)
				{
					if(val)
					{
						this.selected_plan = { ...val }
						if(val.id == process.env.VUE_APP_TRIAL_PLAN_ID)
						{
							this.is_trial = true
						}
					}
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'GetAuthAccount', 'SetRequesterAccount'
			]),
			async WalkStep(param)
			{
				const vm = this

				switch(vm.step)
				{
					case 1:
						if(!vm.pre_plan || !vm.pre_plan.id)
						{
							await vm.SaveOrganization()

							// if(await vm.$refs.form_organization.SaveCostumer(vm.organization))
							// {
							// 	vm.checklist.save_customer = true
							// }

							vm.RedirectPage()

							break
						}

						// if(vm.is_trial)
						// {
						// 	Swal.fire({
						// 		title:		'Atenção!',
						// 		html:		`Aplicar seu tempo de teste para a organização <br><b>${vm.organization.social_name || vm.organization.name}</b>?`,
						// 		icon:		'info',
						// 		showCancelButton: true,
						// 		confirmButtonText: 'Sim, aplicar',
						// 		cancelButtonText: 'Não, cancelar'
						// 	})
						// 		.then(async (result) => {
						// 			if(result.isConfirmed)
						// 			{
						// 				await vm.SaveOrganization()
			
						// 				if(await vm.$refs.form_organization.SaveCostumer(vm.organization))
						// 				{
						// 					vm.checklist.save_customer = true
						// 				}
			
						// 				if(!await vm.SubscribePrePlan()) return
			
						// 				if(vm.checklist.subscribe_pre_plan)
						// 				{
						// 					if(!await vm.RemoveFromPrePlans()) return
						// 				}
			
						// 				vm.RedirectPage()
			
						// 				return
						// 			}
						// 		})
							
						// 	break
						// }
						// vm.step = 2
						break
					case 2:
						vm.$refs.credit_card.Update()

						vm.step = 3
						break
					case 3:
						await vm.SaveOrganization()

						// await vm.$refs.form_organization.SaveCostumer(vm.organization)

						// if(vm.pre_plan.id)
						// {
						// 	if(!vm.is_trial)
						// 	{
						// 		if(!await vm.SavePaymentProfile()) break
						// 	}

						// 	if(!await vm.SubscribePrePlan()) break

						// 	if(vm.checklist.subscribe_pre_plan)
						// 	{
						// 		if(!await vm.RemoveFromPrePlans()) break
						// 	}

						// 	vm.RedirectPage()
						// }

						break
					default:
						break
				}
			},
			UpdateForm(organization)
			{
				this.organization = { ...organization }
			},
			UpdateCard(val)
			{
				this.card = val.card
			},
			async SaveOrganization()
			{
				const vm = this

				vm.StartLoading()

				let response = false
				
				await window.api.call('post', '/save-organization', {
					...vm.organization,
					is_manager: true
				})
					.then(async ({data}) => {

						if(data.response) 
						{
							vm.checklist.save_organization = data.response

							vm.organization_id = data.account.id

							if(vm.pre_plan && vm.pre_plan.id) {
								Swal.fire({
									title: 			'Pronto!',
									text: 			'Organização cadastrada, agora você pode convidar novos usuários.',
									icon:			'success'
								})
							} else {
								Swal.fire({
									title: 			'Pronto!',
									text: 			'Agora você pode convidar novos usuários.',
									icon:			'success'
								})
							}
						} else {
							Swal.fire({
								title: 			'Ops...',
								text: 			'Erro ao cadastrar a organização, contate o suporte...',
								icon:			'error'
							})
						}

						response = data.response
					})
					.finally(() => {
						vm.FinishLoading()
					})
				
				return response
			},
			async SavePaymentProfile()
			{
				const vm = this

				vm.StartLoading()

				const payment_method = { 
					payment_method_code: 	'credit_card',
					holder_name: 			vm.card.holder_name,
					card_number: 			vm.card.number,
					card_cvv: 				vm.card.cvv,
					card_expiration:		vm.card.due_date,
					payment_company_code: 	vm.card.brand,
				}

				let response = false

				await window.api.call('post', '/gateway/set-payment-profile', {
					organization_id: vm.organization_id,
					payment_method
				})
					.then(({data}) => {
						if(data.response)
						{
							vm.checklist.set_payment_profile = data.response
						} else {
							Swal.fire({
								text:	'Houve um erro ao cadastrar o perfil de pagamento, verifique se o formato do cartão é válido.',
								title:	'Eita!',
								icon:	'error',
							})
						}

						response = data.response
					})
					.finally(() => {
						vm.FinishLoading()
					})

				return response
			},
			async SubscribePrePlan()
			{
				const vm = this

				let response = false

				await window.api.call('post', '/gateway/subscribe', {
					organization_id:	vm.organization_id,
					plan_id:			vm.pre_plan.id,
					coupon_code:		null,
					ignore_max_subscriptions: !vm.is_trial
				})
					.then(({data}) => {
						if(data.response)
						{
							vm.checklist.subscribe_pre_plan = data.response
						} else {
							Swal.fire({
								text:		'Não foi possível assinar o plano selecionado no inicio do cadastro, contate o suporte.',
								icon:		'error',
								title:		'Eita!'
							})
						}

						response = data.response
					})
				
				return response
			},
			async RemoveFromPrePlans()
			{
				const vm = this

				let response = false

				await window.api.call('post', '/remove-from-pre-plans', {
					account_id: vm.account.id
				})
					.then(({data}) => {
						if(data.response)
						{
							vm.checklist.remove_pre_plan = data.response
						} else {
							Swal.fire({
								text:		'Ocorreu um erro ao remover da pré-plans!',
								icon:		'error',
								title:		'Eita!'
							})
						}

						response = data.response
					})

				return response
			},
			ParsePrice(price)
			{
				return `${parseFloat(price).toFixed(2)}`.replace('.', ',')
			},
			async RedirectPage()
			{
				const vm = this

				await vm.GetAuthAccount()

				if(!vm.requester_selected_id || !vm.requester_in_roles)
				{
					vm.SetRequesterAccount({
						account_requester_id: vm.organization_id
					})
				}

				vm.$router.push({ name: 'OrganizationPanel', params: {
						organization_id: 	vm.organization_id
					}
				})
			},
			Previous()
			{
				const vm = this
				switch(vm.step)
				{
					case 1:
						this.$router.push({ name: 'Organizations' })
						break
					default:
						vm.step -= 1
						break
				}
			}
		},
		beforeMount() {
		}
	}
</script>

<style lang="css" scoped>
</style>