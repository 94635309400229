export default {
	requestLoading:			0,
	theme: 					localStorage.getItem('theme') ?? 'light',
	icon_size: 				localStorage.getItem('icon_size') ?? 'md',
	carousel_orientation: 	localStorage.getItem('carousel_orientation') ?? 'scroll',
	menu_order:				JSON.parse(localStorage.getItem('menu_order') ?? '[]'),
	modules: 				[],
	modalities: 			[],
	dicom_modalities:		[],
	last_modality_id:		null
}