<template>
	<div class="container">
		<template v-if="type == 'create'">
			<div class="row align-items-center justify-content-center flex-column">
				<div class="col-12">
					<div class="d-block mx-auto pb-4 pb-md-5 text-md-center max-500 pr-6 pr-md-0">
						<span> Criar agenda </span>
						<h4 class="font-24 font-md-32 m-0">Dados básicos</h4>
					</div>
				</div>
			</div>
		</template>
		<div class="row justify-content-center">
			<div class="col-md-9">
				<div class="row justify-content-start">
					<div class="col-md-6">
						<div class="form-group">
							<label>Nome</label>
							<input 
								type="text"
								v-model="$v.selected.name.$model" 
								class="form-control" 
								:class="{ 'is-invalid': $v.selected.name.$error }"
								placeholder="Exemplo: 'Agenda Dr. Hélio SEG/SEX'"
								ref="name"
								@keyup.enter="Next"
							>
							<div class="invalid-feedback">
								Obrigatório
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label>Comparecimento</label>
							<input 
								type="number"
								v-model="selected.time_attendance" 
								class="form-control"
								min="0"
							>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label>Tolerância</label>
							<input 
								type="number" 
								v-model="selected.tolerance" 
								class="form-control"
								min="0"
							>
						</div>
					</div>
					<div class="col-12">
						<div class="form-group">
							<label>Texto do Painel</label>
							<textarea 
								v-model="selected.panel_text" 
								class="form-control"
							>								
							</textarea>
						</div>								
					</div>
					<div v-if="1 === 2" class="col-12 col-md-6">
						<div class="form-group form-check mt-3 mt-md-4">
							<input
								type="checkbox"
								id="auto_scheduling"
								v-model="selected.auto_scheduling"
							>
							<label for="auto_scheduling">Permitir auto agendamento?</label>
						</div>
						<p class="opacity-75 font-14">
							O paciente poderá ver esta agenda e criar um agendamento online
						</p>
                    </div>
					<div class="col-12"
						:class="type === 'create' ? 'col-md-6' : ''">
						<div class="form-row flex-nowrap align-items-center mt-3 pt-md-3 mt-md-2"
							:class="{
								'disabled': type !== 'create'
							}">
							<div class="mr-2 col-auto">
								<div
									class="font-32"
									:class="{
										'text-primary':         selected.is_mobilemed,
										'text-secondary':       !selected.is_mobilemed
									}"
									@click="ToggleMobileMedIntegration"
								>
									<i
										class="fad"
										:class="{
											'fa-toggle-on':     selected.is_mobilemed,
											'fa-toggle-off':    !selected.is_mobilemed
										}"
									></i>
								</div>
							</div>
							<label
								class="mb-0 mobilemed_label"
								for="is_mobilemed"
								@click="ToggleMobileMedIntegration"
							>
								Integração com MobileMed
							</label>
						</div>
					</div>
					<div class="col-12 col-md-6" v-if="type == 'create'">
						<div class="form-group form-check mt-3 mt-md-4">
							<input
								type="checkbox"
								id="continuous_schedule"
								v-model="selected.continuous_schedule"
							>
							<label for="continuous_schedule">Agenda do tipo Pronto Atendimento?</label>
						</div>
						<p class="opacity-75 font-14">
							Funciona como uma pilha, sem hora marcada, sem blocos, ordenada pela classificação de risco.
						</p>
					</div>
					<div v-if="type == 'create'" 
						class="col-12 px-0 d-flex justify-content-between">
						<div class="col-12 col-md-auto mt-3">
							<button
								class="btn btn-light px-5"
								type="button"
								@click="Previous"
							>
								voltar
							</button>
						</div>
						<div class="col-12 col-md-auto mt-3">
							<button
								class="btn btn-primary px-5"
								@click="Next"
							>
								continuar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const _ 	= require('lodash')

	import { required } from 'vuelidate/lib/validators'
	export default {

		name: 'BasicData',
		props: 		{
			form: 		{
				type: 		Object,
				default: 	null
			},
			type: 		{
				type: 		String,
				default: 	'create', //config
			},
			is_started:	{
				type:		Boolean,
				default:	true
			}
		},
		data () {
			return {
				selected: 		{
					name: 					null,
					tolerance: 				0,
					time_attendance:		0,
					panel_text: 			null,
					auto_scheduling: 		false,
					continuous_schedule: 	false,
                    is_mobilemed: false
				}
			}
		},
		validations: {
			selected: 		{
				name: 		{
					required
				}
			}
		},
		watch: {
			form: {
				handler(val) {
					if(!_.isEmpty(val)) {
						this.selected.name 					= val.name
						this.selected.tolerance 			= val.tolerance
						this.selected.time_attendance		= val.time_attendance ?? 0
						this.selected.panel_text 			= val.panel_text
						this.selected.auto_scheduling 		= val.auto_scheduling
						this.selected.continuous_schedule 	= val.continuous_schedule
						this.selected.is_mobilemed 			= val.is_mobilemed
					}
				},
				deep: 		true,
				immediate:  true
			},
			selected: {
				handler(val)
				{
					if(!this.is_started)
					{
						this.$emit('append', {
							type: 'form',
							form: val
						})
					}
				},
				deep:	true
			}
		},
		methods: {
			Next()
			{
				this.$v.selected.$touch()

				if(!this.$v.selected.$invalid)
				{
					this.$emit('next', this.selected)

					return true
				} else {
					window.helpers.focusInvalid(this.$v.selected, this)
				}

				return false
			},
			ToggleMobileMedIntegration() {
				if(this.type !== 'create') return

				this.selected.is_mobilemed = !this.selected.is_mobilemed
			},
			Previous()
			{
				this.$emit('previous')
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.$refs.name.focus()
			})
		}
	}
</script>

<style lang="css" scoped>
.disabled {
	opacity: .6;
}

.disabled i {
	opacity: .7;
}

</style>