<template>
	<div class="container-large pb-4 py-md-0">
		<div class="row justify-content-between align-items-end">
			<div class="col-auto">
				<div class="mb-3 max-500 pr-6 pb-3 pb-md-0">
					<span>Pesquisar</span>
					<h4 class="font-24 font-md-32 m-0">Pesquisar agendamentos</h4>
				</div>
			</div>
			<div class="col-12 max-500">
				<div class="row align-items-center pb-2">
					<div class="col-12 col-md mb-3 mb-md-0">
						<div class="form-group m-0 position-relative">
							<input 
								v-debounce:500="DelayGetSchedulings"
								type="search" 
								class="form-control pl-5" 
								placeholder="Buscar..."
								v-model="search"
							>
							<i class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr class="my-4">
		<div class="row mb-4 mx--1">
			<div class="col-4 col-md-auto px-1">
				<div class="dropdown mb-2 mb-md-0"
					id="dropdown-date">
					<button 
						class="btn btn-block btn-light font-14 font-md-16"
						data-toggle="dropdown"
					>
						<i class="far fa-chevron-down font-18 mr-3"></i>
						data
					</button>
					<div class="dropdown-menu px-3 py-0">
						<form>
							<div class="form-group my-3">
								<label class="font-14 mb-1">do dia</label>
								<input 
									type="date" 
									class="form-control p-3"
									v-model="filters.date_init"
								>
							</div>
							<div class="form-group my-3">
								<label class="font-14 mb-1">até o dia</label>
								<input 
									type="date" 
									class="form-control p-3" 
									v-model="filters.date_end"
								>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="col-4 col-md-auto px-1">
				<div class="dropdown mb-2 mb-md-0"
					id="dropdown-status">
					<button 
						class="btn btn-block btn-light font-14 font-md-16"
						data-toggle="dropdown"
					>
						<i class="far fa-chevron-down font-18 mr-3"></i>
						status
					</button>
					<div class="dropdown-menu px-3 py-0">
						<form>
							<div 
								v-for="(status, index) in scheduling_status"
								:key="index"
								class="form-check my-3"
							>
								<input 
									type="checkbox"
									:value="status.value"
									:id="status.name"
									v-model="filters.status"
								>
								<label :for="status.name">
									{{ status.name }}
								</label>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="col-4 col-md-auto px-1">
				<button 
					class="btn btn-block btn-primary font-14 font-md-16"
					@click="GetSchedulings()"
				>
					<i class="far fa-search font-14 mr-3"></i> 
					Buscar
				</button>
			</div>
		</div>
		<DataTables
			v-model="schedulings"
			type="search"
			max_height="700px"
			:offset="offset"
			:headers="headers"
			:tr-button="true"
			:scrollUpdate="scrollUpdate"
            :prop_sortedBy="sortedBy"
			@load-table="UpdateTable"
			@by-click-on-tr="ShowTimeline"
		>
			<template v-slot:body="{ item }">
				<td scope="col">
					{{ item.data }}
				</td>
				<td scope="col">
					{{ item.hora }}
				</td>
				<td scope="col">
					<b>{{ item.journey.patient.nome_paciente }}</b>
				</td>
				<td scope="col">
					{{ `${item.journey.patient.doc_id}` }}
				</td>
				<td scope="col">
					<b>{{ item.status_descricao.toUpperCase() }}</b>
				</td>
			</template>
		</DataTables>

		<SchedulingModal
			:timeline_data="timeline_data"
		/>
	</div>
</template>

<script>
	const _ 		= require('lodash')
	const moment 	= require('moment')

	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'

	import SchedulingModal from '../shared/SchedulingModal'
	import SchedulingCard from './SchedulingCard'
import { forEach } from 'lodash'
	export default {

		name: 'Index',
		components: 	{
			SchedulingCard,
			SchedulingModal
		},
		data () {
			return {
				starting: 		true,
				filters: 		{
					date_init: 		'',
					date_end: 		'',
					date_edit:		false,
					status: 		[],
					status_edit:	false,
				},
				limit:			200,
				offset:			0,
				search: 		'',
				scrollUpdate:	true,
				schedulings: 	[],
				headers:    	[
                  	{ text: 'Data', value: 'data', sortable: true, },
                  	{ text: 'Hora', value: 'hora', sortable: true, },
          			{ text: 'Paciente', value: 'patient.nome_paciente' },
                  	{ text: 'ID/Número', value: 'patient.doc_id.numero' },
          			{ text: 'Status', value: 'status_descricao' }
        		],
        		timeline_data: 	{
        			patient:      	{},
		          	logs:         	[],
		          	journey_id:     null,
		          	scheduling_id:  null
        		},
				sortedBy:		{
					column: 		'data',
					direction:		'DESC',
					icon:			'fa-chevron-down'
				},
				last_status:	[],
				try_search:			false,
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			...mapGetters('system', [
				'requests'
			]),
			scheduling_status()
            {
                return [
                    { name: "AGENDADO", value: 1 },
					{ name: "CONFIRMADO", value: 2 },
					{ name: "ESPERA", value: 3 },
					{ name: "CHAMADO", value: 4 },
					{ name: "ATENDIMENTO", value: 5 },
					{ name: "PENDENTE", value: 6 },
					{ name: "ATENDIDO", value: 7 },
					{ name: "FINALIZADO", value: 8 },
					{ name: "ENTREGUE", value: 9 },
					{ name: "ENTREGUE2", value: 10 },
					{ name: "FALTA", value: 11 },
					{ name: "CANCELADO_DOC", value: 12 },
					{ name: "CANCELADO_AGD", value: 13 },
					{ name: "CANCELADO_ATD", value: 14 },
					{ name: "RETORNO", value: 15 },
					{ name: "AGUARDANDO_ENCAIXE", value: 16 },
					{ name: "CANCELADO_ECX", value: 17 }
                ]
            },
			first_day_of_month() {
				return moment().startOf('month').format('YYYY-MM-DD')
			},
			last_day_of_month() {
				return moment().endOf('month').format('YYYY-MM-DD')
			}
		},
		watch: {
			'filters.date_init': {
        		handler(val) {
          			const vm        = this
              
					let message     = ''
					let valid       = true

					let date_init   = val.split('-')
					let date_end    = vm.filters.date_end.split('-')

              		if(new Date(date_end[0], date_end[1], date_end[2]) < new Date(date_init[0], date_init[1], date_init[2])) {
						Swal.fire({
							title: 'A data de início não pode ser superior a data final.',
							icon: 'error',
							toast: true,
							position: 'top',
							timer: 4000,
							timerProgressBar: true
						})

                		vm.filters.date_end     = val
              		}
            	}
      		},
			'filters.date_end': {
        		handler(val) {
          			const vm        = this

					let date_end   	 = val.split('-')
					let date_init    = vm.filters.date_init.split('-')

              		if(new Date(date_end[0], date_end[1], date_end[2]) < new Date(date_init[0], date_init[1], date_init[2])) {
						Swal.fire({
							title: 'A data final não pode ser inferior a data de início.',
							icon: 'error',
							toast: true,
							position: 'top',
							timer: 4000,
							timerProgressBar: true
						})

                		vm.filters.date_end     = vm.filters.date_init
              		}
            	}
      		},
			search: {
				handler(val)
				{
					this.try_search = false
				}
			}
      	},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			ShowTimeline(scheduling)
			{
		        this.timeline_data       = {
		          	patient:      	{},
		          	logs:         	[],
		          	journey_id:     null,
		          	scheduling_id:  null
		        }

				if(scheduling.journey.id) {
					// this.timeline_data.logs      		= scheduling.logs
					this.timeline_data.patient     		= scheduling.journey.patient
					this.timeline_data.journey_id  		= scheduling.journey.id
					this.timeline_data.scheduling_id 	= scheduling.id

					window.jQuery('#modal-timeline').modal('show')

					return;
				} else {
					window.helpers.errorMessageTop(
						'Não há registros para esse agendamento',
						5000
					)
				}

        		window.jQuery('#modal-timeline').modal('hide')
			},
	      	async GetSchedulings(clear = true)
			{
				const vm = this

				vm.offset = clear ? 0 : vm.offset

				vm.schedulings = clear ? [] : vm.schedulings

				vm.StartLoading()

				return await window.api.call('post','/get-journeys-schedulings',{
				    	account_id:   	vm.requester_selected_id,
				    	date_init:    	vm.filters.date_init,
				    	date_end:     	vm.filters.date_end,
				    	status:     	vm.filters.status,
						search:			vm.search,
						limit:			vm.limit,
						offset:			vm.offset
			  		})
			  		.then(({data}) => {
			    		if(data.response)
			    		{
							let arr = vm.schedulings

							let temp_arr = []

							for (const journey of Object.keys(data.journeys)) {
								const schedulings = data.journeys[`${journey}`]

								temp_arr.push(schedulings[0])
							}

							temp_arr = temp_arr.reverse()

							arr.push(...temp_arr)

							vm.schedulings = arr.map((s, i) => {
								s.journey.patient.doc_id = s.journey.patient.doc_id.tipo ? `${s.journey.patient.doc_id.tipo} ${s.journey.patient.doc_id.numero}` : `${s.journey.patient.doc_id}`

								return s
							})

			      			// vm.schedulings    = _
							//   	.chain(vm.schedulings)
						    //     .orderBy((scheduling) => {
							// 		const data = scheduling.last_log_date || scheduling.data
							// 		const hora = scheduling.last_log_time || scheduling.hora
							// 		const m     = moment(`${data} ${hora}`, 'DD/MM/YYYY HH:mm')
						    //       	return m.toDate()
						    //     }, 'desc')
						    //     .value()
			    		}else{

					      	Swal.fire({ 
					        	title: 'Ops...',
					        	text: 'Houve um erro ao obter, contate o suporte.',
					        	icon: 'error'
					      	})
			    		}
			  		})
			  		.finally(() => {
						this.starting = false

			    		vm.FinishLoading()
			  		})
			},
			async DelayGetSchedulings() {
				const vm = this

				setTimeout(async () => {
					if(!vm.try_search) {
						await vm.GetSchedulings()
						
						vm.try_search = true
					}
				}, 1000)
			},
			async GetSchedulingsWithSearch()
			{
				const vm = this

				vm.StartLoading()

				return await window.api.call('post','/get-schedulings',{
				    	account_id:   	vm.requester_selected_id,
				    	date_init:    	vm.filters.date_init,
				    	date_end:     	vm.filters.date_end,
				    	status:     	vm.filters.status,
						search:			vm.search
			  		})
			  		.then(({data}) => {
			    		if(data.response)
			    		{
			      			vm.schedulings    = _
							  	.chain(data.schedulings)
						        .orderBy((scheduling) => {
									const data = scheduling.data
									const hora = scheduling.hora
									const m     = moment(`${data} ${hora}`, 'DD/MM/YYYY HH:mm')
						          	return m.toDate()
						        }, 'desc')
						        .value()
			    		}else{

					      	Swal.fire({ 
					        	title: 'Ops...',
					        	text: 'Houve um erro ao obter, contate o suporte.',
					        	icon: 'error'
					      	})
			    		}
			  		})
			  		.finally(() => {
			    		vm.FinishLoading()
			  		})
			},
	     	FormatDate(value, fmt)
			{
				const m   = moment(value, 'YYYY-MM-DD HH:mm:ss')

				return m.format(fmt)
			},
			async UpdateTable(param)
			{
				const vm = this

				// Swal.fire({
				// 	text: 				"Não há mais agendamentos",
				// 	toast: 				true,
				// 	position: 			'top',
				// 	title:				' ',
				// 	icon:				'info',
				// 	timer:				4000,
				// 	timerProgressBar:	true
				// })

				vm.offset = param.offset

				await vm.GetSchedulings(false)
			},
		},
		async beforeMount()
		{
			// const year 		= new Date().toISOString().substr(0, 4)
			// const newYear 	= (parseInt(year) + 25).toString()
			// this.filters.date_end = new Date().toISOString().substr(0, 10).replace(year, newYear)

			await this.GetSchedulings()
		},
		mounted()
		{

		}
	}
</script>

<style lang="css" scoped>
.overflow-search-table
	{
		overflow: hidden auto;
		max-height: calc(100vh - 100px);
	}
</style>