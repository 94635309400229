<template>
	<div class="container pb-4">
		<div class="row justify-content-center">

			<div class="col-12">
				<div class="d-block mx-auto pb-4 pb-md-5 text-md-center max-500 pr-6 pr-md-0">
					<span>Convênio</span>
					<h4 class="font-24 font-md-32 m-0">
						{{ partnership_id ? 'Editar' : 'Criar' }} convênio
					</h4>
				</div>
			</div>
			<div class="col-md-9">
				<form @submit.prevent="Save">
					<div class="row">
						<div class="col-12">
							<div class="form-group">
								<label>Nome</label>
								<input
									type="text"
									class="form-control"
									:class="{ 'is-invalid': $v.form.nome.$error}"
									v-model="$v.form.nome.$model"
									ref="nome"
								>
								<div class="invalid-feedback">
									Obrigatório
								</div>
							</div>
						</div>
						<div class="col-12 col-md-8">
							<div class="form-group">
								<label>Razão Social</label>
								<input
									type="text"
									class="form-control"
									v-model="form.razao_social"
								>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>CNPJ</label>
								<input
									type="tel"
									class="form-control"
									v-model="$v.form.cnpj.$model"
									v-mask="['##.###.###/####-##']"
									ref="cnpj"
								>
						        <div class="invalid-feedback">
						          	CNPJ Invalido
						        </div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-3">
							<div class="form-group">
								<label>CEP</label>
								<input
									type="tel"
									class="form-control"
									v-model="form.cep"
									v-mask="['#####-###']"
									v-on:keyup.enter="SearchZipCode()"
						    		@blur="SearchZipCode()"
								>
							</div>
						</div>
						<div class="col-12 col-md-6 col-lg-7">
							<div class="form-group">
								<label>Logradouro</label>
								<input
									type="text"
									class="form-control"
									v-model="form.logradouro"
								>
							</div>
						</div>
						<div class="col-12 col-md-3 col-lg-2">
							<div class="form-group">
								<label>Numero</label>
								<input
									type="number"
									class="form-control"
									v-model="form.numero"
								>
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div class="form-group">
								<label>Complemento</label>
								<input
									type="text"
									class="form-control"
									v-model="form.complemento"
								>
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div class="form-group">
								<label>Bairro</label>
								<input
									type="text"
									class="form-control"
									v-model="form.bairro"
								>
							</div>
						</div>
						<div class="col-12 col-md-8">
							<div class="form-group">
								<label>Município</label>
								<input
									type="text"
									class="form-control"
									v-model="form.municipio"
								>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="form-group">
								<label>Estado</label>
								<select
									type="text"
									class="form-control"
									v-model="form.estado"
								>
									<option value="">Selecione...</option>
									<option 
										v-for="state in states"
										:key="state.initials"
										:value="state.initials"
									>
										{{state.name}}
									</option>
								</select>
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div class="form-group">
								<label>Telefone</label>
								<input
									type="tel"
									class="form-control"
									v-model="form.telefone"
									v-mask="['(##) ####-####', '(##) #####-####']"
								>
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div class="form-group form-check mt-3 mt-md-4">
								<input 
									type="checkbox" 
									id="status"
									v-model="form.ativo"
								>
								<label for="status">Ativo</label>
							</div>					
							<p class="opacity-75 font-14">
								Definir se este convênio está ativo
							</p>			
						</div>
						<div class="col-12 col-md-6">
							<div class="form-group form-check mt-3 mt-md-4">
								<input 
									type="checkbox" 
									id="authorization"
									v-model="form.exibe_autorizacao"
								>
								<label for="authorization">Autorização</label>
							</div>					
							<p class="opacity-75 font-14">
								Definir se este convênio necessita de autorização.
							</p>			
						</div>
						<div class="col-12 col-md-6">
							<div class="form-group form-check mt-3 mt-md-4">
								<input 
									type="checkbox" 
									id="guide"
									v-model="form.exibe_guia"
								>
								<label for="guide">Guia</label>
							</div>					
							<p class="opacity-75 font-14">
								Definir se este convênio necessita de guia.
							</p>			
						</div>
						<div class="col-12">
							<div class="form-group">
								<label>Observações</label>
								<textarea 
									class="form-control"
									v-model="form.observacao"
									rows="3"
								></textarea>
							</div>
						</div>
						<div class="col-12 text-right mt-4">
							<div class="d-flex justify-content-between">
								<router-link
									:to="{ name: 'Partnerships' }"
									class="btn btn-light px-5"
								>
									voltar
								</router-link>
								<button
									type="submit"
									class="btn btn-primary px-5"
								>
									salvar
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>

	import Helpers from '../../helpers'
	const helpers 		= new Helpers()
	const _ 			= require('lodash')

	import { required, minLength, sameAs, requiredIf } from 'vuelidate/lib/validators'
	const ValidateDocument 	= (value, vm) => {
	
		if(value)
		{
			switch(vm.account_type)
			{
				case 2:
					return helpers.ValidaCNPJ(value)
				
				case 1:
					return helpers.ValidaCPF(value)
				
			}
		}

		return true
	}

	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Form',
		props: {
			partnership_id: {
				type: 		[String, Number],
				default: 	null
			}
		},
		data () {
			return {
				form: 		{
					nome: 				'',
					razao_social: 		'',
					cnpj:				'',
					logradouro: 		'',
					numero: 			'',
					bairro: 			'',
					complemento: 		'',
					municipio: 			'',
					estado: 			'',
					cep: 				'',
					telefone: 			'',
					ativo: 				true,
					observacao: 		'',
					exibe_autorizacao: 	false,
					exibe_guia: 		false
				},
				states: 	window.statesBR
			}
		},
		validations: {
			form: 		{
				nome: 					{
					required
				},
				cnpj:		{
					ValidateDocument
				}
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'requester_selected_id'
			])
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetPartnership()
			{	
				const vm 		= this

				vm.StartLoading()

				return window.api.call('post', '/get-partnership', {
						account_id: 		vm.requester_selected_id,
						partnership_id: 	vm.partnership_id
					})
					.then(({data}) => {
						if(data.response)
						{
							vm.form.nome 				= data.partnership.nome
							vm.form.razao_social	 	= data.partnership.razao_social
							vm.form.cnpj				= data.partnership.cnpj
							vm.form.logradouro	 		= data.partnership.logradouro
							vm.form.numero	 			= data.partnership.numero
							vm.form.bairro	 			= data.partnership.bairro
							vm.form.complemento	 		= data.partnership.complemento
							vm.form.municipio	 		= data.partnership.municipio
							vm.form.estado	 			= data.partnership.estado
							vm.form.cep	 				= data.partnership.cep
							vm.form.telefone	 		= data.partnership.telefone
							vm.form.ativo	 			= data.partnership.ativo
							vm.form.observacao	 		= data.partnership.observacao
							vm.form.exibe_autorizacao	= data.partnership.exibe_autorizacao
							vm.form.exibe_guia	 		= data.partnership.exibe_guia

						}else{

							Swal.fire({
								icon: 	'error',
								title: 	'Opss...',
								text: 	'Convênio não encontrado, contate o suporte.'
							})
							.then(() => {
								vm.$router.back()
							})
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			Save(e)
			{
				e.preventDefault()

				this.$v.form.$touch()

				if(!this.$v.form.$invalid)
				{
					this.StartLoading()

					let obj 		= {
						...this.form,
						account_id: 	this.requester_selected_id
					}

					if(this.partnership_id)
					{
						obj 		= {
							...obj,
							partnership_id: 	this.partnership_id
						}
					}

					window.api.call('post', '/save-partnership', obj)
						.then(({data}) => {
							if(data.response)
							{
								Swal.fire({
			  						toast: 		true,
			  						position: 	'top',
			  						timer: 		3000,
									title: 		'Convênio salvo com sucesso.',
									icon: 		'success'
								})

								this.$router.push({ name: 'Partnerships' })

							}else{

								Swal.fire({
			  						title: 	'Eita !',
									text: 	'Houve um erro ao salvar, contate o suporte.',
									icon: 	'error'
								})
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				} else {
					window.helpers.focusInvalid(this.$v.form, this)
				}
			},
			async SearchZipCode()
			{
				const vm 		= this
				const zipcode 	= vm.form.cep

				if(zipcode.length == 9)
				{
					vm.StartLoading()

					helpers.searchZipCode(zipcode)
						.then(( res ) => {

							vm.form.logradouro  	= res.address
							vm.form.bairro     		= res.district
							vm.form.complemento 	= res.complement
							vm.form.municipio   	= res.city
							vm.form.estado			= res.state
						})
						.finally(() => {
							vm.FinishLoading()
						})
				}
			}
		},
		beforeMount()
		{
			if(this.partnership_id)
			{
				this.GetPartnership()
			}
		}
	}
</script>

<style lang="css" scoped>
</style>