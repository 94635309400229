<template>
    <div class="col-12 d-flex flex-column align-items-center"
        v-if="modal_opened">  
        <div class="card">
            <div class="card-content">
                <img class="logo" src="https://homolog.instantmedical.com.br/@/assets/img/1-2-logo-vertical-shadow.c9e8f8d0.png">
                <p class="number my-1">{{ card.number ? card.number : '0000 0000 0000 0000' }}</p>
                <div class="expiration-and-cvv">
                    <div class="d-flex align-items-center">
                        <small class="mr-2">
                            venc.
                        </small>
                        {{ card.due_date ? card.due_date : current_date }}
                    </div>
                    <div>
                        <small class="mr-2">
                            cvv
                        </small>
                        {{ card.cvv ? card.cvv : '555' }}
                    </div>
                </div>
                <p class="holder">{{ card.holder_name ? card.holder_name : 'NOME DO TITULAR DO CARTÃO' }}</p>
                <div class="brand">
                    <img 
                        v-if="brand_logo"
                        :src="brand_logo">
                </div>
            </div>
        </div>
        
        <div class="form col-12 d-flex flex-column mt-3">
            <div class="row">
                <div class="form-group m-1 d-flex align-items-center col-12">
                    <span class="text-dark col-5">
                        Número do cartão:
                    </span>
                    <input 
                        type="tel"
                        class="form-control col-7"
                        :class="{ 'is-invalid': $v.card.number.$error }"
                        v-model="$v.card.number.$model"
                        maxlength="23"
                        placeholder="0000 0000 0000 0000"
                        v-mask="edit ? ['#### ###### ####', '#### #### #### ####'] : [`${card.number}`]"
                        :disabled="!edit"
                    >
                </div>
            </div>
            <div class="row">
                <div class="form-group m-1 d-flex align-items-center col-12"
                    v-if="no_brand_card">
                    <span class="text-dark col-5">
                        Bandeira:
                    </span>
                    <select 
                        class="form-control col-7"
                        v-model="card_brand_id">
                        <option v-for="(card, index) in all_cards" :key="index" :value="card.id">
                            {{ card.pretty }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="form-group m-1 d-flex align-items-center col-12">
                    <span class="text-dark col-5">
                        Nome do titular:
                    </span>
                    <input 
                        type="text"
                        class="form-control col-7"
                        :class="{ 'is-invalid': $v.card.holder_name.$error }"
                        v-model="$v.card.holder_name.$model"
                        id="holder_name"
                        maxlength="100"
                        placeholder="Nome do titular do cartão"
                        :disabled="!edit"
                    >
                </div>
            </div>
            <div class="row">
                <div class="form-group m-1 d-flex align-items-center col-12">
                    <span class="text-dark col-5">
                        Data de vencimento:
                    </span>
                    <input 
                        type="tel"
                        class="form-control col-4"
                        :class="{ 'is-invalid': $v.card.due_date.$error }"
                        v-model="$v.card.due_date.$model"
                        v-mask="['##/####']"
                        :placeholder="current_date"
                        :disabled="!edit"
                    >
                </div>
            </div>
            <div class="row">
                <div class="form-group m-1 d-flex align-items-center col-12">
                    <span class="text-dark col-5">
                        Código verificador:
                    </span>
                    <input 
                        type="tel"
                        class="form-control col-3"
                        :class="{ 'is-invalid': $v.card.cvv.$error }"
                        v-model="$v.card.cvv.$model"
                        v-mask="['####']"
                        placeholder="***"
                        :disabled="!edit"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import creditCardType, {
    getTypeInfo,
    type as CardType
} from 'credit-card-type'

import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
    name: 'Card',
    props:  {
        prop_card:       {
            type:       Object,
            default:    () => {}
        },
        edit:           {
            type:       Boolean,
            default:    false
        },
        modal_opened:   {
            type:       Boolean,
            default:    false
        },
        mode:           {
            type:       String,
            default:    'new'
        }
    },
    data() {
        return {
            card:			{
                number:				'',
                holder_name:		'',
                due_date:			'',
                cvv:				'',
                brand:		        null,
                brand_id:		    null,
            },
            card_brand_id:          null,
            no_brand_card:			false,
            all_cards:              []
        }
    },
    validations: {
        card: {
            number:         {
                required
            },
            holder_name:    {
                required
            },
            due_date:       {
                required
            },
            cvv:            {
                required
            }
        },

    },
    computed: {
        current_date()
        {
            let date = new Date().toISOString().substr(0, 10).split('-')

            date.pop()

            return date.reverse().join('/')
        },
        brand()
        {
            if(this.card.number)
            {
                return creditCardType(this.card.number).filter((card) => card.type)[0]
            }
            return false
        },
        brand_logo()
        {
            if(this.card_brand_id)
            {
                const item = this.all_cards.filter((e) => e.id === this.card_brand_id)[0]

                if(item)
                {
                    this.card.brand = item.name
                    const finalBrand = require(`@/assets/svg/brands/${item.svg}.svg`)
                    return finalBrand
                }
            }
            if(this.brand)
            {
                const item = this.all_cards.filter((e) => e.name === this.brand.type)[0]

                if(item)
                {
                    const finalBrand = require(`@/assets/svg/brands/${item.svg}.svg`)
                    return finalBrand
                }
            }

            return false
        }
    },
    watch: {
        prop_card:  {
            handler(val)
            {
                this.card           = val
            },
            immediate: true,
            deep:   true
        },
        'card.number': {
            handler(val)
            {
                if(val && val.length >= 20 && (!this.brand))
				{
					this.no_brand_card      = true
                    this.card_brand_id      = this.all_cards[0].id
				}
            }
        },
        edit:   {
            handler(val)
            {
                if(val)
                {
                    this.ClearForm()
                }
            },
            immediate: true
        }
    },
    methods: {
        GetIcon(svg)
        {
            return require(`@/assets/svg/brands/${svg}.svg`)
        },
        Preencher()
        {
            this.card = {
                number:				'4929145634524261',
                holder_name:		'danilo teste',
                due_date:			'08/2029',
                cvv:				'473',
            }
        },
        Update()
        {
            const vm = this

            vm.$v.card.$touch()

            if(!vm.$v.card.$invalid)
            {
                this.card.brand = this.brand ? this.brand.type : this.card.brand
                this.$emit('update', {
                    card:   this.card,
                })
            } else {
                window.helpers.focusInvalid(vm.card, this)
            }
        },
        ClearForm()
        {
            this.card	=   {
                number:				'',
                holder_name:		'',
                due_date:			'',
                cvv:				'',
                brand:		        null,
                brand_id:		    null,
            }
        }
    },
    beforeMount() {
        this.all_cards      = window.creditCards
    },
    mounted() {
        document.addEventListener('keyup', e => {
            if(e.keyCode === 27)
            {
                this.$emit('close')
            }
        })
    }

}
</script>

<style lang="css" scoped>
    .card {
        font-family: 'Quicksand', sans-serif;
		height: 200px;
		width: 350px;
		border-radius: 8px;
		background: rgb(0,158,146);
		background: linear-gradient(32deg, rgba(0,158,146,0.7483368347338936) 3%, rgba(228,241,240,1) 91%);
		position: relative;
	}

	.card-content .logo
	{
		width: 150px;
	}

	.card-content p
	{
		color: #fff;
		padding: 0 15px;
		margin-top: 15px;
		margin-bottom: 15px;
		font-weight: 600;
        text-shadow: -1px 2px 1px #00000087;
        text-transform: uppercase;
	}

	.card-content p.number
	{
		font-size: 1.5rem;
	}

	.card-content .expiration-and-cvv
	{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 15px;
		text-shadow: -1px 2px 1px #00000087;
		color: #fff;
	}

	.card-content .brand
	{
	    position: absolute;
	    top: 10px;
	    right: 10px;
	    width: 70px;
	}

	.card-content .brand img
	{
		width: 100%;
		height: auto;
	}

    .form #holder_name {
        text-transform: uppercase;
    }
</style>