<template>
	<div class="col-12 px-0 d-flex flex-column align-items-center">
		<div class="col-12 px-0">
			<DataTables 
				:headers="headers"
				v-model="crms"
				:tr-button="true"
				@by-click-on-tr="Edit"
			>
				<template v-slot:body="{ item }">
					<td>#{{ item.id }}</td>
					<td>{{ item.title }} {{ item.name }}</td>
					<td>CRM-{{ item.uf.toUpperCase() }} {{ item.crm }}</td>
					<td>{{ item.specialty }}</td>
				</template>
			</DataTables>
		</div>
		
		<div class="col-12 d-flex justify-content-end px-0">
			<button 
				class="btn btn-primary px-5" 
				@click="New"
			>
				Adicionar
			</button>
		</div>

		<div class="modal fade" id="modal-carimbo">
			<div class="modal-dialog modal-dialog-centered modal-lg">
				<div class="modal-content">
					<div class="modal-header px-5 py-4">
						<h4 class="font-20 m-0">Adicionando Carimbo</h4>
				        <button type="button" class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3" data-dismiss="modal">
				         	<i class="far fa-times mx-1"></i>
				        </button>
					</div>
					<hr class="m-0">
				    <div class="modal-body">
						<div class="p-4">
							<div class="form-row">
                                <div class="col-4 col-lg-2">
                                    <div class="form-group">
                                        <label>Titulo</label>
                                        <select 
                                            v-model="$v.form.title.$model"
                                            class="form-control"
                                            :class="{ 'is-invalid': $v.form.title.$error }"
											ref="title"
                                        >
                                            <option value="">Selecione...</option>
                                            <option value="Dr.">Dr.</option>
                                            <option value="Dra.">Dra.</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            Obrigatório
                                        </div>
                                    </div>
                                </div>
								<div class="col-8 col-lg-10">
									<div class="form-group">
										<label>Nome</label>
										<input 
											v-model="$v.form.name.$model"
											:class="{ 'is-invalid': $v.form.name.$error }"
											type="text" 
											class="form-control"
											placeholder="Nome que será exibido" 
											ref="name"
										>
										<div class="invalid-feedback">
											Obrigatório
										</div>
									</div>
								</div>
								<div class="col-8 col-md-4">
									<div class="form-group">
										<label>CRM</label>
										<input
											v-model="$v.form.registry.$model"
											:class="{ 'is-invalid': $v.form.registry.$error }"
											type="tel" 
											class="form-control"
											v-mask="[
												'####.###',
												'###.###', 
												'##.###', 
												'#.###',
											]"
											ref="registry"
										>
										<div class="invalid-feedback">
											Obrigatório
										</div>
									</div>
								</div>
								<div class="col-4 col-md-2">
									<div class="form-group">
										<label>UF</label>
										<select
											v-model="$v.form.uf.$model"
											:class="{ 'is-invalid': $v.form.uf.$error }"
											class="form-control"
											ref="uf"
										>
											<option value="">...</option>
											<option
												v-for="state in states"
												:key="state.id"
											>
												{{ state.initials }}
											</option>
										</select>
										<div class="invalid-feedback">
											Obrigatório
										</div>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label>Especialidade</label>
										<input 
											v-model="$v.form.specialty.$model"
											:class="{ 'is-invalid': $v.form.specialty.$error }"
											type="text" 
											class="form-control"
											placeholder="Ex. Diagnóstico por Imagem" 
											ref="specialty"
										>
										<div class="invalid-feedback">
											Obrigatório
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr class="m-0">
						<div class="d-flex justify-content-between p-4">
							<button 
								class="btn btn-light p-3 px-4" 
								data-dismiss="modal"
							>
								cancelar
							</button>

							<button 
								class="btn btn-primary p-3 px-4"
								@click="Save"
							>
								salvar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import { not, required, minLength, sameAs } from 'vuelidate/lib/validators'

	export default {
		name: 'Stamps',
		data () {
			return {
				headers: 	[
					{ text: 'carimbo', value: 'id' },
					{ text: 'nome', value: 'name' },
					{ text: 'CRM', value: 'crm' },
					{ text: 'especialidade', value: 'specialty' }
				],
				form: 		{
					id: 		'',
					name: 		'',
					registry:	'',
					uf: 		'',
					specialty: 	'',
					title: 		''
				},
				states: 	window.statesBR
			}
		},
		validations: {
			form: 		{
				title: 		{
					required
				},
				name: 		{
					required
				},
				registry: 	{
					required
				},
				uf: 		{
					required
				},
				specialty:  {
					required
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'crms'
			])
		},
		methods: {
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			New()
			{
				this.form.name 			= ''
				this.form.registry 		= ''
				this.form.uf 			= ''
				this.form.specialty 	= ''
				this.form.title 		= ''
				this.form.id 			= ''

				this.$v.form.$reset()

				window.jQuery('#modal-carimbo').modal('show')
			},
			Edit(stamp)
			{
				this.form.name 			= stamp.name
				this.form.registry 		= parseInt(stamp.crm.replace('.',''))
				this.form.uf 			= stamp.uf
				this.form.specialty 	= stamp.specialty
				this.form.title 		= stamp.title
				this.form.id 			= stamp.id

				this.$v.form.$reset()

				window.jQuery('#modal-carimbo').modal('show')
			},
			Clear()
			{
				this.form.title 		= ''
				this.form.registry 		= ''
				this.form.name 			= ''
				this.form.state 		= ''
				this.form.specialty 	= ''
				this.form.id 			= ''

				this.$v.form.$reset()
			},
			Save()
			{
				this.$v.form.$touch()

				if(!this.$v.form.$invalid)
				{
					this.StartLoading()

					window.jQuery('#modal-carimbo').modal('hide')

					this.form.registry 		= parseInt(this.form.registry.replace('.',''))

					window.api.call('post', '/save-stamp', this.form)
						.then(async ({data}) => {
							if(data.response)
							{
								await this.GetAuthAccount()

								this.Clear()

								Swal.fire({
									toast:  	true,
									title:		'Carimbo salvo com sucesso.',
									icon: 		'success',
									timer: 		3000,
									position: 	'top',
									timerProgressBar: true
								})

							}else{
								
								Swal.fire({
									icon: 	'error',
					        		title:  'Ops...',
					        		text: 	'Houve um erro ao concluir, contate o suporte.'
								})
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				} else {
					window.helpers.focusInvalid(this.$v.form, this)
				}
			}
		}
	}
</script>

<style lang="css" scoped>
</style>