<template>
	<div class="container">
		<div class="row align-items-center justify-content-center flex-column" v-if="type == 'create'">
			<div class="col-12">
				<div class="d-block mx-auto pb-4 pb-md-5 text-md-center max-500 pr-6 pr-md-0">
					<span> Criar agenda </span>
					<h4 class="font-24 font-md-32 m-0">Configure o horário da agenda</h4>
				</div>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-md-9">
				<div class="row justify-content-end">
					<div :class="is_vacancy ? 'col-md-3' : 'col-md-4'">
						<div class="form-group">
							<label>Horário de início</label>
							<input 
								type="tel" 
								class="form-control"
								v-mask="['##:##']"
								:class="{ 'is-invalid': $v.form.time_init.$error }"
								v-model="$v.form.time_init.$model"
								placeholder="00:00"
								ref="time_init"
								tabindex="1"
							/>
							<div class="invalid-feedback" v-if="!$v.form.time_init.ValidateTime">
								Horário Invalido
							</div>
							<div class="invalid-feedback" v-else>
								Obrigatório
							</div>
						</div>
					</div>
					<div :class="is_vacancy ? 'col-md-3' : 'col-md-4'">
						<div class="form-group">
							<label>Horário de término</label>
							<input 
								type="tel" 
								class="form-control"
								v-mask="['##:##']"
								:class="{ 'is-invalid': $v.form.time_end.$error }"
								v-model="$v.form.time_end.$model"
								placeholder="00:00"
								ref="time_end"
								tabindex="2"
								@keyup.enter="Next"
							/>
							<div class="invalid-feedback" v-if="!$v.form.time_end.ValidateTime">
								Horário Invalido
							</div>
							<div class="invalid-feedback" v-else>
								Obrigatório
							</div>
						</div>
					</div>
					<div :class="is_vacancy ? 'col-md-3' : 'col-md-4'">
						<div class="form-group">
							<label>Tamanho da agenda</label>
							<input 
								type="tel" 
								class="form-control"
								v-mask="['##:##']"
								:class="{ 'is-invalid': $v.form.total_time.$error }"
								v-model="$v.form.total_time.$model"
								placeholder="00:00"
								disabled
								ref="total_time"
							>
							<div class="invalid-feedback">
								Precisa ser maior que 00:05 pelo menos.
							</div>
						</div>
					</div>
					<div v-if="is_vacancy"
						class="form-group"
						:class="is_vacancy ? 'col-md-3' : 'col-md-4'">
						<label for="attendances" class="mb-0">Atendimentos</label>
						<div class="input-group">
							<input
								id="attendances"
								ref="attendances"
								type="tel"
								class="form-control"
								:class="{ 'is-invalid': $v.form.attendances.$error }"
								v-model.number="$v.form.attendances.$model"
								v-mask="[ '###' ]"
								placeholder="0"
								tabindex="3"
								@keyup.enter="Next"
							>
							<div class="col-4 px-0 input-group-append"
								:content="`Você pode realizar até ${form.max_attendances} atendimento(s) em uma agenda deste tamanho.`"
								v-tippy="{ arrow: true }">
								<p class="col mb-0 input-group-text d-flex justify-content-between align-items-center"
									@click="form.attendances = form.max_attendances">
									<span class="">/</span>
									<span class="">{{ form.max_attendances }}</span>
								</p>
							</div>
							<div class="invalid-feedback" v-if="!$v.form.attendances.ValidateAttendances">
								Quantidade Inválida
							</div>
							<div class="invalid-feedback" v-if="!$v.form.attendances.required">
								Obrigatório
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-9 mt-3" v-if="type == 'create'">
				<div class="d-flex justify-content-between">
					<button 
						class="btn btn-light px-5"
						@click="Previous"
					>
						voltar
					</button>
					<button 
						class="btn btn-primary px-5"
						@click="Next"
					>
						continuar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../../helpers'
	const helpers 		= new Helpers()

	const moment 		= require('moment')
	const _				= require('lodash')

	const ValidateTime 	= (value) => {
		if(!value) return false

		if(typeof value !== 'undefined' && value.length >= 5)
		{
			const pattern 		= /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g

			let result 			= value.match(pattern)

			return  result ? true : false
		}

		return true
	}

	const GreaterThan 	= (value) => {

		const total_time 	= moment(value, "HH:mm")
		const min_time 		= moment('00:05', "HH:mm")

		let diff 			= min_time.diff(total_time)

		return diff < 0
	}

	const ValidateAttendances = (value, siblings) => {
		if(!siblings.total_time) return false;

		const hours = siblings.total_time.split(':')[0]
		const minutes = siblings.total_time.split(':')[1]

		const total_time = (parseInt(minutes) / 60) + parseInt(hours)

		const attendances_per_block = 12

		const max_attendances = total_time * attendances_per_block

		return parseInt(value) <= max_attendances
	}

	import { required, requiredIf, minLength, maxValue } from 'vuelidate/lib/validators'
	export default {

		name: 'TimeRange',
		props: 	{
			is_started: {
				type:		Boolean,
				default:	true
			},
			time_obj:	{
				type:		Object,
				default:	() => {}
			},
			type: 		{
				type: 		String,
				default: 	'create' // 'config'
			},
			is_vacancy: {
				type:		Boolean,
				default:	false
			},
			attendances_obj: {
				type:		Object,
				default:	() => {}
			},
			bypass_validation: {
				type:		Boolean,
				default:	false
			}
		},
		data () {
			return {
				form: 	{
					total_time: 	null,
					time_end: 		null,
					time_init: 		null,
					reverse: 		false,
					attendances:	0,
					max_attendances:0,
				}
			}
		},
		validations() {
			return {
				form: {
					time_init: 		{
						required,
						minLength: minLength(5),
						ValidateTime
					},
					time_end: 		{
						required,
						minLength: minLength(5),
						ValidateTime
					},
					total_time: 	{
						GreaterThan
					},
					attendances: 	{
						required: requiredIf(function (form) {
							return this.is_vacancy
						}),
						maxValue: maxValue(this.form.max_attendances)
					}
				}
			}
		},
		computed: {
		},
		watch: 		{
			time_obj: {
				handler(val) {
					if(!_.isEmpty(val)) {
						const new_val = JSON.parse(JSON.stringify(val))
	
						if(new_val.time_init) {
							this.form.time_init 	= new_val.time_init
						}
	
						if(new_val.time_end) {
							this.form.time_end		= new_val.time_end
						}

						if(new_val.total_time) {
							this.form.total_time		= new_val.total_time
						}
					}
				},
				immediate: true,
				deep: true
			},
			'form.time_init': {
				handler()
				{
					this.GetDifferenceBetweenTimes()
				}
			},
			'form.time_end': {
				handler()
				{
					this.GetDifferenceBetweenTimes()
				}
			},
			attendances_obj: {
				handler(val) {
					this.form.attendances = val.attendances ?? 1
					this.form.max_attendances = val.max_attendances ?? 1
				},
				immediate: true
			},
			'form.attendances': {
				handler(val) {
					this.$emit('attendances', val)
				}
			},
			'form.max_attendances': {
				handler(val) {
					if(!this.is_started) {
						this.$emit('max-attendances', val)
					}
				}
			}
		},
		methods: 	{
			Next() {
				this.$v.form.$touch()
				
				if(!this.bypass_validation) {
					if(!this.$v.form.$invalid) {
						this.$emit('next', this.form)
	
						return true;
					} else {
						window.helpers.focusInvalid(this.$v.form, this)
	
						return false;
					}
				}

				this.$emit('next', this.form)

				return true;
			},
			GetDifferenceBetweenTimes()
			{
				if(!this.form.time_init || !this.form.time_end) return

				if(this.form.time_init.length == 5 && this.form.time_end.length == 5)
				{
					let time_init 			= this.form.time_init.split(':')

					const convertedMinutesInit = parseInt(Math.ceil( time_init[1] / 5 ) * 5)

					time_init 				= time_init[0] + ':' + (convertedMinutesInit == 60 ? '55' : convertedMinutesInit ).toString().padStart(2, '0')

					this.form.time_init 	= time_init == 60 ? 55 : time_init

					let time_end 			= this.form.time_end.split(':')

					const convertedMinutesEnd = parseInt(Math.ceil( time_end[1] / 5 ) * 5)

					time_end 				= time_end[0] + ':' + (convertedMinutesEnd == 60 ? '55' : convertedMinutesEnd ).toString().padStart(2, '0')

					this.form.time_end 		= time_end

					const start_time 		= moment(this.form.time_init, "HH:mm");
					const end_time 			= moment(this.form.time_end, "HH:mm");

					const duration 			= moment.duration(end_time.diff(start_time));

					let hours 				= parseInt(duration.asHours())
					hours 					= hours > 0 ? hours : (hours * -1)
					hours 					= hours.toString().padStart(2, '0');

					let minutes 			= parseInt(duration.asMinutes()) 
					minutes 				= minutes > 0 ? minutes : (minutes * -1)
					minutes 				= (minutes % 60).toString().padStart(2, '0');

					this.form.total_time 	= hours + ':' + minutes

					this.$emit('total-time', this.form.total_time)
					this.$emit('time-init', this.form.time_init)

					if(!this.is_started)
					{
						this.$emit('time-end', this.form.time_end)
					}

					this.RecalculateAttendances()
				}
			},
			RecalculateMaxAttendances() {
				let obj = null

				if(this.time_obj && this.time_obj.total_time) {
					obj = this.time_obj
				}
				
				if(this.form.total_time) {
					obj = this.form
				}
				
				if(obj && obj.total_time) {
					const [ hours, minutes ] = window.helpers.GetHoursAndMinutesFromString(obj.total_time)
	
					const total_time = (parseInt(minutes) / 60) + parseInt(hours)
	
					const attendances_per_block = 12
	
					this.form.max_attendances = total_time * attendances_per_block
				} else {
					this.form.max_attendances = 0
				}

				this.$emit('max-attendances', this.form.max_attendances)
			},
			async RecalculateAttendances() {
				await this.RecalculateMaxAttendances()

				if(this.form.attendances > this.form.max_attendances) {
					this.form.attendances = this.form.max_attendances

					this.$emit('attendances', this.form.attendances)
				}
			},
			Previous()
			{
				this.$emit('previous')
			}
		},
		mounted() {
			this.$refs.time_init.focus();
		}
	}
</script>

<style lang="css" scoped>
.input-group-text {
	padding: 1rem .75rem;
	height: 100% !important;
	border-color: var(--dark-light);
	border-top-right-radius: 8px !important;
	border-bottom-right-radius: 8px !important;
	background-color: var(--dark-light);
	color: var(--color-font-light);
	cursor: pointer;
}
</style>