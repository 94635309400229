<template>
	<div>
		<div class="container">
			<div class="row align-items-center justify-content-center flex-column">
				<div class="col-12">
					<div class="d-block mx-auto pb-4 pb-md-5 text-md-center max-500 pr-6 pr-md-0">
						<span> {{ label }} </span>
						<h4 class="font-24 font-md-32 m-0">Selecione uma modalidade</h4>
					</div>
				</div>
				<div class="col-12 col-md-6">						
					<div 
						v-for="(modality, index) in filteredModalities"
						:key="index"
						class="select-card with-border mb-3"
						:class="{ 'active': selected.modality && modality.id === selected.modality.id }"
						@click="Select(modality)"
					>
						<label>
							{{ modality.name }}
							<i class="far fa-chevron-right"></i>
						</label>
					</div>
						
				</div>		
				<div class="col-12 col-md-6 text-center py-5">
					<div class="d-flex justify-content-between">
						<button 
							class="btn btn-light px-md-5"
							@click="Previous"
						>
							voltar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const _ = require('lodash')
	import { mapGetters } from 'vuex'
	export default {

		name: 'Modalities',
		props: 	{
			label: 		 {
				type: 		String,
				default: 	''
			},
			module_id: 	 {
				type: 		[String, Number],
				default: 	null
			},
			modality_id: {
				type: 		[String, Number],
				default: 	null
			},
			type: 		{
				type: 		String,
				default: 	'strict' //'all'
			}
		},
		data () {
			return {
				selected: 	{
					modality: 		null
				}
			}
		},
		watch: {
			modality_id: {
				handler(val)
				{
					if(val)
					{
						this.selected.modality 	= this.filteredModalities.find( m => m.id === parseInt(val))
					}
					
				},
				immediate: true
			}
		},
		computed: {
			...mapGetters('system', [
				'modalities'
			]),
			filteredModalities()
			{
				return _.sortBy(this.modalities.filter( modality => {

					let allowed 	= true

					if(this.type == 'strict')
					{
						allowed 		= false
						
						if(modality.schedules_count > 0)
						{
							allowed 		= true
						}
					}

					return allowed && modality.module_id == parseInt(this.module_id)
				}), 'name', 'asc')
			}
		},
		methods: {
			Select(modality)
			{
				this.$emit('next', modality)
			},
			Previous()
			{
				this.$emit('previous')
			}
		},
		mounted()
		{
			if(this.filteredModalities.length === 1 && !this.selected.modality)
			{
				this.Select(this.filteredModalities[0])
			}	
		}
	}
</script>

<style lang="css" scoped>
</style>