<template>
	<div class="modal fade" id="indicator_doctor_modal" tabindex="1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body p-3">
					<div v-if="form">
						<div class="form-row align-items-center mb-3">
							<div class="col-md-auto">
								<button
									class="btn btn-block btn-light"
									@click="form = false"
								>
									voltar
								</button>
							</div>
						</div>
						<IndicatorDoctorForm
							@saved="DoctorSaved"
							:base_name="search"
							:edit_doctor="edit_doctor"
						/>
					</div>
					<div v-else>
						<div class="d-flex align-items-center mb-3">
							<div class="col px-0">
								<div class="form-group position-relative m-md-0">
									<input
										type="text"
										id="indicator-search"
										ref="indicator_search"
										class="form-control pl-5"
										placeholder="Pesquisar por nome, telefone ou documento"
										v-model="search"
										autofocus
									>
									<i class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"></i>
								</div>
							</div>
							<div v-if="filteredDoctors.length === 0 && search" class="col-md-auto">
								<button
									class="btn btn-block btn-primary"
									@click="form = true"
								>
									Cadastrar
								</button>
							</div>
						</div>
						<div class="card border-no bg-light rounded doctors-box">
							<div v-if="filteredDoctors.length > 0">
								<div v-for="doctor in filteredDoctors"
									:key="doctor.id"
								>
									<div class="select-card p-3 rounded">
										<label class="bg-white"  @click.prevent="SelectDoctor(doctor)">
											<div class="d-flex align-items-center">
												<div>
													{{ doctor.name }}
												</div>
											</div>
											<div v-if="doctor.crm">
												<small>CRM: {{ doctor.crm }}</small>
											</div>
											<div v-if="doctor.cpf">
												<small>CPF: {{ doctor.cpf }}</small>
											</div>
											<div v-if="doctor.phone">
												<small>Telefone: {{ doctor.phone }}</small>
											</div>
											<i class="fa fa-pencil mr-4" aria-hidden="true" @click.stop="EditDoctor(doctor)"></i>
										</label>
									</div>
								</div>
							</div>
							<div v-else class="card m-5 p-3 rounded">
								<span>
									Nenhum médico encontrado para "{{ search }}"
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Helpers from '../../../helpers'
const helpers 		= new Helpers()
import { mapGetters } from 'vuex'
import IndicatorDoctorForm from './IndicatorDoctorForm.vue'

export default {
	name: 'IndicatorDoctorModal',
	data() {
		return {

			search: '',
			indicator_doctors: [],
			form: false,
			edit_doctor: null

		}
	},
	components: {
		IndicatorDoctorForm
	},
	computed: {
		...mapGetters('auth', [
			'requester_selected_id'
		]),
		filteredDoctors() {
			if(!this.search) return this.indicator_doctors;

			const indicator_doctors	= this.indicator_doctors.filter(doctor => {
				let allowed 	= false
				let search 		= helpers.RemoveAcento(this.search).toLowerCase()

				if(doctor.name) {
					allowed 		= helpers.RemoveAcento(doctor.name).toLowerCase().includes(search)
				}

				if(!allowed && doctor.cpf) {
					allowed 		= helpers.RemoveAcento(doctor.cpf).toLowerCase().includes(search)
				}

				if(!allowed && doctor.crm) {
					allowed 		= helpers.RemoveAcento(doctor.crm).toLowerCase().includes(search)
				}

				if(!allowed && doctor.address) {
					allowed 		= helpers.RemoveAcento(doctor.address).toLowerCase().includes(search)
				}

				if(!allowed && doctor.observation) {
					allowed 		= helpers.RemoveAcento(doctor.observation).toLowerCase().includes(search)
				}

				if(!allowed && doctor.phone) {
					let telefone

	                try {
	                    telefone = JSON.parse(doctor.phone.replace(/\\/g,''))
	                } catch(err) {
	                    telefone = []
	                }

					if(Array.isArray(telefone) && telefone.length > 0) {
						telefone.forEach( tel => {
							if(tel && !allowed && tel.replace(/\D/g, '').indexOf(search) > -1) {
								allowed 	= true

								return;
							}
						})
					}
				}

				return allowed
			})

			return indicator_doctors
		}
	},
	methods: {
		async Load() {
			window.api.call('post', '/get-indicator-doctors', { 
				account_id: this.requester_selected_id 
			})
				.then(({data}) => {
					if(data.response) {
						this.indicator_doctors = data.indicator_doctors
					}
				})
		},
		SelectDoctor(doctor) {
			this.$emit('indicator_doctor', doctor)
		},
		EditDoctor(doctor) {
			this.edit_doctor = doctor
			this.form = true
		},
		DoctorSaved(doctor) {
			this.Load();
			this.search = doctor.cpf
			this.form = false
		}
	},
	beforeMount() {
		this.Load()
	}
}
</script>

<style>
.doctors-box {
	max-height: 330px;
    overflow: auto;
}
</style>