<template>
	<div class="pb-4 pb-md-6">
		<div class="container" v-if="patient">
			<div class="row justify-content-center">
				<div class="col-12">
					<router-link
						class="btn btn-light"
						:to="{ name: 'Patients'}"
					>
						<i class="far fa-chevron-left mr-3"></i> voltar
					</router-link>

					<div class="row mt-4 mb-2">
						<div class="col-12 col-md-auto text-center">
							<div class="d-inline-block mt-2 mb-4">
								<template v-if="exihibit_avatar">
									<img 
										id="img-avatar"
										:src="exihibit_avatar" 
										class="max-150 rounded-lg"
										@click="ShowPhotoModal"
									>
								</template>
								<template v-else>
									<button 
										type="button" 
										class="btn btn-light-dark py-5"
										@click="ShowPhotoModal"
									>
										<i class="far fa-user mx-5 my-2 font-20"></i>
									</button>
								</template>

								<br/>
								<button 
									type="button" 
									class="btn btn-light-primary mt-3 py-3"
									@click="UploadAvatar"
								>
									selecionar
								</button>
								<input 
									type="file" 
									id="input-avatar" 
									accept=".jpg, .jpeg, .png" 
									@change="UploadAvatar($event)" 
									hidden
								>
							</div>
						</div>
						<div class="col">
							<span>Paciente</span>
							<h4 class="font-24 font-md-32 mb-4">{{ patient.nome_paciente }}</h4>
							<div class="row">
								<div class="col-12 max-150">
									<small>DOCUMENTO</small>
								</div>
								<div class="col text-dark font-normal">
									{{ document }}
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-150">
									<small>CADASTRAMENTO</small>
								</div>
								<div class="col text-dark font-normal">
									{{ signin_date }}
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-150">
									<small>STATUS</small>
								</div>
								<div class="col text-dark font-normal">
									<div 
										class="badge"
										:class="{
											'badge-success': patient.ativo,
											'badge-danger': !patient.ativo
										}"
									>
										{{ patient.ativo ? 'ATIVA' : 'INATIVA'}}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="scroll-x scroll-transparent d-flex mx--5 mx-md-0">
						<ul class="nav nav-pills nav-theme w-md-100 justify-content-md-center my-4 my-md-3 px-5">
							<li class="nav-item">
								<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#info">Dados</a>
							</li>
							<li class="nav-item">
								<a class="nav-link pb-md-4 px-md-4 active" data-toggle="tab" href="#history">Prontuário</a>
							</li>
							<li class="nav-item"
								>
								<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#status">Status</a>
							</li>
						</ul>
					</div>

					<div class="tab-content">
						<div id="info" class="tab-pane">
							<div class="d-flex flex-wrap justify-content-end">
								<PatientForm
									class=""
									form_type="update-patient"
									:patient="patient"
									:edit="true"
									@updated="GetPatient"
								>
									<template v-slot:actions="{ Save }">
										<button 
											class="btn btn-primary px-md-5"
											@click="Save"
										>
											salvar <i class="far fa-save ml-4"></i>
										</button>
									</template>
								</PatientForm>
							</div>
						</div>
						<div id="history" class="tab-pane active">
							<History
								:patient="patient"
							/>
						</div>
						<div id="status" class="tab-pane">
							<div class="py-5 max-500 text-center mx-auto">
								<h3 class="font-24 mb-5">
									Você pode desativar e reativar temporariamente um paciente sempre que precisar.
								</h3>
								<button 
									class="btn"
									:class="{
										'btn-light-success': !patient.ativo,
										'btn-light-danger': patient.ativo
									}"

									@click="TogglePatientStatus(patient_id)"
								>
									{{ patient.ativo ? `desativar` : `reativar`}} paciente
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal fade" id="modal-webcam">
				<div class="modal-dialog mw-100 d-flex justify-content-center align-items-center">
					<div class="modal-content">
						<button 
							id="close-modal" 
							class="btn btn-light btn-sm"
							@click="ClosePhotoModal"
						>
							<i class="fal fa-times"></i>
						</button>
						<div class="modal-header px-5 py-4 mt-5 mt-sm-1">
							<h4 class="font-20 m-0 text-center">Registrar a foto do paciente</h4>
						</div>
						<div class="modal-body d-flex flex-column align-items-center">
							<div v-if="webcam.snap" id="img-snapped">
								<img 
									:src="webcam.snap"
									width="100%"
									alt="Foto do paciente">
							</div>
							<WebCam
								v-show="!webcam.snap"
								ref="webcam"
								id="webcam"
								:device-id="deviceId"
								screenshotFormat="image/png"
								width="100%"
								height="100%"
								@cameras="onCameras"
								@camera-change="onCameraChange"
							>
							</WebCam>
							
							<span v-if="!camera && !webcam.snap" class="align-self-center">
								Aguarde a imagem da câmera aparecer...
							</span>
							<button 
								class="btn btn-light my-4"
								@click="SnapPhoto"
							>
								{{ webcam.snap ? 'Tirar outra foto' : 'Tirar foto' }}
							</button>
							<span class="mb-4 text-success">* Para aceitar a foto que foi tirada basta fechar essa janela *</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import PatientForm from '../shared/PatientForm'
import History from 	'../patient/components/History'

import { WebCam } from 'vue-web-cam'

export default {
	name: 'PatientPanel',
	components: {
		PatientForm,
		History,
		WebCam
	},
	props: {
		patient_id:		{
			type:		[Number, String],
			default:	null
		}
	},
	data() {
		return {
			exihibit_avatar: '',
			patient:		null,
			webcam:		{
				ready:		false,
				mode:		'user',
				snap:		null
			},
			camera: 		null,
			deviceId:		null,
			devices:		[]
		}
	},
	computed: {
		...mapGetters('auth', [
			'requester_selected_id'
		]),
		signin_date()
		{
            let data = this.patient.created_at.substr(0, 10)

            data = data.split('-').reverse().join('/')

            return data
        },
		document()
		{
			let doc = this.patient.doc_id

			return `${doc.tipo} - ${doc.numero}`
		},
		device: function() {
			return this.devices.find(n => n.deviceId === this.deviceId);
		}
	},
	watch: {
		'patient.avatar': {
			immediate: true,
			handler(val)
			{
				if(val)
				{
					this.exihibit_avatar = val
				}
			}
		},
		camera(id) 
		{
			this.deviceId = id;
		},
		devices()
		{
			const [first, ...tail] = this.devices;

			if (first) 
			{
				this.camera 			= first.deviceId;
				this.deviceId 			= first.deviceId;
			}
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		GetPatient(patient_id)
		{
			const vm = this

			vm.StartLoading()

			return window.api.call('post', '/get-patient', {
				account_id: vm.requester_selected_id,
				patient_id
			})
				.then(({data}) => {
					if(data.response)
					{

						vm.patient		= data.patient

					}
				})
				.finally(() => {
					vm.FinishLoading()
				})
		},
        TogglePatientStatus(patient_id)
        {
            const vm = this

            vm.StartLoading()

            return window.api.call('post', '/toggle-patient-status', {
                account_id: vm.requester_selected_id,
                patient_id
            })
                .then(async ({data}) => {
                    if(data.response)
                    {
                        await vm.GetPatient(patient_id)
                    }
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
		async ShowPhotoModal() 
		{
			const vm 	= this

			window.jQuery('#modal-webcam').modal({
					backdrop: 	false,
					show:		true
				})//.on('shown.bs.modal', () => {

				vm.webcam.ready = true

				vm.$refs.webcam.start()
									
				//GAMBITECH
				window.jQuery('body').addClass('modal-open')
			//})

			vm.webcam.snap = vm.patient.avatar ?? null
		},
		SnapPhoto() 
		{
			if(this.webcam.snap)
			{
				this.webcam.snap = null
				this.$refs.webcam.start()
				return 
			}
			
			this.webcam.snap = this.$refs.webcam.capture()
		},
		async ClosePhotoModal() 
		{	
			const vm 			= this

			vm.patient.avatar 		= vm.webcam.snap || vm.patient.avatar

			window.jQuery('#modal-webcam').modal('hide')
				.on('hidden.bs.modal', () => {

					vm.$refs.webcam.stop()
					vm.webcam.ready = false

					//GAMBITECH

				})
		},
		onCameras(cameras) 
		{
			this.devices = cameras
		},
		onCameraChange(deviceId) 
		{
			this.deviceId = deviceId
		},
		UploadAvatar(event) 
		{
			if(event.target.files)
			{
				const reader 			= new FileReader()
				const file 				= event.target.files[0]
				
				if(file)
				{
					this.patient.avatar		= null

					this.patient.avatar 	= file

					reader.onload 		= e => this.exihibit_avatar = e.target.result

					reader.readAsDataURL(file)
				}
			}

			document.querySelector('#input-avatar').click()
		},
	},
	async beforeMount()
	{
		await this.GetPatient(this.patient_id)
	}

}
</script>

<style lang="css" scoped>
	#img-avatar {
		cursor: pointer;
	}

	#webcam, #img-snapped, #img-avatar {
		width: 100%;
	}

	#close-modal {
		position: absolute;
		right: 15px;
		top: 15px;
	}

	@media(min-width: 575.98px) {
		.modal-content {
			width: 75% !important;
		}
	}

	@media(min-width: 1000px) {
		.modal-content {
			max-width: 50% !important;
		}
	}

	@media(min-width: 1500px) {
		.modal-content {
			max-width: 800px !important;
		}
	}
</style>