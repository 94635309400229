<template>
	<div class="container-fluid p-5">
		<div class="row">
			<div class="col-12">
				<div>
					<table class="w-100">
						<thead>
							<tr>
								<td colspan="5">
									<div class="row align-items-center">
										<div class="col-12">
											<img :src="organization_logo" height="70">
										</div>
										<div class="col-12 my-4">
											<h5>
												RELATÓRIO DE PROCEDIMENTOS AGENDADOS
											</h5>
											<h5>
												DATA: {{ FormatDate(selected.date) }}
											</h5>
										</div>
									</div>								
								</td>
							</tr>
						</thead>
						<tfoot>
							<tr>
								<td colspan="5">
									<p class="text-right mt-4">
										<b>IMPRESSO EM:</b> {{ FormatDate(today) }}
									</p>
								</td>
							</tr>
						</tfoot>
						<tbody>
							<tr>
								<td colspan="5">
									<table class="table w-100 table-sm">
										<thead>
											<tr>
												<th>
					                                Hora
					                            </th>
					                            <th>
					                                Paciente
					                            </th>
					                            <th>
					                                ID/Número
					                            </th>
					                            <th>
					                                Procedimentos
					                            </th>
					                            <th>
					                                Nome da Agenda
					                            </th>
											</tr>
										</thead>
										<tbody>
											<tr 
					                            v-for="scheduling in schedulings" 
					                            :key="scheduling.block_id"
					                        >
					                            <td>
					                                {{ scheduling.hora.substring(0, 5) }}
					                            </td>
					                            <td>
					                                {{ scheduling.nome_paciente }}
					                            </td>
					                            <td>
					                                {{ scheduling.doc }}
					                            </td>
					                            <td>
					                                {{ scheduling.nome_procedimento }}
					                            </td>
					                            <td>
					                                {{ scheduling.agenda }}
					                            </td>
					                        </tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../helpers'
	const helpers 		= new Helpers()

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'PrintMap',

		data () {
			return {
				schedulings: 		[],
				organization_logo: 	null,
				statistics: 		{
					total: 				0,
					busy: 				0,
					proceedings: 		0
				},
				selected: 			{
					schedules: 			null,
					date: 				null,
					account_id: 		null
				},
				today: 				new Date().toISOString().slice(0,10)
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id', 'requesters'
			]),
			instance_config() {
				const requester = this.requesters.find((r) => r.id == this.requester_selected_id)

				if(requester) {
					return requester.instance.config
				}

				return null
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetSchedulingsFromDate()
			{
				this.StartLoading()

				return window.api.call('post', '/get-schedulings-from-date', this.selected)
					.then(({data}) => {
						if(data.response)
						{
							this.schedulings 	= data.schedulings
							this.statistics 	= data.statistics

							setTimeout(() => {
			                    window.print()
			                }, 1000)

						}else{

							setTimeout(()=>{
			                    window.close()
			                }, 1000) 
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			FormatDate(date)
			{
				return helpers.FormatDate(date)
			}
		},
		async mounted()
		{
			window.addEventListener('afterprint', () => {
	            if (window.mainWindow)
	            {
	            	window.mainWindow.postMessage({ print: true })
	            }

	            window.close()
	        })

			this.selected.schedules 	= JSON.parse(this.$route.query.schedules)
			this.selected.date 			= this.$route.query.date
			this.selected.account_id 	= this.requester_selected_id

			this.organization_logo 		= this.instance_config ? this.instance_config.logo_url : ''

			await this.GetSchedulingsFromDate()

			const style_print = window.jQuery('<style>@media print { body { zoom: 60% } @page { size: landscape !important; } }<style>');
			style_print.appendTo('head');
		}
	}
</script>

<style lang="css" scoped>
	table 
	{
		page-break-after: always;
	}

	table thead 
	{
		display: table-header-group;
	}

	table tfoot 
	{
		display: table-footer-group;
	}

	@media print
	{
		@page 
		{
			margin: 2mm 4mm 10mm 4mm;
		}
	}
</style>