<template>
	<div class="row justify-content-center">
		<div class="col-12">
			<p class="text-center mb-5">
				Você pode alterar as cores do sistema selecionando abaixo:
			</p>
		</div>
		<div class="col-12 col-md-6">
			<div class="d-flex justify-content-center">
				<div 
					class="theme-container"
					:class="{
						'active': theme == 'light'
					}"

					@click="SelectTheme('light')"
				>
					<img :src="light_panel">
				</div>
			</div>
			<p 
				v-if="theme == 'light'"
				class="text-center text-uppercase text-dark"
			>
				<b>Selecionado</b>
			</p>
		</div>
		<div class="col-12 col-md-6">
			<div class="d-flex justify-content-center">
				<div 
					class="theme-container"
					:class="{
						'active': theme == 'dark'
					}"
					@click="SelectTheme('dark')"
				>
					<img :src="dark_panel">
				</div>
			</div>
			<p 
				v-if="theme == 'dark'"
				class="text-center text-uppercase text-light"
			>
				<b>Selecionado</b>
			</p>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Theme',

		data () {
			return {
				light_panel: 		require('../../../assets/img/light-panel.png'),
				dark_panel: 		require('../../../assets/img/dark-panel.png')
			}
		},
		computed: {
			...mapGetters('system', [
				'theme'
			])
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'ToggleTheme'
			]),
			SelectTheme(theme)
			{
				this.StartLoading()

				this.ToggleTheme(theme)
					.finally(() => {
						this.FinishLoading()
					})
			}
		}
	}
</script>

<style lang="css" scoped>
	.theme-container
	{
		border: 2px solid var(--dark-light);
		border-radius: 8px;
		width: 250px;
		object-fit: cover;
		cursor: pointer;
		opacity: .5;
		transition: all .2s ease-in-out;
	}

	.theme-container:hover,
	.theme-container.active
	{
		opacity: 1;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
	}

	.theme-container img
	{
		width: 100%;
		height: auto;
		border-radius: 8px;
	}
</style>