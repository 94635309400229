<template>
	<div>
		<Loading/>
		<template v-if="menuType == 'default'">
			<Navbar />
		</template>
		<template v-else-if="menuType == 'carousel'">
			<div class="position-fixed ps-r-0 ps-t-0 p-4 z-2">
				<template v-if="requesters.length > 0">
					<button 
						type="button" 
						class="btn btn-light ml-3 px-3 py-3" 
						@click="carousel = true"
					>
						<i class="fal fa-bars mx-2 font-24"></i>
					</button>
				</template>
				<template v-else>
					<button 
						type="button" 
						class="btn btn-block px-3 btn-light"
						@click="Logout"
					>
						<i class="far fa-sign-out mx-2"></i>
					</button>

				</template>
			</div>
			<CarouselMenu 
				:open="carousel"
				@close="carousel = false"
			/>
		</template>
		<div>
			<transition name="fade" mode="out-in">
					<router-view/>
			</transition>
		</div>

		<FooterVersion v-if="1 === 2" />
	</div>
</template>

<script>

	import Navbar from './sections/Navbar'
	import CarouselMenu from './sections/CarouselMenu'
	import Loading from './Loading'
	import FooterVersion from './FooterVersion'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Index',
		components: 	{
			Navbar,
			CarouselMenu,
			Loading,
			FooterVersion
		},
		data () {
			return {
				carousel: 	false,
				redirected: false,
			}
		},
		computed: {
			...mapGetters('system', [
				'theme'
			]),
			...mapGetters('auth', [
				'check', 'requesters'
			]),
			menuType()
			{
				// if(this.$route.meta.menuType)
				// {
				// 	return this.$route.meta.menuType
				// } else {
				// 	return 'default'
				// }

				return 'default'
			}
		},
		watch: {
			$route(to, from)
			{
				this.carousel 	= false

				window.jQuery('.modal').modal('hide');
			},
			theme: {
				handler(val)
				{
					switch(val)
					{
						case 'dark':
							this.AddDarkTheme()
						break

						default:
							this.RemoveDarkTheme()
						break
						
					}
				},
				immediate: true
			},
			requesters: {
				handler(val)
				{
					if(val.length == 0)
					{
						if(!this.redirected)
						{
							if(this.$route.name != 'Organizations')
							{
								this.$router.push({ name: 'Organizations' })
								this.redirected = true
							}
						}
					}
				}
			}
		},
		methods: {
			...mapActions('auth', [
				'GetAuthAccount', 'Logout'
			]),
			AddDarkTheme() 
			{
				let darkThemeLinkEl 		= document.createElement("link");
				darkThemeLinkEl.rel 		= 'stylesheet';
				darkThemeLinkEl.href 		= '/css/dark.css';
				darkThemeLinkEl.id 			= 'dark-theme-style';

				let docHead 				= document.querySelector("head");

				docHead.append(darkThemeLinkEl);
			},
			RemoveDarkTheme() 
			{
				let darkThemeLinkEl 	= document.querySelector("#dark-theme-style");
				
				if(darkThemeLinkEl)
				{
					let parentNode 			= darkThemeLinkEl.parentNode;

					parentNode.removeChild(darkThemeLinkEl);
				}
			}
		},
		beforeMount()
		{
			if(this.check)
			{
				this.GetAuthAccount()
			}
		}
	}
</script>

<style lang="css" scoped>
</style>