<template>
	<div>
		<div class="container">
			<div class="row align-items-center justify-content-center flex-column">
				<div class="col-12">
					<div class="d-block mx-auto pb-3 pb-md-5 text-md-center max-500 pr-6 pr-md-0">
						<span>{{ label }}</span>
						<h4 class="font-24 font-md-32 m-0">Selecione um módulo</h4>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div
						v-for="(module, index) in filteredModules"
						:key="index"
						class="select-card with-border mb-3"
						:class="{ 'active': selected.module && module.id === selected.module.id }"
						@click="Select(module)"
					>
						<label>
							{{ module.name }}
							<i class="far fa-chevron-right"></i>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const _ = require('lodash')
	import { mapGetters } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 	'Modules',
		props: 	{
			label: 		{
				type: 		String,
				default: 	''
			},
			module_id: 	{
				type: 		[String, Number],
				default: 	null
			},
			type: 		{
				type: 		String,
				default: 	'strict' //'all'
			},
			filtering_by: {
				type: 		String,
				default: 	'schedule' //'proceeding'
			}
		},
		data () {
			return {
				selected: 	{
					module: 	null
				}
			}
		},
		watch: {
			module_id: {
				handler(val)
				{
					if(val)
					{
						this.selected.module 	= this.modules.find( m => m.id === parseInt(val))
					}
					
				},
				immediate: true
			}
		},
		computed: {
			...mapGetters('system', [
				'modules'
			]),
			filteredModules()
			{
				return _.sortBy(this.modules.filter( module => {

					if(this.type == 'strict')
					{
						switch(this.filtering_by)
						{
							case 'schedule':
								return module.have_schedules && module.have_modalities
							case 'proceeding':
								return module.have_modalities
						}						

					}else{

						return true
					}

				}), 'name', 'asc')
			}
		},
		methods: {
			Select(module)
			{
				this.selected.module 	= module

				this.$emit('next', this.selected.module)
			}
		},
		mounted()
		{
			if(this.filteredModules.length === 1 && !this.selected.module)
			{
				this.Select(this.filteredModules[0])
			}	

			if(this.filteredModules.length === 0)
			{
				Swal.fire({
					icon: 	'info',
					title: 	'Opss...',
					text: 	(this.type === 'strict' ? 'Você não possui agendas cadastradas ou ativas.' : 'Não há modulos para exibir.'),
					showConfirmButton: 	true,
					preConfirm: () => {
						this.$router.push({ name: 'Home' })
					}
				})
			}
		}
	}
</script>

<style lang="css" scoped>
</style>