import Vuex from './vuex'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import Swal from 'sweetalert2'
import _ from 'lodash'

NProgress.configure({
    showSpinner: false
});

const router = new VueRouter({
    mode:   'history',
    routes: [
        {
            path: '*',
            redirect: {
                name: 'Home'
            }
        },
        // {
        //     name:   'Maintenance',
        //     path:   '/manutencao/:secret?',
        //     props: true,
        //     component: require('./components/layout/Maintenance.vue').default,
        //     meta: {
        //         title: 'Manutenção!'
        //     }
        // },
        {
            name:   'Auth',
            path:   '/auth/token/:token',
            props: true,
            component: require('./components/layout/Authenticate.vue').default,
            meta: {
                title: 'Autenticando...'
            }
        },
        {
            name:   'PrintMap',
            path:   '/mapa/agendamento/imprimir',
            component: require('./components/schedule/PrintMap.vue').default,
            meta: {
                title: 'Imprimir agendamentos'
            }
        },
        {
            name:   'PrintReport',
            path:   '/relatorio/imprimir',
            component: require('./components/reports/PrintReport.vue').default,
            meta: {
                title: 'Imprimir relatório'
            }
        },
        {
            name:   'PrintJourneyScheduling',
            path:   '/pesquisa/:journey_id/imprimir/:with_logs?',
            props:  true,
            component: require('./components/search/PrintJourneyScheduling.vue').default,
            meta: {
                title: 'Imprimir jornada',
                middlewareAuth: true
            }
        },
        {
			path: '/',
			component: require('./components/layout/Index.vue').default,
			children: [
				{
					path: '/',
					component: require('./components/layout/EmptyParentComponent.vue').default,
					children: [
						{
							name: 'Home',
							path: 'home',
							component: require('./components/home/Index.vue').default,
							default: true,
							meta: {
                                middlewareAuth: true,
								title:          'Home',
                                menuType:       'default',
                                role:           null
							}
						},
                        {
                            name: 'Reports',
                            path: 'relatorios',
                            component: require('./components/reports/Index.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Relatórios',
                                menuType:       'carousel',
                                role:           7
                            }
                        },
                        {
                            name: 'Profile',
                            path: 'perfil',
                            component: require('./components/profile/Index.vue').default,
							props: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Perfil',
                                menuType:       'carousel',
                                role:           null
                            }
                        },
                        {
                            name: 'Organizations',
                            path: 'organizacoes',
                            component: require('./components/organizations/Index.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Organizações',
                                menuType:       'carousel',
                                role:           null
                            }
                        },
                        {
                            name: 'NewOrganization',
                            path: 'organizacoes/nova',
                            props: true,
                            component: require('./components/organizations/New.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Nova Organização',
                                menuType:       'carousel',
                                role:           null
                            }
                        },
                        {
                            name: 'OrganizationPanel',
                            path: 'organizacao/:organization_id',
                            props: true,
                            component: require('./components/organizations/Panel.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Dados da Organização',
                                menuType:       'carousel',
                                role:           null
                            }
                        },
                        {
                            name: 'ChangeOrganizationPlan',
                            path: 'organizacao/:organization_id/mudar-plano/:plan_id',
                            props: true,
                            component: require('./components/organizations/ChangePlan.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Mudar plano da organização',
                                menuType:       'carousel',
                                role:           null
                            }
                        },
                        {
                            name: 'Patients',
                            path: 'pacientes',
                            component: require('./components/patient/Index.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Gerenciar Pacientes',
                                menuType:       'carousel',
                                role:           null
                            }
                        },
                        {
                            name: 'PatientPanel',
                            path: 'paciente/:patient_id',
                            props: true,
                            component: require('./components/patient/Panel.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Gerenciar Paciente',
                                menuType:       'carousel',
                                role:           null
                            }
                        },
                        {
                            name: 'Schedules',
                            path: 'agendas',
                            props:  true,
                            component: require('./components/schedule/Schedules.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Agendas',
                                menuType:       'carousel',
                                role:           12
                            }
                        },
                        {
                            name: 'ScheduleWizard',
                            path: 'agenda/:modality_id/nova',
                            props: true,
                            component: require('./components/schedule/ScheduleWizard.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Nova Agenda',
                                menuType:       'carousel',
                                role:           12
                            }
                        },
                        {
                            name: 'ScheduleConfig',
                            path: 'agenda/:schedule_id/configurar',
                            props: true,
                            component: require('./components/schedule/ScheduleConfig.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Configurar Agenda',
                                menuType:       'carousel',
                                role:           12
                            }
                        },
                        {
                            name: 'SchedulingMap',
                            path: 'mapa/agendamento',
                            component: require('./components/schedule/Map.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Mapa de Agendamento',
                                menuType:       'carousel',
                                role:           6
                            }
                        },
                        {
                            name: 'SchedulingWizard',
                            path: 'agenda/agendamento',
                            component: require('./components/schedule/SchedulingWizard.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Agendamento',
                                menuType:       'carousel',
                                role:           3
                            }
                        },
                        {
                            name: 'ReschedulingWizard',
                            path: 'agenda/reagendamento/',
                            props:  true,
                            component: require('./components/schedule/ReschedulingWizard.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Reagendamento',
                                menuType:       'carousel',
                                role:           3
                            }
                        },
                        {
                            name: 'FitWizard',
                            path: 'agenda/encaixe',
                            props: true,
                            component: require('./components/schedule/FitWizard.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Encaixe',
                                menuType:       'carousel',
                                role:           4
                            }
                        },
                        {
                            name: 'ReFitWizard',
                            path: 'agenda/encaixar-agendamento/:scheduling_id',
                            props: true,
                            component: require('./components/schedule/ReFitWizard.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Encaixe',
                                menuType:       'carousel',
                                role:           4
                            }
                        },
                        {
                            name: 'ConfirmWizard',
                            path: 'agenda/confirmacao',
                            component: require('./components/schedule/ConfirmWizard.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Confirmação',
                                menuType:       'carousel',
                                role:           5
                            }
                        },
                        {
                            name: 'Proceedings',
                            path: 'procedimentos',
                            component: require('./components/schedule/Proceedings.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Procedimentos',
                                menuType:       'carousel',
                                role:           11
                            }
                        },
                        {
                            name: 'Partnerships',
                            path: 'convenios',
                            component: require('./components/partnership/Index.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Convênios',
                                menuType:       'carousel',
                                role:           13
                            }
                        },
                        {
                            name: 'NewPartnership',
                            path: 'convenio/novo',
                            component: require('./components/partnership/Form.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Novo Convênio',
                                menuType:       'carousel',
                                role:           13
                            }
                        },
                        {
                            name: 'EditPartnership',
                            path: 'convenio/:partnership_id/editar',
                            props:  true,
                            component: require('./components/partnership/Form.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Editar Convênio',
                                menuType:       'carousel',
                                role:           13
                            }
                        },
                        {
                            name: 'FlowWizard',
                            path: 'fluxo-de-atendimento',
                            component: require('./components/schedule/FlowWizard.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Fluxo de atendimento',
                                menuType:       'carousel',
                                role:           14
                            }
                        },
                        {
                            name: 'EHR',
                            path: 'registro-hospitalar',
                            component: require('./components/ehr/EHR.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Registro Hospitalar',
                                menuType:       'carousel',
                                role:           15
                            }
                        },
                        {
                            name: 'ScheduleSearch',
                            path: 'pesquisar/agendamentos',
                            component: require('./components/search/Index.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Pesquisa de Agendamentos',
                                menuType:       'carousel',
                                role:           9
                            }
                        },
                        {
                            name: 'GoBack',
                            path: '401/redirect',
                            component: require('./components/layout/GoBack.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title: 'Voltando...',
                                menuType: 'carousel'
                            }
                        },
						{
							path: '',
							redirect: {
								name: 'Home'
							}
						}
					]
				},
                {
                    path:   '/redirect',
                    component: require('./components/layout/EmptyParentComponent.vue').default,
                    children: [
                        {
                            name: 'REDIR_WorklistWizard',
                            path: 'wizard-worklist',
                            component: require('./components/layout/Redirect.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Redirect',
                                appRouteName:   'WizardWorklist',
                                role:           1
                            }
                        },
                        {
                            name: 'REDIR_WorklistMasks',
                            path: 'wizard-masks',
                            component: require('./components/layout/Redirect.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Redirect',
                                appRouteName:   'WizardMasks',
                                role:           2
                            }
                        },
                        {
                            name: 'REDIR_DocumentSearch',
                            path: 'document-search',
                            component: require('./components/layout/Redirect.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Redirect',
                                appRouteName:   'DocumentSearch',
                                role:           8
                            }
                        },
                        {
                            name: 'REDIR_Library',
                            path: 'library',
                            component: require('./components/layout/Redirect.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Redirect',
                                appRouteName:   'Library',
                                role:           17
                            }
                        },
                        {
                            name: 'REDIR_VariableObject',
                            path: 'variable-object',
                            component: require('./components/layout/Redirect.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Redirect',
                                appRouteName:   'VariableObject',
                                role:           18

                            }
                        },
                        {
                            name: 'REDIR_DynamicObject',
                            path: 'dynamic-object',
                            component: require('./components/layout/Redirect.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Redirect',
                                appRouteName:   'DynamicObject',
                                role:           19
                            }
                        },
                        {
                            name: 'REDIR_Specialties',
                            path: 'specialties',
                            component: require('./components/layout/Redirect.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Redirect',
                                appRouteName:   'Specialties',
                                role:           20
                            }
                        },
                        {
                            name: 'REDIR_EditMasks',
                            path: 'edit-masks',
                            component: require('./components/layout/Redirect.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Redirect',
                                appRouteName:   'EditMasks',
                                role:           21
                            }
                        }
                    ]
                },
                {
                    path: '',
                    redirect: {
                        name: 'Home'
                    }
                }
			]
		}
    ]
});

const permitted_routes = [
	'GoBack',
	'Home',
	'Profile',
	'EmailValidation',
	'Organizations',
	'OrganizationPanel',
];

let is_navigating = false

router.beforeEach(async (to, from, next) => {
    is_navigating = true

    if (to.matched.some(record => record.meta.middlewareAuth)) {
        let roles       = new Vuex().getters['auth/roles'];
        const requester = new Vuex().getters['auth/requester_selected_object'];
        const requester_pf = requester && requester.workgroup_schema && requester.workgroup_schema.substring(0, 2) === "pf" ? true : false;

        if(to.name != 'Auth' && requester) {
            const pf = requester.account_provider_id === requester.account_requester_id
        
            if(requester && !requester.confirmed && !pf)
            {
                roles = [ 10 ]
            }
        }

		if(!await CheckEmailVerified(to, from, next)) return;

		if(!await CheckPermittedRoutes(to, next)) return;

        if(to.meta.role && roles.indexOf(to.meta.role) < 0) {
            if (!requester.confirmed && !requester_pf) {
                Swal.fire({
                    icon:   'warning',
                    title:  'Eita!',
                    text:   `Você deve aceitar o convite da organização para continuar!`
                })
                    .then(() => {
                        next({ name: 'Organizations' })
                    })
    
                return;
            }

            Swal.fire({
                icon:   'error',
                title:  'Eita!',
                text:   'Você não possui permissão para acessar.'
            })

            return;
        }

        NProgress.start()

        if(!new Vuex().getters['auth/check']) {
            window.location.href = `${process.env.VUE_APP_LANDING_PAGE_URL}/login`

            return;
        }
    }

    next();
})

router.afterEach((to) => {
    document.title = 'IM | ' + to.meta.title

    NProgress.done()

    is_navigating = false
})

async function CheckEmailVerified(to, from, next) {
	const account = { ...new Vuex().getters['auth/account'] }
	
	if(permitted_routes.indexOf(to.name) === -1) {
		if(!account.email || !account.email_verified_at) {
			const obj_swal = {
				title: 				'Ops!',
				text: 				'Autentique o seu e-mail para continuar!',
				icon: 				'info',
				confirmButtonText: 	'Ok',
                backdrop:           false,
				preConfirm: () => {
					next({ 
						name: 'Profile',
						params: { 
							props_tab: 'email'
						}
					})
				}
			}

			Swal.fire({
				...obj_swal
			})

			return false;
		}
	}
	
	return true;
}

async function CheckPermittedRoutes(to, next) {
	const subscription_info = { ...new Vuex().getters['auth/subscription_info'] }
	
	if(permitted_routes.indexOf(to.name) === -1) {
		let obj_swal = {
			icon: 'warning',
			title: 'Eita!',
			confirmButtonText: 'Ir para Assinatura',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			preConfirm: () => {
				next({ 
					name: 'Profile', 
					params: { 
						props_tab: 'subscriptions' 
					} 
				})
			}
		};

		if(_.isEmpty(subscription_info)) {
			return true;
		}

		if(subscription_info) {
            if(subscription_info.is_free) return true;

			switch(subscription_info.status) {
				case 'active':
				case 'trialing':
					return true;
				case 'past_due':
					if(!subscription_info.has_payment_method) {
						obj_swal.html				= `Seu período de teste já expirou!<br>
							<br>Cadastre uma forma de pagamento para continuar utilizando todo o sistema!`
						obj_swal.confirmButtonText	= 'Cadastrar forma de pagamento';
						obj_swal.preConfirm			= () => {
							next({ 
								name: 'Profile', 
								params: { 
									props_tab: 'payments' 
								} 
							})
						}
					} else {
						obj_swal.html				= `Erro ao processar o pagamento!<br>
							<br>Verifique se há um método de pagamento válido e tente processar o pagamento, ou entre em contato com o suporte!`
					}
					break;
				case 'no-subscription':
					obj_swal.html 				= 'Você não possui uma assinatura ativa!';
					break;
			}

			Swal.fire({
				...obj_swal
			});

			return false;
		}
	}

	return true;
}

export { router, is_navigating };