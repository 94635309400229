export default {
	account:						JSON.parse(localStorage.getItem('account')),
	crms: 							JSON.parse(localStorage.getItem('crms')),
	requesters: 					JSON.parse(localStorage.getItem('requesters')),
	token: 							localStorage.getItem('token'),
	roles: 							JSON.parse(localStorage.getItem('roles')),
	requester_selected_id: 			localStorage.getItem('requester_selected_id'),
	online_accounts: 				[],
	data_migrating_organizations:	[],
	is_registered:					false,
	recurring_object:				null,
	subscription_info:				null,
	cards:							typeof localStorage.getItem('cards') !== undefined ? JSON.parse(localStorage.getItem('cards')) : [],
	update_current_url:				false
}