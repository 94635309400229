import Vue from 'vue'

//DATATABLES
import DataTables from './components/shared/DataTables'
Vue.component('DataTables', DataTables)

//PATIENTFORM
import PatientForm from './components/shared/PatientForm'
Vue.component('PatientForm', PatientForm)

//AUTOCOMPLETE
import Autocomplete from './components/shared/Autocomplete'
Vue.component('Autocomplete', Autocomplete)