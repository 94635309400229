<template>
	<div class="container-fluid">
		<div class="row align-items-center justify-content-between">
			<div v-if="form_type != 'update-patient'" class="col-12">
				<div class="d-block mx-auto text-center max-350"
					:class="form_type != 'update' ? 'py-5' : 'pb-5 pt-1'">
					<span>{{ label }}</span>
					<h4 class="font-24 font-md-32 m-0">
						<template v-if="form_type == 'pre'">
							Pré cadastro
						</template>
						<template v-else-if="form_type == 'standard'">
							Cadastrar
						</template>
						<template v-else-if="form_type == 'update'">
							Confirmar cadastro
						</template>
					</h4>
				</div>
			</div>
			<div class="col-12">			
				<div v-if="form.rh" class="w-100 max-150 d-inline-block">
					<small>Prontuário</small><br>
					<b>{{ form.rh }}</b>
				</div>		
				<div 
					class="d-inline-block form-group ml-3 mb-0 form-check">
					<input 
						type="checkbox" 
						id="desconhecido"
						v-model="form.desconhecido"
					>
					<label for="desconhecido"
						v-if="edit">Desconhecido</label>
				</div>
				<hr v-if="form.rh">
				<div class="row mt-4">
					<div v-if="form_type != 'update-patient'" class="col-12 col-md-auto text-center">
						<div class="d-inline-block mt-2 mb-4">
							<template v-if="exihibit_avatar">
								<img 
									id="img-avatar"
									:src="exihibit_avatar" 
									class="max-150 rounded-lg"
									@click="ShowPhotoModal"
								>
							</template>
							<template v-else>
								<button 
									type="button" 
									class="btn btn-light-dark py-5"
									@click="ShowPhotoModal"
								>
									<i class="far fa-user mx-5 my-2 font-20"></i>
								</button>
							</template>

							<br/>
							<button 
								type="button" 
								class="btn btn-light-primary mt-3 py-3"
								@click="UploadAvatar"
								:disabled="!edit"
							>
								selecionar
							</button>
							<input 
								type="file" 
								id="input-avatar" 
								accept=".jpg, .jpeg, .png" 
								@change="UploadAvatar($event)" 
								hidden
							>
						</div>
					</div>	
					<div class="col">					
						<div class="form-row">
						   	<div class="col-md-3">
						      	<div class="form-group">
						         	<label for="rg">RG</label>         
						         	<input 
							         	type="text" 
							         	id="rg"  
							         	class="form-control"
							         	:class="{ 
											'is-invalid': $v.form.rg.$error,
											'is-warning': exists_doc.rg
										}"
							         	autocomplete="off"
										v-mask="['XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX']"
							         	v-model="$v.form.rg.$model"
										ref="rg"
										:disabled="!edit"
										@keyup="( exists_doc.rg = false )"
						        	>
							        <div class="invalid-feedback">
										Obrigatório
									</div>
						      	</div>
						   	</div>
						   	<div class="col-md-2">
						      	<div class="form-group">
						         	<label for="estado">UF</label> 
						         	<select 
							         	id="estado" 
							         	class="form-control"
							         	:class="{ 
											'is-invalid': $v.form.rg_uf.$error,
											'is-warning': exists_doc.rg
										}"
							         	v-model="$v.form.rg_uf.$model"
										ref="rg_uf"
										:disabled="!edit"
										@change="( exists_doc.rg = false )"
							        >
						            	<option value="">UF</option>
						            	<option
						            		v-for="state in states"
						            		:key="state.initials"
						            		:value="state.initials"
						            	>
						            		{{ state.initials }}
						            	</option>
						         	</select>
						         	<div class="invalid-feedback">
										Obrigatório
									</div>
						      	</div>
						   	</div>
						   	<div class="col-md-4 col-12">
						      	<div class="form-group">
						         	<label for="cpf">CPF</label>         
						         	<input 
							         	type="tel" 
							         	id="cpf" 
							         	class="form-control"
							         	:class="{ 
											'is-invalid': $v.form.cpf.$error,
											'is-warning': exists_doc.cpf
										}"
							         	autocomplete="off"
							         	v-mask="['###.###.###-##']"
							         	v-model="$v.form.cpf.$model"
										ref="cpf"
										:disabled="!edit"
										@keyup="( exists_doc.cpf = false )"
						         	>
						         	<div 
						         		class="invalid-feedback"
						         		v-if="!$v.form.cpf.ValidateDocument"
						         	>
										Documento inválido
									</div>
						        	<div class="invalid-feedback" v-else>
										Obrigatório
									</div>
						      	</div>
						   </div>
						   <div class="col-md-3 col-12">
						      	<div class="form-group">
						         	<label for="sus">SUS</label>         
						         	<input 
							         	type="text" 
							         	id="sus" 
							         	class="form-control"
							         	:class="{ 
											'is-invalid': $v.form.sus.$error,
											'is-warning': exists_doc.sus
										}"
							         	autocomplete="off"
										v-mask="['XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX']"
							         	v-model="$v.form.sus.$model"
										ref="sus"
										:disabled="!edit"
										@keyup="( exists_doc.sus = false )"
						        	>
						        	<div class="invalid-feedback">
										Obrigatório
									</div>
						      	</div>
						   </div>
						   <div class="col-12 col-md-9">
						      	<div class="form-group">
						         	<label for="nome_paciente">Nome do Paciente</label>
						         	<input 
						         		type="text" 
						         		id="nome_paciente" 
						         		class="form-control"
							         	:class="{ 'is-invalid': $v.form.nome_paciente.$error }"
							         	v-model="$v.form.nome_paciente.$model"
										ref="nome_paciente"
										:disabled="!edit"
						         	>
						         	<div class="invalid-feedback">
										Obrigatório
									</div>
						        </div>
						   	</div>
						   	<div class="col-12 col-md-3">
						      	<div class="form-group">
						         	<label for="sexo" class="obrigatorio">Sexo</label> 
						         	<select
							         	id="sexo"
							         	:class="{ 'is-invalid': $v.form.sexo.$error }"
							         	v-model="$v.form.sexo.$model"
							         	class="form-control"
										ref="sexo"
										:disabled="!edit"
							        >
						            	<option value="">Selecione...</option>
							            <option value="m">Masculino</option>
							            <option value="f">Feminino</option>
							            <option value="x">Indeterminado</option>
						         	</select>
						         	<div class="invalid-feedback">
										Obrigatório
									</div>
						      	</div>
						   	</div>
						   	<div class="col-md-9 col-12">
						      	<div class="form-group">
						         	<label for="nome_social">Nome Social</label>
						         	<input 
						         		type="text" 
						         		id="nome_social"
						         		class="form-control"
						         		v-model="form.nome_social"
										:disabled="!edit"
						        	>
						     	</div>
						   	</div>
						   	<div class="col-md-3 col-12">
						      	<div class="form-group">
						         	<label for="genero">Gênero</label> 
						         	<select 
						         		id="genero" 
						         		class="form-control"
						         		v-model="form.genero"
										:disabled="!edit"
						         	>
							            <option value="">Selecione...</option>
							            <option value="m">Masculino</option>
							            <option value="f">Feminino</option>
							            <option value="x">Indeterminado</option>
						         	</select>
						      	</div>
						   </div>
						   <div class="col-md-6 col-lg-3">
								<div class="form-group">
									<label for="data_nascimento">Data de Nascimento</label>
									<input 
										type="date" 
										id="data_nascimento"
										class="form-control"
										:class="{ 'is-invalid': $v.form.data_nascimento.$error }"
							         	v-model="$v.form.data_nascimento.$model"
										ref="data_nascimento"
										:disabled="!edit"
									>
						         	<div class="invalid-feedback">
										Obrigatório
									</div>
								</div>
						   </div>
						   <div class="col-md-6 col-lg-3">
								<div class="form-group">
									<label for="telefone_1">Telefone</label>
									<input 
										type="text" 
										id="telefone_1" 
										class="form-control"
										:class="{ 'is-invalid': $v.form.telefone_1.$error }"
							         	v-model="$v.form.telefone_1.$model"
					         			v-mask="['(##) ####-####', '(##) #####-####']"
										ref="telefone_1"
										:disabled="!edit"
									>
									<div class="invalid-feedback">
										Obrigatório
									</div>
								</div>
						   </div>
						   <div class="col-md-12 col-lg-6">
								<div class="form-group">
									<label for="email">E-mail</label>
									<input 
										type="email" 
										id="email" 
										class="form-control"
										v-model="form.email"
										:disabled="!edit"
									>
								</div>
						   </div>
						   <div class="col-12">
						      	<div class="form-group">
									<label for="nome_mae">Nome da Mãe</label>
									<input 
										type="text" 
										id="nome_mae"  
										class="form-control"
										v-model="form.nome_mae"
										:disabled="!edit"
									>
						     	</div>
						   </div>
						</div>
					</div>
				</div>

				<div 
					class="d-flex align-items-center mt-5" 
					data-toggle="collapse" 
					data-target="#informacoes"
				>
					<button class="btn btn-light btn-sm mr-3 px-3 py-2">
						<i class="far fa-chevron-down my-2"></i>
					</button>
					<h4>Endereço</h4>
				</div>
				<hr class="mb-0">			
				<div id="informacoes" class="collapse">
					<div class="row mt-5">
					   	<div class="col-md-3">
					      	<div class="form-group">
					         	<label for="cep">CEP</label>         
					         	<input 
					         		type="text" 
					         		id="cep"
					         		class="form-control"
					         		v-mask="['#####-###']"
								    v-on:keyup.enter="SearchZipCode()"
								    @blur="SearchZipCode()" 
					         		autocomplete="off"
					         		v-model="form.cep"
									:disabled="!edit"
					         	>
					      	</div>
					   	</div>	  
					   	<div class="col-md-9">
					      	<div class="form-group">
					         	<label for="logradouro">Logradouro</label>         
					         	<input 
						         	type="text" 
						         	id="logradouro"  
						         	class="form-control" 
						         	autocomplete="off"
						         	v-model="form.logradouro"
									:disabled="!edit"
						        >
					      	</div>
					   	</div>							    							   
					  	<div class="col-md-5">
					      	<div class="form-group">
					         	<label for="bairro">Bairro</label>         
					         	<input 
						         	type="text" 
						         	id="bairro"  
						         	class="form-control" 
						         	autocomplete="off"
						         	v-model="form.bairro"
									:disabled="!edit"
						        >
					      	</div>
					   	</div> 							   
					   	<div class="col-md-5">
					      	<div class="form-group">
					         	<label for="municipio">Municipio</label>         
					         	<input 
						         	type="text" 
						         	id="municipio"  
						         	class="form-control" 
						         	autocomplete="off"
						         	v-model="form.municipio"
									:disabled="!edit"
						        >
					      	</div>
					   	</div> 
					   	<div class="col-md-2">
					      	<div class="form-group">
					         	<label for="estado">UF</label> 
					         	<select 
						         	id="estado" 
						         	class="form-control"
						         	v-model="form.estado"
									:disabled="!edit"
						        >
					            	<option value="">UF</option>
					            	<option
					            		v-for="state in states"
					            		:key="state.initials"
					            		:value="state.initials"
					            	>
					            		{{ state.initials }}
					            	</option>
					         	</select>
					      	</div>
					   	</div>
					   <div class="col col-md-12">
					      	<div class="form-group">
					         	<label for="complemento">Complemento</label>         
					         	<input 
						         	type="text" 
						         	id="complemento"  
						         	class="form-control" 
						         	autocomplete="off"
						         	v-model="form.complemento"
									:disabled="!edit"
					        	>
					      </div>
					   </div>
					</div>
				</div>

				<div 
					class="d-flex align-items-center mt-5" 
					data-toggle="collapse" 
					data-target="#complement"
				>
					<button class="btn btn-light btn-sm mr-3 px-3 py-2">
						<i class="far fa-chevron-down my-2"></i>
					</button>
					<h4>Dados complementares</h4>
				</div>
				<hr class="mb-0">	
				<div id="complement" class="collapse">
					<div class="row mt-5">
					   	<div class="col-md-4">
					      	<div class="form-group">
					         	<label for="telefone_2">Telefone 2</label>
					         	<input 
						         	type="text" 
						         	id="telefone_2"  
						         	class="form-control"
						         	v-model="form.telefone_2"
					         		v-mask="['(##) ####-####', '(##) #####-####']"
									:disabled="!edit"
						        >
					     	</div>
					   	</div>
					   	<div class="col-md-4">
					      	<div class="form-group">
					         	<label for="telefone_3">Telefone 3</label>
					         	<input 
					         		type="text" 
					         		id="telefone_3"  
					         		class="form-control"
					         		v-model="form.telefone_3"
					         		v-mask="['(##) ####-####', '(##) #####-####']"
									:disabled="!edit"
					         	>
					     	</div>
					   	</div>
					   	<div class="col-md-4">
					      	<div class="form-group">
					         	<label for="escolaridade">Escolaridade</label> 
					         	<select 
					         		id="escolaridade" 
					         		v-model="form.escolaridade" 
					         		class="form-control"
									:disabled="!edit"
					         	>
					         		<option>Selecione...</option>
					         	</select>
					      	</div>
					   	</div>
					   	<div class="col-md-6">
					      	<div class="form-group">
					         	<label for="raca">Raça</label> 
					         	<select 
					         		id="raca" 
					         		v-model="form.raca"
					         		class="form-control"
									:disabled="!edit"
					         	>
					         		<option>Selecione...</option>
					         	</select>
					      	</div>
					   	</div>	
					   	<div class="col-md-6">
					      	<div class="form-group">
					         	<label for="religiao">Religião</label> 
					         	<select 
					         		id="religiao" 
					         		class="form-control"
					         		v-model="form.religiao"
									:disabled="!edit"
					         	>
					         		<option>Selecione...</option>
					         	</select>
					      	</div>
					   	</div>							   
					   	<div class="col-md-12">
					      	<div class="form-group">
					         	<label for="observacao">Observação</label>         
					         	<textarea 
					         		id="observacao"
					         		v-model="form.observacao"
					         		class="form-control"
									:disabled="!edit"
					         	/>
					      	</div>
					   	</div>
					</div>			
				</div>
			</div>	
			<div class="col-12 text-center py-5">
				<div class="d-flex"
					:class="form_type != 'update-patient' ? 'justify-content-between' : 'justify-content-end'">
					<slot name="actions" :Save="PreSavePatient"></slot>
				</div>
			</div>
		</div>
		<div class="modal fade" id="modal-webcam" v-if="form_type != 'update-patient'">
			<div class="modal-dialog mw-100 d-flex justify-content-center align-items-center">
				<div class="modal-content">
					<button 
						id="close-modal" 
						class="btn btn-light btn-sm"
						@click="ClosePhotoModal"
					>
						<i class="fal fa-times"></i>
					</button>
					<div class="modal-header px-5 py-4 mt-5 mt-sm-1">
			    		<h4 class="font-20 m-0 text-center">Registrar a foto do paciente</h4>
			    	</div>
					<div class="modal-body d-flex flex-column align-items-center">
						<div v-if="webcam.snap" id="img-snapped">
							<img 
								:src="webcam.snap"
								width="100%"
								alt="Foto do paciente">
						</div>
						<WebCam
							v-show="!webcam.snap"
							ref="webcam"
							id="webcam"
							:device-id="deviceId"
							screenshotFormat="image/png"
							width="100%"
							height="100%"
							@cameras="onCameras"
							@camera-change="onCameraChange"
						>
						</WebCam>
						
						<span v-if="!camera && !webcam.snap" class="align-self-center">
							Aguarde a imagem da câmera aparecer...
						</span>
						<button 
							class="btn btn-light my-4"
							@click="SnapPhoto"
							:disabled="!webcam.ready || !edit"
						>
							{{ webcam.snap ? 'Tirar outra foto' : 'Tirar foto' }}
						</button>
						<span class="mb-4 text-success">* Para aceitar a foto que foi tirada basta fechar essa janela *</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../helpers'
	const helpers 			= new Helpers()

	const _ 				= require('lodash')

	const ValidateDocument 	= (value, vm) => {
	
		if(value)
		{
			let ret 		= false
			let cpf 		= value

			if(cpf == null || cpf == '')
			{
				ret 	= true

			} else if(cpf != null && cpf.length < 14)
			{
				ret 	= false

			} else if(cpf != null && cpf.length == 14)
			{
				ret 	= helpers.ValidaCPF(cpf)
			}

			return ret
		}

		return true
	}

	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import { required, requiredUnless, requiredIf, minLength } from 'vuelidate/lib/validators'
	import { WebCam } from 'vue-web-cam'
	export default {

		name: 'PatientForm',
		props: 	{
			label: 		{
				type: 		String,
				default: 	'' 
			},
			form_type: 	{
				type: 		String,
				default: 	'standard' //STANDARD, PRE, UPDATE
			},
			patient: 	{
				type: 		Object,
				default: 	null
			},
			edit: {
				type:			Boolean,
				default:		false
			},
			base_name: {
				type:		String,
				default:	null
			}
		},
		components: {
			WebCam
		},
		data () {
			return {
				exihibit_avatar:		'',
				states: 	window.statesBR,
				form: 		{
					desconhecido: 		false,
					nome_paciente:		'',
					nome_social: 		'',
					rh: 				'',
					rg: 				'',
					rg_uf: 				'',
					cpf: 				'',
					sus: 				'',
					sexo: 				'',
					genero: 			'',
					data_nascimento: 	'',
					telefone_1: 		'',
					telefone_2: 		'',
					telefone_3: 		'',
					email: 				'',
					nome_mae: 			'',
					cep: 				'',
					logradouro: 		'',
					complemento: 		'',
					bairro: 			'',
					municipio: 			'',
					estado: 			'',
					escolaridade: 		'',
					profissao: 			'',
					raca: 				'',
					religiao: 			'',
					avatar: 			'',
					observacao: 		''
				},
				exists_doc: {
					cpf: false,
					rg: false,
					sus: false
				},
				webcam:		{
					ready:		false,
					mode:		'user',
					snap:		null
				},
				camera: 		null,
				deviceId:		null,
				devices:		[]
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			atLeastOne()
			{
				return !(this.form.rg || this.form.sus || this.form.cpf)
			},
			haveRG()
			{
				return !!this.form.rg
			},
			device: function() {
				return this.devices.find(n => n.deviceId === this.deviceId);
			},
			base64OrCDNImage()
			{
				if(!this.form.avatar) return false

				if(this.form.avatar instanceof File) return false

				let base_text 	= this.form.avatar.substr(0, 21)
				let url_text	= this.form.avatar.substr(0, 7)
				return base_text == 'data:image/png;base64' || base_text == 'data:image/jpg;base64' || base_text == 'data:image/jpeg;base6' || url_text == 'http://'
			}
		},
		validations()
		{
			let obj = {
				form: 		{
					nome_paciente:	{
						required: requiredIf(() => {
							return !this.form.desconhecido
						})
					},
					telefone_1:		{
						minLength: minLength(14),
						required: requiredIf(() => {
							return !this.form.desconhecido
						})
					},
					rg: 			{
						required: requiredIf(() => {
							return this.atLeastOne && !this.form.desconhecido && ['standard', 'update'].indexOf(this.form_type) > -1
						})
					},
					rg_uf: 			{
						required: requiredIf(() => {
							return this.haveRG && !this.form.desconhecido && ['standard', 'update'].indexOf(this.form_type) > -1
						})
					},
					sus: 			{
						required: requiredIf(() => {
							return this.atLeastOne && !this.form.desconhecido && ['standard', 'update'].indexOf(this.form_type) > -1
						})
					},
					cpf: 			{
						required: requiredIf(() => {
							return this.atLeastOne && !this.form.desconhecido && ['standard', 'update'].indexOf(this.form_type) > -1
						}),
						ValidateDocument
					},
					data_nascimento: 	{
						required: requiredIf(() => {
							return !this.form.desconhecido && ['standard', 'update'].indexOf(this.form_type) > -1
						})
					},
					sexo: 				{
						required: requiredIf(() => {
							return !this.form.desconhecido && ['standard', 'update'].indexOf(this.form_type) > -1
						})
					}
				}
			}
			
			return obj
		},
		watch: {
			patient: 	{
				handler(val) {
					if(val && !_.isEmpty(val)) {
						let p 		= {...val}

						if(p.telefone) {
							let telefone 		= JSON.parse(p.telefone)

							if(Array.isArray(telefone) && telefone.length > 0) {
								telefone.forEach((t, index) => {
									p[`telefone_${index + 1}`] 	= t
								})
							}
						} else {
							// Fix para quando o telefone for null no banco, que é diferente de [null, null, null]
							p.telefone_1 = ''
						}
						
						delete p['telefone']

						delete p.schedulings

						this.form 		= p
					}
				},
				immediate: 	true,
				deep: 		true
			},
			'form.desconhecido': {
				handler(val)
				{
					if(val)
					{
						if(!this.form.nome_paciente) {
							this.form.nome_paciente 	= 'DESCONHECIDO'
						}

						this.$v.form.$touch()

						this.$v.form.$reset()
					}
				}
			},
			'form.nome_paciente': {
				handler(val)
				{
					if(val != null)
					{
						this.form.nome_paciente 		= helpers.RemoveAcento(val).toUpperCase()
					}
				}
			},
			'form.nome_mae': {
				handler(val)
				{
					if(val != null)
					{
						this.form.nome_mae 			= helpers.RemoveAcento(val).toUpperCase()
					}
				}
			},
			'form.nome_social': {
				handler(val)
				{
					if(val != null)
					{
						this.form.nome_social 		= helpers.RemoveAcento(val).toUpperCase()
					}
				}
			},
			'form.logradouro': {
				handler(val)
				{
					if(val != null)
					{
						this.form.logradouro 		= helpers.RemoveAcento(val).toUpperCase()
					}
				}
			},
			'form.complemento': {
				handler(val)
				{
					if(val != null)
					{
						this.form.complemento 		= helpers.RemoveAcento(val).toUpperCase()
					}
				}
			},
			'form.municipio': {
				handler(val)
				{
					if(val != null)
					{
						this.form.municipio 		= helpers.RemoveAcento(val).toUpperCase()
					}
				}
			},
			'form.bairro': {
				handler(val)
				{
					if(val != null)
					{
						this.form.bairro 			= helpers.RemoveAcento(val).toUpperCase()
					}
				}
			},
			'form.religiao': {
				handler(val)
				{
					if(val != null)
					{
						this.form.religiao 			= helpers.RemoveAcento(val).toUpperCase()
					}
				}
			},
			'form.avatar': {
				immediate: true,
				handler(val)
				{
					if(val)
					{
						this.exihibit_avatar = val
					}
				}
			},
			camera(id) 
			{
            	this.deviceId = id;
			},
			devices()
			{
				const [first, ...tail] = this.devices;

				if (first) 
				{
					this.camera 			= first.deviceId;
					this.deviceId 			= first.deviceId;
				}
			},
			base_name: {
				handler(val) {
					if(val) {
						this.form.nome_paciente = val
					}
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			async ResetExistsPatientDocs() {
				this.exists_doc = {
					cpf: false,
					rg: false,
					sus: false
				}

				return this.exists_doc
			},
			async CheckPatientDocs() {
				const vm = this;

				await vm.ResetExistsPatientDocs()

				const is_unknown = vm.form.cpf == 'DESCONHECIDO' || vm.form.sus == 'DESCONHECIDO' || (vm.form.rg == 'DESCONHECIDO' && vm.form.rg_uf == 'DESCONHECIDO' )

				const need_validate = vm.form.cpf || vm.form.sus || (vm.form.rg && vm.form.rg_uf)

				if(is_unknown || !need_validate) {
					return vm.ResetExistsPatientDocs()
				}

				vm.StartLoading();

				return await window.api.call('post', '/check-patients-docs', {
					organization_id: this.requester_selected_id,
					...this.form,
				})
					.then(({data}) => {
						if(data.response) {
							this.exists_doc = data.docs;

							return this.exists_doc
						}
					})
					.finally(() => {
						vm.FinishLoading();
					})
			},
			async PreSavePatient() {
				this.$v.form.$touch()

				if(!this.$v.form.$invalid) {
					const { cpf, rg, sus } = await this.CheckPatientDocs()

					if(cpf || rg || sus) {
						const existent_docs = Object.entries(this.exists_doc)
							.filter(d => d[1])
							.map(d => d[0].toUpperCase())

						let docs_html = `O documento <b>(${existent_docs[0]})</b> já está vinculado à outro paciente, se continuar poderá gerar uma duplicidade de prontuário!`

						if(existent_docs.length > 1) {
							docs_html = `Os documentos <b>(${existent_docs.join(', ')})</b> já estão vinculados à outro paciente, se continuar poderá gerar uma duplicidade de prontuário!`
						}
 
						Swal.fire({
							title: 'Atenção!',
							html: docs_html,
							icon: 'warning',
							confirmButtonText: 'Sim, continuar',
							showDenyButton: true,
							denyButtonText: 'Não, cancelar',
							reverseButtons: true,
						})
							.then(async (result) => {
								if(result.isConfirmed) {
									await this.SavePatient();
								}
							})
					} else {
						await this.SavePatient()
					}
				} else {
					window.helpers.focusInvalid(this.$v.form, this)
				}
			},
			async SavePatient() {
				this.StartLoading()

				const formData 		= new FormData()

				let obj 			= {
					account_id: 	this.requester_selected_id,
					form_type: 		this.form_type,
					...this.form
				}

				if(obj.id)
				{
					obj.patient_id 		= obj.id
					
					delete obj.id
				}

				Object.keys(obj).map((key, index) => {
					if(key != 'avatar' && obj[key]) {
						formData.append(key, obj[key])
					}
				})

				if(obj['desconhecido']) {
					formData.append('desconhecido', obj['desconhecido'])
				}

				let avatarSaved = false

				if(this.form.avatar instanceof File) {
					formData.append('avatar', this.form.avatar, 'avatar.jpg')
				} else if(this.form.avatar) {

					if(this.form.avatar.indexOf('http') > -1)
					{
						avatarSaved = true
					}

					if(!avatarSaved)
					{
						let avatar 	= this.form.avatar.replace('data:image/jpeg;base64,','')
						avatar 		= this.form.avatar.replace('data:image/png;base64,','')
						avatar 		= this.Base64ToBlob(avatar, 'image/jpeg')

						formData.append('avatar', avatar, 'avatar.jpg')
					}
				}

				await window.api.call('post', '/save-patient', formData)
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								toast:  	true,
								title:		'Paciente salvo com sucesso !',
								icon: 		'success',
								timer: 		3000,
								position: 	'top',
								timerProgressBar: true
							})

							this.ResetExistsPatientDocs()

							this.$emit('exit', data.patient)

							if(this.form_type == 'update-patient') {
								this.$emit('updated', this.patient.id)

								return 	
							}

							this.ClearForm()
						}else{
							Swal.fire({
								icon: 	'error',
								title: 	'Opss...',
								text: 	'Houve um erro ao salvar o paciente, contate o suporte.'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			async SearchZipCode()
			{
				const vm 		= this
				let cep 		= vm.form.cep

				if(cep.length == 9)
				{	
					vm.StartLoading()

					helpers.searchZipCode(cep)
						.then(( res ) => {

							vm.form.logradouro  	= res.address
							vm.form.bairro     		= res.district
							vm.form.complemento 	= res.complement
							vm.form.municipio   	= res.city
							vm.form.estado			= res.state
						})
						.finally(() => {
							vm.FinishLoading()
						})
				}

			},
			Base64ToBlob(base64, mime) 
			{
				mime 			= mime || '';
				let sliceSize 	= 1024;
				let byteChars 	= window.atob(base64);
				let byteArrays 	= [];

				for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) 
				{
					let slice 		= byteChars.slice(offset, offset + sliceSize);

					let byteNumbers = new Array(slice.length);

					for (let i = 0; i < slice.length; i++) 
					{
						byteNumbers[i] = slice.charCodeAt(i);
					}

					let byteArray 		= new Uint8Array(byteNumbers);

					byteArrays.push(byteArray);
				}

				return new Blob(byteArrays, { type: mime });
			},
			async ShowPhotoModal() 
			{
				const vm 	= this

				window.jQuery('#modal-webcam').modal({
						backdrop: 	false,
						show:		true
					})//.on('shown.bs.modal', () => {

						vm.webcam.ready = true

						vm.$refs.webcam.start()
											
						//GAMBITECH
						window.jQuery('body').addClass('modal-open')
					//})

				vm.webcam.snap = vm.form.avatar ?? null
			},
			SnapPhoto() 
			{
				if(this.webcam.snap) 
				{
					this.webcam.snap = null
					this.$refs.webcam.start()
					return 
				}
				
				this.webcam.snap = this.$refs.webcam.capture()
			},
			async ClosePhotoModal() 
			{	
				const vm 			= this

				vm.form.avatar 		= vm.webcam.snap || vm.form.avatar
				vm.exihibit_avatar  = vm.form.avatar

				window.jQuery('#modal-webcam').modal('hide')
					.on('hidden.bs.modal', () => {

						vm.$refs.webcam.stop()
						vm.webcam.ready = false

						//GAMBITECH
						if(vm.form_type == "pre" || vm.form_type == "standard")
						{
							window.jQuery('body').addClass('modal-open')
						}

					})
			},
			onCameras(cameras) 
			{
				this.devices = cameras
			},
			onCameraChange(deviceId) 
			{
				this.deviceId = deviceId
			},
			UploadAvatar(event) 
			{
				if(event.target.files)
				{
            		const reader 			= new FileReader()
					const file 				= event.target.files[0]
					
					if(file)
					{
						this.form.avatar 	= file
	
						reader.onload 		= e => this.exihibit_avatar = e.target.result
	
						reader.readAsDataURL(file)
					}
				}

				document.querySelector('#input-avatar').click()
			},
			ClearForm()
			{
				this.form.avatar 					= ''
				this.form.cpf 						= ''
				this.form.rg 						= ''
				this.form.rg_uf 					= ''
				this.form.sus 						= ''
				this.form.rh 						= ''
				this.form.nome_paciente 			= ''
				this.form.sexo 						= ''
				this.form.origem			   		= ''
				this.form.nome_social 				= ''
				this.form.genero 					= ''
				this.form.nome_mae 					= ''
				this.form.data_nascimento 			= ''
				this.form.logradouro 				= ''
				this.form.bairro 					= ''
				this.form.complemento 				= ''
				this.form.cep 						= ''
				this.form.municipio 				= ''
				this.form.estado 					= ''
				this.form.telefone_1  		    	= ''
				this.form.telefone_2 				= ''
				this.form.telefone_3 				= ''
				this.form.email 					= ''
				this.form.escolaridade 				= ''
				this.form.profissao 				= ''
				this.form.raca 						= ''
				this.form.religiao 					= ''
                this.form.observacao 				= ''

                this.$v.form.$reset()
			}
		},
	}
</script>

<style lang="css" scoped>
	#img-avatar {
		cursor: pointer;
	}

	#webcam, #img-snapped, #img-avatar {
		width: 100%;
	}

	#close-modal {
		position: absolute;
		right: 15px;
		top: 15px;
	}

	@media(min-width: 575.98px) {
		.modal-content {
			width: 75% !important;
		}
	}

	@media(min-width: 1000px) {
		.modal-content {
			max-width: 50% !important;
		}
	}

	@media(min-width: 1500px) {
		.modal-content {
			max-width: 800px !important;
		}
	}
</style>