<template>
	<div class="row align-items-center justify-content-between flex-column">
		<div class="col-12">
			<div class="d-block mx-auto pb-2 pb-md-3 text-md-center max-500 pr-6 pr-md-0">
				<span>{{ label }}</span>
				<h4 class="font-24 font-md-32 m-0">Confirmar Procedimento</h4>
			</div>
		</div>
		<div class="col-12 col-md-8 d-flex justify-content-between align-items-center mb-3">
			<button 
				class="btn btn-light px-md-5 my-5"
				@click="Previous"
			>
				voltar
			</button>
			<button 
				class="btn btn-light px-md-5 my-5"
				@click="OpenModal('#payment-methods-modal')"
			>
				pagamento
			</button>
		</div>
		<div 
			class="col-12"
			v-for="journey in journeys"
			:key="journey.id"
		>
			<div class="col-12 col-md-8 offset-md-2 mb-3 mb-md-5">
				<div class="form-row justify-content-between text-dark">
					<div class="col-auto">
						<span>ID: {{ journey.id }}</span>
					</div>
					<div class="col-auto">
						<span>
							Agenda:
							<strong> {{ journey.schedule.nome }}</strong>
						</span>
					</div>
					<div class="col-auto">
						<span>Quantidade: {{ journey.quantity }}</span>
					</div>
				</div>
				<hr class="split-journey">
			</div>
			<div 
				class="col-12 col-md-8 offset-md-2"
				v-for="scheduling in $v.items.$each.$iter"
				:key="scheduling.$model.id"
				:journey_id="scheduling.$model.journey_id"
			>
				<div 
					class="card mb-4"
					:class="selected.schedulings.indexOf(scheduling.$model.id) > -1 ? 'selected' : ''"
					v-if="journey.id === scheduling.$model.journey_id"
				>
					<div class="card-body p-4"
						@click.self="ClickOnInput(scheduling.$model.id)"
					>
						<div class="row"
							@click="ClickOnInput(scheduling.$model.id)"
						>
							<div class="col-auto pr-2">
								<div class="form-group m-0 form-check">
									<input 
										type="checkbox" 
										:id="`scheduling-${scheduling.$model.id}`"
										:value="scheduling.$model.id"
										v-model="selected.schedulings"
										@change="FormValidate"
									>
									<label 
										:for="`scheduling-${scheduling.$model.id}`"
										@click="ClickOnInput(scheduling.$model.id)"
									></label>
								</div>
							</div>
							<div class="col pl-0 pr-4">
								<p class="font-20 font-normal text-dark m-0">
									{{ scheduling.$model.proceeding.nome }}
								</p>
								<span 
									class="py-1 px-4 rounded d-inline-block mb-3 mt-2"
									:class="{
										'bg-danger': scheduling.$model.status == 11,
										'bg-success': scheduling.$model.status === 1
									}"
								/>
								<div class="font-14 mb-4" v-if="scheduling.$model.proceeding.tuss">
									{{scheduling.$model.proceeding.tuss.codigo}} - {{ scheduling.$model.proceeding.tuss.terminologia }}
								</div>

								<div class="row">
									<div class="col-12 max-100">Agenda</div>
									<div class="col">
										<p class="text-dark font-normal m-0">
											{{ scheduling.$model.schedule.nome }}
										</p>
									</div>
								</div>

								<div class="row">
									<div class="col-12 max-100">Data</div>
									<div class="col">
										<p class="text-dark font-normal m-0">
											{{ scheduling.$model.data }} às {{ scheduling.$model.hora }}
										</p>
									</div>
								</div>

							</div>
							<div class="col-12 mt-5" v-if="scheduling.$model.status == 11">
								<div 
									class="alert alert-danger text-center"
									role="alert"
								>
									<b>{{ scheduling.$model.status_descricao.toUpperCase() }}</b>
								</div>
							</div>
						</div>					
						<hr class="my-4"
							@click="ClickOnInput(scheduling.$model.id)"
						>
						<div class="form-row">
							<div class="col-12">
								<div class="form-group">
									<label 
										:for="`partnership-${scheduling.$model.id}`"
									>
										Convênio
									</label> 
									<select
										:id="`partnership-${scheduling.$model.id}`" 
										class="form-control"
										v-model="scheduling.$model.selected_partnership.id"
										:disabled="scheduling.$model.status == 11"
										@change="GetPartnership"
									>
										<option value="">Selecione...</option>
										<option 
											v-for="partnership in scheduling.$model.proceeding.partnerships"
											:key="partnership.id"
											:value="partnership.id"
										>
											{{ partnership.nome }}
										</option>
									</select>
								</div>
							</div>
							<template v-if="scheduling.$model.status != 11">
								<template v-if="scheduling.$model.partnership.exibe_autorizacao">
									<div class="col-md">
										<div class="form-group m-0">
											<label>Autorização</label>
											<input 
												type="text"
												class="form-control"
												:class="{ 'is-invalid': ValidateItem(scheduling.$model.authorization) }"
												v-model="scheduling.$model.authorization"
												@input="UpdateState(scheduling.$model.id)"
											>
											<div class="invalid-feedback">
												Obrigatória para o convênio
											</div>
										</div>
									</div>
								</template>
								<template v-if="scheduling.$model.partnership.exibe_guia">
									<div class="col-md">
										<div class="form-group m-0">
											<label>Guia</label>
											<input 
												type="text"
												class="form-control"
												:class="{ 'is-invalid': ValidateItem(scheduling.$model.guide) }"
												v-model="scheduling.$model.guide"
												@input="UpdateState(scheduling.$model.id)"
											>
											<div class="invalid-feedback">
												Obrigatória para o convênio
											</div>
										</div>
									</div>
								</template>
							</template>
					
						</div>
						<div 
							class="d-flex justify-content-end mt-4" 
							v-if="scheduling.$model.status == 11"
						>
							<button 
								class="btn btn-light-info btn-sm"
								@click="Fit(scheduling)"
							>
								encaixar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-8 text-center pb-4 py-md-3">
			<div class="row justify-content-between my-2 align-items-center">
				<div class="col">
					<div class="row align-items-center">
						<div class="col-12 col-md-6">
							<button 
								class="btn btn-light-danger btn-block mb-3 mb-md-0"
								:disabled="selected.schedulings.length === 0"
								@click="Cancel(selected.schedulings)"
							>
								cancelar
							</button>
						</div>
						<div class="col-12 col-md-6">
							<button 
								class="btn btn-light-info btn-block"
								:disabled="selected.schedulings.length === 0"
								@click="ReSchedule(selected.schedulings)"
							>
								reagendar
							</button>
						</div>
					</div>
				</div>
				<div class="col-auto">
					<button 
						class="btn btn-primary px-md-5"
						:disabled="hasnt_missing_schedulings"
						@click="Confirm"
					>
						confirmar <i class="far fa-check ml-4"></i>
					</button>
				</div>
			</div>
		</div>

		<PaymentMethodsModal
			:total="total_proceedings"
			@payment-methods-added="PaymentMethodsHandler"
		/>
	</div>
</template>

<script>
	const _ 		= require('lodash')

	import Swal from 'sweetalert2'
	import { required, requiredIf } from 'vuelidate/lib/validators'
	import PaymentMethodsModal from './PaymentMethodsModal.vue'
	export default {

		name: 'Proceedings',
		props: 		{
			label: 			{
				type: 			String,
				default: 		''
			},
			schedulings: 	{
				type: 			Array,
				default: 		() => []
			}
		},
		components: {
			PaymentMethodsModal
		},
		data () {
			return {
				items: 		[],
				selected: 	{
					schedulings: 	[],
					journey_id:		null,
					payment_methods_info:	null
				},
				form_invalid: 		false,
				validations_form: 	[]
			}
		},
		validations: {
			items: {
				$each: {
					authorization: 	{
						required: requiredIf((n) => {
							return n.selected && n.partnership.exibe_autorizacao
						})
					},
					guide: 			{
						required: requiredIf((n) => {
							return n.selected && n.partnership.exibe_guia
						})
					}
				}
			}
		},
		watch: {
			schedulings: {
				handler(val)
				{
					if(val.length > 0)
					{
						this.items 		= [...val]
					}
				},
				immediate: 	true,
				deep: 		true
			},
			'selected.schedulings': {
				handler(val) {
					this.items 		= this.items.map(item => {
							item.selected 	= false

							return item
						})

					if(val.length > 0) {
						val.forEach((scheduling_id) => {
							let find 	= _.findIndex(this.items, ['id', scheduling_id])

							if(find > -1) {
								this.items[find].selected 	= true
							}

						})
					}
				}
			}
		},
		computed: 	{
			hasnt_missing_schedulings()
			{
				let allowed = true

				if(this.selected.schedulings.length > 0)
				{
					allowed 	= false

					this.selected.schedulings.forEach( s => {

						let tmp 	= this.items.find( item => s == item.id)

						if(tmp && tmp.status == 11) //FALTA
						{
							allowed 	= true

							return;
						}
					})
				}

				return allowed				
			},
			journeys()
			{
				let arr = this.items.map(el => {
					const o = { 
						...el.journey,
						schedule:	 el.schedule,
						date:		 el.data
					}

					o.quantity	= this.items.filter(n => {
										return n.journey_id === el.journey_id
									}).length

					return o
				})
								

				const journeys_arr = _.uniqWith(arr, _.isEqual)

				return _.orderBy(journeys_arr, ['schedule.nome'], 'asc')
			},
			total_proceedings() {
				let value = 0

				const schedulings = this.items.filter(item => this.selected.schedulings.indexOf(item.id) > -1) ?? []

				if(!schedulings.length) return value

				value = schedulings.reduce((acc, scheduling) => {
					const proceeding = scheduling.proceeding

					scheduling.partnership = proceeding.partnerships.find(p => p.id == scheduling.partnership.id)

					return acc + Number(scheduling.partnership.pivot.valor)
				}, 0)

				return value
			}
		},
		methods: 	{
			Previous()
			{
				this.$emit('previous')
			},
			FormValidate()
			{
				const schedulings = this.items.filter(s => this.selected.schedulings.indexOf(s.id) > -1)

				schedulings.forEach(s => {
					let authorization_error = false
					let guide_error = false

					// if(s.partnership.id != "")
					// {
					// 	authorization_error = true
					// }

					if(s.partnership.exibe_autorizacao && !s.authorization)
					{
						authorization_error = true
					}
					if(s.partnership.exibe_guia && !s.guide)
					{
						guide_error = true
					}

					if(authorization_error || guide_error)
					{
						this.form_invalid = true
					}
				})
			},
			ValidateItem(item)
			{
				if(item)
				{
					return false
				}
				return true
			},
			UpdateState(scheduling_id)
			{
				const el = document.querySelector(`#scheduling-${scheduling_id}`)

				this.form_invalid = false

				el.click()
				el.click()
			},
			async Confirm()
			{	
				await this.FormValidate()
				
				if(this.selected.schedulings.length > 0 && !this.form_invalid)
				{
					const schedulings 	= this.items.filter( s => this.selected.schedulings.indexOf(s.id) > -1)
						.map( s => {
							return {
								partnership_id:	s.selected_partnership.id,
								id: 			s.id,
								authorization: 	s.authorization,
								guide: 			s.guide
							}
						})

					this.$emit('next', {
						method: 		'confirm',
						schedulings,
						payment_methods_info: this.selected.payment_methods_info
					})
				} else {
					const arr = document.getElementsByClassName('is-invalid')
		
					for(let key in arr)
					{
						const input = arr[key]

						if(input)
						{
							Swal.fire({
								icon: 		'error',
								title: 		'Preencha os campos obrigatórios',
								toast: 		true,
								position: 	'top',
								timer: 		4000,
								timerProgressBar: true
							})
							
							input.focus();
							break;
						}
					}
				}
			},
			async Cancel()
			{
				const schedulings 	= this.items.filter( s => this.selected.schedulings.indexOf(s.id) > -1)
					.map( s => {
						return {
							id: s.id
						}
					})

				const vm = this

                if(schedulings.length > 0)
				{
					let acp = false
					if(!acp)
					{
						const { value: act } = await Swal.fire({
							title: 'Cancelamento',
							text: 'Para confirmar o cancelamentos deste(s) agendamento(s) marque o campo abaixo:',
							input: 'checkbox',
							inputValue: 0,
							inputPlaceholder: '',
							inputValidator: (result) => {
								return !result && 'Para confirmar o cancelamento é necessário marcar o campo'
							},
							confirmButtonText:
								'Confirmar <i class="fa fa-arrow-right"></i>',
							focusConfirm: false,
							width: 'auto',
						})

						acp 	= act
					}
			
					if (!acp) return

					vm.$emit('next', {
						method: 		'cancel',
						schedulings: 	schedulings
					})
				}
			},
			Fit(scheduling)
			{
				this.$emit('next', {
					method: 		'fit',
					schedulings: 	scheduling
				})
			},
			ReSchedule(schedulings)
			{
				this.$emit('next', {
					method: 		'reschedule',
					schedulings
				})
			},
			GetPartnership(event)
			{
				this.form_invalid = false

				const id = parseInt(event.target.id.replace('partnership-', ''))
				const partnership_id = parseInt(event.target.value)

				let indexElement

				this.items.forEach((el, i) => {
					if(el.id == id) {
						indexElement = i
					}
				})
				
				const element = this.items[indexElement]

				let partnership
				 
				element.proceeding.partnerships.forEach(el => {
					if(el.id == partnership_id) {
						partnership = el
					}
				})

				if(!partnership) {
					this.form_invalid = true
					return
				}
				if(partnership.exibe_autorizacao || partnership.exibe_guia)
				{
					this.form_invalid = true
				}

				this.items[indexElement].partnership = {}
				this.items[indexElement].partnership = partnership
			},
			ClickOnInput(scheduling_id) {
				const already_set = this.selected.schedulings.indexOf(scheduling_id) > -1

				if(already_set) {
					this.selected.schedulings = this.selected.schedulings.filter(s => {
						return s != scheduling_id
					})
				} else {
					this.selected.schedulings.push(scheduling_id)
				}
			},
			OpenModal(id) {
				window.jQuery(id).modal('show')
			},
			PaymentMethodsHandler(payment_methods_info) {
				this.selected.payment_methods_info = payment_methods_info
			}
		},
		beforeMount() {
			if(this.items.length === 1) {
				this.selected.schedulings[0] = this.items[0].id
			}
		}
	}
</script>

<style lang="css" scoped>
	.split-journey {
		width: 100%;
		border: 0.05rem solid rgba(255, 255, 255, 0.185);
	}

	.selected {
		border-color: var(--primary);
	}
</style>