<template>
	<div>
		<router-view/>
	</div>
</template>

<script>
	export default {

		name: 'Index',

		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>
</style>