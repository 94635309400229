<template>
	<div 
		id="modal-menu" 
		class="modal fade"
	>
		<div class="modal-dialog modal-dialog-centered m-0">
			<div class="modal-content">
				<div class="row align-content-start vh-100">
					<div class="col-12">
						<Navbar 
							:carousel="true" 
							@close-carousel="Close"
						/>
					</div>
					<div class="col-12 align-self-md-center">
						<div class="d-flex flex-row justify-content-end mb-4 mx-4">
							<div class="btn-group-checkbox d-inline-block">
								<label for="horizontal">
									<input 
										type="radio"
										id="horizontal"
										value="scroll"
										v-model="scroll_orientation"
										@click="SetCarouselOrientation('scroll')"
									>
									<div class="btn btn-white">
										<i class="fal fa-arrows-h fa-lg"></i>
									</div>
								</label>
								<label for="vertical">
									<input 
										type="radio" 
										name="tipo" 
										id="vertical"
										value="block"
										v-model="scroll_orientation"
										@click="SetCarouselOrientation('block')"
									>
									<div class="btn btn-white">
										<i class="fas fa-th fa-lg"></i>
									</div>
								</label>
							</div>
						</div>
						<template v-if="scroll_orientation == 'scroll'">
							
					      	<div 
					      		class="menu-container scroll-x scroll-transparent pt-5 px-md-7 z-1"
					      		ref="scroll_container"
					      		v-dragscroll.x="true"
					      		@dragscrollstart="DragToggle(true)"
							  	@dragscrollend="DragToggle(false)"
							  	@wheel.prevent="ScrollX"
					      	>
								<div 
									v-for="menu in menuOptions"
									:key="menu.role"
									class="item"
								>
									<router-link 
										:event="click ? 'click': ''"
										:to="{ name: menu.route }" 
										class="box w-100 icons-card"
										:class="{
											'opacity-50': menu.role && !menu.force ? DoesntHavePermission(menu.role) : false,
											'primary-hover' : menu.role && !menu.force ? !DoesntHavePermission(menu.role) : true
										}"
									>
										<div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
											<div class="max-100 max-md-auto">
												<i 
													:class="`${menu.icon} fal font-32 font-sm-40 mb-3 text-theme`"
												/>
												<h5 class="font-16 m-0" style="white-space: break-spaces;">{{ menu.text }}</h5>
											</div>
										</div>
									</router-link>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="menu-container scroll-x scroll-transparent px-4 z-1">
								<Home :carousel="true" />
							</div>
						</template>
					</div>
				</div>		
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import Navbar from './Navbar'
	import Home from '../../home/Index'
	export default {

		name: 'CarouselMenu',
		components: {
			Navbar,
			Home
		},
		props: 	{
			open: 	{
				type: 		Boolean,
				default: 	false
			}
		},
		data () {
			return {
				click: 		true,
				scroll_orientation: 	'scroll'
			}
		},
		watch: {
			open(val)
			{
				window.jQuery('#modal-menu').modal((val ? 'show' : 'hide'))
			},
			carousel_orientation: {
				handler(val)
				{
					this.scroll_orientation 	= val
				},
				immediate: true
			}
		},
		computed: {
			...mapGetters('system', [
				'carousel_orientation', 'menu_order'
			]),
			...mapGetters('auth', [
				'roles', 'requester_selected_object'
			]),
			menuOptions() {
				return this.menu_order
			}
		},
		methods: {
			...mapActions('system', [
				'SetCarouselOrientation'
			]),
			Close()
			{
				window.jQuery('#modal-menu').modal('hide')

				this.$emit('close')
			},
			DoesntHavePermission(role)
			{
				const requester = this.requester_selected_object
				const pf		= requester && requester.workgroup_schema.substring(0, 2) === 'pf'
				
				if((requester && requester.confirmed) || pf)
                {
                    return this.roles.indexOf(parseInt(role)) < 0
                }
                return true
			},
			ScrollX(e)
			{
				let scroll_container 	= this.$refs.scroll_container

				if ( e.deltaY < 0 )
				{
					scroll_container.scrollLeft -= 100
				}
				else
				{
					scroll_container.scrollLeft += 100
				}
			},
			DragToggle(move)
			{
				if(move)
				{
					this.click 	= false
				}else{

					setTimeout(() => {

						this.click 	= true

					}, 100)
				}
				
			}
		}
	}
</script>

<style lang="css" scoped>
</style>