import state from './state'

const _ = require('lodash')

const account 		= (state) => {
	return state.account
}

const token 		= (state) => {
	return state.token
}

const check 		= (state) => {
	return !! state.token;
}

const roles 		= (state) => {
	return state.roles && state.requester_selected_id && state.roles[state.requester_selected_id] ? state.roles[state.requester_selected_id] : []
}

const all_roles 	= (state) => {
	return state.roles
}

const update_current_url		= (state) => {
	return state.update_current_url
}

const requesters 	= (state) => {
	return window._.sortBy(state.requesters, ['requester.name'])
}

const crms 			= (state) => {
	return state.crms
}

const requester 	= (state) => (id) => {
	return state.requesters.find( requester => requester.account_requester_id == id )
}

const requester_selected_id		= (state) => {
	return state.requester_selected_id
}

const requester_selected_object	= (state) => {
	return state.requesters.find( requester => requester.account_requester_id == state.requester_selected_id )
}

const online_accounts		= (state) => {
	return _.uniq(state.online_accounts)
}

const data_migrating_organizations	= (state) => {
	return state.data_migrating_organizations
}

const is_registered			= (state) => {
	return state.is_registered
}

const recurring_object		= (state) => {
	return state.recurring_object
}

const subscription_info 		= (state) => {
	return state.subscription_info
}

const organization_schema	= (state) => {
	return state.requesters.find(r => r.account_requester_id == state.requester_selected_id).instance.config.schema_name ?? ''
}

const cards					= (state) => {
	return state.cards ? state.cards : []
}

export {
	account,
	token,
	check,
	roles,
	all_roles,
	update_current_url,
	crms,
	requesters,
	requester,
	requester_selected_object,
	requester_selected_id,
	online_accounts,
	data_migrating_organizations,
	is_registered,
	recurring_object,
	subscription_info,
	organization_schema,
	cards,
}