import Swal from 'sweetalert2'

const StartLoading = ({ commit, state }) => {
	commit('ADD')
}

const FinishLoading = ({ commit, state }) => {
	commit('REM')
}

const ToggleThemeCommit 	= ({ commit }, payload) => {

	localStorage.setItem('theme', payload)

	commit('TOGGLE_THEME', payload)
}

const ToggleTheme 	= ({ commit, rootState, dispatch }, payload) => {

	return window.api.call('post', '/save-setting', {
			account_id: 	rootState.auth.account.id,
			theme: 			payload
		})
		.then(async ({data}) => {
			if(data.response)
			{
				await dispatch('ToggleThemeCommit', payload)

			}else{

				Swal.fire({
					icon: 	'error',
					title: 	'Eita!',
					text: 	'Houve um erro ao salvar o tema, contate o suporte.'
				})
			}
		})
} 

const SetCarouselOrientationCommit = ({ commit }, payload ) => {

	localStorage.setItem('carousel_orientation', payload)

	commit('UPDATE_CAROUSEL_ORIENTATION', payload)
}

const SetCarouselOrientation = ({ commit, rootState, dispatch }, payload) => {

	return window.api.call('post', '/save-setting', {
			account_id: 	rootState.auth.account.id,
			menu_grid_type:	payload
		})
		.then(async ({data}) => {
			if(data.response)
			{
				await dispatch('SetCarouselOrientationCommit', payload)

			}else{

				Swal.fire({
					icon: 	'error',
					title: 	'Eita!',
					text: 	'Houve um erro ao salvar a orientação do menu carrosel, contate o suporte.'
				})
			}
		})
}

const SetIconsSizeCommit = ({ commit }, payload ) => {

	localStorage.setItem('icon_size', payload)

	commit('UPDATE_ICON_SIZE', payload)
}

const SetIconsSize = ({ commit, rootState, dispatch }, payload) => {

	return window.api.call('post', '/save-setting', {
			account_id: 	rootState.auth.account.id,
			menu_icon_size:	payload
		})
		.then(async ({data}) => {
			if(data.response)
			{
				await dispatch('SetIconsSizeCommit', payload)

			}else{

				Swal.fire({
					icon: 	'error',
					title: 	'Eita!',
					text: 	'Houve um erro ao salvar o tamanho do ícone, contate o suporte.'
				})
			}
		})
}

const SetMenuOrderCommit = ({ commit }, payload ) => {
	
	localStorage.setItem('menu_order', JSON.stringify(payload))

	commit('UPDATE_MENU_ORDER', payload)
}

const SetMenuOrder = ({ commit, rootState, dispatch }, payload) => {

	return window.api.call('post', '/save-setting', {
			account_id: 	rootState.auth.account.id,
			menu_order:	 	payload.menu_mapped
		})
		.then(async ({data}) => {
			if(data.response)
			{
				await dispatch('SetMenuOrderCommit', payload.custom_menu)

			}else{

				Swal.fire({
					icon: 	'error',
					title: 	'Eita!',
					text: 	'Houve um erro ao salvar a ordem do menu, contate o suporte.'
				})
			}
		})
}

const GetModulesAndModalities = ({ commit, dispatch, rootState }) => {

	if(!rootState.auth.requester_selected_id)
	{
		return;
	}

	dispatch('StartLoading')

	return window.api.call('post', '/get-modules-and-modalities', {
			account_id: 	rootState.auth.requester_selected_id
		})
		.then(({data}) => {

			if(data.response)
			{
				commit('UPDATE_MODULES', data.modules)
				commit('UPDATE_MODALITIES', data.modalities)
				commit('UPDATE_DICOM_MODALITIES', data.dicom_modalities)
			}	

		})
		.finally(() => {
			dispatch('FinishLoading')
		})
}

const SetLastModalityId = ({ commit, dispatch }, payload) => {
	commit('UPDATE_LAST_MODALITY_ID', payload)
}

export {
	StartLoading,
	FinishLoading,
	GetModulesAndModalities,
	ToggleTheme,
	ToggleThemeCommit,
	SetIconsSize,
	SetIconsSizeCommit,
	SetCarouselOrientation,
	SetCarouselOrientationCommit,
	SetMenuOrder,
	SetMenuOrderCommit,
	SetLastModalityId
}