<template>
	<div>
		<form @submit.prevent="Save">
			<div class="form-row justify-content-end">
				<div class="col-12">
					<div class="form-group">
						<label>Senha atual</label>
						<input 
							v-model="$v.form.current.$model"
							:class="{ 'is-invalid': $v.form.current.$error }"
							type="password" 
							class="form-control"
							ref="current"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
				</div>
				<div class="col-12">
					<div class="form-group">
						<label>Nova Senha</label>
						<input 
							v-model="$v.form.new.$model"
							:class="{ 'is-invalid': $v.form.new.$error }"
							type="password" 
							class="form-control"
							ref="new"
						>
						<div class="invalid-feedback" v-if="!$v.form.new.required">
							Obrigatório
						</div>
						<div class="invalid-feedback" v-else-if="!$v.form.new.minLength">
							Precisa ser maior que 6 caracteres
						</div>
						<div class="invalid-feedback" v-else-if="!$v.form.new.DifferentOfCurrent">
							Precisa ser diferente da atual
						</div>
					</div>
				</div>
				<div class="col-12">
					<div class="form-group">
						<label>Confirme a senha</label>
						<input 
							v-model="$v.form.new_confirmation.$model"
							:class="{ 'is-invalid': $v.form.new_confirmation.$error }"
							type="password" 
							class="form-control"
							ref="new_confirmation"
						>
						<div class="invalid-feedback">
							Você deve informar a mesma senha
						</div>
					</div>
				</div>
				<div class="col-md-auto">
					<button
						type="submit"
						class="btn btn-primary btn-block px-5"
					>
						Salvar
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import { not, required, minLength, sameAs } from 'vuelidate/lib/validators'
	export default {

		name: 'Password',

		data () {
			return {
				form: 			{
					current: 			'',
					new: 				'',
					new_confirmation: 	''
				},
			}
		},
		validations: {
			form: 		{
				current:	{
					required
				},
				new: 	{
					required,
					minLength: minLength(6),
					DifferentOfCurrent: not(sameAs('current')) 
				},
				new_confirmation:	{
					SameAsPassword: sameAs('new')
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			Save()
			{
				this.$v.form.$touch()

				if(!this.$v.form.$invalid)
				{
					this.StartLoading()

					window.api.call('post', '/save-password', this.form)
						.then(({data}) => {
							if(data.response)
							{
								Swal.fire({
									toast:  	true,
									title:		'Senha alterada com sucesso.',
									icon: 		'success',
									timer: 		3000,
									position: 	'top',
									timerProgressBar: true
								})

							}else{
								
								if(data.current)
								{
									Swal.fire({
										icon: 	'error',
						        		title:  'Ops...',
						        		text: 	'Senha atual está incorreta.'
									})

								}else{

									Swal.fire({
										icon: 	'error',
						        		title:  'Ops...',
						        		text: 	'Houve um erro ao concluir, contate o suporte.'
									})
								}
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				} else {
					window.helpers.focusInvalid(this.$v.form, this)
				}
			}
		}
	}
</script>

<style lang="css" scoped>
</style>