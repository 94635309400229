<template>
	<div class="container pb-4">
		<div class="row justify-content-between align-items-end">
			<div class="col-12 mb-3 max-500 pr-6 pb-3 pb-md-0">
				<span>Convênios</span>
				<h4 class="font-24 font-md-32 m-0">Convênios</h4>
			</div>
			<div class="col-12 max-600">
				<div class="form-row align-items-center pb-2">
					<div class="col mb-4 mb-md-0">
						<div class="form-group m-0 position-relative">
							<input 
								type="search" 
								class="form-control pl-5" 
								placeholder="Buscar..."
								v-model="search"
							>
							<i 
								class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"
							></i>

						</div>
					</div>
					<div class="col-auto">
						<router-link
							class="btn btn-primary px-5"
							:to="{ name: 'NewPartnership' }"
						>
							criar
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<hr class="my-4">
		<DataTables
			:headers="headers"
			:search="search"
			:tr-button="true"
			@by-click-on-tr="Edit"
			v-model="partnerships"
		>
			<template v-slot:body="{ item }">
				<td class="text-muted align-middle" scope="col">
					<b>{{ item.nome.toUpperCase() }}</b>
				</td>
		        <td class="text-muted align-middle" scope="col">
		        	{{ item.cnpj }}
		        </td>
		        <td class="text-muted align-middle" scope="col">
		        	<div
		        		class="badge font-16" 
		        		:class="{
		        			'badge-success': item.ativo,
		        			'badge-danger':  !item.ativo
		        		}" 
		        	>
		        		{{ item.ativo ? 'ativo' : 'inativo'}}
		        	</div>
		        </td>
		        <td class="text-muted align-middle text-right" scope="col" @click.stop>
		        	<button type="button" class="btn btn-light-danger btn-sm" @click="Delete(item)">
		        		deletar
		        	</button>
		        </td>
			</template>
		</DataTables>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Index',

		data () {
			return {
				search: 		'',
				partnerships: 	[],
				headers: 				[
					{ text: 'Nome', value: 'nome', sortable: true },
					{ text: 'CNPJ', value: 'cnpj' },
					{ text: 'Status', value: 'ativo' },
					{
						text: '',
						sortable: false,
						value: null
					}
				],
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'requester_selected_id'
			])
		},
		methods: 	{
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetPartnerships()
			{
				this.StartLoading()

				return window.api.call('post', '/get-partnerships', {
						account_id: 	this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.partnerships 	= data.partnerships
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			Edit(partnership)
			{
				this.$router.push({ name: 'EditPartnership', params: {
					partnership_id: 	partnership.id
				}})
			},
			async Delete(partnership)
			{
				const vm 	= this

				await Swal.fire({
					title: 'Confirmação',
					text: `Deseja mesmo deletar este convênio e os vinculos com os procedimentos?`,
					icon: 'info',
					showCancelButton: true,
					//confirmButtonColor: '#009e92',
					//cancelButtonColor: '#d33',
					confirmButtonText: 'sim',
					cancelButtonText: 'não',
					showLoaderOnConfirm: true,
					preConfirm: () => {

				    	return window.api.call('post','/delete-partnership',{
			  				account_id: 	vm.requester_selected_id,
			  				partnership_id: partnership.id,
			  			})
			  			.then(({data}) => {
			  				return data
			  			})
			  			
					},
				  	allowOutsideClick: () => !Swal.isLoading()
				}).then(({value}) => {

					const data 	= value

					if(data)
					{
						if(data.response)
						{
							vm.GetPartnerships()

							Swal.fire({ 
								toast: 		true,
								position: 	'top',
								timer: 		3000,
								title: 	    'Convênio deletado com sucesso.',
								icon: 	    'success'
							})

						}else{

							Swal.fire({ 
								text: 'Houve um erro ao deletar, contate o suporte.',
								icon: 'error'
							})
						}
					}

				})
			}
		},
		beforeMount()
		{
			this.GetPartnerships()
		}
	}
</script>

<style lang="css" scoped>
</style>