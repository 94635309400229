<template>
	<div class="container-large pb-4">
		<transition name="fade" mode="out-in">
			<div 
				v-if="step == 'pre'" 
				:key="step"
			></div>
			<div 
				v-if="step == 'module'" 
				:key="step"
				class="pb-3"
			>
				<Modules 
					:label="'Agendas'"
					type="all"
					:module_id="selected.module_id"
					@next="WalkStep"
				/>
			</div>
			<div 
				v-if="step == 'modality'"
				:key="step"
				class="pb-3"
			>
				<Modalities 
					:label="'Agendas'"
					type="all"
					:module_id="selected.module_id"
					:modality_id="selected.modality_id"
					@next="WalkStep"
					@previous="step = 'module'"
				/>
			</div>
			<div 
				v-if="step == 'schedules-list'"
				:key="step"
				class="pb-3"
			>
				<div class="row justify-content-between align-items-end">
					<div class="col-12">
						<button
							type="button"
							class="btn btn-light px-5"
							@click="step = 'modality'"
						>
							voltar
						</button>
					</div>
					<div class="col-12 d-flex align-items-center justify-content-between flex-wrap mt-4">
						<div class="col px-0 mb-3 m-md-0">
							<span>{{ modality_name }}</span>
							<h4 class="font-24 font-md-32 m-0">Agendas</h4>
						</div>
						<div class="col px-0 mr-0 d-flex flex-nowrap align-items-center justify-content-end justify-content-md-start">
							<div class="mr-2 col-auto">
								<div
									class="font-32"
									:class="{
										'text-primary':         selected.is_vacancy,
										'text-secondary':       !selected.is_vacancy
									}"
									@click="ToggleIsVacancy"
								>
									<i
										class="fad"
										:class="{
											'fa-toggle-on':     selected.is_vacancy,
											'fa-toggle-off':    !selected.is_vacancy
										}"
									></i>
								</div>
							</div>
							<label
								class="mb-0"
								@click="ToggleIsVacancy"
							>
								Por vaga
							</label>
						</div>
						<div class="col-12 col-md-5 px-0 d-flex align-items-center">
							<div class="col mr-3 px-0 ">
								<div class="form-group m-0 position-relative">
									<input 
										type="search" 
										class="form-control pl-5" 
										placeholder="Buscar..."
										v-model="search"
									>
									<i 
										class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"
									></i>

								</div>
							</div>
							<div class="col-auto px-0">
								<button
									type="button"
									class="btn btn-primary px-5"
									@click="HasProceedings"
								>
									criar
								</button>
							</div>
						</div>
					</div>
				</div>
				<hr class="my-3 my-md-4">
				<DataTables
					:headers="dynamic_headers"
					v-model="schedules"
					:search="search"
					:tr-button="true"
					@by-click-on-tr="ConfigSchedule"
				>
					<template v-slot:body="{ item }">
						<td scope="col" class="align-middle">
							<b>{{ item.nome.toUpperCase() }}</b>
						</td>
						<td scope="col" class="align-middle">
							{{ item.continua ? 'Pronto Atendimento' : 'Pré-agendamento'}}
						</td>
						<td scope="col" class="align-middle">
							{{ item.total_time }}h
						</td>
						<td scope="col" class="align-middle">
							{{ selected.is_vacancy ? item.attendances : (item.attendance_time + 'h') }}
						</td>
						<td scope="col" class="align-middle">
							{{ item.periodicity }}
						</td>
						<td scope="col" class="align-middle">
							{{ item.date_end }}
						</td>
						<td scope="col"
							:class="{
								'text-primary':         item.status,
								'text-secondary':       !item.status
							}"
						>
							<i 
								class="font-40 fad"
								:class="{
									'fa-toggle-on':     item.status,
									'fa-toggle-off':    !item.status
								}"
								@click.stop="ToggleScheduleStatus(item)"
							></i>
						</td>
						<td scope="col">
							<button
								type="button"
								@click.stop="DeleteSchedule(item)"
								class="btn btn-block btn-danger btn-sm"
							>
								deletar
							</button>
						</td>
					</template>
				</DataTables>
			</div>
		</transition>
	</div>
</template>

<script>
	import Modules from './components/Modules'
	import Modalities from './components/Modalities'
	import ScheduleForm from './components/ScheduleForm'
	import moment from 'moment';

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'

	export default {

		name: 'Schedules',
		components: 	{
			Modules,
			Modalities,
			ScheduleForm
		},
		props: 	{
			modality_id: 	{
				type: 	[Number, String],
				default: 	null,
				required: 	false
			},
			module_id: 		{
				type: 	[Number, String],
				default: 	null,
				required: 	false
			}
		},
		data () {
			return {
				step: 		'pre',
				schedules: 	[], 
				selected: 	{
					module_id: 		null,
					modality_id:	null,
					schedule: 		null,
					is_vacancy:		false,
				},
				search: 		'',
				open_modal: 	false,
				form_type: 		'new', // 'config'
			}	
		},
		watch: {
			step: {
				handler(val)
				{
					let hash 	= ''

					switch(val)
					{
						case 'module':
							hash = '#modulo'
							this.selected.modality_id		= null
							this.selected.schedule 			= null
						break
						case 'modality':
							hash = '#modalidade'
							this.selected.schedule 			= null
						break
						case 'schedules-list':
							hash = '#lista-de-agendas'
						break
					}

					window.location.hash 	= hash
				},
				immediate: true
			},
			$route(to)
			{
				switch(to.hash)
				{
					case '#modulo':
						this.step = 'module'
					break
					case '#modalidade':
						this.step = 'modality'
					break
					case '#lista-de-agendas':
						this.step = 'schedules-list'
					break
				}
			},
			time_init: {
				handler()
				{
					this.GetDifferenceBetweenTimes()
				}
			},
			time_end: {
				handler()
				{
					this.GetDifferenceBetweenTimes()
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id', 'requesters', 'organization_schema'
			]),
			...mapGetters('system', [
				'modalities'
			]),
			modality_name() {
				return window.helpers.Capitalize(this.modalities.find(modality => modality.id == this.selected.modality_id)?.name ?? '')
			},
			dynamic_headers() {
				const headers = [
					{ text: 'Nome', sortable: true, value: 'nome' },
	                { text: 'Tipo', sortable: true, value: 'continua' },
					{ text: 'Duração', sortable: true, value: 'total_time' },
					{ text: 'Tempo de Atendimento', sortable: true, value: 'attendance_time' },
	                { text: 'Periodicidade', sortable: true, value: 'periodicity' },
	                { text: 'Data de Término', sortable: true, value: 'date_end_tmp' },
					{ text: 'Status', sortable: true, value: 'status' },
	                { text: '', sortable: false, value: null },
				]

				if(this.selected.is_vacancy) {
					headers[3] = { text: 'Atendimentos', sortable: true, value: 'attendances' }
				}

				return headers
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'GetModulesAndModalities', 'SetLastModalityId'
			]),			
			async WalkStep(param = null)
			{
				switch(this.step)
				{
					case 'pre':

						if(this.modality_id && this.module_id)
						{
							this.selected.module_id 		= this.module_id
							this.selected.modality_id 		= this.modality_id

							this.GetSchedulesFromModality()

							return;
						}

						this.step 						= 'module'

					break
					case 'module':
						
						this.selected.module_id 		= param.id

						this.step 						= 'modality'

					break
					case 'modality':
						
						this.selected.modality_id		= param.id

						this.GetSchedulesFromModality()

					break
					case 'schedules-list':

					break
				}
			},
			GetSchedulesFromModality()
			{
				this.StartLoading()

				return window.api.call('post', '/get-schedules-from-modality',{
						modality_id: 		this.selected.modality_id,
						account_id: 		this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response) {
							this.schedules 		= data.schedules

							this.selected.is_vacancy 	= data.is_vacancy

							this.schedules = this.schedules.map((schedule, index) => {
								if(schedule.continua == true) {
									schedule.total_time = '24'
								} else if(schedule.horario_inicio && schedule.horario_fim) {
									schedule.horario_inicio = schedule.horario_inicio.substring(0, 5)
									schedule.horario_fim = schedule.horario_fim.substring(0, 5)

									schedule.total_time = this.GetDifferenceBetweenTimes(schedule.horario_inicio, schedule.horario_fim)
								}

								if(!schedule.attendances) {
									schedule.attendances = '0'
								}
								
								if(schedule.continua) {
									schedule.periodicity = 'Dom / Seg / Ter / Qua / Qui / Sex / Sab'
									schedule.attendances = 'Contínua'
								} else if(schedule.ciclica) {
									schedule.periodicity =  JSON.parse(schedule.dias_habilitados)
										.filter(dia => dia.selected)
										.map(dia => dia.name.substring(0, 3))
										.join(' / ')
								} else {
									schedule.periodicity = 'Esporádica'
								}
								
								if(!schedule.mapa_blocos) {
								 	schedule.attendance_time = schedule.total_time
								} else {
									const attendance_time = this.GetTimeAttendances(schedule)

									schedule.attendance_time = window.helpers.MinutesToTime(attendance_time)
								}
								
								
								return schedule
							})

							this.step 			= 'schedules-list'
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			ConfigSchedule(schedule)
			{
				this.$router.push({ name: 'ScheduleConfig', params: {
					schedule_id: 	schedule.id
				}})
			},
			ToggleScheduleStatus(schedule)
			{
                const vm 		= this

                vm.StartLoading()

                return window.api.call('post','/toggle-schedule-status', {
	                    account_id: vm.requester_selected_id,
	                    schedule_id: schedule.id
	                })
                	.then(({data}) => {
                		if(data.response)
                		{
							const selected_schedule = vm.schedules.find(s => s.id == schedule.id)
							
							Swal.fire({
								icon:				'success',
								title: 				`Agenda ${ selected_schedule.status ? 'desativada' : 'ativada' } com sucesso!`,
								toast: 				true,
								position: 			'top',
								showConfirmButton: 	false,
								timer: 				4000,
								timerProgressBar: 	true
							})

	                        vm.GetSchedulesFromModality()
                		}
                	})
                	.finally(() => {
                		vm.FinishLoading()
                	})
            
			},
            async DeleteSchedule(schedule)
            {
                const vm        = this

                vm.StartLoading()

				await Swal.fire({
					title: 'Confirmação',
					text: `Deseja mesmo deletar esta agenda ?, esta ação é irreversivel.`,
					icon: 'info',
					showCancelButton: true,
					confirmButtonColor: '#009e92',
					cancelButtonColor: '#d33',
					confirmButtonText: 'sim',
					cancelButtonText: 'não',
					showLoaderOnConfirm: true,
					preConfirm: () => {

				    	return window.api.call('post','/delete-schedule',{
				  				account_id:     vm.requester_selected_id,
            					schedule_id:    schedule.id
				  			})
				  			.then(({data}) => {

				  				return data
				  				
				  			})
			  			
					},
				  	allowOutsideClick: () => !Swal.isLoading()
				})
				.then(({value}) => {

					const data 	= value

					if(data)
					{
						if(data.response)
	                    {
	                        vm.GetSchedulesFromModality()

	                    }else{

	                        if(data.future_schedulings)
	                        {
	                            Swal.fire({
	                            	title: 	'Opsss...',
	                                text: 	'Você não pode deletar uma agenda com agendamentos ativos.',
	                                icon: 	'error'
	                            })

	                        }else{

	                        	Swal.fire({
	                            	title: 	'Opsss...',
	                                text: 	'Houve um erro ao deletar esta agenda.',
	                                icon: 	'error'
	                            })
	                        }
	                    }
					}


				})
				.finally(() => {
                    vm.FinishLoading()
                })
            },
			async HasProceedings() {
				const vm = this

				vm.StartLoading()

				return await window.api.call('post', '/get-proceedings-by-modality', {
					account_id: vm.requester_selected_id,
					modality_id: vm.selected.modality_id
				})
					.then(({data}) => {
						if(!data.response) {
							Swal.fire({
								icon:				'error',
								title:				'Ops...',
								text:				'Cadastre um procedimento antes de cadastrar agenda.',
								confirmButtonText:	'Cadastrar Procedimento',
								cancelButtonText:	'Cancelar',
								showCancelButton:	true,
								reverseButtons:		true
							})
								.then((result) => {
									if(result.isConfirmed) {
										vm.SetLastModalityId(vm.selected.modality_id)

										vm.$router.push({ name: 'Proceedings' })
									}
								})

							return
						}

						vm.$router.push({ 
							name: 'ScheduleWizard', 
							params: {
								modality_id: 	vm.selected.modality_id
							}
						})						
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			ToggleIsVacancy() {
				this.selected.is_vacancy = !this.selected.is_vacancy

				return window.api.call('post', '/toggle-schedules-is-vacancy', {
					modality_id: this.selected.modality_id,
					organization_schema: this.organization_schema,
					is_vacancy: this.selected.is_vacancy
				})
					.then(({data}) => {
						if(data.response) {
							
							return
						}

						Swal.fire({
							title: "Ops...",
							text: "Houve um erro ao salvar o tipo das agendas!",
							icon: "error"
						})
					})
			},
			GetDifferenceBetweenTimes(time_init_param, time_end_param) {
				if(!time_init_param || !time_end_param) return

				if(time_init_param.length == 5 && time_end_param.length == 5) {
					let time_init 			= time_init_param.split(':')

					const convertedMinutesInit = parseInt(Math.ceil( time_init[1] / 5 ) * 5)

					time_init 				= time_init[0] + ':' + (convertedMinutesInit == 60 ? '55' : convertedMinutesInit ).toString().padStart(2, '0')

					time_init_param 	= time_init == 60 ? 55 : time_init

					let time_end 			= time_end_param.split(':')

					const convertedMinutesEnd = parseInt(Math.ceil( time_end[1] / 5 ) * 5)

					time_end 				= time_end[0] + ':' + (convertedMinutesEnd == 60 ? '55' : convertedMinutesEnd ).toString().padStart(2, '0')

					time_end_param 		= time_end

					const start_time 		= moment(time_init_param, "HH:mm");
					const end_time 			= moment(time_end_param, "HH:mm");

					const duration 			= moment.duration(end_time.diff(start_time));

					let hours 				= parseInt(duration.asHours())
					hours 					= hours > 0 ? hours : (hours * -1)
					hours 					= hours.toString().padStart(2, '0');

					let minutes 			= parseInt(duration.asMinutes()) 
					minutes 				= minutes > 0 ? minutes : (minutes * -1)
					minutes 				= (minutes % 60).toString().padStart(2, '0');

					const total_time = hours + ':' + minutes

					return total_time
				}
			},
			GetTimeAttendances(schedule) {
				const SCHEDULING_TYPE = 1

				const total_duration = JSON.parse(schedule.mapa_blocos)
					.filter(block => block.type === SCHEDULING_TYPE)
					.map(block => {
						return {
							first_duration: block.first_obj.duration,
							last_duration: block.last_obj.duration
						}
					})
					.map(block => {
						const [ first_hours, first_minutes ] = window.helpers.GetHoursAndMinutesFromString(block.first_duration)
						const first = (parseInt(first_hours) * 60) + parseInt(first_minutes)

						const [ last_hours, last_minutes ] = window.helpers.GetHoursAndMinutesFromString(block.last_duration)
						const last = (parseInt(last_hours) * 60) + parseInt(last_minutes)

						return first + last
					})
					.reduce((total, duration) => {
						return total + duration
					}, 0)

				return total_duration
			}
		},
		async beforeMount()
		{
			await this.GetModulesAndModalities()

			this.WalkStep()
		}
	}
</script>

<style lang="css" scoped>
</style>