<template>
	<div 
		class="modal fade" 
		id="modal-schedule"
		data-backdrop="static"
	>
	  	<div class="modal-dialog modal-xl">
		    <div class="modal-content">
		    	<div class="container-fluid">
					<div class="row align-items-center justify-content-between">
						<div class="col-12">
							<div class="d-block mx-auto py-5 text-center max-350">
								<span>Agendas</span>
								<h4 class="font-24 font-md-32 m-0">
									{{ type == 'config' ? 'Configurar' : 'Nova' }} agenda
								</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {

		name: 'ScheduleForm',
		props: 	{
			open: 			{
				type: 			Boolean,
				default: 		false
			},
			schedule: 		{
				type: 			Object,
				default: 		null
			},
			modality_id: 	{
				type: 			[String, Number],
				default: 		null
			},
			type: 			{
				type: 			String,
				default: 		'new' //'config'
			}
		},
		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>
</style>