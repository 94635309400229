import { loadStripe } from '@stripe/stripe-js';

export default (vm) => class Stripe {
	constructor() {
		this.vm = vm;

		this.stripe = null;
		this.elements = null;
	}

	async load() {
		this.stripe = await loadStripe(this.vm.publishable_key);

		const appearance = {
			theme: vm.theme == 'dark' ? 'night' : 'stripe',
			variables: {
				colorPrimary: '#0570de',
				colorDanger: '#df1b41',
				fontFamily: 'Ideal Sans, system-ui, sans-serif',
				spacingUnit: '2px',
				borderRadius: '8px',
				fontSizeBase: '1.1rem'
			}
		};

		if(appearance.theme == 'night') {
			appearance.variables = {
				...appearance.variables,
				colorBackground: '#2d303b',
				colorText: '#e8e9e9',
			}
		}
	
		this.elements = this.stripe.elements({
			clientSecret: this.vm.client_secret,
			appearance
		});
	
		const paymentElement = this.elements.create('payment', {
			fields: {
				billingDetails: 'auto'
			}
		});

		paymentElement.mount(`#${this.vm.payment_element_id}`);
	
		paymentElement.on('loaderstart', () => {
			// console.log('loaderstart')
			this.vm.form_start = true
		})
		
		paymentElement.on('ready', () => {
			// console.log('ready')
			this.vm.form_ready = true
		})
	}

	getStripe() {
		return this.stripe;
	}

	async getElements() {
		return this.elements;
	}
}
