<template>
	<div class="py-4">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8">
				<div class="row justify-content-between align-items-center pt-md-5">
					<div class="col-12 mb-4 mb-md-0">
						<button
							class="btn btn-light"
							@click="Previous()"
						>
							<i class="far fa-chevron-left mr-3"></i> voltar
						</button>
					</div>
					<div class="col-12 mt-4 mt-md-0">

						<div class="d-block mx-auto pb-4 pb-md-2 text-md-center max-500 pr-6 pr-md-0">
							<span>Plano</span>
							<h4 class="font-24 font-md-32 m-0">Altere seu plano</h4>
						</div>

					</div>
				</div>
				<hr class="my-4"/>
			</div>
			<transition name="fade" mode="out-in">
				<div 
					class="col-12 px-5" 
					v-if="step === 1" 
					key="1"
				>
					<div class="d-flex justify-content-center flex-wrap mt-4"
						v-if="all_plans.length > 0">
						<div class="card-plan mx-2 my-2"
							v-for="(plan, index) in all_plans" :key="index"
							:class="plan.id == plan_id ? ' active' : ''">
							<div class="card-plan-content">
								<div class="row">
									<div class="col-12">
										<h3 class="text-center text-uppercase">{{ plan.name }}</h3>
									</div>
									<div class="col-12 text-center position-relative">
										<div class="d-flex card-price flex-row justify-content-center">
											<div class="currency">R$</div>
											<div class="amount">{{ ParsePrice(plan.total_price) }}</div>
											<div class="per-month">/mês</div>
										</div>
										<!-- <div class="active-plan"
											v-if="plan.id == plan_id">
											<h6 class="text-center font-14 text-primary">PLANO ATUAL</h6>
										</div> -->
										<hr class="my-4"/>
									</div>
									<div class="col-12 px-4">
										<p class="text-left">
											<i class="far fa-users mr-1"
												:class="plan.selected ? 'text-dark' : 'text-primary' "></i> {{ plan.recurring_accesses > 1 ? 'até' : ''}} <b>{{ plan.recurring_accesses }} usuário{{ plan.recurring_accesses > 1 ? 's' : ''}}</b> {{ plan.recurring_accesses > 1 ? 'simultâneos' : ''}}
										</p>
										<p class="text-left description-content">
											{{ plan.description }}
										</p>
										<div class="benefits-content">
											<p class="text-left"
												v-for="benefit in plan.benefits" :key="benefit.name"
												:class="benefit.have ? benefit.have : 'mr-0'">
												<i class="fas mr-1"
													:class="benefit.have ? 'fa-check text-success' : 'fa-times text-danger mr-2'"></i>{{ benefit.text }}
											</p>
										</div>
									</div>
									<div class="col-12">
										<hr class="my-4"/>
										<button 
											type="button"
											class="btn btn-primary btn-block"
											@click="Next(plan)" 
										>
											{{ plan.id == plan_id ? 'visualizar' : 'selecionar' }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-5"
						v-else>
						<p class="mt-5 text-center">Não há nenhum plano cadastrado!</p>
					</div>
					<div class="d-flex justify-content-end mt-5" v-if="plan_id">
						<div class="d-flex flex-column">
							<p v-if="active_plan" class="mb-0">Seu plano atual é o plano <b>{{ active_plan.name }}</b>.</p>
							<a class="cancel-button py-2 align-self-end" @click="CancelSubscription()">
								cancelar assinatura
							</a>
						</div>
					</div>
				</div>
				<div 
					class="col-12" 
					v-if="step == 2"
					key="2"
				>
					<div class="row">
						<div class="col-md-4">
							<p class="mb-4 font-18 text-uppercase font-weight-bold text-dark">Plano selecionado:</p>
							<div class="card-plan">
								<div class="card-plan-content">
									<div class="row">
										<div class="col-12">
											<h3 class="text-center text-uppercase">{{ selected_plan.name }}</h3>
										</div>
										<div class="col-12 text-center">
											<div class="d-flex flex-row justify-content-center flex-wrap">
												<div v-if="!discount_selected"
													class="row col-12 flex-row justify-content-center">
													<div class="currency">R$</div>
													<div class="amount">
														{{ ParsePrice(selected_plan.total_price) }}
													</div>
													<div class="per-month">/mês</div>
												</div>
												<div v-if="tooltip" 
													class="tooltip-discount">
													Esse é o valor total, mas para você está sendo aplicado um desconto devido a uma compra anterior.
												</div>
												<div v-if="discount_selected"
													@mouseover="tooltip = true"
													@mouseleave="tooltip = false"
													class="row col-12 justify-content-center align-items-center">
													<div
														class="align-items-center justify-content-center d-flex"
														:class="discount_selected ? 'discounted' : 'amount'">
														<div>R$</div>
														<div class="">
															{{ ParsePrice(selected_plan.total_price) }}
														</div>
														<div class="">/mês</div>
													</div>
													<i class="ml-2 fal fa-info-circle text-primary"></i>
												</div>
												<div v-if="discount_selected"
													class="row">
													<div class="currency">R$</div>
													<div class="amount">
														{{ ParsePrice(discount_selected) }}
													</div>
													<div class="per-month">/mês</div>
												</div>
											</div>
											<hr class="my-4"/>
										</div>
										<div class="col-12 px-4">
											<p class="text-left">
												<i class="far fa-user text-primary mr-1"></i> {{ selected_plan.recurring_accesses > 5 ? 'permite' : 'somente' }} <b>{{ selected_plan.recurring_accesses }} usuário{{ selected_plan.recurring_accesses > 1 ? 's' : '' }} </b> por vez
											</p>
											<p class="text-left">
												<i class="fas fa-check text-success mr-1"></i> R$ <b>{{ price_per_user }}</b>/usuário
											</p>
											<p class="text-left"
												v-for="benefit in selected_plan.benefits" :key="benefit.id">
												<i class="fas mr-1"
													:class="benefit.have ? 'fa-check text-success' : 'fa-times text-danger mr-2'"></i> {{ benefit.text }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-8 pt-5">
							<div class="row">
								<div class="col-12">
									<p class="text-center font-20 mt-2"
										v-if="active_plan && active_plan.id && active_plan.id != selected_plan.id">
										Você está alterando seu plano atual de <b>{{ active_plan.name }}</b> para <b> {{ selected_plan.name }}</b>.
									</p>
									<p class="text-center font-20 mt-2"
										v-else-if="active_plan && active_plan.id && active_plan.id == plan_id">
										<b>Este é o seu plano atual.</b>
									</p>
									<p class="text-center font-20 mt-2"
										v-else>
										Você está contratando o plano <b>{{ selected_plan.name }}</b>.
									</p>
								</div>
								<div class="col-12">
									<p class="text-center font-20">
										Ao prosseguir, você estará concordando com os termos e condições abaixo descritos.
									</p>
								</div>
								<div class="col-12">
									<div class="border rounded-lg overflow-auto p-3 bg-light contract">
										{{ contract }}
									</div>
								</div>
								<div class="col-12">
									<div class="form-group mr-4 mb-0 form-check mt-4"
										v-if="!same_plan">
										<input 
											type="checkbox" 
											id="agree_contract"
											v-model="agree_contract"
										>
										<label for="agree_contract" class="font-18">
											<b>Li e concordo com os termos e condições apresentados.</b> 
										</label>	
									</div>
								</div>
								<div class="col-12 text-right">
									<button
										v-if="!same_plan"
										type="button"
										class="btn btn-primary px-5 mt-3"
										:disabled="same_plan || !agree_contract"
										@click="ChangePlan"
									>
										{{ !plan_id ? 'assinar plano' : 'concluir alteração' }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>

			<div class="modal fade" id="modal-credit-card">
				<div class="modal-dialog modal-dialog d-flex justify-content-center align-items-center"
					@keydown.esc="CloseModal()">
					<div class="modal-content p-3 d-flex">
						<div class="align-self-end">
							<button
								id="close-modal"
								class="btn btn-light btn-sm m-2"
								@click="CloseModal"
							>
								<i class="fal fa-times"></i>
							</button>
						</div>
						<div class="modal-header mb-4">
							<h4 class="font-20 m-0 text-center">
								Alteração do cartão de crédito
							</h4>
						</div>
						<div class="modal-body d-flex flex-column align-items-center justify-content-center">
							<Card
								ref="credit_card"
								:edit="true"
								:prop_card="card"
								:modal_opened="modal_opened"
								@close="CloseModal"
								@update="UpdateCard"
								class="mb-3"
							/>
						</div>
						<div class="modal-footer p-1">
							<button 
								id="save-credit-card"
								class="btn btn-primary align-self-end mt-2 mb-0 mx-3"
								@click="SavePaymentProfile">
								salvar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import Card from './components/Card'
	import { mapActions } from 'vuex'

	const _ = require('lodash')

	export default {

		name: 'ChangePlan',
		components: {
			Card
		},
		props: 	{
			plan_id: {
				type:		[ Number, String, null ],
				default:	null,
				required:	false
			},
			organization_id: 	{
				type: 		[ Number, String ],
				required: 	true
			}
		},
		data () {
			return {
				contract:		`What is Lorem Ipsum?
								Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

								Why do we use it?
								It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).


								Where does it come from?
								Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.`,
				all_plans:		[],
				selected_plan:	{},
				total_discount:	null,
				tooltip:		false,
				step: 			1,
				agree_contract: false,
				card:			{
					number:				'',
					holder_name:		'',
					due_date:			'',
					cvv:				'',
					brand:		        null,
				},
				modal_opened:			false,

			}
		},
		computed: {
			price_per_user()
			{
				let val = this.selected_plan.total_price / this.selected_plan.recurring_accesses

				return this.ParsePrice(val.toFixed(2))
			},
			active_plan()
			{
				if(this.plan_id)
				{
					return this.all_plans.filter((p) => p.id === parseInt(this.plan_id))[0]
				}
				return {}
			},
			same_plan()
			{
				if(this.active_plan)
				{
					return this.active_plan.id === this.selected_plan.id
				}
				return false
			},
			discount_selected()
			{
				if(this.total_discount)
				{
					let result = parseFloat(this.selected_plan.total_price) - parseFloat(this.total_discount)

					return `${result.toFixed(2)}`
				}

				return null
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			ParsePrice(price)
			{
				return `${parseFloat(price).toFixed(2)}`.replace('.', ',')
			},
			async Next(param)
			{
				switch(this.step)
				{
					case 1: 
						this.selected_plan = param
						await this.GetRemainingTime()
						this.step = 2
				}
			},
			async GetRemainingTime()
			{
				const vm = this

				vm.StartLoading()

				return await window.api.call('post', '/gateway/get-remaining-time', {
					organization_id: vm.organization_id
				})
					.then(({data}) => {
						if(data.response)
						{
							vm.total_discount = parseFloat(data.remaining_time.total_discount)

							return
						}

						vm.total_discount = 0
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async GetPlans()
			{
				const vm = this

				vm.StartLoading()

				return await window.api.call('get', '/gateway/get-plans')
					.then(({data}) => {
						if(data.response)
						{
							vm.all_plans = data.plans

							vm.all_plans.map((p) => {
								p.benefits = JSON.parse(p.description).benefits
								p.description = JSON.parse(p.description).description
								return p
							})

							vm.all_plans = _.orderBy(vm.all_plans, [(p) => {
								return p.total_price
							}], 'asc')
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async ChangePlan()
			{
				const vm = this

				if(!vm.card.holder_name)
				{
					Swal.fire({
						text:		'Cadastre um perfil de pagamento para prosseguir!',
						icon:		'info',
						title:		'Eita!',
						preConfirm: () => {
							vm.OpenModal()
						}
					})

					return
				}

				vm.StartLoading()

				return await window.api.call('post', '/gateway/subscribe', {
					organization_id: 	vm.organization_id,
					plan_id:			vm.selected_plan.id,
					coupon_code:		null
				})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon:	'success',
								text:	'Plano assinado com sucesso!',
								title:	'Parabéns!'
							})

							vm.$router.push({ name: 'OrganizationPanel', params: {
								organization_id: this.organization_id
							}})

							return
						}

						Swal.fire({
							icon:		'error',
							title:		'Eita!',
							text:		'Ocorreu um erro ao assinar o plano, contate o suporte.'
						})
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async GetPaymentProfile()
			{
				const vm = this

				vm.StartLoading()

				return await window.api.call('post', '/gateway/get-payment-profile', {
					organization_id: vm.organization_id
				})
					.then(({data}) => {
						if(data.response)
						{
							const p = data.payment_profile
							vm.card.holder_name 	= p.holder_name
							vm.card.number 			= vm.ParseCardNumber(p.card_number_first_six, p.card_number_last_four)
							vm.card.due_date		= vm.ParseDueDate(p.card_expiration)
							return
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async SavePaymentProfile()
			{
				const vm = this

				await vm.$refs.credit_card.Update()

				const payment_method = { 
					payment_method_code: 	'credit_card',
					holder_name: 			vm.edit_card.holder_name,
					card_number: 			vm.edit_card.number,
					card_cvv: 				vm.edit_card.cvv,
					card_expiration:		vm.edit_card.due_date,
					payment_company_code: 	vm.edit_card.brand,
				}

				vm.StartLoading()

				return await window.api.call('post', '/gateway/set-payment-profile', {
					organization_id: vm.organization_id,
					payment_method
				})
					.then(({data}) => {
						if(data.response)
						{
							vm.GetPaymentProfile()

							Swal.fire({
								title:				'Perfil de pagamento adicionado com sucesso, continue a assinatura!',
								icon:				'success',
								toast:				true,
								position:			'top',
								timer:				5000,
								timerProgressBar: 	true,
								showConfirmButton:	false
							})

							return
						}

						Swal.fire({
							text:	'Houve um erro ao cadastrar o perfil de pagamento, verifique se o formato do cartão é válido.',
							title:	'Eita!',
							icon:	'error',
						})
					})
					.finally(() => {
						vm.FinishLoading()

						vm.CloseModal()
					})
			},
			async CancelSubscription() {
				const vm = this

				Swal.fire({
					icon:	'warning',
					title: 	'Aviso!',
					text:	'Ao confirmar, a sua assinatura será cancelada. Caso tenha dúvidas entre em contato com o suporte informações.',
					showCancelButton: true,
					confirmButtonText: 'Sim, quero cancelar'
				}).then(async (result) => {
					if (result.isConfirmed) { 

						vm.StartLoading()

						return await window.api.call('post', '/gateway/cancel-subscription', {
							organization_id: vm.organization_id
						})
							.then(({data}) => {
								if(data.response)
								{
									Swal.fire({
										title:				'A sua assinatura foi cancelada!',
										icon:				'success',
										toast:				true,
										position:			'top',
										timer:				5000,
										timerProgressBar: 	true,
										showConfirmButton:	false
									})

									this.$router.push({ 
										name: 'OrganizationPanel', params: {
											organization_id: this.organization_id
										}
									})

									return
								}

								Swal.fire({
									text:	'Houve um erro ao cancelar a sua assinatura, entre em contato com o suporte!',
									title:	'Eita!',
									icon:	'error',
								})
							})
							.finally(() => {
								vm.FinishLoading()
							})

					}
				})
			}, 
			UpdateCard(val)
			{
				this.edit_card = val.card
			},
			Previous()
			{
				switch(this.step)
				{
					case 1: 
						this.$router.push({ 
							name: 'OrganizationPanel', params: {
								organization_id: this.organization_id
							}
						})
						break;
					default: 
						this.step -= 1
				}
			},
			OpenModal()
			{
				window.jQuery('#modal-credit-card').modal('show')

				this.modal_opened = true

				if(this.mode == 'new') this.edit = true

				window.jQuery('body').addClass('modal-open')
			},
			CloseModal()
			{
				window.jQuery('#modal-credit-card').modal('hide')

				this.modal_opened = false

				window.jQuery('body').removeClass('modal-open')
			},
			ParseDueDate(date)
			{
				return date.substr(0, 7).split('-').reverse().join('/')
			},
			ParseCardNumber(first, last)
			{
				if(first && last)
				{
					return first.substr(0, 4) + ' **** **** ' + last
				}
				return ''
			},
		},
		beforeMount() {
			// window.jQuery(['data-toggle="tooltip"'].tooltip())

			this.GetPlans()

			this.all_plans.map(p => {
				if(p.id == this.plan_id)
				{
					p.selected = true
					this.selected_plan = p
				}
			})

			this.GetPaymentProfile()
		}
	}
</script>

<style lang="css" scoped>

.discounted
{
	font-size: 1.4rem;
	text-decoration: line-through;
	text-decoration-color: red;
}

.tooltip-discount
{
	border: 1px solid black;
	border-radius: 10px;
	position:absolute;
	background-color: black;
	top: -70px;
	transition: ease 5s;
}

/* .container {
    max-width: 1400px !important;
} */

</style>