<template>
	<div class="container pb-4">
		<transition name="fade" mode="out-in">
			<div 
				v-if="step === 'pre'" 
				:key="step"
			>
			</div>
			<div 
				v-if="step === 'schedulings'" 
				:key="step"
			>
				<div class="row justify-content-between align-items-end">
					<div class="col-12">
						<div class="d-block mx-auto mb-4 pb-md-5 text-md-center max-500 pr-6 pr-md-0">
							<span>Confirmação</span>
							<h4 class="font-24 font-md-32 m-0">Confirmação de Agendamento</h4>
						</div>
					</div>
					<div class="col-12 max-lg-500">
						<div class="form-group m-0 position-relative">
							<input 
								type="search" 
								class="form-control pl-5" 
								placeholder="Buscar..."
								v-model="search"
							>
							<i class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"></i>
						</div>
					</div>
				</div>
				<hr class="my-4">
				<DataTables
					:headers="headers"
					:search="search"
					:tr-button="true"
					@by-click-on-tr="WalkStep"
					v-model="schedulings"
				>
					<template v-slot:body="{ item }">
						<td scope="col" class="text-muted">
							<b>{{ item.nome_paciente }}</b>
						</td>
				        <td scope="col" class="text-muted">
				        	{{ item.desconhecido ? 'SEM IDENTIFICAÇÃO' : `${item.tipo_id} ${item.numero_id}` }}
				        </td>
				        <td scope="col" class="text-muted">
				        	{{ item.schedulings.length.toString().padStart(2, '0') }}
				        </td>
					</template>
				</DataTables>
			</div>
			<div 
				v-if="step === 'register'" 
				:key="step"
			>
				<PatientForm
					label="Confirmação"
					form_type="update"
					ref="patient_form"
					:patient="selected.patient"
					:edit="edit"
					@exit="WalkStep"
				>
					<template v-slot:actions="{ Save }">
						<button 
							class="btn btn-light px-md-5"
							@click="step = 'schedulings'"
						>
							voltar
						</button>
						<!-- <div class="col-4 d-flex"
							:class="edit ? 'justify-content-between' : 'justify-content-center'">
							<button
								v-if="edit" 
								class="btn btn-light-danger px-md-5"
								@click="edit = false"
							>
								cancelar
							</button>
							<button 
								class="btn px-md-5"
								:class="!edit ? 'btn-light-info' : 'btn-light-success'"
								@click="!edit ? edit = true : SavePatient()"
							>
								{{ edit ? 'salvar' : 'editar' }}
							</button>
						</div> -->
						<button 
							class="btn btn-primary px-md-5"
							@click="Save"
						>
							continuar <i class="far fa-arrow-right ml-4"></i>
						</button>
					</template>
				</PatientForm>
			</div>
			<div 
				v-if="step === 'proceedings'" 
				:key="step"
			>
				<Proceedings 
					label="Confirmação"
					@previous="step = 'register'"
					@next="WalkStep"
					:schedulings="selected.schedulings"
				/>
			</div>
		</transition>
	</div>
</template>

<script>
	const _ 			= require('lodash')
	import Proceedings from './components/Proceedings'
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'ConfirmWizard',
		components: 	{
			Proceedings
		},
		data () {
			return {
				step: 			'pre',
				search: 		'',
				edit:			true,
				schedulings: 	[],
				headers: 		[
					{ text: 'Nome', value: 'nome_paciente' },
					{ text: 'ID/Número', value: 'numero_id' },
					{ text: 'Procedimentos', value: 'schedulings.length' }
				],
				selected: 		{
					patient: 				null,
					schedulings: 			[],
					parsed_schedulings: 	[],
					payment_methods_info:	{},
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			])
		},
		watch: 	{
			step: {
				handler(val)
				{
					let hash 	= ''

					switch(val)
					{
						case 'schedulings':
							this.selected.patient 				= null
							this.selected.schedulings 			= []
							this.selected.parsed_schedulings 	= []

							hash 								= '#agendados'
						break
						case 'register':
							this.selected.parsed_schedulings 	= []
							hash 								= '#cadastro'
						break
						case 'proceedings':
							hash 								= '#procedimentos'
						break
					}

					window.location.hash 	= hash
				},
				immediate: true
			},
			$route(to)
			{
				switch(to.hash)
				{
					case '#agendados':
						this.step = 'schedulings'
					break
					case '#cadastro':
						this.step = 'register'
					break
					case '#procedimentos':
						this.step = 'proceedings'
					break
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			async WalkStep(param = null) {
				switch(this.step) {
					case 'pre':
						await this.GetSchedulingsToConfirm()

						this.step 					= 'schedulings'
					break
					case 'schedulings':
						this.selected.schedulings 	= param.schedulings.map(scheduling => {
							scheduling.authorization 			= scheduling.partnerships[0].pivot.autorizacao
							scheduling.guide 					= scheduling.partnerships[0].pivot.guia
							scheduling.partnership 				= scheduling.partnerships[0]
							scheduling.selected_partnership		= scheduling.partnerships[0]

							return scheduling
						});

						this.selected.patient 		= { 
							...param,
							schedulings: null
						}
						
						this.step 					= 'register'
					break
					case 'register':
						this.selected.patient 		= param

						this.step 					= 'proceedings'
					break
					case 'proceedings':
						switch(param.method) {
							case 'confirm':
								this.selected.parsed_schedulings 	= param.schedulings
								this.selected.payment_methods_info = param.payment_methods_info

								await this.ConfirmSchedulings()
							break
							case 'cancel':
								this.selected.parsed_schedulings 	= param.schedulings

								await this.CancelSchedulings()
							break
							case 'fit':
								this.selected.parsed_schedulings	= param.schedulings

								this.FitSchedulings()
							break
							case 'reschedule':
								this.selected.parsed_schedulings	= param.schedulings

								this.Rescheduling()
							break
						}
					break
				}
			},
			CancelSchedulings()
			{
				this.StartLoading()

				return window.api.call('post', '/cancel-schedulings', {
						schedulings: 	this.selected.parsed_schedulings,
						account_id: 	this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon: 				'success',
								title: 				'Agendamento(s) cancelado(s) com sucesso.',
								toast: 				true,
								timer: 				3000,
								position: 			'top',
								timerProgressBar: 	true
							})

							this.step 		= 'pre'

							this.WalkStep()

						}else{

							Swal.fire({
								icon: 	'error',
								title: 	'Eita!',
								text: 	'Houve um erro ao concluir, contate o suporte.'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			ConfirmSchedulings() {
				this.StartLoading()

				return window.api.call('post', '/confirm-schedulings', {
						schedulings: this.selected.parsed_schedulings,
						payment_info: this.selected.payment_methods_info
					})
					.then(({data}) => {
						if(data.response) {
							Swal.fire({
								icon: 				'success',
								title: 				'Agendamento(s) confirmado(s) com sucesso.',
								toast: 				true,
								timer: 				3000,
								position: 			'top',
								timerProgressBar: 	true
							})

							if(this.selected.parsed_schedulings.length < this.selected.schedulings.length) {
								this.selected.schedulings = this.selected.schedulings.filter(scheduling => {
									return !this.selected.parsed_schedulings.find(s => s.id == scheduling.id)
								})

								return
							}

							this.step 		= 'pre'

							this.WalkStep()
						} else {
							Swal.fire({
								icon: 	'error',
								title: 	'Eita!',
								text: 	'Houve um erro ao concluir, contate o suporte.'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			GetSchedulingsToConfirm()
			{
				this.StartLoading()

				return window.api.call('post','/get-schedulings-to-confirm', {
						account_id: 		this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.schedulings 	= _.sortBy(data.schedulings, ['nome_paciente'])
							
						}else{

							Swal.fire({ 
								title: 	'Eita!',
								text: 	'Houve um erro ao obter, contate o suporte.',
								icon: 	'error'
							})
						}
					})
					.finally(() => {

						this.FinishLoading()
					})
			},
			FitSchedulings()
			{
				const vm = this

				const scheduling_id = this.selected.parsed_schedulings.$model.id

				vm.$router.push({ name: 'ReFitWizard',
					params: {
						scheduling_id,
						source: 'ConfirmWizard'
					}
				})
			},
			Rescheduling() {
				const vm = this
				
				const schedulings = this.selected.parsed_schedulings

				vm.$router.push({
					name: 'ReschedulingWizard',
					params: { 
						source: 'ConfirmWizard'
					},
					query: {
						schedulings: JSON.stringify(schedulings)
					},
				})
			},
			async SavePatient()
			{
				await this.$refs.patient_form.SavePatient()

				this.edit = false
			}
		},
		beforeMount()
		{
			this.WalkStep()
		}
	}
</script>

<style lang="css" scoped>
</style>