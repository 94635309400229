<template>
	<div>
		<p>
			E-mail atual: <b>{{ account.email }}</b>
		</p>
		<div v-if="account.email && account.email_verified_at" class="col-12 alert alert-success">
			<i class="far fa-check mr-3"></i>
			<b>
				Autenticado
			</b>
		</div>
		<div v-else-if="account.email && !account.email_verified_at" class="col-12 alert alert-warning">
			<i class="far fa-hourglass-end mr-3"></i>
			<b>
				Pendente de autenticação
			</b>
		</div>
		<div v-else class="col-12 alert alert-danger d-flex align-items-center">
			<i class="far fa-times mr-3 font-18"></i>
			<b>
				Não definido
			</b>
		</div>
		<div class="row align-items-end mt-5">
			<div class="col-md pr-md-0">
				<div class="form-group mb-md-0">
					<input 
						type="email"
						class="form-control"
						:class="{ 'is-invalid': $v.form.email.$error}"
						placeholder="Novo email"
						v-model="$v.form.email.$model"
						ref="email"
					>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div>
			</div>
			<div class="col-md-auto">
				<button 
					type="button"
					@click="Send" 
					class="btn btn-primary btn-block px-5"
				>	
					enviar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import { required } from 'vuelidate/lib/validators'
	export default {

		name: 'Email',

		data () {
			return {
				form: 	{
					email: 	''
				}
			}
		},
		validations: {
			form: 	{
				email: 	{
					required
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'account'
			])
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			Send() {
				this.$v.form.$touch()

				if(!this.$v.form.$invalid) {
					if(this.form.email == this.account.email) {
						Swal.fire({
							icon: 	'warning',
			        		title: 	'O e-mail informado é o mesmo que o atual.',
							timer: 	3000,
							toast:	true,
							position: 'top',
							timerProgressBar: true,
						})

						return
					}

					this.StartLoading()

					window.api.call('post', '/send-validation-to-new-email', this.form)
						.then(({data}) => {
							if(data.response)
							{
								Swal.fire({
									icon: 	'success',
					        		title:  'Validação iniciada',
					        		text: 	'Em instantes, voce receberá um email de validação no endereço indicado com instruções para continuar.',
					        		showConfirmButton: true
								})

							}else{
								if(data.exists) {
									Swal.fire({
										icon: 	'error',
						        		title:  'Ops...',
						        		text: 	'Este e-mail já está em uso.'
									})

									return;
								}

								Swal.fire({
									icon: 	'error',
					        		title:  'Ops...',
					        		text: 	'Houve um erro ao concluir, contate o suporte.'
								})
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				} else {
					window.helpers.focusInvalid(this.$v.form, this)
				}
			}
		}
	}
</script>

<style lang="css" scoped>
</style>