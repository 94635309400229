<template>
	<div>
		<div class="container">
			<div class="row align-items-center vh-md-50 justify-content-between flex-column">
				<div class="col-12">
					<div class="d-block mx-auto mb-4 pb-md-5 text-md-center max-500">
						<span>{{ label }}</span>
						<h4 class="font-24 font-md-32 m-0">Prioridade de atendimento</h4>
					</div>
				</div>
				<div class="col-12 col-md">
					<div class="row justify-content-between">
						<div class="col-12 mb-3">					
							<div class="row align-items-center">
								<div class="col form-group form-select">
									<select 
										class="form-control"
										v-model="selected.classification"
										@click="select_focused = !select_focused"
										@blur="select_focused = false"
									>
										<option
											v-for="(classification, index) in classifications"
											:key="index"
											:value="classification.value"
										>
											{{ classification.text }}
										</option>
									</select>
									<i class="font-18 position-absolute ps-r-4 mr-3 ps-v-center fas "
									  :class="select_focused ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 text-center py-5">
					<div class="d-flex justify-content-between">
						<button 
							class="btn btn-light px-md-5"
							@click="Previous"
						>
							voltar
						</button>
						<button 
							class="btn btn-primary px-md-5"
							@click="Continue"
						>
							continuar <i class="far fa-arrow-right ml-4"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {

		name: 'AuthorizationAndGuide',
		props: {
			label: 			{
				type: 			String,
				default: 		''
			},
			classification: {
				type: 			[Number, String],
				default: 		1
			},
			authorization: {
				type: 			String,
				default: 		''
			},
			guide: {
				type: 			String,
				default: 		''
			}
		},
		data () {
			return {
				selected: {
					classification: 	1,
					authorization: 		'',
					guide: 				''
				},
				classifications: 	window.classifications,
				select_focused:		false,
			}
		},
		watch: 	{
			classification: {
				handler(val)
				{
					this.selected.classification 	= val
				},
				immediate: true
			},
			authorization: {
				handler(val)
				{
					this.selected.authorization 	= val
				},
				immediate: true
			},
			guide: {
				handler(val)
				{
					this.selected.guide 	= val
				},
				immediate: true
			}
		},
		methods: {
			Previous()
			{
				this.$emit('previous')
			},
			Continue()
			{
				this.$emit('next', this.selected)
			}
		}
	}
</script>

<style lang="css" scoped>
	/* .form-select {
		position: relative;
	}

	.form-select i::before {
		position: absolute;
		top: 0;
		right: 0;
	} */
</style>