<template>
	<div class="container">
		<div class="row align-items-center justify-content-center flex-column">
			<div class="col-12" v-if="type == 'create'">
				<div class="d-block mx-auto pb-4 pb-md-5 text-md-center max-500 pr-6 pr-md-0">
					<span> Criar agenda </span>
					<h4 class="font-24 font-md-32 m-0">Configure os blocos de agendamento</h4>
				</div>
			</div>
			<div class="col-md-9">
				<div class="d-flex justify-content-between my-4">
					<div class="col-md-6 pl-0">
						<div class="col-12 px-0 mb-3 d-flex flex-column">
							<label for="block_times">Gerar intervalo de blocos automaticamente</label>
							<div class="d-flex justify-content-center mt-3">
								<select id="block_times"
									class="form-control mr-3"
									name="block_times"
									v-model="selected.block_interval"
								>
									<option value="">Selecione o intervalo...</option>
									<option v-for="(interval, index) in blocks_interval"
										:key="`interval-${ index }`"
										:value="interval.minutes"
									>
										{{ interval.label }}
									</option>
								</select>
								<button
									class="btn btn-light"
									@click="GenerateScheduleBlocks()"
								>
									Gerar
								</button>
							</div>
						</div>
						<h3 class="font-20 mb-4">
							Horários disponiveis: {{ blocks.length }}
						</h3>		
						<div id="type-buttons-container" class="row form-row mb-3">
							<div class="col">
								<button 
									class="btn btn-light-info btn-sm btn-block font-14"
									:disabled="selected.blocks.length < 2"
									@click="AddBlock(2)"
								>
									<p class="mb-0">
										<span class="badge bg-white text-dark mr-2">
											{{ selected.blocks.length }}
										</span> Pausa
									</p>
									<small v-if="from_to_time_label">
										{{ from_to_time_label }}
									</small>
								</button>
							</div>
							<div class="col">
								<button 
									class="btn btn-light-success btn-sm btn-block font-14"
									:disabled="selected.blocks.length < 2"
									@click="AddBlock(1)"
								>
									<p class="mb-0">
										<span class="badge bg-white text-dark mr-2">
											{{ selected.blocks.length }}
										</span> Agendamento
									</p>
									<small v-if="from_to_time_label">
										{{ from_to_time_label }}
									</small>
								</button>
							</div>
						</div>		
						<div class="card bg-light border-no overflow-hidden">
							<div class="card-body p-3 p-md-4 scroll-this">
								<template v-if="blocks.length > 0 && !all_blocks_selected">
									<div 
										v-for="(block, index) in blocks"
										:key="`block_${index}`"
									>
										<div 
											v-if="!block.is_block && !block.owner"
											class="select-card with-border mb-3"
											:class="{
												'active': block.selected
											}"
											@click="SelectBlock(index)"
										>
											<label class="py-3">
												{{ block.time }}
												<i class="far fa-plus"></i>
											</label>
										</div>
									</div>
								</template>
								<template v-else>
									<div class="rounded-lg border mb-3 text-center">
										<label class="p-3 m-0">
											<small class="d-block text-muted opacity-75">Não há mais blocos disponiveis</small>
										</label>
									</div>
								</template>
							</div>
						</div>					
					</div>
					<div class="col-md-auto px-0">
						<span class="float-left border-left h-100"></span>
					</div>
					<div class="col-md-5 mt-3 mt-md-0 pr-0 defined-blocks">
						<h3 class="font-20 mb-4">
							Blocos definidos
						</h3>
						<template v-if="selected.map.length > 0">
							<div
								v-for="(block, index) in selected.map"
		  				  		:key="index"
							>
								<div 
									class="select-card with-border mb-3"
									:class="{
										'select-blue': block.type == 2
									}"
								>
									<label class="py-3">
										<small class="d-block opacity-75">{{block.type == 2 ? 'pausa' : 'agendamento'}}</small>
										{{ block.first_obj.time }} até {{ ParseBlockEndTime(block.last_obj.time) }}
										<i class="fas"
											:class="{
												'fa-pause': block.type == 1,
												'fa-play': block.type == 2
											}"
											@click="(block.type == 2 ? block.type = 1 : block.type = 2)"></i>
										<i class="far fa-times"
											@click="RemoveBlock(block)"></i>
									</label>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="rounded-lg border mb-3 text-center">
								<label class="p-3 m-0">
									<small class="d-block text-muted opacity-75">Não há blocos definidos</small>
								</label>
							</div>
						</template>
						<button 
							class="btn btn-primary btn-block px-5"
							@click="Next"
							v-if="type == 'create'"
						>
							continuar
						</button>
					</div>
				</div>
			</div>
			<div class="col-md-9" v-if="type == 'create'">
				<button
					class="btn btn-light px-5"
					type="button"
					@click="Previous"
				>
					voltar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../../helpers'
	import Swal from 'sweetalert2'

	const helpers 		= new Helpers()

	const _ 			= require('lodash')

	export default {

		name: 'BlockConfig',
		props: 		{
			time_obj: 	{
				type: 		Object,
				default: 	null
			},
			props_blocks: 		{
				type: 		Array,
				default: 	() => []
			},
			map: 		{
				type: 		Array,
				default: 	() => []
			},
			is_started: {
				type: 		Boolean,
				default: 	true
			},
			type: 		{
				type: 		String,
				default: 	'create' // 'config'
			},
			is_vacancy: {
				type:		Boolean,
				default:	false
			}
		},
		data () {
			return {
				blocks: 		[],
				first_and_last: {
					first: 			null,
					last: 			null
				},
				selected: 		{
					block_interval:	30, // DEFAULT
					blocks: 		[],
					block_type: 	1,
					map: 			[]
				},
				pause_time:			null
			}
		},
		computed: {
			all_blocks_selected()
			{
				const have_blocks_selected 		= this.blocks.filter( b => {
					return b.owner || b.is_block
				})

				return have_blocks_selected.length == this.blocks.length
			},
			blocks_interval() {
				if(!this.time_obj.total_time) {
					return []
				}

				const objs = [
					{ minutes: 10, label: "10 Min" },
					{ minutes: 15, label: "15 Min" },
					{ minutes: 20, label: "20 Min" },
					{ minutes: 30, label: "30 Min" },
					{ minutes: 40, label: "40 Min" },
					{ minutes: 50, label: "50 Min" },
					{ minutes: 60, label: "1 Hora" },
				]

				const [ hours, minutes ] = window.helpers.GetHoursAndMinutesFromString(this.time_obj.total_time)

				const max_time = (parseInt(hours) * 60) + parseInt(minutes)

				objs.push({ minutes: max_time, label: "Tempo Máximo" })

				return objs
			},
			from_to_time_label() {
				const first_obj = this.selected.blocks.find(b => b.id === this.first_and_last.first)
				const last_obj = this.selected.blocks.find(b => b.id === this.first_and_last.last)

				if(!first_obj || !last_obj) return null

				const init_time = first_obj.time

				const end_time = this.ParseBlockEndTime(last_obj.time)
			
				if(init_time && end_time) {
					return `${init_time} - ${end_time}`
				}

				return null
			},
			has_unselected_blocks() {
				const has_unselected = !!this.blocks.filter(b => !b.owner && !b.is_block).length

				return has_unselected
			}
		},
		watch: {
			time_obj: {
				handler(val) {
					let new_val = JSON.parse(JSON.stringify(val))

					if(new_val.time_init) {
						let total_time = val.total_time ? val.total_time : window.helpers.GetDifferenceBetweenTimes(val.time_init, val.time_end).total

						if(this.type == 'config') {
							const diff_msec 		= helpers.TimeToMsec(total_time)
	
							this.blocks 			= helpers.GenerateBlockSchedule(val.time_init, val.time_end, diff_msec)
	
							if(this.is_started) {
								this.$emit('append', {
									type: 'blocks',
									blocks: this.blocks
								})
							}
	
							this.selected.blocks = []
							this.selected.map = []
						} else {
							if(this.is_started) {
								const diff_msec 		= helpers.TimeToMsec(total_time)
		
								this.blocks 			= helpers.GenerateBlockSchedule(val.time_init, val.time_end, diff_msec)
							}
						}
					}
				},
				immediate: 	true,
				deep: 		true
			},
			props_blocks: {
				async handler(val) {
					if(val && val.length > 0 && this.is_started) {
						this.blocks = val
					}
				},
				immediate: 	true
			},
			map: {
				async handler(val) {
					if(val && val.length > 0 && this.is_started) {
						val.forEach(async i => {
							this.first_and_last.first 	= i.first_id
							this.first_and_last.last 	= i.last_id

							await this.AddBlock(i.type)
						})
					}

					await this.RecalculatePauseTime()

					this.$emit('mounted')
				},
				immediate: 	true
			},
			'selected.map': {
				async handler(val) {
					if(!this.is_started) {
						await this.RecalculatePauseTime()

						this.$emit('append', {
							type: 'map',
							map: val
						})

						if(this.selected.map.length === 0) {
							this.$emit('append', {
								type: 'blocks',
								blocks: this.blocks
							})
						}
					}
				},
				deep: true
			},
			pause_time: {
				handler(pause_time) {
					if(!this.time_obj.total_time) return;
					
					const [ allowed_hours, allowed_minutes ] = window.helpers.GetHoursAndMinutesFromString(this.time_obj.total_time);
	
					const allowed_time = window.helpers.TimeToMinutes(allowed_hours, allowed_minutes);
	
					const allowed_attendances = (parseInt(allowed_time) - parseInt(pause_time)) / 5;

					this.$emit('pause-time', {
						blocks: JSON.parse(JSON.stringify(this.blocks)),
						block_map: JSON.parse(JSON.stringify(this.selected.map)),
						max_attendances: allowed_attendances
					})
				}
			},
			has_unselected_blocks: {
				handler(val) {
					this.$emit('has_unselected_blocks', val)
				},
				immediate: true
			}
		},
		methods: {
			AddBlock(mode) {
				this.selected.block_type 	= mode

				this.CreateBlocksOnTimeArr([{
					first_id: 	this.first_and_last.first,
					first_obj: 	this.blocks.find(x => x.id == this.first_and_last.first),
					last_id:	this.first_and_last.last,
					last_obj: 	this.blocks.find(x => x.id == this.first_and_last.last),
					type: 		this.selected.block_type
				}])

				this.first_and_last.first 	= null
				this.first_and_last.last 	= null
				this.selected.block_type 	= ''
				this.selected.blocks		= []

				this.blocks 				= this.blocks.map(block => {
					block.selected 				= false

					return block
				})

				if(this.type == 'config') {
					if(this.is_started) {
						this.$emit('append', {
							type: 'map',
							block_map: 	this.selected.map,
						})
					}

					this.$emit('append', {
						type: 'blocks',
						blocks: 	this.blocks
					})

					return true
				}
			},
			RemoveBlock(target)
			{
				this.blocks				= this.blocks.map( block => {

					if(block.id == target.first_id || block.owner == target.first_id)
					{
						block.is_block 			= false
						block.owner 			= null
						block.selected 			= false

					}

					return block
				})

				this.selected.map 		= this.selected.map.filter( block => block.first_id !== target.first_id )

				this.selected.map 		= _.sortBy(this.selected.map, ['first_id'])
			},
			CreateBlocksOnTimeArr(items) {
				this.selected.map 		= this.selected.map.concat(items)

				items.map(item => {
					let ifoundit 			= false
					let block_id 			= null
					let duration 			= helpers.GetDifferenceBetweenTimes(item.first_obj.time, item.last_obj.time)

					this.blocks 			= this.blocks.map(block => {
						if(block.id == this.first_and_last.first) {
							block.is_block 		= true
							block.duration 		= duration.total
							block_id 			= block.id
						}else if(block.id > this.first_and_last.first && block.id <= this.first_and_last.last) {
							block.owner 		= block_id
						} else {
							block.selected 		= false
						}

						return block
					})
				})

				this.selected.map 		= _.sortBy(this.selected.map, ['first_id'])
			},
			SelectBlock(index) {
				let selected_id = this.blocks[index].id

				//DEFINE O PRIMEIRO
				if(!this.first_and_last.first) {
					this.first_and_last.first = selected_id
					this.blocks[index].selected = !this.blocks[index].selected
				//DEFINE O ULTIMO E SELECIONA TODO MUNDO QUE TA ENTRE OS 2
				} else if(!this.first_and_last.last && this.first_and_last.first != selected_id) {
					if(this.first_and_last.first > selected_id) {
						this.first_and_last.last = this.first_and_last.first
						this.first_and_last.first = selected_id
					} else {
						this.first_and_last.last = selected_id
					}

					this.blocks = this.blocks.filter(block => {
						if(block.id >= this.first_and_last.first && block.id <= this.first_and_last.last) {
							block.selected = true
						} else {
							block.selected = false
						}

						return true
					})
				} else {
					//SE SELECIONOU ANTES DO first
					if(selected_id < this.first_and_last.first) {
						this.first_and_last.first = selected_id
					//SE CLICOU ENTRE OS 2
					} else if(selected_id > this.first_and_last.first && selected_id < this.first_and_last.last) {
						this.first_and_last.last = (selected_id - 1) == this.first_and_last.first ? null : (selected_id - 1)

						if(!this.first_and_last.last) {
							this.blocks[index].selected = !this.blocks[index].selected
						}

					//SE CLICOU DEPOIS DO last
					} else if(this.first_and_last.last && selected_id > this.first_and_last.last) {
						this.first_and_last.last = selected_id
					//SE CLICOU NO first
					} else if(selected_id == this.first_and_last.first) {
						if(this.first_and_last.last) {
							this.first_and_last.first 	= (selected_id + 1) == this.first_and_last.last ? this.first_and_last.last : (selected_id + 1)

							if(this.first_and_last.first == this.first_and_last.last) {
								this.blocks[index].selected = !this.blocks[index].selected
								this.first_and_last.last = null
							}
						} else {
							this.first_and_last.first = null
							this.blocks[index].selected = !this.blocks[index].selected
						}
					//SE CLICOU NO last
					} else if(selected_id == this.first_and_last.last) {
						this.first_and_last.last = (selected_id - 1) == this.first_and_last.first ? null : (selected_id - 1)

						if(!this.first_and_last.last) {
							this.blocks[index].selected = !this.blocks[index].selected
						}
					}

					this.blocks 		= this.blocks.filter(block => {
						if(block.id == this.first_and_last.first || block.id == this.first_and_last.last) {
							block.selected = true
						} else if(block.id > this.first_and_last.first && block.id < this.first_and_last.last) {
							block.selected = true
						} else {
							block.selected = false
						}

						return true
					})
				}

				this.selected.blocks = this.blocks.filter(block => block.selected)
			},
			ResetScheduleBlocksAttributes() {
				this.selected.map = []

				this.blocks = this.blocks.map(b => {
					b.owner = null
					b.is_block = false

					return b
				})
			},
			async GenerateScheduleBlocks() {
				if(!this.selected.block_interval) return

				await this.ResetScheduleBlocksAttributes()

				const minimal_minutes = 5

				const [ hours, minutes ] = window.helpers.GetHoursAndMinutesFromString(this.time_obj.total_time)

				const total_time = (parseInt(hours) * 60) + parseInt(minutes)

				const blocks_amount = Math.floor(total_time / this.selected.block_interval)

				const allowed_blocks_amount = (this.selected.block_interval / minimal_minutes)

				if(allowed_blocks_amount <= 0) return;
				
				let last_index = -1

				let max_index_allowed = (allowed_blocks_amount - 1)

				for(let i = 1; i <= blocks_amount; i++) {
					const blocks = this.blocks.filter((block, index) => {
						if(index > last_index && index <= max_index_allowed) {
							last_index = index;

							return true;
						}
					})

					max_index_allowed = max_index_allowed + allowed_blocks_amount

					this.first_and_last.last = blocks.reverse()[0].id
					this.first_and_last.first = blocks.reverse()[0].id

					this.CreateBlocksOnTimeArr([{
						first_id: 	this.first_and_last.first,
						first_obj: 	this.blocks.find(x => x.id == this.first_and_last.first),
						last_id:	this.first_and_last.last,
						last_obj: 	this.blocks.find(x => x.id == this.first_and_last.last),
						type: 		1
					}])
				}

				this.GenerateBlocksForUnselected()
				
				this.first_and_last.last = null
				this.first_and_last.first = null
			},
			GenerateBlocksForUnselected() {
				const unselected_blocks = this.blocks.filter(b => !b.is_block && !b.owner)

				if(!unselected_blocks.length) return;

				this.first_and_last.last = unselected_blocks.reverse()[0].id
				this.first_and_last.first = unselected_blocks.reverse()[0].id

				this.CreateBlocksOnTimeArr([{
					first_id: 	this.first_and_last.first,
					first_obj: 	this.blocks.find(x => x.id == this.first_and_last.first),
					last_id:	this.first_and_last.last,
					last_obj: 	this.blocks.find(x => x.id == this.first_and_last.last),
					type: 		1
				}])
			},
			Next() {
				if(this.is_vacancy) {
					if(!this.selected.map || this.selected.map.length == 0 || this.has_unselected_blocks) {
						Swal.fire({
							icon: 'error',
							title: 'Ops...',
							text: 'Em uma agenda por vaga, a configuração de blocos é obrigatória.'
						})
	
						return;
					}
				}

				this.$emit('next', {
					block_map: 	this.selected.map,
					blocks: 	this.blocks
				})

				return true
			},
			Previous()
			{
				this.$emit('previous')
			},
			async RecalculatePauseTime() {
				const PAUSE_TYPE = 2

				if(this.selected.map && this.selected.map.length > 0) {
					this.pause_time = this.selected.map
						.filter(block => block.type == PAUSE_TYPE)
						.map(block => {
							const [ hours, minutes ] = window.helpers.GetHoursAndMinutesFromString(block.first_obj.duration)
	
							return (parseInt(hours) * 60) + parseInt(minutes) + 5
						})
						.reduce((total, duration) => {
							return total + duration
						}, 0)
				} else {
					this.pause_time = 0;
				}
			},
			ParseBlockEndTime(time_string) {
				const [ hours_last, minutes_last ] = window.helpers.GetHoursAndMinutesFromString(time_string)

				return window.helpers.MinutesToTime((parseInt(hours_last) * 60) + (parseInt(minutes_last) + 5))
			}
		},
	}
</script>

<style lang="scss" scoped>
.scroll-this {
	max-height: 45vh !important;
}

.defined-blocks .select-card {
	label i.fa-play:hover {
		color: var(--success) !important;
	}

	label i.fa-pause:hover {
		color: var(--blue) !important;
	}

	label i.fa-times:hover {
		color: var(--danger-hover) !important;
	}
}

.defined-blocks .select-card label i:nth-of-type(1) {
	right: 3rem !important;
}

#type-buttons-container button {
	height: 4.7rem;
}
</style>