<template>
	<div class="modal fade"
		id="record-modal"
	>
		<div class="modal-dialog" style="max-width: 1000px">
			<div class="modal-content">
				<div class="modal-header px-5 py-4 mb-3">
					<h4 class="font-20 m-0 text-center">
						Edição do registro do Relatório
					</h4>
					<button 
						type="button" 
						class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3"
						data-dismiss="modal"
					>
						<i class="far fa-times mx-1"></i>
					</button>
				</div>
				<div class="modal-body">
					<RecordFrom 
						ref="record_form"
						:props_record="record"
					/>
				</div>
				<div class="modal-footer">
					<button
						id="finish"
						type="button"
						class="btn btn-primary p-3 px-4"
						@click="CloseModal()"
					>
						salvar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RecordFrom from './components/RecordForm'
import Swal from 'sweetalert2'

export default {
	name: 'RecordModal',
	components: {
		RecordFrom,
	},
	props: {
		record: {
			type: Object,
			default: () => {}
		}
	},
	methods: {
		CloseModal() {
			this.$refs.record_form.$v.touch

			if(this.$refs.record_form.$v.$invalid) {
				Swal.fire({
					icon: 'error',
					title: 'Ops...',
					text: 'Há dados inválidos. Por favor, corrija-os e tente novamente.'
				})

				return;
			}

			Swal.fire({
				title: 'Cuidado!',
				icon: 'warning',
				html: '<p>Essa ação é <b>irreversível</b></p><p>Tem certeza que deseja continuar?</p>',
				confirmButtonText: 'Sim',
				reverseButtons: true,
				showCancelButton: true,
				cancelButtonText: 'Não',
			})
				.then(async (result) => {
					if(result.isConfirmed) {
						const record = this.$refs.record_form.record

						this.UpdateReportRecord(this.$refs.record_form.record)
							.then(({data}) => {
								if(data.response) {
									Swal.fire({
										icon: 'success',
										title: 'Registro atualizado com sucesso.',
										toast: true,
										position: 'top',
										timer: 4000,
										timerProgressBar: true,
									})

									window.jQuery('#record-modal').modal('hide')
									window.jQuery('body').removeClass('modal-open')

									this.$emit('update-item', record)
								} else {
									Swal.fire({
										icon: 'error',
										title: 'Erro!',
										text: data.message || 'Erro ao atualizar o registro do relatório, contate o suporte.',
									})
								}
							})
					}
				})
		},
		async UpdateReportRecord(record) {
			return await window.api.call('post', '/update-report-record', {
				...record
			})
		}
	}
}
</script>

<style lang="css" scoped>
	.modal-body {
		max-height: 600px;
		overflow: auto;
	}
</style>