<template>
    <div class="container-fluid">
		<div class="row align-items-center justify-content-between">
            <DataTables
                v-if="!is_load && (history && history.length != 0)"
                class="col-12"
                v-model="history"
                :headers="headers"
                :trButton="true"
                :prop_sortedBy="sortedBy"
                @by-click-on-tr="ShowTimeline"
            >
                <template v-slot:body="{ item }">
                    <td class="text-muted align-middle" scope="col">
                        {{ item.logs && item.logs.length > 0 ? ParseDate(item.logs[0].data) : item.data }}
                    </td>
                    <td class="text-muted align-middle" scope="col">
                        {{ item.logs && item.logs.length > 0 ? ParseTime(item.logs[0].data) : item.hora }}
                    </td>
                    <td class="align-middle" scope="col">
                        <b>{{ item.logs && item.logs.length > 0 ? item.logs[0].proceeding : item.proceeding ? item.proceeding.nome : 'Sem procedimento vinculado' }}</b>
                    </td>
                    <td scope="col" class="text-muted align-middle">
                        <b>{{ item.status_descricao ? item.status_descricao.toUpperCase() : 'Atendimento direto' }}</b>
                    </td>
                    <td scope="col" class="text-muted align-middle">
                        <!-- <button
							content="Visualizar informações"
							v-tippy="{ arrow: true }"
							class="btn btn-light-success px-2 py-2 mx-2"
							@click="ShowTimeline(item)">
							<i class="m-1 fal fa-eye font-20"></i>
						</button> -->
                        <button v-if="GetLaudoPDFs(item).length > 0 || (item.status && item.status == 8)"
							content="Visualizar laudo"
							v-tippy="{ arrow: true }"
							class="btn btn-light-info px-3 py-2 mx-2"
							@click.stop="OpenModalReport(GetLaudoPDFs(item), item)">
							<i class="m-1 fal fa-file-medical font-20"></i>
						</button>
                        <button v-if="GetDicomImages(item).length > 0 || (item.status && item.status == 8)"
							content="Visualizar Imagens DICOM"
							v-tippy="{ arrow: true }"
							class="btn btn-light-info px-3 py-2 mx-2"
							@click.stop="OpenDicomImages(item)">
							<i class="m-1 fal fa-file-image font-20"></i>
						</button>
                    </td>
                </template>
            </DataTables>
            <div v-else
                    class="mx-auto my-5">
                Não há registros no prontuário.
            </div>
        </div>

        <SchedulingModal 
			:timeline_data="timeline_data"
            :origin="'history'"
		/>

		<!-- MODAL LAUDO -->
		<div
			class="modal fade"
			id="report-modal"
		>
			<div class="modal-dialog">
				<div class="modal-content p-3 d-flex">
					<div class="align-self-end">
						<button
							id="close-modal"
							class="btn btn-light btn-sm m-2"
							@click="CloseModal('#report-modal')"
						>
							<i class="fal fa-times"></i>
						</button>
					</div>
					<div class="modal-header mb-4">
						<h3 class="m-0 text-center">
							Visualização do laudo
						</h3>
					</div>
					<div class="modal-body d-flex justify-content-center" ref="report_content">
						
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import DataTables from '../../shared/DataTables'
import SchedulingModal from '../../shared/SchedulingModal'
const axios = require('axios')
const _ = require('lodash')
import Swal from 'sweetalert2'

export default {
    name:   'History',
    components: {
        DataTables,
        SchedulingModal,
    },
    props: {
        patient:        {
            type:       Object,
            required:   true,
            default:    null
        }
    },
    data() {
        return {
            is_load:        false,
            history:        null,
			app_url:		process.env.VUE_APP_APP_URL,
			report_url:		'',
			ended_report:	'',
			report_code:	'',
            headers: [
                { text: 'Data', value: 'data' },
                { text: 'Hora', value: 'hora' },
                { text: 'Procedimento', value: 'proceeding' },
                { text: 'Status', value: 'status_descricao' },
                { text: 'Ações', value: 'actions' },
            ],
            timeline_data: 	{
                patient:      	{},
                logs:         	[],
                journey_id:     null,
                scheduling_id:  null
            },
            sortedBy:		{
                column: 		'data',
                direction:		'DESC',
                icon:			'fa-chevron-down'
            }
        }
    },
    computed: {
        ...mapGetters('auth', [
            'requester_selected_id'
        ])
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        ParseDate(date)
        {
            let data = date.substr(0, 10)

            data = data.split('-').reverse().join('/')

            return data
        },
        ParseTime(date)
        {
            let hora = date.substr(11, 5)

            return hora
        },
        GetDicomImages(item)
        {
            let dicom_images_urls = [];

			if (item.documents && item.documents.dicom_images_urls) {
				dicom_images_urls = JSON.parse(item.documents.dicom_images_urls);
			} else if (item.dicom_images_urls) {
				dicom_images_urls = JSON.parse(item.dicom_images_urls);
			}

			return dicom_images_urls
        },
        OpenDicomImages(item)
        {
            let link = _.last(JSON.parse(item.documents.dicom_images_urls));

            window.open(link, '_blank');
        },
		GetLaudoPDFs(item)
		{
			let pdf_urls = [];

			if(item.documents && item.documents.pdf_url && item.documents.pdf_url != '{}') {
				pdf_urls = JSON.parse(item.documents.pdf_url);
			} else if (item.pdf_url && item.pdf_url != '{}') {
				pdf_urls = JSON.parse(item.pdf_url); 
			}

			return pdf_urls
		},
        GetPatientSchedulings()
		{
			const vm = this

			vm.StartLoading()

			return window.api.call('post', '/get-journeys-schedulings', {
				account_id: vm.requester_selected_id,
				patient_id: `${vm.patient.id}`
			})
				.then(({data}) => {
					if(data.response)
					{
                        let temp_arr = []

                        for (const journey of Object.keys(data.journeys)) {
                            let schedulings = data.journeys[`${journey}`]

                            if(schedulings.length) {
                                temp_arr.push(...schedulings)
                            } else {
                                temp_arr.push(schedulings)
                            }
                        }

                        vm.history = temp_arr
					}
				})
				.finally(() => {
					vm.FinishLoading()
				})
		},
        ShowTimeline(scheduling)
        {
            this.timeline_data       = {
                patient:      	{},
                logs:         	[],
                journey_id:     null,
                scheduling_id:  null
            }

			if(scheduling.el_laudo) { 
				Swal.fire({
                    icon: 'error',
                    title: 'Não há registros para esse agendamento, pois ele é um um atendimento direto',
                    toast: true,
                    position: 'top',
                    timer: 5000,
                    timerProgressBar: true,
                })
			}

            if(scheduling.logs && scheduling.logs.length > 0)
            {
                this.timeline_data.logs      		= scheduling.logs
                this.timeline_data.patient     		= scheduling.journey.patient
                this.timeline_data.journey_id  		= scheduling.journey.id
                this.timeline_data.scheduling_id 	= scheduling.id

                window.jQuery('#modal-timeline').modal('show')

                return;
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Não há registros para esse agendamento',
                    toast: true,
                    position: 'top',
                    timer: 5000,
                    timerProgressBar: true,
                })
            }

            window.jQuery('#modal-timeline').modal('hide')
        },
        PrintTimeline()
        {
            const vm  = this
            
            const fn  = (event) => {
                if (event.data.print)
                {
                    vm.StartLoading()

                    window.removeEventListener('message', fn)

                    win.close()
                }
            }
            
            vm.FinishLoading()

            const win     	= window.open(`/pesquisa/${vm.timeline_data.scheduling_id}/imprimir`, '_blank')
            win.mainWindow  = window
            window.addEventListener('message', fn)
        },
        PrintReport()
        {
            const vm  = this
            
            const fn  = (event) => {
                if (event.data.print)
                {
                    vm.StartLoading()

                    window.removeEventListener('message', fn)

                    win.close()
                }
            }
            
            vm.FinishLoading()

            const win     	= window.open(`${this.app_url}/consultar_laudos/visualizar/${this.report_code}?print`, '_blank')
            win.mainWindow  = window
            window.addEventListener('message', fn)
        },
		async OpenModalReport(urls, item)
		{
			// VISUALIZAÇÃO SEM PDF
			if (urls.length <= 0 && item && item.codigo_laudo ) {

				let url = `${this.app_url}/consultar_laudos/visualizar/${item.codigo_laudo}`
				this.$refs.report_content.innerHTML = `<iframe id='report-body' src='${url}'>`

			} else {
			// VISUALIZAÇÃO COM O PDF

				let url = _.last(urls)
				this.$refs.report_content.innerHTML = `<embed id='report-body' src='${url}' type='application/pdf'>`

			}

			window.jQuery('#report-modal').modal('show')
				.on('shown', () => {
					window.jQuery('body').addClass('modal-open')
				})
		},
		CloseModal(modal_id)
		{
			this.report_code = ''
			this.report_url = ''

			window.jQuery(modal_id).modal('hide')
				.on('hidden.bs.modal', () => {
					window.jQuery('body').removeClass('modal-open')
				})
		}
    },
    async beforeMount()
    {
        this.is_load = true
        await this.GetPatientSchedulings()
        this.is_load = false
    }
}
</script>

<style lang="css">
#report-modal .modal-dialog
{
	max-width: 1100px !important;
}

#report-modal .modal-content
{
	min-width: 600px;
	min-height: 600px;
}

#report-body
{
	border: unset; 
    width: 1045px;
    height: 810px;
}

#holder img.logo
{
	width: 50% !important;
}

#holder .paciente div
{
	line-height: 1.8rem !important;
}

#holder img.border-paciente
{
	width: 100%;
	margin: 1rem 0 !important;
}

#holder .content
{
	margin-top: .5rem;
	visibility: unset !important;
}

#holder .content span a.a-impressao
{
	color: #000;
	cursor: pointer;
}

#holder .content span a.a-impressao:hover
{
	color: #000 !important;
	text-decoration: underline !important;
}

#holder p.titulo
{
	font-weight: bold;
	text-align: center;
	font-size: 1.7rem;
}

#holder p.subtitulo
{
	font-weight: bold;
	text-align: center;
	font-size: 1.5rem;
}

#holder p.estrutura
{
	font-weight: 700;
	font-size: 1.3rem;
}

#holder 
p.tracinho.impressao_diagnostica.conclusao,
p.tracinho.diagnostico_diferencial_patologico
{
	font-weight: bold;
}

#holder 
p.tracinho.tecnica_exame_normal,
p.tracinho.diagnostico_diferencial_normal
{
	font-weight: 400;
}

#holder .carimbo
{
	margin-top: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

#holder #footer
{
	width: 100%;
	margin: 3rem 2rem 0 0;
	padding-top: 2px;
	border-top: 2px solid #323232;
	display: flex;
	justify-content: flex-end;
}

#holder #footer p
{
	margin: 0;
}

#holder #footer p.codigo
{
	background-color: #fff;
	margin: -16px 10px -2px 0 !important;
	padding: 0 8px;
	width: 304px;
}
</style>