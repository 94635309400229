import Swal from 'sweetalert2'
import Vuex from './vuex'

class Api {
    constructor () {}

    call (requestType, url, data = null, opts = {}) {

    	const axios 		= window.axios.create()

    	delete axios.defaults.headers.common["X-Socket-Id"]

		if(opts.for_app)
		{
			delete axios.defaults.headers.common["Authorization"]
			delete axios.defaults.headers.common["X-Requested-With"]
		}

	    return new Promise((resolve, reject) => {
	        axios[requestType](url, data, opts)
	            .then(response => {
	                resolve(response)
	            })
	            .catch(({response}) => {
					if(!response) {
						Swal.fire({
							icon: 'error',
							title: 'Erro!',
							text: 'Ocorreu um erro ao realizar sua solicitação. Contate o suporte!'
						})

						reject()
					}

	                if([401, 418].indexOf(response.status) > -1)  {	               
	                	new Vuex().dispatch('auth/Logout')
	                		.finally(() => {
	                			reject(response)
	                		})
	                }

	                resolve(response)
	            });
	    });
	}
}

export default Api;