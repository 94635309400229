var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4 pb-md-6"},[(_vm.patient)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('router-link',{staticClass:"btn btn-light",attrs:{"to":{ name: 'Patients'}}},[_c('i',{staticClass:"far fa-chevron-left mr-3"}),_vm._v(" voltar ")]),_c('div',{staticClass:"row mt-4 mb-2"},[_c('div',{staticClass:"col-12 col-md-auto text-center"},[_c('div',{staticClass:"d-inline-block mt-2 mb-4"},[(_vm.exihibit_avatar)?[_c('img',{staticClass:"max-150 rounded-lg",attrs:{"id":"img-avatar","src":_vm.exihibit_avatar},on:{"click":_vm.ShowPhotoModal}})]:[_c('button',{staticClass:"btn btn-light-dark py-5",attrs:{"type":"button"},on:{"click":_vm.ShowPhotoModal}},[_c('i',{staticClass:"far fa-user mx-5 my-2 font-20"})])],_c('br'),_c('button',{staticClass:"btn btn-light-primary mt-3 py-3",attrs:{"type":"button"},on:{"click":_vm.UploadAvatar}},[_vm._v(" selecionar ")]),_c('input',{attrs:{"type":"file","id":"input-avatar","accept":".jpg, .jpeg, .png","hidden":""},on:{"change":function($event){return _vm.UploadAvatar($event)}}})],2)]),_c('div',{staticClass:"col"},[_c('span',[_vm._v("Paciente")]),_c('h4',{staticClass:"font-24 font-md-32 mb-4"},[_vm._v(_vm._s(_vm.patient.nome_paciente))]),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col text-dark font-normal"},[_vm._v(" "+_vm._s(_vm.document)+" ")])]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col text-dark font-normal"},[_vm._v(" "+_vm._s(_vm.signin_date)+" ")])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col text-dark font-normal"},[_c('div',{staticClass:"badge",class:{
										'badge-success': _vm.patient.ativo,
										'badge-danger': !_vm.patient.ativo
									}},[_vm._v(" "+_vm._s(_vm.patient.ativo ? 'ATIVA' : 'INATIVA')+" ")])])])])]),_vm._m(3),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane",attrs:{"id":"info"}},[_c('div',{staticClass:"d-flex flex-wrap justify-content-end"},[_c('PatientForm',{attrs:{"form_type":"update-patient","patient":_vm.patient,"edit":true},on:{"updated":_vm.GetPatient},scopedSlots:_vm._u([{key:"actions",fn:function({ Save }){return [_c('button',{staticClass:"btn btn-primary px-md-5",on:{"click":Save}},[_vm._v(" salvar "),_c('i',{staticClass:"far fa-save ml-4"})])]}}],null,false,118309902)})],1)]),_c('div',{staticClass:"tab-pane active",attrs:{"id":"history"}},[_c('History',{attrs:{"patient":_vm.patient}})],1),_c('div',{staticClass:"tab-pane",attrs:{"id":"status"}},[_c('div',{staticClass:"py-5 max-500 text-center mx-auto"},[_c('h3',{staticClass:"font-24 mb-5"},[_vm._v(" Você pode desativar e reativar temporariamente um paciente sempre que precisar. ")]),_c('button',{staticClass:"btn",class:{
									'btn-light-success': !_vm.patient.ativo,
									'btn-light-danger': _vm.patient.ativo
								},on:{"click":function($event){return _vm.TogglePatientStatus(_vm.patient_id)}}},[_vm._v(" "+_vm._s(_vm.patient.ativo ? `desativar` : `reativar`)+" paciente ")])])])])],1)]),_c('div',{staticClass:"modal fade",attrs:{"id":"modal-webcam"}},[_c('div',{staticClass:"modal-dialog mw-100 d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"modal-content"},[_c('button',{staticClass:"btn btn-light btn-sm",attrs:{"id":"close-modal"},on:{"click":_vm.ClosePhotoModal}},[_c('i',{staticClass:"fal fa-times"})]),_vm._m(4),_c('div',{staticClass:"modal-body d-flex flex-column align-items-center"},[(_vm.webcam.snap)?_c('div',{attrs:{"id":"img-snapped"}},[_c('img',{attrs:{"src":_vm.webcam.snap,"width":"100%","alt":"Foto do paciente"}})]):_vm._e(),_c('WebCam',{directives:[{name:"show",rawName:"v-show",value:(!_vm.webcam.snap),expression:"!webcam.snap"}],ref:"webcam",attrs:{"id":"webcam","device-id":_vm.deviceId,"screenshotFormat":"image/png","width":"100%","height":"100%"},on:{"cameras":_vm.onCameras,"camera-change":_vm.onCameraChange}}),(!_vm.camera && !_vm.webcam.snap)?_c('span',{staticClass:"align-self-center"},[_vm._v(" Aguarde a imagem da câmera aparecer... ")]):_vm._e(),_c('button',{staticClass:"btn btn-light my-4",on:{"click":_vm.SnapPhoto}},[_vm._v(" "+_vm._s(_vm.webcam.snap ? 'Tirar outra foto' : 'Tirar foto')+" ")]),_c('span',{staticClass:"mb-4 text-success"},[_vm._v("* Para aceitar a foto que foi tirada basta fechar essa janela *")])],1)])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 max-150"},[_c('small',[_vm._v("DOCUMENTO")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 max-150"},[_c('small',[_vm._v("CADASTRAMENTO")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 max-150"},[_c('small',[_vm._v("STATUS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-x scroll-transparent d-flex mx--5 mx-md-0"},[_c('ul',{staticClass:"nav nav-pills nav-theme w-md-100 justify-content-md-center my-4 my-md-3 px-5"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pb-md-4 px-md-4",attrs:{"data-toggle":"tab","href":"#info"}},[_vm._v("Dados")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pb-md-4 px-md-4 active",attrs:{"data-toggle":"tab","href":"#history"}},[_vm._v("Prontuário")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pb-md-4 px-md-4",attrs:{"data-toggle":"tab","href":"#status"}},[_vm._v("Status")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header px-5 py-4 mt-5 mt-sm-1"},[_c('h4',{staticClass:"font-20 m-0 text-center"},[_vm._v("Registrar a foto do paciente")])])
}]

export { render, staticRenderFns }