<template>
	<div>
		<p class="text-center">Você pode cadastrar endereços/locais onde serão realizados os procedimentos e selecioná-los no momento do agendamento.</p>
		<template v-if="places.length > 0">
			<div 
				class="border px-4 py-3 mt-3 rounded-lg"
				v-for="place in places"
				:key="place.id"
			>
				<div class="row justify-content-md-between align-items-center">
					<div class="col mb-4 mb-md-0">
						<b>{{ place.local.toUpperCase() }}</b>
						<br/>
						<small>{{ place.endereco }}</small>						
					</div>
					<div class="col-auto">
						<button 
							class="btn btn-light btn-sm px-3 mr-2"
							@click="Edit(place)"
						> 
							editar 
						</button>
						<button 
							class="btn btn-danger btn-sm px-3" 
							@click="Delete(place)"
						> 
							deletar 
						</button>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="border px-4 py-3 mt-3 rounded-lg">
				<div class="row justify-content-md-between align-items-center">
					<div class="col mb-4 mb-md-0 text-center">
						Não há locais para listar.
					</div>
				</div>
			</div>
		</template>

		<button 
			class="btn btn-primary px-5 mt-4"
			@click="New()"
		>
			criar
		</button>
	    <div 
	    	class="modal fade" 
	    	id="modal-places"
	    >
	        <div class="modal-dialog modal-lg modal-dialog-centered">
	            <div class="modal-content">
	            	<div class="modal-header px-5 pt-4">
			    		<h4 class="font-20 m-0">{{ form.id ? 'Editar' : 'Criar'}} local</h4>
				        <button 
				        	type="button" 
				        	class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3" data-dismiss="modal"
				        >
				         	<i class="far fa-times mx-1"></i>
				        </button>
			    	</div>
		            <div class="modal-body">
		                <div class="row p-4">
							<div class="col-12">
								<div class="form-group">
									<label>Nome do lugar</label>
									<input 
										type="text" 
										class="form-control" 
										v-model="form.place" 
									>
								</div>
							</div>
							<div class="col-12">
								<div class="form-group">
									<label>Endereço</label>
									<textarea
										class="form-control" 
										v-model="form.address" 
									></textarea>
								</div>
							</div>
		                </div>
		            	<div class="d-flex justify-content-between p-4">
			                <button 
			                	type="button" 
			                	class="btn btn-light p-3 px-4" 
			                	data-dismiss="modal" 
			                >	
			            		cancelar
			            	</button>
			                <button 
			                	type="button" 
			                	class="btn btn-primary p-3 px-4" 
			                	@click="Save"
			                >
			            		salvar
			            	</button>
			            </div>					  
		            </div>
	            </div>
	        </div>
	    </div>  
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Places',
		props: 	{
			organization_id: 	{
				type: 		[Number, String],
				default: 	null,
				required: 	true
			}
		},
		data () {
			return {
				places: 		[],
				form: 			{
					id: 			null,
					place: 			null,
					address: 		null
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetOrganizationPlaces()
			{
				this.StartLoading()

				return window.api.call('post', '/get-organization-places',{
						organization_id: 		this.organization_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.places 		= data.places
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			New()
			{
				window.jQuery('#modal-places').modal('show')
			},
			Edit(place)
			{
				this.form.id 		= place.id
				this.form.place 	= place.local
				this.form.address 	= place.endereco

				window.jQuery('#modal-places').modal('show')
			},
			Delete(place)
			{
				const vm 		= this

				Swal.fire({
					icon: 				'question',
					title: 				'Deseja mesmo deletar este local ? <br> Os agendamentos já vinculados não serão afetados.',
					showConfirmButton: 	true,
					showCancelButton: 	true,
					confirmButtonText: 	'Sim',
					cancelButtonText: 	'Não',
					customClass: 		{
						confirmButton: 		'bg-danger',
					},
				}).then(async (res) => {

					if (res.isConfirmed)
					{
						vm.StartLoading()

						window.api.call('post', '/delete-place', {
								organization_id: 	vm.organization_id,
								place_id: 			place.id
							})
							.then(({data}) => {
								if(data.response)
								{
									Swal.fire({
										icon: 		'success',
										title: 		'Local deletado com sucesso.',
										toast: 		true,
										position: 	'top',
										timer: 		3000
									})

									vm.GetOrganizationPlaces()

								}else{

									Swal.fire({
										icon: 		'error',
										title: 		'Opss...',
										text: 		'Houve um erro ao deletar, contate o suporte.'
									})
								}
							})
							.finally(() => {
								vm.FinishLoading()
							})
					}
				})
			},
			Save()
			{
				this.StartLoading()

				let obj 		= {
					organization_id: 	this.organization_id,
					place: 				this.form.place,
					address: 			this.form.address
				}

				if(this.form.id)
				{
					obj 			= {
						...obj,
						place_id: 	this.form.id
					}
				}

				return window.api.call('post', '/save-place', obj)
					.then(({data}) => {
						if(data.response)
						{
							this.GetOrganizationPlaces()

						}else{

							Swal.fire({
								icon: 	'error',
								title: 	'Eita!',
								text: 	'Houve um erro ao salvar, contate o suporte.'
							})
						}
					})
					.finally(() => {

						this.FinishLoading()

						this.Close()
					})
			},
			Close()
			{
				this.form.id 		= null
				this.form.place 	= null
				this.form.address 	= null

				window.jQuery('#modal-places').modal('hide')
			}
		},
		beforeMount()
		{
			this.GetOrganizationPlaces()
		}
	}
</script>

<style lang="css" scoped>
</style>