<template>
	<div class="container-large pb-4 pt-0">
		<transition name="fade" mode="out-in">
			<div 
				v-if="step === 'pre'" 
				:key="step"
			>
				
			</div>
			<div 
				v-if="step === 'module'" 
				:key="step"
				class="pb-3"
			>
				<Modules 
					:label="'Fluxo'"
					type="all"
					:module_id="flow.module ? flow.module.id : null"
					@next="WalkStep"
				/>
			</div>
			<div 
				v-if="step === 'modality'"
				:key="step"
				class="pb-3"
			>
				<Modalities 
					:label="'Fluxo'"
					type="all"
					:module_id="flow.module ? flow.module.id : null"
					:modality_id="flow.modality ? flow.modality.id : null"
					@next="WalkStep"
					@previous="step = 'module'"
				/>
			</div>
			<div 
				v-if="step === 'flow'"
				:key="step"
				class="pb-3"
			>
				<Flow
					:label="'Fluxo'"
					:module="flow.module"
					:modality="flow.modality"
					@next="WalkStep"
					@previous="step = 'modality'"
				/>
			</div>
		</transition>
	</div>
</template>

<script>
	const _ 		= require('lodash')
	import Modules from './components/Modules'
	import Modalities from './components/Modalities'
	import Flow from './components/Flow'
	import { mapActions, mapGetters } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'FlowWizard',
		components: 	{
			Modules,
			Modalities,
			Flow
		},
		data () {
			return {
				step: 		'pre',
				flow: 		{
					module: 		null,
					modality: 		null,
					user_list: 		[]
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			])
		},
		watch: 	{
			step: {
				handler(val)
				{
					let hash 	= ''

					switch(val)
					{
						case 'module':
							hash = '#modulo'

							this.flow.modality 	= null
							this.flow.user_list = null

						break
						case 'modality':
							hash = '#modalidade'
							this.flow.user_list = null
						break
						case 'flow':
							hash = '#fluxos'
						break
					}

					window.location.hash 	= hash
				},
				immediate: true
			},
			$route(to)
			{
				switch(to.hash)
				{
					case '#modulo':
						this.step = 'module'
					break
					case '#modalidade':
						this.step = 'modality'
					break
					case '#fluxos':
						this.step = 'flow'
					break					
				}
			}
		},
		methods: {
			...mapActions('system', [
				'GetModulesAndModalities', 'StartLoading', 'FinishLoading'
			]),
			async WalkStep(param = null)
			{
				switch(this.step)
				{
					case 'pre':

						this.step 				= 'module'
						
					break
					case 'module':

						this.flow.module 		= param

						this.step 				= 'modality'

					break
					case 'modality':

						this.flow.modality 		= param

						this.step 				= 'flow'

					break
					case 'flow':

						this.flow.user_list 	= param

						await this.SaveFlow()

					break
				}
			},
			SaveFlow()
			{
				this.StartLoading()

				return window.api.call('post', '/save-flow', {
						account_id: 		this.requester_selected_id,
						module:  			{
							id: 	this.flow.module.id,
							name: 	this.flow.module.name
						},
						modality: 			{
							id: 	this.flow.modality.id,
							name: 	this.flow.modality.name
						},
						user_list: 			this.flow.user_list
					})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								toast: 		true,
								position:  	'top',
								title: 		'Fluxo salvo com sucesso',
								icon: 		'success',
								timer: 		3000
							})

						}else{

							Swal.fire({
								icon: 		'error',
								title: 		'Eita!',
								text: 		'Houve um erro ao salvar o fluxo, contate o suporte.'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			}
		},
		async beforeMount()
		{
			await this.GetModulesAndModalities()

			this.WalkStep()
		}
	}
</script>

<style lang="css" scoped>
</style>