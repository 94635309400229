<template>
	<div 
		class="modal fade" 
		id="select-account" 
		tabindex="-1" 
		data-backdrop="static"
		data-keyboard="false"
		aria-labelledby="select-account" 
		aria-hidden="true"
		v-if="account"
	>
		<div class="modal-dialog stop">
		    <div class="modal-content stop">
		        <div class="modal-body">
		        	<div class="p-4 p-md-5">
						<div class="mb-4 text-center">
							<button type="button" class="btn btn-light-primary mb-4 py-5">
								<i class="far fa-user mx-4"></i>
							</button>
							<h2 class="font-32">Selecione uma conta</h2>
						</div>
						<form class="row justify-content-between">
							<div class="col-12">
								<div 
									v-for="(requester, index) in filtered_requesters"
									:key="index"
									class="select-card with-border mt-2"
									:class="{'active': requester.account_requester_id === requester_selected_id}"
								>
									<label 
										for="card-checkbox" 
										class="pr-55 text-uppercase text-overflow"
										@click="SelectAccount(requester)"
									>
										{{ requester.requester.name }}
										<i v-if="typeof requester.confirmed !== 'undefined' && !requester.confirmed" class="far fa-envelope font-24 text-danger"></i>
										<i v-else class="far fa-chevron-right"></i>
									</label>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'

	export default {

		name: 'SelectAccount',
		props: 	{
			open: 	{
				type: 		Boolean,
				default: 	false
			}
		},
		data () {
			return {

			}
		},
		computed: {
			...mapGetters('auth', [
				'account', 'requesters', 'requester_selected_id', 'is_registered', 'subscription_info'
			]),
			filtered_requesters()
			{
				return this.requesters.filter((r) => {
					return (r.instance && r.instance.status)
				}) 
			}
		},
		watch: {
			'open': {
				handler(val)
				{
					window.jQuery('#select-account').modal((val ? 'show' : 'hide'))
				},
				immediate: true
			}	
		},
		methods: {
			...mapActions('auth', [
				'SetRequesterAccount', 'GetAuthAccount', 'GetSubscriptionInfo', 'IsRegistered'
			]),
			
			async SelectAccount(requester)
			{
				this.SetRequesterAccount(requester)

				// await this.IsRegistered(requester.requester.id)

				if(this.is_registered) {
					// await this.GetSubscriptionInfo(requester.requester.id)

					// await this.SubscriptionValidate()
				}

				this.$emit('change-account')

				this.Close()
			},
			Close()
			{
				window.jQuery('#select-account').modal('hide')

				this.$emit('close')
			},
			
		},
		async mounted() {
			if(!this.requester_selected_id) {
				if(this.requesters.length === 1) {
					await this.SetRequesterAccount(this.requesters[0])
				} else if(this.requesters.length > 1) {
					window.jQuery('#select-account').modal('show')
				}
			} else {
				window.Echo.RunOrganizationPool()
			}

			document.addEventListener('keyup', e => {
				if(e.keyCode === 27 && this.requester_selected_id) {
					this.Close()
				}
			})
		}
	}
</script>

<style lang="css" scoped>

</style>