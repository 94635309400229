<template>
	<div>
		<div class="row">
			<div class="col-12 col-md-auto text-center mb-4">
				<template v-if="exihibit_avatar">
					<img :src="avatar" class="max-150 rounded-lg">
				</template>
				<template v-else>
					<button type="button" class="btn btn-light py-5">
						<i class="far fa-user mx-4"></i>
					</button>
				</template>

				<br>
				<button 
					type="button" 
					class="btn btn-light-primary mt-3 btn-sm btn-input"
				>
					<input 
						class="file-input" 
      					type="file" 
      					accept="image/*" 
      					ref="inputFile" 
						@change="UploadAvatar($event)"
					/>
					selecionar
				</button>
			</div>
			<div class="col pl-md-5">
				<form 
					class="form-row justify-content-end" 
					@submit.prevent="SavePersonalInfo"
				>
					<div class="col-12">
						<div class="form-group">
							<label>Nome</label>
							<input 
								type="text" 
								v-model="$v.form.name.$model"
								class="form-control"
								:class="{ 'is-invalid': $v.form.name.$error }"
								ref="name"
							>
							<div class="invalid-feedback">
								Obrigatório
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="form-group">
							<label>Telefone</label>
							<input 
								type="tel" 
								v-model="form.phone_1"
								class="form-control"
								v-mask="['(##) #####-####', '(##) ####-####']" 
							>
						</div>
					</div>
					<div class="col-12">
						<div class="form-group">
							<label>CPF</label>
							<input 
								type="tel" 
								v-model="$v.form.document_number.$model"
								class="form-control"
								:class="{ 'is-invalid': $v.form.document_number.$error }"
								v-mask="['###.###.###-##']"
								ref="document_number"
							>
					        <div class="invalid-feedback" v-if="!$v.form.document_number.CPFValidation">
					          	CPF Invalido
					        </div>
						</div>
					</div>
					<div class="col-12 col-md-auto mt-2">
						<button 
							type="submit" 
							class="btn btn-primary btn-block px-5"
						>
							salvar
						</button>
					</div>
				</form>	
			</div>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../../helpers'
	const helpers 		= new Helpers()

	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import { not, required, minLength, sameAs } from 'vuelidate/lib/validators'

	const CPFValidation = (value, vm) => value.length > 0 ? helpers.ValidaCPF(value) : true
	export default {

		name: 'PersonalInfo',

		data () {
			return {
				exihibit_avatar: 	null,
				default_avatar: 	'/img/profile.png',
				form: 				{
					name: 				'',
					document_number: 	'',
					phone_1: 			'',
					avatar: 			{
						file: 				'',
						name: 				''
					},
				}
			}
		},
		validations: {
			form: 	{
				name: 		{
					required
				},
				document_number: 	{
					CPFValidation //if
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'account'
			]),
			avatar()
			{
				const path 	= this.exihibit_avatar

				return path ? path : this.default_avatar
			}
		},
		watch: {
			account: {
				immediate: true,
				handler(val)
				{
					this.form.name 				= val.name
					this.form.document_number 	= val.document_number
					this.form.phone_1 			= val.phone_1
					this.exihibit_avatar 		= val.avatar ? `${process.env.VUE_APP_API_URL}/${val.avatar}` : null
				},
				deep: 		true
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			async SavePersonalInfo()
			{
				this.$v.form.$touch()

				if(!this.$v.form.$invalid)
				{
					this.StartLoading()

					const formData 		= new FormData()

					if(this.form.avatar.file)
					{
						formData.append('avatar', this.form.avatar.file, this.form.avatar.name)
					}

					await Object.keys(this.form).map((key, index) => {

						if(key != 'avatar')
						{
							formData.append(key.toString(), this.form[key])
						}
						
					})

					window.api.call('post', '/save-personal-info', formData)
						.then(({data}) => {
							if(data.response)
							{
								this.form.avatar.file = ''
								this.form.avatar.name = ''

								this.GetAuthAccount()

								Swal.fire({
									toast:  	true,
									title:		'Dados salvos com sucesso.',
									icon: 		'success',
									timer: 		3000,
									position: 	'top',
									timerProgressBar: true
								})

							}else{

								if(data.document)
								{
									Swal.fire({
										icon: 	'error',
						        		title:  'Ops...',
						        		text: 	'Este CPF já está cadastrado, verifique.'
									})

								}else{

									Swal.fire({
										icon: 	'error',
						        		title:  'Ops...',
						        		text: 	'Houve um erro ao concluir, contate o suporte.'
									})
								}
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				} else {
					window.helpers.focusInvalid(this.$v.form, this)
				}
			},
			UploadAvatar(event)
			{
            	const reader 			= new FileReader()
	            const me 				= this
	            let file 				= event.target.files[0]

	            if(file)
	            {
	            	this.StartLoading()

	            	this.form.avatar.file	= file
		            this.form.avatar.name	= file.name

		            reader.onload 			= e => me.exihibit_avatar = e.target.result

		            reader.readAsDataURL(file)

		            this.FinishLoading()
	            }
			},
		}
	}
</script>

<style lang="css" scoped>
	.btn-input
	{
		position: relative;
	}

	.file-input {
	    position: absolute;
	    left: 0;
	    top: 0;
	    height: 100%;
	    width: 100%;
	    cursor: pointer;
	    opacity: 0;
	}
</style>