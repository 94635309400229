<template>
	<div>
		<p class="text-center">Aqui você pode definir um template médico como padrão ou até restaurar um template médico ao padrão da organização.</p>
		<template v-if="providers.length > 0">
			<div class="form-group position-relative mb-0"
				 v-if="permission">
				<input 
					type="search" 
					class="form-control border-no pl-5" 
					placeholder="Buscar..."
					v-model="search"
				>
				<i class="far fa-search font-14 position-absolute ps-l-1 ml-3 ps-v-center"></i>
			</div>
			<template v-if="filtered_templates.length > 0">
				<div 
					class="border px-4 py-3 mt-3 rounded-lg"
					v-for="template in filtered_templates"
					:key="template.id"
				>
					<div class="row justify-content-md-between">
						<div class="col-12 d-flex justify-content-between">
							<div class="mb-4 mb-md-0" style="line-height: 1;">
								<p class="m-0 text-dark"><b>{{ template.provider.name.toUpperCase() }}</b></p>
								<small class="">Template: <b>{{ template.workgroup_schema.toUpperCase() }}</b></small>
							</div>
							<div class="">
								<button class="btn btn-primary btn-sm px-3" type="button"
									data-toggle="collapse"
									:data-target="'#content_' + template.workgroup_schema"
									aria-expanded="false"
									:aria-controls="'#content_' + template.workgroup_schema"
									v-if="can_modify"
								>
									<i class="fa fa-angle-down" aria-hidden="true"></i>
								</button>
							</div>
						</div>
						<div class="collapse col m-0 b-0 p-0 show" :id="'content_' + template.workgroup_schema"
							v-if="can_modify"
						>
							<div class="col-12 d-flex flex-wrap row">
								<!-- Botões de definir padrão e restaurar -->
								<div class="col-12 mb-4">
									<button
										class="btn btn-light-primary btn-sm px-3 mr-2"
										@click="ConfirmDecision('set-default', template)"
									> 
										definir como padrão da organização
									</button>
									<button
										class="btn btn-light-info btn-sm px-3" 
										@click="ConfirmDecision('restore-template', template)"
									> 
										restaurar com o padrão
									</button>
								</div>
								<div class="col-12 d-flex my-2">
									<!-- Area de exportacao -->
									<div class="col mr-2 card bg-light rounded-lg border-no overflow-hidden">
										<p class="my-2 text-center font-weight-bold text-dark font-20">Exportações</p>
										<div class="select-card with-border my-2 py-1 px-2 mr-1"
											@click="OpenModal(template)">
											<label class="">
												<div class="col rounded-lg d-flex justify-content-center">
													<p class="mb-0">Nova exportação</p>
												</div>
											</label>
										</div>
										<div class="mh-138">
											<div class="mh-200 text-muted d-flex justify-content-center align-items-center"
												v-if="GetDataMovements('e', template) == 0">
												<span>Não há nenhum arquivo aqui</span>
											</div>
											<div v-for="(data_movement) in GetDataMovements('e', template)"
												:key="data_movement.id">
												<div class="border rounded-lg my-2 py-1 px-2 mr-1">
													<div class="col d-flex justify-content-between">
														<span>Exportação #{{ data_movement.id }}</span>
														<span>{{ ParseDate(data_movement.created_at) }}</span>
														<a class="px-1"
															v-tippy="{ arrow: true }"
															content="Fazer download"
															@click="DownloadFile(data_movement.url)"
															v-if="data_movement.url && !data_movement.in_progress"
														>
															<i class="fa fa-download"></i>
														</a>
														<span
															v-tippy="{ arrow: true }"
															content="Arquivo na fila de processamento"
															v-else-if="data_movement.status == 'queued'" 
														>
															<i class="fas fa-clock    "></i>
														</span>
														<span
															v-tippy="{ arrow: true }"
															:content="'Gerando arquivo: ' + (data_movement.progress_percentage > 100 ? 99 : data_movement.progress_percentage) + '%'"
															v-else-if="data_movement.status == 'executing'" 
														>
															<span class="spinner-border spinner-border-sm mt-2" role="status" aria-hidden="true"></span>  
														</span>
														<span
															v-tippy="{ arrow: true }"
															content="Houve uma falhar ao gerar o arquivo..."
															v-else-if="data_movement.status == 'failed' || data_movement.status == 'retrying' || ( data_movement.status == 'finished' && !data_movement.url )" 
														>
															<i class="fas fa-exclamation-circle text-danger"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- Area de importacao -->
									<div class="col ml-2 card bg-light rounded-lg border-no overflow-hidden">
										<p class="my-2 text-center font-weight-bold text-dark font-20">Importações</p>
										<div class="select-card with-border my-2 py-1 px-2 mr-1"
											@click="ImportTemplate(null, template)"
											:disabled="HasPendingProgress(template)"
										>
											<label class="">
												<div class="col rounded-lg d-flex justify-content-center">
													<p class="mb-0">Nova importação</p>
												</div>
											</label>
										</div>
										<div class="mh-138">
											<div class="mh-200 text-muted d-flex justify-content-center align-items-center"
												v-if="GetDataMovements('i', template) == 0"
											>
												<span>Não há nenhum arquivo aqui</span>
											</div>
											<div v-for="(data_movement) in GetDataMovements('i', template)"
												:key="data_movement.id">
												<div class="border rounded-lg my-2 py-1 px-2">
													<div class="col d-flex justify-content-between">
														<span>Importação #{{ data_movement.id }}</span>
														<span>{{ ParseDate(data_movement.created_at) }}</span>
														<a class="px-1"
															v-tippy="{ arrow: true }"
															content="Fazer download"
															@click="DownloadFile(data_movement.url)"
															v-if="data_movement.url && !data_movement.in_progress"
														>
															<i class="fa fa-download"></i>
														</a>
														<span
															v-tippy="{ arrow: true }"
															content="Arquivo na fila de processamento"
															v-else-if="data_movement.status == 'queued'" 
														>
															<i class="fas fa-clock    "></i>
														</span>
														<span
															v-tippy="{ arrow: true }"
															:content="'Importando os dados: ' + data_movement.progress_percentage + '%'"
															v-else-if="data_movement.status == 'executing'" 
														>
															<span class="spinner-border spinner-border-sm mt-2" role="status" aria-hidden="true"></span>  
														</span>
														<span
															v-tippy="{ arrow: true }"
															:content="GetDataMovementFailMessage(data_movement)"
															v-else-if="data_movement.status == 'failed' || data_movement.status == 'retrying' || (data_movement.status == 'finished' && !data_movement.url)" 
														>
															<i class="fas fa-exclamation-circle text-danger"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
										<input type="file" name="" :id="'input-template' + template.id"
											accept=".xlsx"
											hidden
											@change="ImportTemplate($event, template)">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="border px-4 py-3 mt-3 rounded-lg">
					<div class="row justify-content-md-between align-items-center">
						<div class="col mb-4 mb-md-0 text-center">
							Sua busca por "{{ search }}" retornou sem resultado.
						</div>
					</div>
				</div>			
			</template>
		</template>
		<template v-else>
			<div class="border px-4 py-3 mt-3 rounded-lg">
				<div class="row justify-content-md-between align-items-center">
					<div class="col mb-4 mb-md-0 text-center">
						Não há templates para exibir.
					</div>
				</div>
			</div>	
		</template>
		<div class="modal fade" id="modal-export-template">
			<div class="modal-dialog d-flex justify-content-center align-items-center"
				@keydown.esc="CloseModal">
				<div class="modal-content p-3 d-flex" style="width: 900px">
					<div class="align-self-end">
						<button
							id="close-modal"
							class="btn btn-light btn-sm m-2"
							@click="CloseModal"
						>
							<i class="fal fa-times"></i>
						</button>
					</div>
					<div class="modal-header mb-4">
						<h4 class="font-20 m-0 text-center">
							Exportar template
						</h4>
					</div>
					<div class="modal-body d-flex flex-column align-items-center justify-content-center" style="width: 600px">
						<div class="mb-3">
							<p class="mb-4 text-center">Selecione apenas as opcões de planilhas que deseja no arquivo exportado.</p>
							<div class="col-12">
								<div class="col-4 px-0 mb-0 d-inline-block form-group form-check">
									<input 
										type="checkbox"
										id="select_all"
										v-model="form_export.select_all"
									>
									<label for="select_all">
										Selecionar Todos
									</label>
								</div>
								<hr>
							</div>
							<div id="export-options">
								<div class="col-12 d-flex my-2">
									<div class="col-4 px-0 mb-0 d-inline-block form-group form-check">
										<input 
											type="checkbox"
											id="description"
											v-model="form_export.description"
										>
										<label for="description">
											Descrição
										</label>
									</div>
									<div class="col-4 px-0 mb-0 d-inline-block form-group form-check">
										<input 
											type="checkbox"
											id="calculus"
											v-model="form_export.calculus"
										>
										<label for="calculus">
											Cálculo
										</label>
									</div>
									<div class="col-4 px-0 mb-0 d-inline-block form-group form-check">
										<input 
											type="checkbox"
											id="classification"
											v-model="form_export.classification"
										>
										<label for="classification">
											Classificação
										</label>
									</div>
								</div>
								<div class="col-12 d-flex my-2">
									<div class="col-4 px-0 mb-0 d-inline-block form-group form-check">
										<input 
											type="checkbox"
											id="information"
											v-model="form_export.information"
										>
										<label for="information">
											Informação
										</label>
									</div>
									<div class="col-4 px-0 mb-0 d-inline-block form-group form-check">
										<input 
											type="checkbox"
											id="observation"
											v-model="form_export.observation"
										>
										<label for="observation">
											Observação
										</label>
									</div>
									<div class="col-4 px-0 mb-0 d-inline-block form-group form-check">
										<input 
											type="checkbox"
											id="var_dynamic"
											v-model="form_export.var_dynamic"
										>
										<label for="var_dynamic">
											Variáveis dinâmicas
										</label>
									</div>
								</div>
								<div class="col-12 d-flex my-2">
									<div class="col-12 px-0 mb-0 d-inline-block form-group form-check">
										<input 
											type="checkbox"
											id="var_struct"
											v-model="form_export.var_struct"
										>
										<label for="var_struct">
											Variáveis estruturais
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer p-1">
						<button 
							id="btn-export-template"
							class="btn align-self-end mt-2 mb-0 mx-3 btn-primary"
							@click="ExportTemplate()">
							Exportar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import _, { split } from 'lodash'
	import Helpers from '../../../helpers'
	import moment from 'moment'
	import axios from 'axios'
	const helpers 		= new Helpers()

	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Templates',
		props: {
			providers: 			{
				type: 				Array,
				default: 			() => []
			},
			organization: 		{
				type: 				Object,
				default: 			() => {}
			},
			permission:			{
				type:				Boolean,
				default:			false
			},
			can_modify:			{
				type:				Boolean,
				default:			false
			}
		},
		data () {
			return {
				search: 			'',
				mandatory:			false,
				selected:			{
					template:			null
				},
				form_export: 		{
					select_all:			false,
					description:		false,
					calculus:			false,
					classification:		false,
					information:		false,
					observation:		false,
					var_dynamic:		false,
					var_struct:			false,
				},
                input_clickable:    true
			}
		},
		computed: {
			...mapGetters('auth', [
				'account', 'requester_selected_id', 'update_current_url'
			]),
			filtered_templates()
			{
				const search 		= helpers.RemoveAcento(this.search.toLowerCase())

				if(!this.permission)
				{
					return this.providers.filter( provider => {
						return this.account.id === provider.account_provider_id
					})
				}

				return this.providers.filter( provider => {
					return helpers.RemoveAcento(provider.provider.name.toLowerCase()).includes(search)
				})
			},
            lib_schema()
            {
                return this.organization.instances[0].config.schema_name
            }
		},
		watch: {
			update_current_url: {
				handler(val) {
					if(val) {
 						this.$parent.GetOrganizationData(false)

						this.UpdateCurrentUrl(false);

                        this.$nextTick();
					}
				}
			},
			'form_export.select_all': {
				handler(val) {
					const form = this.form_export

					form.description 	= val
					form.calculus 		= val
					form.classification = val
					form.information 	= val
					form.observation 	= val
					form.var_dynamic 	= val
					form.var_struct 	= val
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),	
			...mapActions('auth', [
				'UpdateCurrentUrl'
			]),
			ConfirmDecision(type, template)
			{

				if ( this.requester_selected_id != this.organization.id ) {
					Swal.fire({
						icon:			'error',
						title:			'Logue nesta organização',
						text:			'Você precisa está logado nessa organização para fazer isso!'
					})
					return;
				}

				Swal.fire({
					icon: 				'question',
					title: 				`${ type == 'set-default' ? 'Definir como o padrão da organização' : 'Restaurar template ao padrão'}`,
					text: 				'Esta ação é IRREVERSÍVEL e não poderá ser desfeita, deseja mesmo continuar?',
					showConfirmButton: 	true,
					showCancelButton: 	true,
					input:				type == 'set-default' ? 'checkbox' : '',
					inputValue:			this.mandatory,
					inputPlaceholder:	'Atualização <b>obrigatória</b> para todos os membros da organização.',
					confirmButtonText: 	'sim',
					cancelButtonText: 	'não',
					customClass: 		{
						confirmButton: 		'bg-danger',
					},
				}).then(async (res) => {

					if (res.isConfirmed)
					{
						switch(type)
						{
							case 'set-default':
								this.mandatory = res.value ? true : false

								this.SetDefault(template)
							break
							case 'restore-template':
								this.RestoreTemplate(template)
							break
						}
					}
				})
			},
			SetDefault(template)
			{
				this.StartLoading()

				const obj 	= {
					source: 	{
						account_id: 		template.account_requester_id,
						schema: 			template.workgroup_schema,
						provider_id: 		template.account_provider_id,
						type: 				'WK'
					},
					options: 	{
						template: 					true,
						schedules_and_proceedings: 	false,
						configurations: 			false,
						flow: 						false
					},
					destiny: 	{
						account_id: 		this.organization.id,
						schema: 			this.organization.instances[0].config.schema_name,
						type: 				'PJ',
						only_set_default: 	true
					},
					account_responsible_id: this.account.id,
					organization_id: this.requester_selected_id,
					mandatory: this.mandatory
				}

				return window.api.call('post', '/set-default-template', obj)
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon: 		'success',
								title: 		'Procedimento Concluido !',
								text: 		'Template padrão setado com sucesso.'
							})

						}else{

							Swal.fire({
								icon: 		'error',
								text: 		'Houve um erro ao concluir, contate o suporte.',
								title: 		'Eita!'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			RestoreTemplate(template)
			{
				this.StartLoading()

				const obj 	= {
					destiny: 	{
						account_id: 		template.account_requester_id,
						schema: 			template.workgroup_schema,
						provider_id: 		template.account_provider_id,
						type: 				'WK',
						only_restore: 		true
					},
					options: 	{
						template: 					true,
						schedules_and_proceedings: 	false,
						configurations: 			false,
						flow: 						false
					},
					source: 	{
						account_id: 		this.organization.id,
						schema: 			this.organization.instances[0].config.schema_name,
						type: 				'PJ',
					},
					account_responsible_id: this.account.id,
					organization_id: this.requester_selected_id,
				}

				return window.api.call('post', '/restore-from-default-template', obj)
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon: 		'success',
								title: 		'Procedimento Concluido !',
								text: 		'Template restaurado com sucesso.'
							})

						}else{

							Swal.fire({
								icon: 		'error',
								text: 		'Houve um erro ao concluir, contate o suporte.',
								title: 		'Eita!'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			GetDataMovements(type, template)
			{
				return template.data_movements.filter( e => e.movement_type == type )
			},
			ParseDate(date)
			{
				return moment(date).format('DD/MM/YYYY HH:mm');
			},
			HasPendingProgress(template) 
			{
				let pendings = template.data_movements.filter( e => e.status == 'queued' || e.status == 'executing' )

				return pendings.length > 0
			},
			DownloadFile(url) 
			{

				const method = 'GET';

				this.StartLoading()

				axios.request({
					url,
					method,
					responseType: 'blob',
				})
				.then(({ data }) => {

					const downloadUrl = window.URL.createObjectURL(new Blob([data], {type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
					const link = document.createElement('a');
					link.href = downloadUrl;
					// let filename = (split(url, '/')[6] + '.xlsx')
					link.setAttribute('download', '')
					document.body.appendChild(link);
					link.click();
					link.remove();

				})				
				.finally(() => {
					this.FinishLoading()
				})


			},
			ExportTemplate() {
				const template = this.selected.template;

				if (this.HasPendingProgress(template)) {
					return Swal.fire({
						icon: 		'info',
						toast: 		true,
						timer: 		5000,
						position: 	'top',
						html: 		'Um procedimento já em andamento, aguarde.'
					});
				}

				const vm = this;

				this.StartLoading();

				return window.api.call('post', '/data-movement/export/template', {
					schema: template.workgroup_schema,
					account_workgroup_id: template.id,
					options: {
						...this.form_export
					}
				})
				.then(({data}) => {
					if(!data.response) {
						Swal.fire({
							icon: 		'error',
							text: 		data.message,
							title: 		'Ops!'
						});
					} else {
						if(data.pending_laudos > 0) {
							Swal.fire({
								icon: 		'warning',
								text: 		`A importação ${data.data_movement.id} foi para a fila de processamento. Porém, esse template possui ${data.pending_laudos} laudos em abertos, e não será possível importar caso haja laudos em aberto.`,
							});
						} else {
							Swal.fire({
								icon: 		'info',
								title: 		'Em andamento...',
								toast: 		true,
								position: 	'top',
								html: 		`${data.message}`,
							});
						}

						this.CloseModal();
						this.$parent.GetOrganizationData();
					}
				})
				.finally(() => {
					this.FinishLoading();
				})
			}, 
			ImportTemplate(event, template)
			{
				if(this.HasPendingProgress(template)) {
					return Swal.fire({
						icon: 		'info',
						toast: 		true,
						timer: 		5000,
						position: 	'top',
						html: 		'Um procedimento já em andamento, aguarde.'
					});
				}

				if(event && event.target.files.length > 0) {
					const file 				= event.target.files[0];

					this.StartLoading();

					const formData 		= new FormData();

					formData.append('file', file, file.name);
					formData.append('schema', template.workgroup_schema);
					formData.append('lib_schema', this.lib_schema);

					return window.api.call('post', '/data-movement/import/template', formData)
                        .then(({data}) => {
                            if(!data.response) {
                                Swal.fire({
                                    icon: 		'error',
                                    text: 		data.message,
                                    title: 		'Ops!'
                                });
                            } else {
                                Swal.fire({
                                    icon: 		        'info',
                                    toast: 		        true,
                                    position: 	        'top',
                                    timer:		        5000,
                                    text: 		        `A importação ${data.data_movement.id} foi para a fila de processamento.`,
                                    timerProgressBar:   true
                                });

                                this.$parent.GetOrganizationData();
                            }
								
							document.querySelector('#input-template' + template.id).value = null;
                        })
                        .finally(() => {
                            this.FinishLoading();
                        })
				}

                if(!this.input_clickable) return
                
				document.querySelector('#input-template' + template.id).click();
			},
			GetDataMovementFailMessage(data_movement) {
				if (data_movement?.job_status?.output?.message) { 
					return data_movement.job_status.output.message.substring(0, 100) + '...';
				}

				return 'Não foi possível importar.';
			},
			OpenModal(template) {
				window.jQuery('#modal-export-template').modal('show');

				window.jQuery('body').addClass('modal-open');

				window.jQuery('#modal-export-template').addClass('d-flex');

				this.selected.template = template;
			},
			CloseModal() {
				window.jQuery('#modal-export-template').modal('hide')
					.on('hidden.bs.modal', () => {
						window.jQuery('body').removeClass('modal-open');
						
						window.jQuery('#modal-export-template').removeClass('d-flex');
					});
			}
		}
	}
</script>

<style lang="css" scoped>

.mh-138
{
	height: 138px;
	overflow: auto;
}

.fa-download {
	cursor: pointer;
}

.select-card label {
	padding-right: 1.5rem !important;
}

</style>

