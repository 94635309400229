var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-large pb-4"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.step == 'pre')?_c('div',{key:_vm.step}):_vm._e(),(_vm.step == 'basic-data')?_c('div',{key:_vm.step,staticClass:"pb-3"},[_c('BasicData',{attrs:{"form":_vm.schedule},on:{"previous":_vm.Previous,"next":_vm.WalkStep}})],1):_vm._e(),(_vm.step == 'time-range')?_c('div',{key:_vm.step,staticClass:"pb-3"},[_c('TimeRange',{attrs:{"is_started":false,"time_obj":{
					total_time: _vm.schedule.total_time,
					time_init: _vm.schedule.time_init,
					time_end: _vm.schedule.time_end,
					max_attendances: _vm.schedule.max_attendances
				},"is_vacancy":_vm.is_vacancy,"attendances_obj":{
					attendances: _vm.schedule.attendances,
					max_attendances: _vm.schedule.max_attendances
				},"bypass_validation":false},on:{"previous":function($event){_vm.step = 'basic-data'},"total-time":function($event){_vm.schedule.total_time = $event},"time-init":function($event){_vm.schedule.time_init = $event},"time-end":function($event){_vm.schedule.time_end = $event},"max-attendances":function($event){_vm.schedule.max_attendances = $event},"next":_vm.WalkStep}})],1):_vm._e(),(_vm.step == 'block-config')?_c('div',{key:_vm.step,staticClass:"pb-3"},[_c('BlockConfig',{ref:"block_config",attrs:{"is_started":_vm.block_config_is_started,"time_obj":{
					total_time: 	_vm.schedule.total_time,
					time_init: 		_vm.schedule.time_init,
					time_end: 		_vm.schedule.time_end
				},"props_blocks":_vm.schedule.blocks,"map":_vm.schedule.block_map,"is_vacancy":_vm.is_vacancy},on:{"mounted":function($event){_vm.block_config_is_started = false},"pause-time":_vm.PauseTimeChangeHandler,"previous":function($event){_vm.step = 'time-range'},"next":_vm.WalkStep}})],1):_vm._e(),(_vm.step == 'periodicity-and-dates')?_c('div',{key:_vm.step,staticClass:"pb-3"},[_c('PeriodicityAndDates',{attrs:{"is_started":false,"continuous_schedule":_vm.schedule.continuous_schedule},on:{"previous":function($event){_vm.step = _vm.schedule.continuous_schedule ? 'time-range' : 'block-config'},"next":_vm.WalkStep}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }