<template>
	<div>
		<div class="container">
			<div class="row align-items-center justify-content-center flex-column">
				<div class="col-12">
					<div class="d-block mx-auto pb-3 pb-md-5 text-md-center max-500 pr-6 pr-md-0">
						<span>{{ label }}</span>
						<h4 class="font-24 font-md-32 m-0">Selecione ou crie um grupo</h4>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<template v-if="filtered_groups.length > 0">
						<div
							v-for="group in filtered_groups"
							:key="group.id"
							class="list-tile rounded-lg border mb-3"
							:class="{ 
								'active': selected.group && group.id === selected.group.id 
							}"
							@click="Select(group)"
						>
							<div class="form-row align-items-center">
							 	<div class="col pl-3">
							 		{{ group.nome }}
							 	</div>
							 	<div class="col-auto">
							 		<label @click.stop class="not text-dark">
										<i 
											class="fas fa-pen" 
											@click="Edit(group)"
										/>
									</label>
							 	</div>
							 	<div class="col-auto">
							 		<label>
										<i class="far fa-chevron-right"></i>
									</label>
							 	</div>
							</div>
						</div>
					</template>
					<template v-else>
						
						<p class="text-muted text-center">Não há procedimentos</p>
								
					</template>
				</div>
				<div class="col-12 col-md-6 text-center py-5">
					<div class="d-flex justify-content-between">
						<button 
							class="btn btn-light px-md-5"
							@click="Previous"
						>
							voltar
						</button>
						<button 
							class="btn btn-primary px-md-5"
							@click="New"
						>
							criar
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="modal-group">
		  	<div class="modal-dialog">
			    <div class="modal-content">
			    	<div class="modal-header px-5 py-4">
			    		<h4 class="font-20 m-0">{{ form.id ? 'Editar' : 'Novo' }} grupo</h4>
				        <button 
				        	type="button" 
				        	class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3" 
				        	data-dismiss="modal"
				        >
				         	<i class="far fa-times mx-1"></i>
				        </button>
			    	</div>
			    	<hr class="m-0">
			        <div class="modal-body">
			        	<div class="form-row p-4 justify-content-center">
			        		<div class="col-12">
			        			<div class="form-group">
			        				<input 
			        					type="text"
			        					placeholder="Nome" 
			        					v-model="form.name"
			        					class="form-control"
			        				>
			        			</div>
			        		</div>
			        		<div class="col-12">
			        			<div class="form-group">
			        				<input 
			        					type="text"
			        					placeholder="Informações" 
			        					v-model="form.info"
			        					class="form-control"
			        				>
			        			</div>
			        		</div>
			        	</div>
		        		<hr class="m-0">
			    		<div class="d-flex justify-content-between p-4">
							<button 
								class="btn btn-light p-3 px-4" 
								data-dismiss="modal"
							>
								cancelar
							</button>

							<button 
								class="btn btn-primary p-3 px-4"
								@click="Save()"
							>
								salvar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const _ = require('lodash')
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'ProceedingGroup',
		props: 	{
			label: 		{
				type: 		String,
				default: 	''
			},
			groups: 	{
				type: 		Array,
				default: 	() => []
			},
			group_id: 	{
				type: 		[String, Number],
				default: 	null
			},
			modality_id: 	{
				type: 		[String, Number],
				default: 	null
			}
		},
		data () {
			return {
				selected: 	{
					group: 		null
				},
				form: 		{
					id: 		'',
					name: 		'',
					info: 		'',
					type: 		1
				}
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			filtered_groups()
			{
				return _.sortBy(this.groups, 'nome', 'asc')
			}
		},
		watch: {
			group_id: {
				handler(val)
				{
					if(val)
					{
						this.selected.group 	= this.groups.find( m => m.id === parseInt(val))
					}
					
				},
				immediate: true
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			Previous()
			{
				this.$emit('previous')
			},
			Select(group)
			{
				this.$emit('next', group)
			},
			New()
			{
				this.form.id 		= ''
				this.form.name 		= ''
				this.form.info 		= ''

				window.jQuery('#modal-group').modal('show');
			},
			Edit(group)
			{
				this.form.id 		= group.id
				this.form.name 		= group.nome
				this.form.info 		= group.info

				window.jQuery('#modal-group').modal('show');
			},
			Close()
			{
				window.jQuery('.modal').modal('hide');
			},
			Save()
			{
				this.StartLoading()

				return window.api.call('post', '/save-proceeding', {
						account_id: 		this.requester_selected_id,
						modality_id: 		this.modality_id,
						...this.form
					})
					.then(({data}) => {
						if(data.response)
						{
							let groups 	= this.groups

							if(this.form.id)
							{
								groups 	= groups.map( g => {

									if(g.id == this.form.id)
									{
										g.nome  	= data.proceeding.nome
										g.info 		= data.proceeding.info
									}

									return g
								})

							}else{
								groups.push(data.proceeding)
							}

							this.$emit('update-groups', groups)
						}
					})
					.finally(() => {
						
						window.jQuery('.modal').modal('hide');

						this.FinishLoading()
					})
			}
		}
	}
</script>

<style lang="css" scoped>

</style>