<template>
	<div class="col-12 px-0 d-flex flex-column align-items-center">
		<div v-if="!content_loaded" class="spinner-border text-theme" role="status"></div>
		<div v-else class="col-12 px-0 d-flex flex-column align-items-center">
			<div v-if="has_more_than_one_subscription" class="col-9 px-0 scroll-x scroll-transparent d-flex mx--5 mx-md-0">
				<ul class="nav nav-pills nav-theme w-md-100 justify-content-start mb-4 px-0">
					<li class="nav-item">
						<a class="nav-link pb-md-3 px-md-4 active" data-toggle="tab" href="#professional">Profissional</a>
					</li>
					<li class="nav-item">
						<a class="nav-link pb-md-3 px-md-4" data-toggle="tab" href="#corporate">Corporativo</a>
					</li>
				</ul>
			</div>

			<div v-if="professional_subscription && 
				(professional_subscription.status === 'past_due' || professional_subscription.status === 'incomplete')"
				class="col-12 col-md-6 alert alert-warning"
			>
				<i class="fal fa-exclamation-triangle mr-2"></i>
				Não foi possível realizar a cobrança da forma de pagamento selecionada. Altere a forma de pagamento e depois selecione a opção <b>Reativar assinatura</b>.
			</div>
			<div class="col-12 px-0 tab-content d-flex justify-content-center mb-4">
				<!-- PROFISSIONAL -->
				<div id="professional" class="tab-pane px-0 active"
					:class="professional_subscription ? 'col-md-6' : 'col-md-9'">
					
					<!-- SEM ASSINATURA -->
					<div v-if="!professional_subscription" class="col-12 px-0">
						<div class="col-12 px-0 d-flex">
							<div id="card-container" class="col-7 d-flex flex-column justify-content-center pl-0 pr-4">
								<div v-if="!cards.length" class="px-0">
									<div class="cardholders-name-container" v-if="form_ready">
										<label for="" class="Label">
											Nome
										</label>
										<input 
											id="cardholders-name"
											type="text"
											class="text-uppercase"
											placeholder="NOME NO CARTÃO"
											v-model="card.name"
											:maxlength="24"
										>
									</div>
									<div ref="payment_element" id="payment-element"></div>
								</div>
								<div v-else class="px-0">
									<p class="px-0">
										<b>Método de pagamento selecionado:</b>
									</p>
									<div v-if="default_payment_method" class="col-12 px-0 d-flex align-items-center justify-content-between">
										<div class="col-8 px-0 d-flex align-items-center">
											<div v-if="GetBrand(default_payment_method.brand)" class="credit-card-brand mx-1">
												<img :src="GetBrand(default_payment_method.brand)">
											</div>
											<p class="m-0 ml-3">
												<b>**{{ default_payment_method.last4 }}</b>
											</p>
										</div>
										<div class="col-4 px-0 d-flex justify-content-end">
											<p class="m-0">
												<span 
													class="link"
													v-tippy="{ arrow: true }"
													content="A assinatura utiliza o pagamento padrão cadastrado."
													@click="OpenModal('change-default-payment')"
												>
													Alterar
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div v-if="price && form_ready" class="col-5 pl-4 pr-0 purshase-overview d-flex flex-column justify-content-around">
								<div class="px-0">
									<p class="m-0 text-uppercase"><b>Você está assinando</b></p>
									<h5 class="">Licença {{ price.product.name }} ({{ ParseInterval(price.interval) }})</h5>
								</div>
								<div class="px-0 my-5">
									<p class="m-0 text-uppercase"><b>Total</b></p>
									<h4 class="m-0">R$ {{ ParsePrice(price.unit_amount) }}/{{ ParsePeriod(price.interval) }}</h4>
								</div>
								<div class="px-0">
									<p class="m-0 text-uppercase"><b>Nós aceitamos</b></p>
									<div class="col-12 px-0 d-flex">
										<div v-for="card in filtered_cards" class="credit-card-brand mx-1" :key="card.id">
											<img :src="GetBrand(card.name)">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-end py-4">
							<button 
								id="finish_subscription"
								type="button"
								class="btn btn-primary p-3 px-4"
							>	
								Finalizar
							</button>
						</div>
					</div>

					<!-- COM ASSINATURA -->
					<div v-else class="col-12 p-0 col-12 px-0 d-flex flex-column align-items-center">
						<div class="col-12 border p-3_5">
							<div class="col-12 px-0 d-flex align-items-start justify-content-between">
								<div class="col-10 px-0">
									<div class="col-12 px-0 d-flex align-items-center">
										<h6 class="m-0">
											Licença {{ professional_subscription.plan.nickname }} ({{ ParseInterval(professional_subscription.plan.interval) }})
										</h6>
										<small class="ml-2 badge"
											:class="{
												'badge-success': professional_subscription.status === 'active',
												'badge-info': professional_subscription.status === 'trialing',
												'badge-danger': professional_subscription.status === 'past_due',
											}"
										>
											{{
												professional_subscription.status === 'active' ? 'Ativa' :
												professional_subscription.status === 'trialing' ? 'Em teste' :
												professional_subscription.status === 'past_due' ? 'Atrasada' :
												professional_subscription.status === 'incomplete' ? 'Incompleta' :
												professional_subscription.status === 'incomplete_expired' ? 'Expirada' :
												professional_subscription.status === 'paused' ? 'Pausada' : 'Cancelada'
											}}
										</small>
									</div>
									<p class="m-0">
										R$ {{ ParsePrice(professional_subscription.plan.per_unit) }}/{{ ParsePeriod(professional_subscription.plan.interval) }}
									</p>
								</div>
								<div class="col-2 px-0 d-flex justify-content-end">
									<span v-if="1 === 2"
										class="link"
									>
										Alterar
									</span>
								</div>
							</div>
						</div>
						<div v-if="professional_subscription && (professional_subscription.status !== 'canceled' &&
						 professional_subscription.status !== 'incomplete_expired')" class="col-12 border p-0 mt-5">
							<div v-if="default_payment_method" class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Forma de pagamento
								</p>
								<div class="col-4 px-0 d-flex align-items-center justify-content-between">
									<div v-if="GetBrand(default_payment_method.brand)" class="credit-card-brand mx-1">
										<img :src="GetBrand(default_payment_method.brand)">
									</div>
									<p class="m-0">
										<b>**{{ default_payment_method.last4 }}</b>
									</p>
									<p class="m-0 ml-2">
										<span 
											id="change-default-payment"
											class="link"
											v-tippy="{ arrow: true }"
											content="A assinatura utiliza o pagamento padrão cadastrado."
											@click="OpenModal('change-default-payment')"
										>
											Alterar
										</span>
									</p>
								</div>
							</div>
							<hr class="my-0">
							<div class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Próxima data de cobrança
								</p>
								<p class="m-0">
									<b>{{ professional_subscription.next_invoice }}</b>
								</p>
							</div>
							<hr class="my-0">
							<div class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Último pagamento
								</p>
								<p v-if="professional_subscription.last_invoice && 
									professional_subscription.last_invoice.date &&
									professional_subscription.last_invoice.total"
									class="m-0"
								>
									<span>
										<b>{{ professional_subscription.last_invoice.date }}</b>
									</span>
									<span class="ml-3">
										<b>R$ {{ ParsePrice(professional_subscription.last_invoice.total) }}</b>
									</span>
								</p>
								<p v-else class="m-0">
									<span>
										<b>Ainda não houve</b>
									</span>
								</p>
							</div>
						</div>
						<div v-if="professional_subscription && 
							(professional_subscription.status === 'past_due' || professional_subscription.status === 'incomplete')"
							id="reactivate-subscription"
							class="col-12 px-0 border mt-5"
							@click="PayPendingInvoice()"
						>
							<button class="col-12 p-3_5 btn">
								Reativar assinatura
							</button>
						</div>
						<div v-if="professional_subscription && 
							(professional_subscription.status !== 'canceled' &&
							professional_subscription.status !== 'incomplete_expired')" class="col-12 border p-3_5 mt-5">
							<p class="m-0 text-center">
								<span 
									class="cancel-link text-danger"
									:class="{
										'disabled': professional_subscription.status === 'trialing',
									}"
									@click="CancelSubscription('professional')"
								>
									Cancelar assinatura
								</span>
							</p>
						</div>
						
						<!-- FATURAS -->
						<div v-if="!invoices_loaded" class="spinner-border text-theme" role="status"></div>
						<div v-if="invoices.length > 0 && invoices_loaded" class="col-12 my-5 px-0">
							<h5 class="mb-3 text-center">
								{{ professional_subscription ? 'Faturas' : 'Histórico de cobrança' }}
							</h5>
							<div class="col-12 px-0">
								<DataTables 
									:headers="headers_invoices"
									v-model="invoices"
									:prop_sortedBy="invoices_sortedBy"
								>
									<template v-slot:body="{ item }">
										<td scope="col" style="width: 80%">
											<div class="px-0 d-flex flex-column">
												<div class="col-4 px-0">
													<span class="badge"
														:class="{
															'badge-success': item.status === 'paid',
															'badge-info': item.status === 'draft',
															'badge-warning': item.status === 'open',
															'badge-danger': item.status === 'void',
														}"
													>
														{{ ParseStatus(item.status) }}
													</span>
												</div>
												<b>{{ ParseDate(item.date) }}</b>
											</div>
										</td>
										<td scope="col" class="pl-0">
											R$ {{ ParsePrice(item.total) }}
										</td>
									</template>
								</DataTables>
							</div>
						</div>
					</div>
				</div>

				<!-- CORPORATIVO -->
				<div v-if="corporate_subscription" id="corporate" class="tab-pane col-12 px-0"
					:class="corporate_subscription ? 'col-md-6' : 'col-md-9'">
					<div class="col-12 p-0 col-12 px-0 d-flex flex-column align-items-center">
						<div class="col-12 border p-3_5">
							<div class="col-12 px-0 d-flex align-items-center justify-content-between">
								<div class="col-10 px-0">
									<div class="px-0 d-flex align-items-center">
										<h6 class="m-0">
											Licença {{ corporate_subscription.plan.nickname }} ({{ ParseInterval(corporate_subscription.plan.interval) }})
										</h6>
										<small class="ml-2 badge"
											:class="{
												'badge-success': corporate_subscription.status === 'active',
											}"
										>
											Ativa
										</small>
									</div>
									<p class="m-0">
										R$ {{ ParsePrice(corporate_subscription.plan.per_unit) }}/{{ corporate_subscription.plan.unit_name }}/{{ ParsePeriod(corporate_subscription.plan.interval) }}
									</p>
								</div>
							</div>
						</div>
						<div class="col-12 border p-0 my-5">
							<div class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Usos durante o período
								</p>
								<p class="m-0">
									<b>{{ corporate_subscription.usage.total || 0 }}</b>
								</p>
							</div>
							<hr class="my-0">
							<div class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Próxima data de cobrança
								</p>
								<p class="m-0">
									<b>{{ corporate_subscription.next_invoice }}</b>
								</p>
							</div>
							<hr class="my-0">
							<div class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Último pagamento
								</p>
								<p v-if="corporate_subscription.last_invoice" class="m-0">
									<span>
										<b>{{ corporate_subscription.last_invoice.date }}</b>
									</span>
									<span class="ml-3">
										<b>R$ {{ ParsePrice(corporate_subscription.last_invoice.total) }}</b>
									</span>
								</p>
								<p v-else class="m-0">
									<span>
										<b>Ainda não houve</b>
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade" 
			id="modal-change-default-payment"
		>
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header px-5 py-4 mb-3">
						<h4 class="font-20 m-0 text-center">
							Alterar pagamento padrão
						</h4>
				        <button 
				        	type="button" 
				        	class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3"
							@click="CloseModal('change-default-payment')" 
				        >
				         	<i class="far fa-times mx-1"></i>
				        </button>
					</div>
					<div class="modal-body">
						<div class="col-12">
							<DataTables 
								:headers="headers_card"
								v-model="cards_modal"
								:showTotal="false"
								:tr-button="true"
								@by-click-on-tr="SetDefaultPayment"
							>
								<template v-slot:body="{ item }">
									<td scope="col">
										<span v-if="item.default" class="badge badge-primary">Padrão</span>
									</td>
									<td scope="col">
										<div v-if="GetBrand(item.brand)" class="credit-card-brand">
											<img :src="GetBrand(item.brand)">
										</div>
										<div v-else>{{ item.brand }}</div>
									</td>
									<td scope="col">**{{ item.last4 }}</td>
									<td scope="col">{{ item.exp_date }}</td>
								</template>
							</DataTables>
						</div>
						<div class="col-12 mb-5 d-flex align-items-center justify-content-between">
							<p class="m-0">
								Atenção: A próxima cobrança será feita no método de pagamento padrão.
							</p>
							<button
								class="btn btn-primary p-3 px-4"
								@click="RedirectToPaymentMethods()"
							>
								Adicionar
							</button>
						</div>
						<!-- <div class="d-flex justify-content-end py-4">
							<button 
								id="finish"
								type="button"
								class="btn btn-primary p-3 px-4"
							>	
								Salvar
							</button>
						</div>		   -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2';
	import { mapGetters, mapActions } from 'vuex';
	import DataTables from './../../shared/DataTables';
	import StripeFactory from './../../../utils/payments/Stripe.js';
	import _ from 'lodash';
	import moment from 'moment';

	export default {
		name: 'Subscriptions',
		components: {
			DataTables,
		},
		props: {
			cards:			{
				type:			Array,
				default:		() => []
			},
			subscriptions:	{
				type:			Array,
				default:		() => []
			},
			client_secret:	{
				type:			String,
				default:		''
			},
			price:			{
				type:			Object,
				default:		() => {}
			},
			content_loaded:	{
				type:			Boolean,
				default:		false
			},
		},
		data () {
			return {
				publishable_key: 	process.env.VUE_APP_STRIPE_PUBLISH_KEY,
				management_url:		process.env.VUE_APP_MANAGEMENT_URL,
				headers_invoices: 	[
					{ text: 'Data', value: 'date', sortable: true },
					{ text: 'Total', value: 'total' },
				],
				invoices_sortedBy:           {
                    column:         "date",
                    direction:      "DESC",
                    icon:           "fa-chevron-down"
                },
				headers_card: 	[
					{ text: '', value: '' },
					{ text: 'Bandeira', value: 'brand' },
					{ text: 'Final', value: 'last4' },
					{ text: 'Vencimento', value: 'exp_date' },
				],
				all_cards:			[],
				invoices:			[],
				cards_modal:		[],
				form_start:			false,
				form_ready:			false,
				invoices_loaded:	false,
				card:			{
					name:		''
				},
				payment_element_id:	'payment-element',
			}
		},
		computed: {
			...mapGetters('auth', [
				'account'
			]),
			...mapGetters('system', [
				'theme'
			]),
			filtered_cards() {
				return this.all_cards.filter(c => c.status)
			},
			has_at_least_one_subscription() {
				return this.subscriptions.length > 0
			},
			has_more_than_one_subscription() {
				return this.subscriptions.length > 1
			},
			professional_subscription() {
				return this.subscriptions.find(s => s.plan.type === 'licensed') || null
			},
			corporate_subscription() {
				return this.subscriptions.find(s => s.plan.type === 'metered') || null
			},
			default_payment_method() {
				return this.cards.find(c => c.default)
			},
		},
		watch: {
			'card.name': {
				handler(val) {
					if(val) {
						if(!isNaN(val)) {
							this.card.name = null
						}
	
						const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]+/gi;
	
						this.card.name = val.replace(regex, '')
					}
				},
				immediate: true
			},
			cards: {
				handler(val) {
					if(val.length > 0) {
						this.cards_modal = [ ...val ]
					}
				},
				immediate: true
			},
			content_loaded: {
				immediate: true,
				handler(val) {
					if(val) {
						if(this.client_secret) {
							if(this.cards.length === 0) {
								this.SetPaymentElement()
							} else {
								this.form_start = true
								this.form_ready = true
								
								this.$nextTick(() => {
									window.jQuery('#professional #finish_subscription').off('click').on('click', this.SubscribeProfessional)
								})
							}
						}

						this.GetInvoices()
					}
				},
			},
		},
		methods: {
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			ParsePeriod(period) {
				let new_period = ''

				switch(period) {
					case 'month': new_period = 'mês'
						break;
					case 'year': new_period = 'ano'
						break;
				}

				return new_period
			},
			ParseInterval(interval) {
				let new_interval = ''

				switch(interval) {
					case 'month': new_interval = 'Mensal'
						break;
					case 'year': new_interval = 'Anual'
						break;
				}

				return new_interval
			},
			ParsePrice(price) {
				let new_price = ''

				if(price) {
					new_price = `${(price / 100).toFixed('2')}`
				}

				return new_price
					.replace(',','@')
					.replace('.',',')
					.replace('@','.') || '0,00'
			},
			ParseDate(date) {
				return moment(date).format('DD/MM/YYYY');
			},
			ParseStatus(status) {
				let new_status

				switch(status) {
					case 'paid': new_status = 'Pago'
						break;
					case 'draft': new_status = 'Provisória'
						break;
					case 'open': new_status = 'Aguardando pagamento'
						break;
					default: new_status = 'Anulada'
						break;
				}

				return new_status
			},
			GetBrand(brand)
			{
				const item = this.all_cards.find((e) => e.name === brand.toLowerCase())

				if(item) {
					let finalBrand = require(`@/assets/svg/brands/${item.svg}.svg`)

					return finalBrand
				}

				return false
			},
			GetInvoices() {
				const vm = this;

				vm.invoices_loaded = false;

				return window.api.call('get', '/gateway/get-all-invoices')
					.then(({data}) => {
						if(data.response) {
							vm.invoices = data.invoices.map((i) => {
								i.date = moment(i.date);

								return i;
							})
						}
					})
					.finally(() => {
						vm.invoices_loaded = true;
					})
			},
			async SetDefaultPayment(card) {
				const vm = this

				if(card.default) {
					this.CloseModal('change-default-payment')

					return
				}

				vm.StartLoading()

				return await window.api.call('post', '/gateway/set-default-payment', {
					...card,
					account_id: vm.account.id
				})
					.then(({data}) => {
						if(data.response) {
							this.$emit('refresh_subscriptions')
							this.$emit('refresh_cards')

							this.CloseModal('change-default-payment')

							return
						}

						Swal.fire({
							icon: 	'error',
							title:  'Ops...',
							text: 	'Houve um erro ao concluir, contate o suporte.'
						})
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			CancelSubscription() {
				const vm = this

				if(this.professional_subscription.status === 'trialing') {
					return
				}

				Swal.fire({
					icon:	'warning',
					title: 	'Atenção!',
					text:	'Realmente deseja cancelar a assinatura? (Essa ação é irreversível!)',
					showCancelButton: true,
					confirmButtonText: 'Sim',
					confirmButtonColor: '#9a3b3b',
					cancelButtonText: 'Não'
				})
					.then(async (result) => {
						if (result.isConfirmed) {
							vm.StartLoading()

							return await window.api.call('post', '/gateway/cancel-subscription', {
								account_id: vm.account.id,
								subscription_id: vm.professional_subscription.id
							})
								.then(({data}) => {
									if(data.response) {
										this.$emit('refresh_subscriptions')

										Swal.fire({
											icon: 		'success',
											title: 		'Sua assinatura foi cancelada com sucesso.',
											toast: 		true,
											position:	'top',
										})

										return
									}

									Swal.fire({
										icon: 	'error',
										title:  'Ops...',
										text: 	'Houve um erro ao concluir, contate o suporte.'
									})
								})
								.finally(async () => {
									vm.FinishLoading()
									
									// vm.$emit('reset_component')
								})
						}
					})
			},
			async SubscribeProfessional() {
				const vm = this

				vm.StartLoading()
				
				return await window.api.call('get', '/gateway/subscribe-professional')
					.then(async ({data}) => {
						if(data.response) {
							Swal.fire({
								toast:  	true,
								title:		'Assinatura realizada com sucesso!',
								icon: 		'success',
								timer: 		5000,
								position: 	'top',
								timerProgressBar: true
							})
							
							vm.$emit('refresh_subscriptions')
						} else {
							Swal.fire({
								icon: 	'error',
								title:  'Ops...',
								text: 	'Houve um erro ao realizar a assinatura, contate o suporte.'
							})
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async SetPaymentElement() {
				const vm = this
				
				if(vm.cards.length || this.professional_subscription) return;
				
				this.card.name = null;

				const StripeClass = StripeFactory(vm);

				const StripeInstance = new StripeClass();

				await StripeInstance.load();

				const stripe = await StripeInstance.getStripe();

				const elements = await StripeInstance.getElements();

				const confirmPayment = async function () {
					if(!vm.card.name) {
						Swal.fire({
							icon: 		'error',
							title: 		'Preencha o nome do titular do cartão.',
							toast: 		true,
							position: 	'top'
						})

						return
					}

					vm.StartLoading()

					await stripe.confirmSetup({
						elements,
						redirect: 'if_required',
						confirmParams: {
							payment_method_data: {
								billing_details: {
									name: vm.card.name.toUpperCase().trim()
								}
							}
						}
					})
						.then((result) => {
							if(result.error) {
								Swal.fire({
									icon: 	'error',
									title:  'Ops...',
									text: 	'Houve um erro ao cadastrar o cartão, contate o suporte.'
								})

								return
							} else {
								Swal.fire({
									toast:  	true,
									title:		'Cartão em processo de validação...	',
									icon: 		'info',
									timer: 		5000,
									position: 	'top',
									timerProgressBar: true
								})
									.then(async () => {
										vm.$emit('refresh_cards')

										if(!vm.professional_subscription) {
											await vm.SubscribeProfessional()
										}
									})
								
								vm.CloseModal('add-payments')
							}
						})
						.finally(() => {
							vm.FinishLoading()
						})
				}

				window.jQuery('#professional #finish_subscription').off('click').on('click', confirmPayment)
			},
			PayPendingInvoice() {
				const vm = this

				if(!vm.cards.length) {
					Swal.fire({
						icon: 				'info',
						title: 				'Eita',
						text: 				'Você precisa cadastrar um cartão para realizar o pagamento.',
						confirmButtonText: 	'Ir para Pagamento',
						showCancelButton: 	true,
						cancelButtonText: 	'Cancelar',
						preConfirm: 		() => {
							vm.RedirectToPaymentMethods()
						}
					});

					return;
				}

				vm.StartLoading()

				return window.api.call('get', '/gateway/pay-pending-invoice', {
					account_id: vm.account.id
				})
					.then(async ({data}) => {
						if(data.response) {
							vm.$emit('refresh_subscriptions')
							vm.$emit('refresh_cards')

							Swal.fire({
								toast:  	true,
								title:		'Pagamento realizado com sucesso!',
								icon: 		'success',
								timer: 		5000,
								position: 	'top',
								timerProgressBar: true
							})
						} else {
							Swal.fire({
								icon: 		'error',
								title: 		'Ops...',
								text:  		'Houve um erro ao realizar o pagamento, contate o suporte.',
							})
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async OpenModal(id) {
				window.jQuery(`#modal-${id}`).modal('show')
			},
		  	CloseModal(id) {
		  		window.jQuery(`#modal-${id}`).modal('hide')
		  	},
			RedirectToPaymentMethods() {
				this.CloseModal('change-default-payment')

				this.$emit('redirect_after', true)

				window.jQuery('.nav-link[href="#payments"]').click()

				window.jQuery('#btn-add-payment').click()
			},
		},
		async beforeMount() {
			this.all_cards      = window.creditCards
		},
		mounted() {
		}
	}
</script>

<style lang="css" scoped>
a:not(.nav-link), .link:not(.nav-link) {
	color: var(--primary);
	cursor: pointer;
}
.p-3_5 {
	padding: 1.3rem !important;
}

.credit-card-brand img {
	width: 40px;
}

#reactivate-subscription button {
	color: #fff;
	font-weight: bold;
	border-radius: 0;
	background-color: #146214;
}

#reactivate-subscription button:hover {
	background-color: #0d450d;
}

.cancel-link {
	cursor: pointer;
}

.cancel-link:hover {
	color: #ae2431 !important;
}

.cancel-link.disabled {
	color: #741c24 !important;
	cursor: not-allowed;
}

.cancel-link.disabled:hover {
	color: #741c24 !important;
}

#modal-change-default-payment .modal-dialog
{
	max-width: 800px !important;
}

.dropdown-menu.payment-options {
	min-width: 185px;
	border-radius: 10px;
	overflow: hidden;
	padding: 0 !important;
}

.dropdown-menu.payment-options label:hover {
	background-color: var(--gray-light);
    border-color: var(--gray-light);
	color: #fff !important;
	cursor:	pointer;
}

.dropdown-menu.payment-options label.disabled {
	color: var(--color-font) !important;
}

.dropdown-menu.payment-options label.disabled:hover {
	background-color: var(--dark);
	cursor:	default;
}

.dropdown-menu.payment-options .delete-payment:hover {
	background-color: #9a3b3b;
	color: #fff !important;
}

.credit-card-brand img {
	width: 40px;
}

.purshase-overview {
	border-left: 1px solid var(--hr);
}

.purshase-overview p {
	font-size: 1rem;
}

.purshase-overview .credit-card-brand img {
	width: 30px;
}
</style>