<template>
	<div class="row justify-content-center align-content-center vh-80 vw-100">
		<b>redirecionando...</b>
		<div 
			class="modal fade" 
			id="select-crm" 
			tabindex="-1" 
			data-backdrop="static" 
			aria-labelledby="select-crm" 
			aria-hidden="true"
		>
			<div class="modal-dialog">
			    <div class="modal-content">
			        <div class="modal-body">
			        	<div class="p-4 p-md-5">
							<div class="mb-4 text-center">
								<h2 class="font-32">Selecione um CRM</h2>
							</div>
							<form class="row justify-content-between">
								<div class="col-12">
									<div 
										v-for="(crm, index) in crms"
										:key="index"
										class="select-card with-border mt-2"
									>
										<label 
											for="card-checkbox" 
											class="pr-55 text-uppercase text-overflow"
											@click="Select(crm)"
										>
											CRM-{{crm.uf}} {{ crm.crm }}
											<i class="far fa-chevron-right"></i>
										</label>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vuex from './../../vuex'
	import _ from 'lodash'
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Redirect',

		data () {
			return {
				selected: 	{
					crm: 		null,
					route: 		null
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'crms', 'requester_selected_id', 'data_migrating_organizations'
			])
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			Select(crm)
			{
				this.selected.crm 	= crm

				window.jQuery('#select-crm').modal('hide')

				this.Redirect()
			},
			HasPendingTemplateUpdate(organization_id)
			{
				let requesters 	= new Vuex().getters['auth/requesters']
 
				let requester 	= _.filter(requesters, (requester) => {
					if ( requester.account_requester_id == organization_id ) { 
						return requester
					}
				})[0] ?? null;

				if ( requester && requester.pending_template_update) return requester.pending_template_update;

				return null;
			},
			async Redirect()
			{

				let update_template = false;
				let pending_template_update = this.HasPendingTemplateUpdate(this.requester_selected_id) 
				
				let provider = new Vuex().getters['auth/account'];

				if (pending_template_update) {
	
					await Swal.fire({
						icon:	'warning',
						title: 	'Aviso!',
						html:	`O template padrão da sua organização foi atualizado por <b>${pending_template_update.template_manager.account_responsible.name}</b> e você precisa atualizar o seu! Não será possível laudar até que você atualize-o.`,
						showCancelButton: true,
						confirmButtonText: 'Atualizar agora',
						cancelButtonText: 'Agora não'
					}).then(async (result) => {

						if (result.isConfirmed) { 

							this.StartLoading()

							await window.api.call('post', '/check-pending-laudos', {
								account_provider_id: 	provider.id,
								account_requester_id: 	this.requester_selected_id
							})
							.then(async ({data}) => {
								if(data.response)
								{

									if ( data.laudos_pendentes > 0 ) {

										return Swal.fire({
											icon: 				'error',
											title: 				'Laudos em aberto!',
											html: 				`Você possui <b>${data.laudos_pendentes} laudos</b> em aberto. Finalize todos para concluír a ação`,
											showConfirmButton: 	true,
										})

									}
									
									update_template = true

								}else{

									Swal.fire({
										icon: 				'error',
										title: 				'Eita!',
										text: 				'Houve um erro ao concluir, contate o suporte.',
										showConfirmButton: 	true,
										preConfirm: () => {
											this.$router.push({ name: 'Home' })
										}
									})

								}
							})
							.finally(() => {
								this.FinishLoading()
							})

						}
					})
				}

				if ( update_template ) {
					
					
					this.StartLoading()

					let destiny_vuex 	= new Vuex().getters['auth/requesters'];
					let wk 		 		= _.filter(destiny_vuex, (requester) => { 
						if ( requester.account_requester_id == this.requester_selected_id ) { 
							return requester
						}
					})[0] ?? null;

					const obj 	= {
						destiny: 	{
							account_id: 		wk.account_requester_id,
							schema: 			wk.workgroup_schema,
							provider_id: 		provider.id,
							type: 				'WK',
							only_restore: 		true
						},
						options: 	{
							template: 					true,
							schedules_and_proceedings: 	false,
							configurations: 			false,
							flow: 						false
						},
						source: 	{
							account_id: 		wk.account_requester_id,
							schema: 			wk.instance.config.schema_name,
							type: 				'PJ',
						},
						account_responsible_id: provider.id,
						organization_id: 		this.requester_selected_id,
					}

					await window.api.call('post', '/restore-from-default-template', obj)
						.then(({data}) => {
							if(data.response)
							{
								Swal.fire({
									icon: 		'success',
									title: 		'Procedimento Concluido !',
									text: 		'Template restaurado com sucesso.', 
								})

							}else{

								Swal.fire({
									icon: 		'error',
									text: 		'Houve um erro ao concluir, contate o suporte.',
									title: 		'Eita!'
								})
							}
						})
						.finally(() => {
							this.FinishLoading()
						})

				}

				const data_migrating_organizations = [ ...this.data_migrating_organizations ];

				if(data_migrating_organizations.length > 0 && data_migrating_organizations.indexOf(parseInt(this.requester_selected_id)) > -1) {
					Swal.fire({
						icon: 				'warning',
						title: 				'Aviso!',
						text:				'No momento está sendo feita uma atualização nos dados da sua Organização, aguarde alguns minutos e tente novamente...'
					});

					this.$router.push({ name: 'Home' });

					await this.GetAuthAccount();

					return;
				}

				if(this.crms.length === 0)
				{
					Swal.fire({
						icon: 				'error',
						title: 				'Eita!',
						text: 				'Você precisa cadastrar um CRM para continuar.',
						showConfirmButton: 	true,
						preConfirm: () => {
							this.$router.push({ name: 'Stamps' })
						}
					})

					return
				}

				this.StartLoading()

				const APP_URL 			= process.env.VUE_APP_APP_URL

				return window.api.call('post', '/redirect-to-app',{
						...this.selected,
						account_id: 	this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response) {
							window.location.href 	= `${APP_URL}/auth/login/${data.bridge_key}`
						} else {
							Swal.fire({
								icon: 				'error',
								title: 				'Eita!',
								text: 				'Houve um erro ao concluir, contate o suporte.',
								showConfirmButton: 	true,
								preConfirm: () => {
									this.$router.push({ name: 'Home' })
								}
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			}
		},
		mounted()
		{
			if(!this.requester_selected_id)
			{
				this.$route.push({ name: 'Home' })
			}

			const meta 						= this.$route.meta

			this.selected.route 	 		= meta.appRouteName
			
			if(this.crms.length > 1)
			{
				window.jQuery('#select-crm').modal('show')

			}else{

				this.selected.crm 	= this.crms[0]

				this.Redirect()
			}
		}
	}
</script>

<style lang="css" scoped>
</style>