<template>
	<div class="container-large pb-4">
		<transition name="fade" mode="out-in">
			<div 
				v-if="step == 'pre'" 
				:key="step"
			></div>
			<div 
				v-if="step == 'basic-data'" 
				:key="step"
				class="pb-3"
			>
				<BasicData
					@previous="Previous"
					:form="schedule"
					@next="WalkStep"
				/>
			</div>
			<div 
				v-if="step == 'time-range'"
				:key="step"
				class="pb-3"
			>
				<TimeRange
					@previous="step = 'basic-data'"
					:is_started="false"
					:time_obj="{
						total_time: schedule.total_time,
						time_init: schedule.time_init,
						time_end: schedule.time_end,
						max_attendances: schedule.max_attendances
					}"
					:is_vacancy="is_vacancy"
					:attendances_obj="{
						attendances: schedule.attendances,
						max_attendances: schedule.max_attendances
					}"
					:bypass_validation="false"
					@total-time="schedule.total_time = $event"
					@time-init="schedule.time_init = $event"
					@time-end="schedule.time_end = $event"
					@max-attendances="schedule.max_attendances = $event"
					@next="WalkStep"
				/>
			</div>
			<div 
				v-if="step == 'block-config'"
				:key="step"
				class="pb-3"
			>
				<BlockConfig
					ref="block_config"
					:is_started="block_config_is_started"
					:time_obj="{
						total_time: 	schedule.total_time,
						time_init: 		schedule.time_init,
						time_end: 		schedule.time_end
					}"
					:props_blocks="schedule.blocks"
					:map="schedule.block_map"		
					:is_vacancy="is_vacancy"
					@mounted="block_config_is_started = false"
					@pause-time="PauseTimeChangeHandler"
					@previous="step = 'time-range'"
					@next="WalkStep"
				/>
			</div>
			<div 
				v-if="step == 'periodicity-and-dates'"
				:key="step"
				class="pb-3"
			>
				<PeriodicityAndDates
					:is_started="false"
					:continuous_schedule="schedule.continuous_schedule"
					@previous="step = schedule.continuous_schedule ? 'time-range' : 'block-config'"
					@next="WalkStep"
				/>
			</div>
		</transition>
	</div>
</template>

<script>
	import BasicData from './components/BasicData'
	import TimeRange from './components/TimeRange'
	import BlockConfig from './components/BlockConfig'
	import PeriodicityAndDates from './components/PeriodicityAndDates'
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'ScheduleWizard',
		components: 	{
			BasicData,
			TimeRange,
			BlockConfig,
			PeriodicityAndDates
		},
		props: 	{
			modality_id: 	{
				type: 			[Number, String],
				default: 		null,
				required: 		true
			}
		},
		data () {
			return {
				step: 		'pre',
				is_vacancy: false,
				schedule: 	{
					name: 					null,
					tolerance: 				0,
					time_attendance: 		0,
					panel_text: 			null,
					auto_scheduling: 		false,
					continuous_schedule: 	false,
					time_init: 				null,
					time_end: 				null,
					total_time: 			null,
					attendances:			0,
					max_attendances:		0,
					blocks: 				[],
					block_map: 				[],
					cyclic_schedule: 		true,
					temporary_schedule: 	true,
					date_init: 				new Date().toISOString().substr(0, 10),
					date_end: 				new Date().toISOString().substr(0, 10),
					dates: 					[],
					week: 					[]
				},
				block_config_is_started: false
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			...mapGetters('system', [
				'modalities'
			]),
			module_id() {
				const vm = this

				return vm.modalities.find(m => m.id == vm.modality_id).module_id
			}
		},
		watch: {
			step: {
				handler(val)
				{
					let hash 		= ''
					const today 	= new Date().toISOString().substr(0, 10)

					switch(val)
					{
						case 'basic-data':

							hash 								= '#dados-basicos'

							this.schedule.cyclic_schedule 		= true
							this.schedule.temporary_schedule 	= true
							this.schedule.date_init 			= today
							this.schedule.date_end 				= today

						break
						case 'time-range':

							hash 								= '#horario'

							this.schedule.cyclic_schedule 		= true
							this.schedule.temporary_schedule 	= true
							this.schedule.date_init 			= today
							this.schedule.date_end 				= today

						break
						case 'block-config':

							hash 								= '#configuracao-dos-blocos'
							this.schedule.date_init 			= today
							this.schedule.date_end 				= today

							this.block_config_is_started		= true

						break
						case 'periodicity-and-dates':

							hash 								= '#periodicidade-e-datas'

						break
					}

					window.location.hash 	= hash
				},
				immediate: true
			},
			$route(to)
			{
				switch(to.hash)
				{
					case '#dados-basicos':
						this.step = 'basic-data'
					break
					case '#horario':
						this.step = 'time-range'
					break
					case '#configuracao-dos-blocos':
						this.step = 'block-config'
					break
					case '#periodicidade-e-datas':
						this.step = 'periodicity-and-dates'
					break
				}
			}
		},
		methods: 	{
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'GetModulesAndModalities'
			]),
			async WalkStep(param = null)
			{
				switch(this.step)
				{
					case 'pre':

						this.step 					= 'basic-data'

					break
					case 'basic-data':

						this.schedule 				= {
							...this.schedule,
							...param
						}

						if(!this.schedule.continuous_schedule) {
							this.step 					= 'time-range'
						} else {
							this.step					= 'periodicity-and-dates'
						}

					break
					case 'time-range':

						this.schedule.time_init 	= param.time_init
						this.schedule.time_end 		= param.time_end
						this.schedule.total_time 	= param.total_time

						this.schedule.attendances	= param.attendances

						if(this.schedule.continuous_schedule)
						{
							this.step 					= 'periodicity-and-dates'

						}else{

							this.step 					= 'block-config'
						}

					break
					case 'block-config':

						this.schedule 				= {
							...this.schedule,
							...param
						}

						this.step 					= 'periodicity-and-dates'

					break
					case 'periodicity-and-dates':

						this.schedule 				= {
							...this.schedule,
							...param
						}

						await this.SaveSchedule()

					break
				}
			},
			async SaveSchedule() {
				this.StartLoading()

				await this.PrepareMappedBlocksToSave()

				return window.api.call('post', '/save-schedule', {
						...this.schedule,
						account_id: 	this.requester_selected_id,
						modality_id: 	this.modality_id
					})
					.then(({data}) => {
						if(data.response)
						{
							const vm 	= this

							Swal.fire({
									icon: 		'success',
									title: 		'Agenda salva com sucesso.'
								})
								.then(() => {
									vm.$router.push({ name: 'Schedules', params: {
										modality_id: 	vm.modality_id,
										module_id: 		data.schedule.modality.module_id
									}})
								})
															
						}else{
							
							Swal.fire({
								icon: 		'error',
								title: 		'Eita!',
								text: 		'Houve um erro ao concluir, contate o suporte.'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			Previous()
			{
				const vm = this
				
				vm.$router.push({ 
					name: 'Schedules', 
					params: {
						module_id: 		vm.module_id,
						modality_id: 	vm.modality_id
					}
				})	
			},
			Append(param) {
				switch(param.type) {
					case 'map':
						this.schedule.block_map = param.map

						this.$refs.block_config.blocks = []
					break;
				}
			},
			async GetIsVacancyModality() {
				this.StartLoading()

				return window.api.call('post', '/get-is-vacancy-modality', {
					organization_id: this.requester_selected_id,
					modality_id: this.modality_id
				})
					.then(({data, status}) => {
						if(status == 200) {
							this.is_vacancy = data.is_vacancy
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			async GetVacancysFromBlockObjects() {
				let attendances = this.schedule.attendances || 0

				const blocks = this.schedule.block_map

				let j = 0;

				let counter = 0

				while(attendances > 0) {
					if(counter > 1000) {
						// console.log('quebrou o looping infinito')
						// break;
					}
					
					counter++
					
					if(blocks[j].vacancys < blocks[j].limit) {
						blocks[j].vacancys++;
						attendances--;
					}

					j++;

					if(j == blocks.length) {
						j = 0;
					}
				}

				const MINIMAL_BLOCK_SIZE = 5

				this.schedule.block_map = blocks.map(block => {
					block.duration_for_vacancys = block.vacancys * MINIMAL_BLOCK_SIZE

					return block
				})
			},
			async PrepareMappedBlocksToSave() {
				this.schedule.block_map = this.schedule.block_map
					.map(block => {
						const MINIMAL_BLOCK_SIZE = 5

						const hours = block.first_obj.duration.split(':')[0] * 60
						const minutes = block.first_obj.duration.split(':')[1]

						return {
							...block,
							vacancys: 0,
							limit: (parseInt(hours) + parseInt(minutes) + 5) / MINIMAL_BLOCK_SIZE
						}
					})

				await this.GetVacancysFromBlockObjects()
			},
			PauseTimeChangeHandler(param) {
				this.schedule.max_attendances = param.max_attendances
				this.schedule.blocks = param.blocks
				this.schedule.block_map = param.block_map

				if(this.is_vacancy && this.schedule.attendances > param.max_attendances) {
					this.step = 'time-range'

					Swal.fire({
						icon: 'error',
						title: 'Ops...',
						html: `<p>O tempo de agendamento dos blocos é menor do que o tempo para executar <strong>${this.schedule.attendances}</strong> atendimentos! Corrigiremos para o máximo permitido.</p>`
					})

					this.schedule.attendances = param.max_attendances
				}
			}
		},
		async beforeMount()
		{
			await this.GetModulesAndModalities()

			await this.GetIsVacancyModality()

			this.WalkStep()
		}
	}
</script>

<style lang="css" scoped>
</style>