<template>
	<div>
		<header 
			class=" ps-t-0 ps-l-0 ps-r-0"
			:class="{
        		'px-4 py-3': 	!carousel,
        		'w-100 p-4': 	carousel
        	}"
		>
   			<div class="col-12 px-0 d-flex justify-content-sm-between align-items-center">
        		<div class="order-1 align-self-start">
					<router-link
						:to="{ name: 'Home' }"
					>
						<img :src="logo_url" width="150">
					</router-link>
				</div>

		        <div class="order-2 col-8 px-0 mt-2">
		            <div class="d-flex flex-wrap justify-content-end align-items-center pr-md-3">
		                <div class="order-1 order-sm-3 col-10 col-sm-auto px-2 mb-3 d-flex justify-content-center" v-if="requesters.length > 1 && requester_selected_object">
		                    <button 
		                    	type="button" 
		                    	class="btn btn-block max-250 py-3"
		                    	:class="{
				            		'btn-light': 	!carousel,
				            		'btn-white': 	carousel
				            	}" 
		                    	@click="OpenModal('select-account')"
		                    >
		                        <div class="d-flex align-items-center justify-content-center px-3">
									<i class="far fa-hospital-user mr-3"></i>
									<div class="text-left w-100 max-250 mt--2">
		                                <span class="font-12 text-muted font-normal">
		                                    conta selecionada:
		                                </span>
		                                <h4 class="font-16 m-0 text-uppercase overflow-text">
		                                    {{ requester_selected_object.requester.name }}
		                                </h4>
		                            </div>
								</div>
							</button>
						</div>

						<div class="order-4 order-sm-2 col-md-auto px-2 mb-3 col-3">

							<router-link 
								class="btn btn-block px-3"
								:class="{
									'btn-light': 	!carousel,
									'btn-white': 	carousel
								}"
								:to="{ name: 'Home' }"
							>
								<i class="far fa-home mx-2"></i>
							</router-link>
						</div>
						<div 
							class="order-5 col-md-auto px-2 mb-3"
							:class="{
								'col-3': 	!carousel,
								'col-2': 	carousel
							}"
						>
							<router-link  
								class="btn btn-block px-3"
								:class="{
									'btn-light': 	!carousel,
									'btn-white': 	carousel
								}"
								:to="{ name: 'Profile' }"
							>
								<i class="far fa-user mx-2"></i>
							</router-link>
						</div>
						<div 
							class="order-7 col-md-auto px-2 mb-3"
							:class="{
								'col-3': 	!carousel,
								'col-2': 	carousel
							}"
						>
							<button 
								type="button" 
								class="btn btn-block px-3"
								:class="{
									'btn-light': 	!carousel,
									'btn-white': 	carousel
								}"
								@click="SendToWhatsapp"
							>
								<i class="fab fa-whatsapp mx-2"></i>
							</button>
						</div>
						<div 
							class="order-7 col-md-auto px-2 mb-3"
							:class="{
								'col-3': 	!carousel,
								'col-2': 	carousel
							}"
						>
							<button 
								type="button" 
								class="btn btn-block px-3"
								:class="{
									'btn-light': 	!carousel,
									'btn-white': 	carousel
								}"
								@click="Logout"
							>
								<i class="far fa-sign-out mx-2"></i>
							</button>
						</div>
						<div 
							v-if="carousel"
							class="order-8 col-3 col-md-auto px-2 mb-3"
						>
							<div class="pl-3 pl-md-4 border-left ml-md-3">
								<button 
									type="button" 
									class="btn btn-block px-3"
									:class="{
										'btn-light': 	!carousel,
										'btn-white': 	carousel
									}"
									@click="CloseCarousel" 
									
								>
									<i class="far fa-chevron-up mx-2"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<SelectAccount 
			:open="modal.select_account"
			@close="modal.select_account = false"
			@change-account="CheckOrganizationStatus"
		/>
	</div>

</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import SelectAccount from './modals/SelectAccount'

	const _ = require('lodash')
	export default {

		name: 		'Navbar',
		components: 	{
			SelectAccount
		},
		props: 			{
			carousel: 		{
				type: 			Boolean,
				default: 		false
			}
		},
		data () {
			return {
				logo_url: 		window.logo_url,
				modal: 		{
					select_account: 	false,
					carousel: 			false
				},
				online_tooltip:			''
			}
		},
		computed: {
			...mapGetters('auth', [
				'requesters', 'requester_selected_object', 'online_accounts', 'recurring_object', 'requester_selected_id', 'is_registered'
			]),
			onlines()
			{
				return this.online_accounts.length > this.recurring_object.recurring_accesses ? this.recurring_object.recurring_accesses : this.online_accounts.length
			},
		},
		watch: {
			'requester_selected_object': {
				handler(val)
				{
					if(this.requesters.length > 1 && !val)
					{
						this.OpenModal('select-account')
					}
				},
				immediate: true
			}
		},
		methods: {
			...mapActions('auth', [
				'Logout', 'GetSubscriptionInfo'
			]),
			...mapActions('system', [
				'ToggleTheme'
			]),
			SendToWhatsapp() {
				window.open('https://api.whatsapp.com/send?phone=5517996568467')
			},
			OpenModal(modal)
			{
				this.modal.select_account 	= false
				this.modal.profile 			= false

				switch(modal)
				{
					case 'select-account':
						this.modal.select_account 	= true
					break
					case 'profile':
						this.modal.profile 			= true
					break
					case 'carousel':
						this.modal.carousel 		= true
					break
				}
			},
			CloseCarousel()
			{
				this.$emit('close-carousel')
			},
			CheckOrganizationStatus() {
				const requester = this.requester_selected_object

				if(requester) {
					const pf = requester.account_provider_id === requester.account_requester_id

					if(!requester.confirmed && !pf) {
						this.RedirectToOrganizations()

						return
					}

					this.$router.push({ name: 'Home' })
				}
			},
			RedirectToOrganizations() {
				this.$router.push({ name: 'Organizations' })
			},
			ParseNameToTooltip(name)
			{
				let names = name.toLowerCase().split(' ')

				names = names.map((n, i) => {
					if(n == 'Jr.') return 'Júnior'
					if(i == 0) return n.charAt(0).toUpperCase() + n.slice(1)
					if(n.length == 2) return n
					if(i == (names.length - 1)) return n.charAt(0).toUpperCase() + n.slice(1)

					return n.charAt(0).toUpperCase() + '.'
				})

				return names.join(' ')
			},
			OnlineButtonEffect(effect)
			{
				const vm = this
				switch(effect)
				{
					case 'out':
						if(vm.online_tooltip != 'fixed')
						{
							// vm.ColorIcon(false)
							vm.online_tooltip = ''
						}
						break
					case 'in':
						if(vm.online_tooltip != 'fixed')
						{
							// vm.ColorIcon(true)
							vm.online_tooltip = 'hover'
						}
						break
					case 'click':
						if(vm.online_tooltip != 'fixed')
						{
							vm.online_tooltip = 'fixed'
							break
						}
						vm.online_tooltip = ''
						
						break
					default:
						break
				}
			},
			ColorIcon(state)
			{
				if(state)
				{
					document.querySelector('.online-icon').classList.remove('text-primary')
				} else {
					document.querySelector('.online-icon').classList.add('text-primary')
				}
			}
		},
		mounted() {
			if(this.requester_selected_id && this.is_registered)
			{
				// this.GetSubscriptionInfo(this.requester_selected_id)
			}
		}
	}
</script>

<style lang="css" scoped>
.status-indicator
{
	margin: 0 0 0 25px;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	align-self: center;
}

.status-indicator::before
{
	content: '';
	border: 1px solid #8e8e8e;
	background-color: #8e8e8e;
	position: absolute;
	top: 6px;
	left: 10px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
}

.status-indicator.online::before
{
	border-color: #20c964;
	background-color: #20c964;
	box-shadow: 0px 0px 7px 2px #20c964;
}

</style>