<template>
	<div class="col-12 px-0 d-flex flex-column align-items-center">
		<div class="col-12">
			<div class="col-12 d-flex justify-content-center align-items-center" :class="{ 'mb-5': cards.length === 0 }">
				<p class="m-0">Você possui <b>{{ cards.length }} método(s) de pagamento</b> cadastrado(s).</p>
			</div>
			<div v-if="cards.length" class="col-12 mt-3" id="list">
				<DataTables
					:headers="headers"
					v-model="cards_2"
					:height="'clamp(162px, '+ (list_height < 162 ? 162 : list_height) +'px, '+(list_height + 10)+'px)'"
					:showTotal="false"
				>
					<template v-slot:body="{ item }">
						<td scope="col">
							<span v-if="item.default" class="badge badge-primary">Padrão</span>
						</td>
						<td scope="col">
							<div v-if="GetBrand(item.brand)" class="credit-card-brand">
								<img :src="GetBrand(item.brand)">
							</div>
							<div v-else>{{ item.brand }}</div>
						</td>
						<td scope="col">**{{ item.last4 }}</td>
						<td scope="col">{{ item.exp_date }}</td>
						<td scope="col" style="width: 200px;">
							<div class="dropdown mb-2 mb-md-0">
								<button class="btn btn-light font-14 px-3 py-2" data-toggle="dropdown" data-display="static">
									...
								</button>
								<div class="dropdown-menu px-3 py-0 payment-options">
									<label
										class="col-12 mb-0 py-1"
										:class="{
											'disabled': item.default
										}"
										v-tippy="{ arrow: true, trigger: item.default ? 'mouseenter focus' : '' }"
										content="Já é o padrão."
										@click="SetDefaultPayment(item)"
									>
										Definir como padrão
									</label>
									<label
										class="delete-payment col-12 mb-0 py-1"
										:class="{
											'disabled': item.default && has_professional_subscription
										}"
										v-tippy="{ arrow: true, trigger: item.default && has_professional_subscription ? 'mouseenter focus' : '' }"
										content="Não é possível excluir o padrão!"
										@click="DeletePayment(item)"
									>
										Excluir
									</label>
								</div>
							</div>
						</td>
					</template>
				</DataTables>
			</div>
			<div class="col-12 d-flex justify-content-center" :class="{ 'pt-3': cards.length === 0 }">
				<button
					id="btn-add-payment"
					class="btn btn-primary"
					@click="OpenModal('add-payments')"
				>
					Adicionar
				</button>
			</div>
			<div
				class="modal fade" 
				id="modal-add-payments"
			>
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header px-5 py-4 mb-3">
							<h4 class="font-20 m-0 text-center">
								Adicionar cartão de crédito
							</h4>
						</div>
						<div class="modal-body">
							<div v-if="!form_start" class="col-12 px-0 d-flex align-items-center justify-content-center">
								<div class="spinner-border text-theme" role="status">
								</div>
							</div>
							<div v-else class="col-12 px-0">
								<div id="card-container" class="col-12">
									<div class="cardholders-name-container" v-if="form_ready">
										<label for="" class="Label">
											Nome
										</label>
										<input 
											type="text"
											class="text-uppercase"
											placeholder="NOME NO CARTÃO"
											v-model="card.name"
											:maxlength="24"
										>
									</div>
									<div ref="payment_element" id="payment-element-modal"></div>
								</div>
							</div>
							<div class="col-12 d-flex justify-content-end py-4">
								<button
									type="button" 
									class="btn btn-light p-3 px-4" 
									@click="CloseModal('add-payments')" 
								>	
									Cancelar
								</button>
								<button
									id="finish"
									type="button"
									class="btn btn-primary p-3 px-4 ml-4"
								>	
									Finalizar
								</button>
							</div>					  
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2';
	import { mapGetters, mapActions } from 'vuex';
	import DataTables from './../../shared/DataTables';
	import { StripeElementCard } from '@vue-stripe/vue-stripe';
	import _ from 'lodash';
	import StripeFactory from './../../../utils/payments/Stripe.js';
	import { subscription_info } from '../../../store/auth/getters';

	export default {
		name: 'Payments',
		components: {
			DataTables,
			StripeElementCard
		},
		props: {
			content_loaded: {
				type: 			Boolean,
				default: 		false
			},
			subscriptions:	{
				type:			Array,
				default:		() => []
			},
			client_secret:	{
				type:			String,
				default:		''
			},
			cards:			{
				type:			Array,
				default:		() => []
			},
			redirect_after:	{
				type:			Boolean,
				default:		false
			},
			open_modal:		{
				type:			Boolean,
				default:		false
			},
		},
		data () {
			return {
				publishable_key: 	process.env.VUE_APP_STRIPE_PUBLISH_KEY,
				management_url:		process.env.VUE_APP_MANAGEMENT_URL,
				headers: 	[
					{ text: '', value: '' },
					{ text: 'Bandeira', value: 'brand' },
					{ text: 'Final', value: 'last4' },
					{ text: 'Vencimento', value: 'exp_date' },
				],
				all_cards:		[],
				form_start:		false,
				form_ready:		false,
				card:			{
					name:		''
				},
				payment_element_id:	'payment-element-modal',
			}
		},
		computed: {
			...mapGetters('auth', [
				'account'
			]),
			...mapGetters('system', [
				'theme'
			]),
			filtered_cards() {
				return this.all_cards.filter(c => c.status)
			},
			cards_2() {
				return this.cards
			},
			list_height() {
				return this.cards.length * 125
			},
			has_at_least_one_subscription() {
				return this.subscriptions.length > 0
			},
			has_professional_subscription() {
				return this.subscriptions.some(s => s.plan.type === 'licensed')
			},
			professional_subscription() {
				return this.subscriptions.find(s => s.plan.type === 'licensed') || null
			},
		},
		watch: {
			'card.name': {
				handler(val) {
					if(val) {
						if(!isNaN(val)) {
							this.card.name = null
						}
	
						const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]+/gi;
	
						this.card.name = val.replace(regex, '')
					}
				},
				immediate: true
			},
			cards: {
				handler(val) {

				},
				immediate: true
			},
			content_loaded: {
				handler(val) {
					if(val) {
						if(this.open_modal) {
							this.OpenModal('add-payments')
						}
					}
				},
				immediate: true
			},
		},
		methods: {
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetBrand(brand)
			{
				const item = this.all_cards.find((e) => e.name === brand.toLowerCase())

				if(item) {
					const finalBrand = require(`@/assets/svg/brands/${item.svg}.svg`)

					return finalBrand
				}

				return false
			},
			SetDefaultPayment(card) {
				if(card.default) return

				if(card.is_or_expiring) {
					Swal.fire({
						icon: 		'error',
						title:		'Atenção!',
						text:		'Você não pode definir um cartão expirado/expirando como padrão.'
					})

					return
				}

				const vm = this

				vm.StartLoading()

				return window.api.call('post', '/gateway/set-default-payment', {
					...card,
					account_id: vm.account.id
				})
					.then(async ({data}) => {
						if(data.response) {
							vm.$emit('refresh_cards')

							return
						}

						Swal.fire({
							icon: 	'error',
							title:  'Ops...',
							text: 	'Houve um erro ao concluir, contate o suporte.'
						})
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			DeletePayment(card) {
				const vm = this

				if(card.default && vm.has_professional_subscription) {
					Swal.fire({
						icon: 		'error',
						title:		'Atenção!',
						html:  		'Garanta que você não tenha nenhuma assinatura ativa antes de excluir o cartão padrão.',
					})

					return
				}

				Swal.fire({
					icon:	'warning',
					title: 	'Atenção!',
					text:	'Realmente deseja excluir o método de pagamento?',
					showCancelButton: true,
					confirmButtonText: 'Sim, excluir',
					confirmButtonColor: '#9a3b3b'
				}).then(async (result) => {
					if (result.isConfirmed) {
						vm.StartLoading()

						return window.api.call('post', '/gateway/delete-card', {
							...card,
							account_id: vm.account.id
						})
							.then(async ({data}) => {
								if(data.response) {
									vm.$emit('refresh_cards')

									return
								}

								Swal.fire({
									icon: 	'error',
									title:  'Ops...',
									text: 	'Houve um erro ao concluir, contate o suporte.'
								})
							})
							.finally(() => {
								vm.FinishLoading()
							})
					}
				})
			},
			async Subscribe() {
				const vm = this
				
				return await window.api.call('get', '/gateway/subscribe-professional')
					.then(async ({data}) => {
						if(data.response) {
							Swal.fire({
								toast:  	true,
								title:		'Assinatura realizada com sucesso!',
								icon: 		'success',
								timer: 		5000,
								position: 	'top',
								timerProgressBar: true
							})
							
							vm.$emit('refresh_subscriptions')
							vm.$emit('refresh_cards')
						} else {
							Swal.fire({
								icon: 	'error',
								title:  'Ops...',
								text: 	'Houve um erro ao realizar a assinatura, contate o suporte.'
							})
						}
					})
			},
			async SetPaymentElement() {
				const vm = this;

				const StripeClass = StripeFactory(vm);

				const StripeInstance = new StripeClass();

				await StripeInstance.load();

				const stripe = await StripeInstance.getStripe();

				const elements = await StripeInstance.getElements();

				const confirmPayment = async function () {
					if(!vm.card.name) {
						Swal.fire({
							icon: 		'error',
							title: 		'Preencha o nome do titular do cartão.',
							toast: 		true,
							position: 	'top'
						})

						return
					}

					vm.StartLoading()
					
					await stripe.confirmSetup({
						elements,
						redirect: 'if_required',
						confirmParams: {
							payment_method_data: {
								billing_details: {
									name: vm.card.name.toUpperCase().trim()
								}
							}
						}
					})
						.then((result) => {
							if(result.error) {
								Swal.fire({
									icon: 	'error',
									title:  'Ops...',
									text: 	'Houve um erro ao cadastrar o cartão, contate o suporte.'
								})

								return
							} else {
								Swal.fire({
									toast:  	true,
									title:		'Cartão em processo de validação...	',
									icon: 		'info',
									timer: 		5000,
									position: 	'top',
									timerProgressBar: true,
								})
									.then(async () => {
										vm.$emit('refresh_cards')
									})
								
								vm.CloseModal('add-payments')

								if(vm.redirect_after) {
									vm.RedirectToSubscriptionsPayments()
								}
							}
						})
						.finally(() => {
							vm.FinishLoading()
						})
				}

				window.jQuery('#payments #finish').on('click', confirmPayment)
			},
			async OpenModal(id) {
				window.jQuery(`#modal-${id}`).modal('show')
				this.form_start = true

				if(id == 'add-payments') {
					if(this.client_secret) {
						this.SetPaymentElement()
					}
				}
			},
			RedirectToSubscriptionsPayments() {
				this.$emit('refresh_cards')

				window.jQuery('.nav-link[href="#subscriptions"]').click()

				window.jQuery('#change-default-payment').click()
			},
		  	CloseModal(id) {
		  		window.jQuery(`#modal-${id}`).modal('hide')
		  	},
		},
		async beforeMount() {
			this.all_cards      = window.creditCards
		},
	}
</script>

<style lang="css" scoped>
.dropdown-menu.payment-options {
	min-width: 185px;
	border-radius: 10px;
	overflow: hidden;
	padding: 0 !important;
}

.dropdown-menu.payment-options label:hover {
	background-color: var(--gray-light);
    border-color: var(--gray-light);
	color: #fff !important;
	cursor:	pointer;
}

.dropdown-menu.payment-options label.disabled {
	color: var(--color-font) !important;
}

.dropdown-menu.payment-options label.disabled:hover {
	background-color: var(--dark);
	cursor:	default;
}

.dropdown-menu.payment-options .delete-payment:hover {
	background-color: #9a3b3b;
	color: #fff !important;

}
.dropdown-menu.payment-options .delete-payment.disabled {
	color: var(--color-font) !important;
}

.dropdown-menu.payment-options .delete-payment.disabled:hover {
	background-color: var(--dark);
	cursor:	default;
}

.credit-card-brand img {
	width: 40px;
}

.card-container {
	border-right: 1px solid var(--hr);
}

.purshase-overview p {
	font-size: 1rem;
}

.purshase-overview .credit-card-brand img {
	width: 30px;
}
</style>