<template>
	<div class="container-large pb-4 pt-0">
		<transition name="fade" mode="out-in">
			<div 
				v-if="step === 'pre'" 
				key="pre"
			>
				
			</div>
			<div 
				v-if="step === 'module'" 
				key="module"
				class="pb-3"
			>
				<Modules 
					:label="'Mapa de agendamento'"
					type="strict"
					:module_id="selected.module_id"
					@next="WalkStep"
				/>
			</div>
			<div 
				v-if="step === 'modality'"
				key="modality"
				class="pb-3"
			>
				<Modalities 
					:label="'Mapa de agendamento'"
					type="strict"
					:module_id="selected.module_id"
					:modality_id="selected.modality_id"
					@next="WalkStep"
					@previous="step = 'module'"
				/>
			</div>
			<div 
				v-if="step === 'map'"
				key="map"
				class="container-large pb-4"
			>
				<div class="row justify-content-between align-items-end">
					<div class="col-12">
						<div class="d-block mx-auto pb-4 pb-md-2 text-md-center max-500 pr-6 pr-md-0">
							<span>Mapa</span>
							<h4 class="font-24 font-md-32 m-0">Mapa de Agendamento</h4>
						</div>
					</div>
				</div>
				<Calendar
					:default_vision="selected.vision"
					:events="events.items"
					:rows="schedulings.items"
					@refresh="GetSchedulingsToMap"
					:schedules="schedules_for_calendar"
					:is_vacancy="is_vacancy"
					@vision-or-day-has-changed="VisionOrDayHasChanged"
					@print-map="PrintMap"
					@get-fits="GetFitsFromSchedules"
				>
					<template v-slot:form>
						<div class="dropdown mr-md-2">
							<button class="btn btn-block btn-light" data-toggle="dropdown">
								<i class="far fa-chevron-down font-14 mr-3"></i> agenda
							</button>
							<div class="dropdown-menu dropdown-menu-right px-3 py-0">
								<form>
									<div 
										class="form-check my-3"
										v-for="schedule in filtered_schedules"
										:key="schedule.id"
									>
										<input 
											type="checkbox" 
											:id="`schedule-${schedule.id}`"
											:value="schedule.id"
											v-model="selected.schedules"
										>
										<label 
											:for="`schedule-${schedule.id}`" 
											class="nowrap"
										>
											{{ schedule.nome }}
										</label>
									</div>
								</form>
							</div>
						</div>
					</template>
					<template v-slot:statistics>
						<div class="col-12 d-flex flex-wrap justify-content-between">
							<!-- <div class="d-flex align-items-center justify-content-center">
								<small class="d-flex justify-content-end">Disponível: </small><span class="ml-2 text-dark font-normal">{{ statistic_free_time }}</span>
							</div> -->
							<div class="d-flex align-items-center justify-content-center">
								<small class="d-flex justify-content-end">OCUPAÇÃO: </small>
								<span class="ml-2 "
									:class="GetFullBusyClass(statistic_full_busy)"
								>
									{{ statistic_full_busy.label }}
								</span>
							</div>
							<div class="d-flex align-items-center justify-content-end">
								<small class="d-flex justify-content-end">PROCEDIMENTO: </small>
								<span class="ml-2"
									:class="statistic_proceedings_count ? 'font-weight-bold text-busy': ' font-normal'"
								>
									{{ statistic_proceedings_count }}
								</span>
							</div>
							<div v-if="is_vacancy" class="d-flex align-items-center justify-content-start">
								<small class="d-flex justify-content-end">VAGA: </small>
								<span class="ml-2"
									:class="statistic_busy_vacancys ? 'font-weight-bold text-busy': 'font-normal'"
								>
									{{ statistic_busy_vacancys }}
								</span>
								<span class="ml-2">
									/ {{ statistic_attendances }}
								</span>
							</div>
							<div v-else class="d-flex align-items-center justify-content-start">
								<small class="d-flex justify-content-end">TEMPO: </small>
								<span class="ml-2"
									:class="statistic_busy.value ? 'font-weight-bold text-busy': ' font-normal'"
								>
									{{ statistic_busy.label }}
								</span>
								<span class="ml-2">
									/ {{ statistic_total_time.label }}
								</span>
							</div>
							<div class="d-flex align-items-center justify-content-center">
								<small class="d-flex justify-content-end">BLOQUEIO: </small>
								<span class="ml-2"
									:class="statistic_blocked.value ? 'font-weight-bold text-warning': ' font-normal'"
								>
									{{ statistic_blocked.label }}
								</span>
							</div>
						</div>
					</template>
				</Calendar>

			    <div 
			    	class="modal fade" 
			    	id="modal-fit"
			    >
			        <div class="modal-dialog modal-xl">
			            <div class="modal-content">
			            	<div class="modal-header px-5 py-4">
					    		<h4 class="font-20 m-0">Encaixes</h4>
					    	</div>
				            <div class="modal-body">
				                <div class="row p-4">
				                	<div class="col-12">
				                		<DataTables
											:headers="fit.headers"
											v-model="fit.items"
										>
											<template v-slot:body="{ item, index }">
												<td scope="col" class="text-muted" valign="middle">
													#{{( index + 1 )}}
												</td>
												<td scope="col" class="text-dark" valign="middle">
													<b>{{ item.schedule_name }}</b>
												</td>
												<td scope="col" class="text-muted" valign="middle">
													{{ item.classificacao_descricao}}
												</td>
												<td scope="col" class="text-muted" valign="middle">
													{{ item.created_at_time_parse }}
												</td>
												<td scope="col" class="text-dark" valign="middle">
													<b>
														{{ item.journey.patient.nome_paciente}}
													</b>
												</td> 
												<td scope="col" class="text-dark" valign="middle">
													<b>
														{{ item.proceeding.nome}}
													</b>
												</td> 
												<td scope="col" class="text-muted" valign="middle">
													{{ item.status}}
												</td>
												<td scope="col" class="text-right" valign="middle">
													<button 
														type="button" 
														class="btn btn-sm btn-danger"
														@click="CancelFit(item)"
													>
														cancelar
													</button>
												</td>
											</template>
										</DataTables>
				                	</div>
				                </div>
				            </div>
				        </div>
				    </div>
				</div>

			</div>
		</transition>
	</div>
</template>

<script>

	const _ 		= require('lodash')

	import Modules from './components/Modules'
	import Modalities from './components/Modalities'
	import Calendar from './components/Calendar'

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Map',
		components: {
			Modules,
			Modalities,
			Calendar
		},
		data () {
			return {
				step: 			'pre',
				statistics: 	{
					total: 			0,
					free:			0,
					busy: 			0,
					proceedings: 	0
				},
				schedulings: 	{
					items: 			[],
					schedules: 		[],
					statistics: 	null
				},
				events: 		{
					items: 			null,
					schedules: 		[],
					statistics: 	null
				},
				schedules: 		[],
				selected: 		{
					vision: 			'month',
					module_id: 			null,
					modality_id: 		null,
					schedules: 			[],
					day: 				null,
					days_of_month:		31,
					filter: 	'all',
				},
				fit: 			{
					items: 			[],
					headers: 		[
						{ text: 'Posição', sort: false },
						{ text: 'Agenda', value: 'schedule_name' },
						{ text: 'Prioridade', value: 'classificacao_descricao' },
						{ text: 'Hora', value: 'created_at_time_parsed' },
						{ text: 'Nome', value: 'journey.patient.nome_paciente' },
						{ text: 'Procedimento', value: 'proceeding.nome' },
						{ text: 'Status', value: 'status' },
						{ 
							text: 'Ações',
							sort: false,
							align: 'center'
						}
					]
				},
				is_vacancy: false
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			schedules_for_calendar()
			{
				return this.selected.schedules.length > 0 ? this.selected.schedules : _.map(this.filtered_schedules, 'id')
			},
			statistic_total_time() {
				const obj = {
					value: 0,
					label: '00:00 [0%]'
				}
							
				let isset = false

				let total_time_label = ''

				switch(this.selected.vision) {
					case 'day':
						if(this.schedulings.statistics) {
							isset = true

							total_time_label = this.schedulings.statistics.total
						}
					break
					case 'month':
						if(this.events.statistics) {
							isset = true

							total_time_label = this.events.statistics.total
						}
					break
					case 'year':
						if(this.events.statistics) {
							isset = true

							total_time_label = this.events.statistics.total
						}
					break
				}

				if(!isset) return obj

				obj.value = total_time_label

				total_time_label = window.helpers.MinutesToTime(total_time_label) + ' '

				obj.label = `${total_time_label}`

				return obj
			},
			statistic_full_busy() {
				const obj = {
					value: 0,
					percent: 0,
					label: `${this.is_vacancy ? '' : '00:00 '}[0%]`
				}

				let isset = false

				let total = 0
				let busy = 0
				let blocked = 0

				switch(this.selected.vision) {
					case 'day':
						if(this.schedulings.statistics) {
							isset = true

							total = this.is_vacancy ? this.schedulings.statistics.total_for_vacancys : this.schedulings.statistics.total
							busy = this.schedulings.statistics.busy
							blocked = this.is_vacancy ? this.schedulings.statistics.blocked_for_vacancys : this.schedulings.statistics.blocked
						}
					break
					case 'month':
					case 'year':
						if(this.events.statistics) {
							isset = true

							total = this.is_vacancy ? this.events.statistics.total_for_vacancys : this.events.statistics.total
							busy = this.events.statistics.busy
							blocked = this.is_vacancy ? this.events.statistics.blocked_for_vacancys : this.events.statistics.blocked
						}
					break
				}

				if(!isset) return obj

				obj.value = busy + blocked
				obj.percent = ((obj.value * 100) / total).toFixed(2)

				obj.label = `[${!isNaN(obj.percent) ? obj.percent : 0}%]`

				return obj
			},
			statistic_busy() {
				const obj = {
					value: 0,
					percent: 0,
					label: `${this.is_vacancy ? '' : '00:00 '}[0%]`
				}

				let total = 0
				let busy = 0

				let isset = false

				let busy_time_label = ''

				switch(this.selected.vision) {
					case 'day':
						if(this.schedulings.statistics) {
							isset = true

							total = this.is_vacancy ? this.schedulings.statistics.total_for_vacancys : this.schedulings.statistics.total
							busy = this.schedulings.statistics.busy

							busy_time_label = this.schedulings.statistics.busy
						}
					break
					case 'month':
					case 'year':
						if(this.events.statistics) {
							isset = true

							total = this.is_vacancy ? this.events.statistics.total_for_vacancys : this.events.statistics.total
							busy = this.events.statistics.busy

							busy_time_label = this.events.statistics.busy
						}
					break
				}

				if(!isset) return obj

				obj.value = busy
				obj.percent = ((obj.value * 100) / total).toFixed(2)
				
				busy_time_label = window.helpers.MinutesToTime(busy_time_label)

				obj.label = `${busy_time_label}`

				return obj
			},
			statistic_proceedings_count() {
				switch(this.selected.vision) {
					case 'day':
						if(this.schedulings.statistics) {
							return this.schedulings.statistics.proceedings
						}
					break
					case 'month':
					case 'year':
						if(this.events.statistics){
							return this.events.statistics.proceedings
						}
					break
				}

				return 0				
			},
			statistic_busy_vacancys() {
				const minimal_block_size = 5 // 5 minutos

				switch(this.selected.vision) {
					case 'day':
						if(this.schedulings.statistics) {
							return this.schedulings.statistics.busy / minimal_block_size
						}
					break
					case 'month':
					case 'year':
						if(this.events.statistics){
							return this.events.statistics.busy / minimal_block_size
						}
					break
				}

				return 0
			},
			statistic_attendances() {
				switch(this.selected.vision) {
					case 'day':
						if(this.schedulings.statistics) {
							return this.schedulings.statistics.attendances
						}
					break
					case 'month':
					case 'year':
						if(this.events.statistics){
							return this.events.statistics.attendances
						}
					break
				}

				return 0
			},
			statistic_blocked() {
				const obj = {
					value: 0,
					percent: 0,
					label: `${this.is_vacancy ? '' : '00:00 '}[0%]`
				}

				let total = 0
				let blocked = 0

				let isset = false

				switch(this.selected.vision) {
					case 'day':
						if(this.schedulings.statistics) {
							isset = true

							total = this.is_vacancy ? this.schedulings.statistics.total_for_vacancys : this.schedulings.statistics.total
							blocked = this.is_vacancy ? this.schedulings.statistics.blocked_for_vacancys : this.schedulings.statistics.blocked
						}
					break
					case 'month':
					case 'year':
						if(this.events.statistics) {
							isset = true

							total = this.is_vacancy ? this.events.statistics.total_for_vacancys : this.events.statistics.total
							blocked = this.is_vacancy ? this.events.statistics.blocked_for_vacancys : this.events.statistics.blocked
						}
					break
				}

				if(!isset) return obj

				obj.percent		= ((blocked * 100) / total).toFixed(2)

				obj.value = blocked
				obj.label = `[${!isNaN(obj.percent) ? obj.percent : 0}%]`

				return obj
			},
			statistic_free_time() {
				let percent 	= 0

				let total = 0
				let busy = 0
				let blocked = 0

				switch(this.selected.vision) {
					case 'day':
						if(this.schedulings.statistics) {
							total = this.schedulings.statistics.total
							busy = this.schedulings.statistics.busy

							this.schedulings.statistics.free = (total - busy - this.schedulings.statistics.blocked)

							percent		= ((this.schedulings.statistics.free * 100) / this.schedulings.statistics.total).toFixed(2)

							return `${window.helpers.MinutesToTime(this.schedulings.statistics.free)} [${!isNaN(percent) ? percent : 0}%]`
						}
					break
					case 'month':
					case 'year':
						if(this.events.statistics) {
							total = this.events.statistics.total
							busy = this.events.statistics.busy
							blocked = this.events.statistics.blocked
						
							this.events.statistics.free = (total - busy - blocked)

							percent		= ((this.events.statistics.free * 100) / total).toFixed(2)

							return `${window.helpers.MinutesToTime(this.events.statistics.free)} [${!isNaN(percent) ? percent : 0}%]`
						}
					break
				}

				return '00:00 [0%]'
			},
			statistic_pause_time() {
				let percent = 0

				switch(this.selected.vision) {
					case 'day':
						if(this.schedulings.statistics) {
							percent 	= ((this.schedulings.statistics.pause * 100) / this.schedulings.statistics.total).toFixed(2)

							return `${window.helpers.MinutesToTime(this.schedulings.statistics.pause)} [${!isNaN(percent) ? percent : 0}%]`
						}

					break
					case 'month':
					case 'year':
						if(this.events.statistics) {
							percent 	= ((this.events.statistics.pause * 100) / this.events.statistics.total).toFixed(2)

							return `${window.helpers.MinutesToTime(this.events.statistics.pause)} [${!isNaN(percent) ? percent : 0}%]`
						}

					break
				}

				return '00:00[0%]'
			},
			statistic_inative_time()
			{
				let percent = 0

				switch(this.selected.vision)
				{
					case 'day':
						if(this.schedulings.statistics)
						{
							const dayInMinutes = 1440 // 24 horas em minutos

							let totalDayInMinutes

							this.schedulings.statistics.inative = this.schedulings.statistics.total > dayInMinutes
																	? this.schedulings.statistics.total - dayInMinutes
																	: dayInMinutes - this.schedulings.statistics.total

							if(this.selected.schedules.length === 1) {

								percent		= ((this.schedulings.statistics.inative * 100) / dayInMinutes).toFixed(2)
								
								return `${window.helpers.MinutesToTime(this.schedulings.statistics.inative)} [${!isNaN(percent) ? percent : 0}%]`
								
							} else if (this.selected.schedules.length === 0){
								
								totalDayInMinutes = dayInMinutes * this.schedulings.schedules.length
								
							} else {

								totalDayInMinutes = dayInMinutes * this.selected.schedules.length

							}

							this.schedulings.statistics.inative =  totalDayInMinutes - this.schedulings.statistics.total

							percent		= (((this.schedulings.statistics.inative) * 100) / totalDayInMinutes).toFixed(2)
							
							return `[${!isNaN(percent) ? percent : 0}%]`
						}
					break
				}
				
				return '00:00 [0%]'
			},
			total_in_minutes()
			{
				const vm = this.schedulings.statistics
				const result = vm.free + vm.busy + vm.pause + vm.blocked
				
				return result
			},
			filtered_schedules()
			{
				switch(this.selected.vision)
				{
					case 'day':
						return _.orderBy(this.schedulings.schedules, 'nome', 'asc')
					case 'month':
					case 'year':
						return _.orderBy(this.events.schedules, 'nome', 'asc')
				}

				return []
			}
		},
		watch: 	{
			'selected.schedules': {
				handler(val)
				{
					if(this.step == 'map')
					{
						this.GetSchedulingsToMap()
					}					
				}
			},
			step: 	{
				handler(val)
				{
					let hash 	= ''

					switch(val)
					{
						case 'pre':
							this.selected.vision		= 'month'
							this.selected.module_id		= null
							this.selected.modality_id 	= null
							this.selected.schedules 	= []

						break
						case 'module':
							
							hash = '#modulo'

							this.selected.vision		= 'month'
							this.selected.modality_id 	= null
							this.selected.schedules 	= []

						break
						case 'modality':

							hash = '#modalidade'

							this.selected.vision		= 'month'
							this.selected.schedules 	= []

						break
						case 'map':
							hash = '#mapa-e-estatisticas'
						break
					}
				
					window.location.hash 	= hash
				},
				immediate: true
			},
			$route(to)
			{
				switch(to.hash)
				{
					case '#modulo':
						this.step = 'module'
					break
					case '#modalidade':
						this.step = 'modality'
					break
					case '#mapa-e-estatisticas':
						this.step = 'map'
					break
				}
			}
		},
		methods: 	{
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'GetModulesAndModalities'
			]),
			async WalkStep(param = null)
			{
				switch(this.step)
				{
					case 'pre':

						this.step 						= 'module'

					break
					case 'module':

						this.selected.module_id 		= param.id
						this.step 						= 'modality'

					break
					case 'modality':

						this.selected.modality_id 		= param.id
						this.step 						= 'map'

					break
					case 'map':

					break
				}
			},
			GetFitsFromSchedules()
			{
				if(this.schedules_for_calendar.length > 0 && this.selected.vision == 'day')
				{
					this.StartLoading()

					window.api.call('post', '/get-fits-from-schedule', {
							schedules: 	this.schedules_for_calendar
						})
						.then(({data}) => {
							if(data.response)
							{
								this.fit.items 		    = data.fits
								
								window.jQuery('#modal-fit').modal('show')
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				}
			},
			CancelFit(fit)
			{
            	this.StartLoading()

            	return window.api.call('post', '/cancel-fit', {
	            		scheduling_id: 	fit.id
	            	})
            		.then(({data}) => {
            			if(data.response)
            			{
            				this.GetFitsFromSchedules()

            				Swal.fire({
            					toast:  			true,
								title:				'Encaixe excluido com sucesso.',
								icon: 				'success',
								timer: 				3000,
								position: 			'top',
								timerProgressBar: 	true
            				})

            				//window.jQuery('#modal-fit').modal('hide')

            			}else{

            				Swal.fire({
            					icon: 	'error',
            					title: 	'Ops...',
            					text: 	'Houve um erro ao concluir, contate o suporte.'
            				})
            			}
            		})
            		.finally(() => {
            			this.FinishLoading()
            		})
			},
			refresh(val) 
			{
				this.GetSchedulingsToMap(val)
			},
			PrintMap()
			{
				if(this.selected.vision == 'day')
				{
					let schedules 	= this.selected.schedules

					if(this.selected.schedules.length === 0)
					{
						schedules 		= this.schedulings.schedules.map( schedule => schedule.id )
					}

					const url 			= `/mapa/agendamento/imprimir?schedules=${JSON.stringify(schedules)}&date=${this.selected.day}`

					const print 		= window.open(url)

					print.mainWindow 	= window			

				}
			},
			async VisionOrDayHasChanged(data) {
				this.selected.vision 		= data.vision
				this.selected.day 			= data.day
				this.schedulings.items 		= []
				this.schedulings.schedules 	= []
				this.events.items			= null
				this.events.schedules 		= []

				await this.GetSchedulingsToMap()
			},
			async GetYearSchedulingsToMap() {
				const promises = []

				for(let i = 1; i <= 12; i+=4) {
					const new_promise = window.api.call('post', '/get-schedulings-to-map', {
						...this.selected,
						account_id: this.requester_selected_id,
						init_month: i,
						last_month: i + 3
					})
					
					promises.push(new_promise)
				}

				Promise.allSettled(promises)
					.then((results) => {
						results.forEach((result, index) => {
							if(result.status == 'fulfilled') {
								console.log(result)
							}
						})
					})
			},
			async GetSchedulingsToMap() {
				this.StartLoading()

				const vision 	= this.selected.vision

				return await window.api.call('post', '/get-schedulings-to-map', {
						...this.selected,
						account_id: 	this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response) {
							this.is_vacancy = data.is_vacancy

							switch(vision) {
								case 'day':
									this.schedulings = {
										items: 			data.schedulings,
										schedules: 		data.schedules,
										statistics: 	data.statistics
									}
								break
								case 'month':
									this.events = {
										items: 			typeof data.schedulings === 'object' && !_.isEmpty(data.schedulings) ? {...data.schedulings} : null,
										schedules: 		data.schedules,
										statistics: 	data.statistics	
									} 
								break
								case 'year':
									this.events = {
										items: 			typeof data.schedulings === 'object' && !_.isEmpty(data.schedulings) ? {...data.schedulings} : null,
										schedules: 		data.schedules,
										statistics: 	data.statistics	
									}

									// this.ParseSchedulingsToAnualVision()
								break
							}
						} else {
							Swal.fire({
								icon: 	'error',
								title: 	'Eita!',
								text: 	'Houve um erro ao obter os agendamentos, contate o suporte.'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			ParseSchedulingsToAnualVision() {
				let reseted_items = false;

				for (const [index, date] of Object.entries({ ...this.events.items })) {
					if(!reseted_items) {
						this.events.items = {}

						reseted_items = true;
					}

					const month = index.split('-')[1]

					if(!this.events.items[month]) {
						this.events.items[month] = {}
						this.events.items[month].heatmap = date.heatmap
					} else {
						for(const [key, value] of Object.entries({ ...date.heatmap })) {
							this.events.items[month].heatmap[key] += value
						}
					}
				}
			},
			GetFullBusyClass() {
				let dynamic_class = ''
				
				let total = 0
				let free = 0
				let busy = 0
				let blocked = 0

				switch(this.selected.vision) {
					case 'day':
						if(this.schedulings.statistics) {
							total = this.is_vacancy ? this.schedulings.statistics.total_for_vacancys : this.schedulings.statistics.total
							busy = this.schedulings.statistics.busy
							blocked = this.is_vacancy ? this.schedulings.statistics.blocked_for_vacancys : this.schedulings.statistics.blocked
						}
					break
					case 'month':
					case 'year':
						if(this.events.statistics) {
							total = this.is_vacancy ? this.events.statistics.total_for_vacancys : this.events.statistics.total
							busy = this.events.statistics.busy
							blocked = this.is_vacancy ? this.events.statistics.blocked_for_vacancys : this.events.statistics.blocked
						}
					break
				}

				dynamic_class += 'font-weight-bold '

				if((total + busy + blocked) == 0) {
					return dynamic_class
				}

				free = total - busy - blocked

				if(free > 0) {
					dynamic_class += 'text-success '
				}

				if((busy + blocked) == total) {
					if(blocked) {
						dynamic_class += 'text-warning '

						return dynamic_class
					}

					dynamic_class += 'text-danger '
				}

				return dynamic_class
			}
		},
		async beforeMount()
		{
			await this.GetModulesAndModalities()

			this.WalkStep()
		}
	}
</script>

<style lang="css" scoped>
	.split-time {
		margin: 0.3rem 0;
	}
</style>