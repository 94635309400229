<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-7">
				<div class="row justify-content-between align-items-center">

					<div class="col-auto">
						<div class="mb-3 max-500 pr-6 pb-3 pb-md-0">
							<span>Configurações</span>
							<h4 class="font-24 font-md-32 m-0">Organizações</h4>
						</div>
					</div>

					<div class="col-auto">
						<router-link 
							class="btn btn-primary px-5" 
							:to="{ name: 'NewOrganization' , params: {
								pre_plan
							}}"
						>
							adicionar
						</router-link>
					</div>

				</div>
				<hr class="my-4">
				<div class="row">
					<div class="col-12">
						<div 
							v-for="requester in requesters"
							:key="requester.id"
						>	
							<template v-if="requester.confirmed || requester.account_requester_id === requester.account_provider_id">
								<router-link 
									class="select-card with-border mb-3"
									:to="{ name: 'OrganizationPanel', params: {
										organization_id: requester.account_requester_id
									}}"
								>
									<label>
										<small class="d-block opacity-50">
											{{ requester.requester.document_number }}
										</small>
										{{ requester.requester.name.toUpperCase() }}
										<i class="far fa-chevron-right"></i>
									</label>
								</router-link>
							</template>
							<template v-else>
								<div 
									class="select-card select-blue with-border mb-3" 
								>
									<label style="cursor: default;"
										@click.prevent="">
										<div class="form-row">
											<div class="col-12">
												A organização <b>{{ requester.requester.name.toUpperCase() }}</b> enviou uma solicitação de conexão, deseja aceitar ?
											</div>
											<div class="col-12 mt-1">
												<div class="d-flex justify-content-between">
													
													<button
														type="button"
														class="btn btn-danger btn-sm"
														@click="ConfirmWorkgroup(false, requester.id)"
													>
														recusar
													</button>
													<button
														type="button"
														class="btn btn-success btn-sm"
														@click="ConfirmWorkgroup(true, requester.id)"
													>
														aceitar
													</button>
												</div>
											</div>
										</div>
									</label>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Index',

		data () {
			return {
				pre_plan:			{},
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'account', 'requesters'
			])
		},
		watch: {
			pre_plan:	{
				deep: true,
				handler(val)
				{
					if(val)
					{
						Swal.fire({
							title:		`Adicione uma organização para aplicarmos a assinatura do plano "${val.name}"`,
							icon:		'info',
							toast:		true,
							position:	'top-end',
							timer:		10000,
							timerProgressBar: true
						})
					}
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			ConfirmWorkgroup(decision, id)
			{
				this.StartLoading()

				return window.api.call('post','/confirm-workgroup', {
						workgroup_id: 	id,
						confirmed:		decision
					})
					.then(({data}) => {
						if(data.response)
						{
							
							if(decision)
							{
								Swal.fire({ 
									text: 'Agora você pode acessar as informações da organização.',
									title: 'Conexão confirmada com sucesso',
									icon: 'success'
								})

							}else{

								Swal.fire({
									title: 'Conexão removida com sucesso',
									icon: 'success'
								})
							}

							this.GetAuthAccount()

						}else{
							
							Swal.fire({ 
								title: 	'Opss...',
								text: 'Houve um erro ao concluir, contate o suporte.',
								icon: 'error'
							})		
						}

					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			async HasPrePlan()
			{
				const vm = this

				vm.StartLoading()

				return await window.api.call('post', '/has-pre-plan', {
					account_id: this.account.id
				})
					.then(async ({data}) => {
						if(data.response)
						{
							await vm.GetPlan(data.pre_plan.plan_id)
						}

						if(vm.requesters.length === 0)
						{
							Swal.fire({
								title:		`Crie uma organização para começar a utilizar o sistema! 👍`,
								icon:		'info',
								toast:		true,
								position:	'top-end',
								timer:		10000,
								timerProgressBar: true
							})
						}

					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async GetPlan(plan_id)
			{
				const vm = this

				return await window.api.call('post', '/gateway/get-plan', {
					plan_id	
				})
					.then(({data}) => {
						if(data.response)
						{
							vm.pre_plan = data.plan
							vm.pre_plan.benefits 	= JSON.parse(data.plan.description).benefits
							vm.pre_plan.description = JSON.parse(data.plan.description).description

							return
						}

						Swal.fire({
							text:		'Não foi possível carregar o plano selecionado no inicio do cadastro, contate o suporte.',
							icon:		'error',
							title:		'Eita!'
						})
					})
			},
		},
		beforeMount()
		{
			// this.GetAuthAccount()
			this.HasPrePlan()
		}
	}
</script>

<style lang="css" scoped>
.swal-message
{
	display: flex;
}
</style>