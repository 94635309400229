const requests 	= (state) => {
	return state.requestLoading
}

const theme 	= (state) => {
	return state.theme
}

const modules 	= (state) => {
	return state.modules
}

const modalities 	= (state) => {
	return state.modalities
}

const dicom_modalities = (state) => {
	return state.dicom_modalities
}

const icon_size 	= (state) => {
	return state.icon_size
}

const carousel_orientation 	= (state) => {
	return state.carousel_orientation
}

const menu_order  = (state) => {
	return state.menu_order
}

const last_modality_id = (state) => {
	return state.last_modality_id
}

export {
	requests,
	theme,
	modules,
	modalities,
	dicom_modalities,
	icon_size,
	carousel_orientation,
	menu_order,
	last_modality_id
}