<template>
	<div class="container-large pb-4">
		<transition name="fade" mode="out-in">
			<div 
				v-if="step === 'pre'" 
				:key="step"
			>
				
			</div>
			<div 
				v-if="step === 'schedules'"
				:key="step"
				class="pt-5 pb-3"
			>
				<Schedules 
					:label="'Encaixe'"
					type="fit"
					:modality_id="scheduling.modality_id"
					:proceedings="scheduling.proceedings"
					:schedules="scheduling.schedules"
					@next="WalkStep"
					@previous="DynamicPrevious"
				/>
			</div>
			<div 
				v-if="step === 'authorization-and-guide'"
				:key="step"
				class="pt-5 pb-3"
			>
				<AuthorizationAndGuide 
					:label="'Agendamento'"
					:classification="scheduling.classification"
					:authorization="scheduling.authorization"
					:guide="scheduling.guide"
					@next="WalkStep"
					@previous="step = 'schedules'"
				/>
			</div>
			<div 
				v-if="step === 'confirm'"
				:key="step"
				class="pt-5 pb-3"
			>
				<Confirm 
					:label="'Encaixe'"
					type="fit"
					:module_id="selected_module_id"
					:modality_id="scheduling.modality_id"
					:partnership="scheduling.partnership"
					:proceedings="scheduling.proceedings"
					:time="scheduling.time"
					:patient="scheduling.patient"
					:classification="scheduling.classification"
					:authorization="scheduling.authorization"
					:guide="scheduling.guide"
					ref="confirm"
					@next="WalkStep"
					@previous="step = 'authorization-and-guide'"
				/>
			</div>
		</transition>
	</div>
</template>

<script>
	const _ 		= window._
	import Schedules from './components/Schedules'
	import Patient from './components/Patient'
	import AuthorizationAndGuide from './components/AuthorizationAndGuide'
	import Confirm from './components/Confirm'

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Fit',
		components: 	{
			AuthorizationAndGuide,
			Schedules,
			Patient,
			Confirm
		},
		props: {
			scheduling_id: 	{
				type: 			[String, Number],
				default:		null,
			},
			source: {
				type:			String,
				default:		null
			}
		},
		data () {
			return {
				step: 			'pre',
				source_page:	this.source,
				scheduling: 	{
					module_id: 			null,
					modality_id: 		null,
					schedules: 			[],
					proceedings: 		[],
					partnership: 		null,
					place: 				null,
					time: 				null,
					patient: 			null,
					classification: 	2,
					authorization: 		'',
					guide: 				'',
					obs: 				''
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			...mapGetters('system', [
				'modules'
			]),
			selected_module_id()
			{
				const module = this.modules.find(m => m.name === this.scheduling.module)

				return module.id
			}
		},
		watch: 	{
			step: {
				handler(val)
				{
					let hash 	= ''

					switch(val)
					{
						case 'schedules':
							hash = '#horarios'
						break
						case 'authorization-and-guide':
							hash = '#classificacao-e-autorizacao'
						break
						case 'patient':
							hash = '#paciente'
						break
						case 'confirm':
							hash = '#confirmacao'
						break
					}

					window.location.hash 	= hash
				},
				immediate: true
			},
			$route(to)
			{
				switch(to.hash)
				{
					case '#horarios':
						this.step = 'schedules'
					break
					case '#paciente':
						this.step = 'patient'
					break
					case '#classificacao-e-autorizacao':
						this.step = 'authorization-and-guide'
					break
					case '#confirmacao':
						this.step = 'confirm'
					break
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'GetModulesAndModalities'
			]),
			DynamicPrevious()
			{
				if(this.step == 'schedules')
				{
					window.history.go(-2)
				}
			},
			async WalkStep(param = null)
			{
				const vm 				= this
				const day_of_week 		= new Date().getDay()

				switch(this.step)
				{
					case 'pre':

						this.step 						= 'schedules'

						this.scheduling.schedules 		=  _.orderBy(_.filter(this.scheduling.schedules, schedule => {

							let dias_habilitados 	= JSON.parse(schedule.dias_habilitados)

							let allowed 			= false

							if(dias_habilitados)
							{
								if(schedule.ciclica)
								{
									allowed 	= dias_habilitados.find( dia => dia.value == day_of_week && dia.selected)

									allowed 	= typeof allowed === 'object'

								}else{

									let today 	= new Date()
									today 		= `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`

									allowed 	= _.findIndex(dias_habilitados, { 'fulldate': today }) > -1
								}
							}

							return !schedule.continua && schedule.status && allowed

						}), 'nome')


						if(this.scheduling.schedules.length > 0)
						{
							this.step 						= 'schedules'

						}else{

							Swal.fire({	
								title: 	'Eita!',
								text: 	'Não há agendas disponiveis para hoje.',
								icon: 	'error'
							})
						}

					break
					case 'schedules':

						this.scheduling.time 			= param
						this.scheduling.authorization	= ''
						this.scheduling.classification 	= 2
						this.scheduling.guide 			= ''
						this.scheduling.obs 			= ''
						this.scheduling.place 			= null

						this.step 						= 'authorization-and-guide'

					break
					case 'authorization-and-guide':

						this.scheduling.authorization	= param.authorization
						this.scheduling.classification 	= param.classification
						this.scheduling.guide 			= param.guide
						this.scheduling.obs 			= ''
						this.scheduling.place 			= null

						this.step 						= 'confirm'

					break
					case 'confirm':

						this.scheduling.obs 			= param.obs
						this.scheduling.place 			= param.place
						
						await this.SaveFit()					

					break
				}
			},
			GetProceedingsDetails()
			{
				this.StartLoading()

				return window.api.call('post', '/get-proceeding-details',{
						account_id: 		this.requester_selected_id,
						modality_id: 		this.scheduling.modality_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.base_proceedings 	= data.proceedings
							this.scheduling.schedules = data.schedules
						}
					})
					.finally(() => {

						this.FinishLoading()

					})
			},
			async GetSchedulingData() {
				const vm = this
				
				vm.StartLoading()

				return await window.api.call('post', '/get-scheduling-data', {
					account_id: vm.requester_selected_id,
					scheduling: vm.scheduling_id
				})
					.then(async ({data}) => {
						if(data.response) {
							const scheduling = data.scheduling

							vm.scheduling				= {...scheduling}
							vm.scheduling.patient		= scheduling.journey.patient
							vm.scheduling.partnership	= scheduling.partnerships[0]
							
							await vm.GetProceedingsDetails()

							let arr = []

							vm.base_proceedings.forEach((p) => {
								if(p.id === vm.scheduling.proceeding.id)
								{
									arr.push(vm.scheduling.proceeding)
								}
							})

							vm.scheduling.proceedings = arr
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			SaveFit()
			{
				const vm 	= this
				this.StartLoading()
				
				return window.api.call('post', '/save-fit', {
						...this.scheduling,
						account_id: 	this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon: 				'question',
								title: 				'Deseja imprimir o dados do encaixe ?',
								showConfirmButton: 	true,
								showCancelButton: 	true,
								confirmButtonText: 	'Sim',
								cancelButtonText: 	'Não',
								width: 				'auto',
								customClass: 		{
									confirmButton: 		'success',
								},
							}).then(async (res) => {
								if (res.isConfirmed)
								{
									vm.$refs.confirm.Print()

									await vm.CancelScheduling()

									vm.step 						= 'pre'

									vm.WalkStep()

									Swal.fire({	
										title: 	'Concluido !',
										html: 	`Paciente encaixado com sucesso.<br>ID: ${data.journey}`,
										icon: 	'success'
									})

								}else{

									await vm.CancelScheduling()

									vm.step 						= 'pre'

									vm.WalkStep()

									Swal.fire({
										title: 	'Concluido !',
										html: 	`Paciente encaixado com sucesso.<br>ID: ${data.journey}`,
										icon: 	'success'
									})
								}
								
								this.$router.push({ name: this.source_page })
							})
						}else{

							Swal.fire({
								icon: 	'error',
								title: 	'Opss...',
								text: 	'Houve um erro ao concluir, contate o suporte.'
							})
						}	
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			CancelScheduling()
			{
				const vm = this

				vm.StartLoading()

				let arr = []

				arr.push({id: vm.scheduling.id})

				return window.api.call('post','/cancel-schedulings', {
							schedulings: arr,
							account_id: vm.requester_selected_id
						})
						.then(({data}) => {
							if(data.response)
							{
								vm.step		= 'pre'

								return data
							}else{

								window.helpers.errorMessageTop('Houve um erro ao excluir o agendamento anterior, contate o suporte.', 4000)
							}
						})
						.finally(() => {
							vm.dialog 	= false

							vm.FinishLoading()
						})
			}
		},
		async beforeMount()
		{
			await this.GetModulesAndModalities()

			await this.GetSchedulingData()

			this.WalkStep()
		}
	}
</script>

<style lang="css" scoped>
</style>