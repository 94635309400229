<template>
	<div>
		<div class="footer" v-if="$route.name == 'Home'">
			{{ version }}
		</div>
	</div>
</template>

<script>

export default {

	data() {
		return {
			version: 'v0.0.0-loading',
		}
	},
	methods: { 
		async GetVersion() { 
			await window.api.call('get', '../generalized_version')
				.then(({data}) => {
					if (data.response) { 
						this.version = data.generalized_version 
					} else {
						this.version = 'Erro na consulta'
					}
				})
		},
	},
	computed: { 

	},
	beforeMount() {
		// this.GetVersion()
	}

}
</script>

<style lang="css" scoped>
	.tooltip-version
	{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.footer {
		background: #d6d6d6;
		color: #848484;
		position: fixed;
		left: 0;
		bottom: 5px;
		margin: 5px;
		padding: 2px 5px 2px 5px;
		border-radius: 8px;
	}    

</style>
