const ADD 				= (state) => {
	state.requestLoading++
}

const REM 				= (state) => {
	state.requestLoading--
}

const TOGGLE_THEME 		= (state, payload) => {
	state.theme 	= payload
}

const UPDATE_MODULES 	= (state, payload) => {
	state.modules	= payload ? [...payload] : []
}

const UPDATE_MODALITIES 	= (state, payload) => {
	state.modalities	= payload ? [...payload] : []
}

const UPDATE_DICOM_MODALITIES = (state, payload) => {
	state.dicom_modalities = payload ? [...payload] : []
}

const UPDATE_ICON_SIZE 		= (state, payload) => {
	state.icon_size 	= payload
}

const UPDATE_CAROUSEL_ORIENTATION = (state, payload) => {
	state.carousel_orientation = payload
}

const UPDATE_MENU_ORDER 	= (state, payload) => {
	state.menu_order = payload
}

const UPDATE_LAST_MODALITY_ID = (state, payload) => {
	state.last_modality_id = payload
}

export {
	ADD,
	REM,
	TOGGLE_THEME,
	UPDATE_MODULES,
	UPDATE_MODALITIES,
	UPDATE_DICOM_MODALITIES,
	UPDATE_ICON_SIZE,
	UPDATE_CAROUSEL_ORIENTATION,
	UPDATE_MENU_ORDER,
	UPDATE_LAST_MODALITY_ID
}