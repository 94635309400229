<template>
	<div class="container">
		<div class="row align-items-center vh-md-80 justify-content-between flex-column">
			<div class="col-12">
				<div class="d-block mx-auto py-md-2 text-md-center max-500">
					<span>{{ label }}</span>
					<h4 class="font-24 font-md-32 m-0">Configuração do Fluxo</h4>
				</div>
			</div>
			<div class="col-12 col-md">
				<div class="row justify-content-between">
					<div class="col-12 col-md-6">

						<div class="form-group position-relative mb-0">
							<input 
								type="search" 
								class="form-control border-no pl-5" 
								placeholder="Buscar..."
								v-model="search"
							>
							<i class="far fa-search font-14 position-absolute ps-l-1 ml-3 ps-v-center"></i>
						</div>

						<div class="card bg-light border-no overflow-hidden">

							<ul class="nav nav-pills nav-theme nav-fill" id="pills-tab" role="tablist">
								<li class="nav-item" role="presentation">
									<a 
										class="nav-link font-16 pt-4 pb-3 active" 
										id="pills-action-tab" 
										data-toggle="pill" 
										href="#pills-action" 
										role="tab" 
										aria-controls="pills-action" 
										aria-selected="true"
									>
										Ação
									</a>
								</li>
								<li class="nav-item" role="presentation">
									<a 
										class="nav-link font-16 pt-4 pb-3" 
										id="pills-mask-tab" 
										data-toggle="pill" 
										href="#pills-mask" 
										role="tab" 
										aria-controls="pills-mask" 
										aria-selected="false"
									>
										Máscaras
									</a>
								</li>
								<li class="nav-item" role="presentation">
									<a 
										class="nav-link font-16 pt-4 pb-3"  
										id="pills-schedule-tab" 
										data-toggle="pill" 
										href="#pills-schedule" 
										role="tab" 
										aria-controls="pills-schedule" 
										aria-selected="false"
									>
										Agendamento
									</a>
								</li>
							</ul>

							<div class="card-body px-3 pb-3 pb-md-5 px-md-5 pt-md-4 scroll-this">

								<div class="tab-content" id="pills-tabContent">
									<div class="tab-pane fade show active" id="pills-action" role="tabpanel" aria-labelledby="pills-action-tab">
										<template v-if="filtered_action_buttons.length > 0">
											<transition-group tag="div" name="fast-fade" mode="out-in">
												<div 
													class="select-card select-blue with-border mt-3"
													v-for="btn in filtered_action_buttons"
													:key="btn.id"
												>
													<label style="cursor: default;">
														{{ btn.name.toUpperCase() }}
														<i class="far fa-plus"
															@click="AddActionButton(btn)"
														></i>
													</label>
												</div>
											</transition-group>
										</template>
										<template v-else>
											<p class="text-muted text-center">Não há itens para exibir.</p>
										</template>
									</div>
									<div class="tab-pane fade" id="pills-mask" role="tabpanel" aria-labelledby="pills-mask-tab">
										<template v-if="filtered_masks.length > 0">
											<transition-group tag="div" name="fast-fade" mode="out-in">
												<div 
													class="select-card with-border mt-3"
													:class="{
														'select-blue': mask.type != 'maskModule' && mask.id > 0
													}"
													v-for="mask in filtered_masks"
													:key="mask.id"													
												>
													<template v-if="mask.type == 'maskModule' || mask.id === -1">
														<label style="cursor: default;" >
															{{ mask.name.toUpperCase() }}
															<i 
																class="far fa-chevron-right"
																@click="AddMaskButton(mask)"
															></i>
														</label>
													</template>
													<template v-else-if="mask.type == 'maskModality'">
														<label style="cursor: default;" >
															{{ mask.name.toUpperCase() }}
															<i class="far fa-plus mr-5"
																@click="AddMaskButton(mask, true)"
															></i>
															<i 
																class="far fa-chevron-right"
																@click="AddMaskButton(mask)"
															></i>
														</label>
													</template>
													<template v-else-if="mask.type == 'mask'">
														<label style="cursor: default;" >
															{{ mask.name.toUpperCase() }}
															<i class="far fa-plus"
																@click="AddMaskButton(mask, true)"
															></i>
														</label>
													</template>
												</div>
											</transition-group>
										</template>
										<template v-else>
											<p class="text-muted text-center">Não há itens para exibir.</p>
										</template>
									</div>
									<div class="tab-pane fade" id="pills-schedule" role="tabpanel" aria-labelledby="pills-schedule-tab">						<template v-if="filtered_schedules.length > 0">
											<transition-group tag="div" name="fast-fade" mode="out-in">
												<div 
													class="select-card with-border mt-3"
													:class="{
														'select-blue': schedule.type != 'scheduleModule' && schedule.id > 0
													}"
													v-for="schedule in filtered_schedules"
													:key="schedule.id"
												>
													<template v-if="schedule.type == 'scheduleModule' || schedule.id === -1">
														<label style="cursor: default;">
															{{ schedule.name.toUpperCase() }}
															<i 
																class="far fa-chevron-right"
																@click="AddScheduleButton(schedule)"
															></i>
														</label>
													</template>
													<template v-else-if="schedule.type == 'scheduleModality'">
														<label style="cursor: default;">
															{{ schedule.name.toUpperCase() }}
															<i 
																class="far fa-plus"
																@click="AddScheduleButton(schedule)"
															></i>
														</label>
													</template>
												</div>
											</transition-group>
										</template>
										<template v-else>
											<p class="text-muted text-center">Não há itens para exibir.</p>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-5 pt-md-5">
						<div class="row">
							<div class="col-4">
								<span class="opacity-75">
									Módulo:  
								</span>
							</div>
							<div 
								class="col-8 text-dark"
								v-if="module"
							>
								{{ module.name }}
							</div>
							<div class="col-4">
								<span class="opacity-75">
									Modalidade:
								</span>
							</div>
							<div 
								class="col-8 text-dark"
								v-if="modality"
							>
								{{ modality.name }}
							</div>
						</div>
						<hr class="opacity-50"/>
						<div class="my-4">
							<template v-if="user_list.length > 0">
								<transition-group tag="div" name="fast-fade" mode="out-in">
									<div 
										class="select-card select-orange with-border mt-3"
										v-for="item in user_list"
										:key="`${item.name}-${item.id}-${item.type}`"
										
									>
										<label style="cursor: default;">
											<template v-if="item.tab === 0">
		                                        [AÇÃO]
		                                    </template>
		                                    <template v-else-if="item.tab === 1">
		                                        [MÁSC]
		                                    </template>
		                                    <template v-else>
		                                        [AGEN]
		                                    </template>
		                                    {{item.name.toUpperCase()}}
											<i 
												class="far fa-times"
												@click="RemSelectedItem(item)"
											></i>
										</label>
									</div>
								</transition-group>
							</template>
							<template v-else>
								<p class="text-muted text-center">Não há itens adicionados.</p>
							</template>	
						</div>
					</div>
				</div>
			</div>	
			<div class="col-12  text-center py-5">
				<div class="d-flex justify-content-between">
					<button 
						class="btn btn-light px-md-5"
						@click="$emit('previous')"
					>
						voltar
					</button>
					<button 
						class="btn btn-primary px-md-5"
						@click="Next"
					>
						salvar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../../helpers'
	const helpers 		= new Helpers()

	const _ 			= require('lodash')

	import { mapActions, mapGetters } from 'vuex'
	export default {

		name: 'Flow',
		props: {
			label: 		{
				type: 	String,
				default: ''
			},
			module: 	{
				type: 	Object,
				default: null,
				required: true
			},
			modality: 	{
				type: 	Object,
				default: null,
				required: true
			}
		},
		data () {
			return {
				search: 		'',
				action_buttons: [],
				user_list: 		[],
				modules: 		[],
				modalities: 	[],
				masks: 			{
					module: 		null,
					modality: 		null
				},
				schedules: 		{
					module: 		null,
					modality: 		null
				}
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			filtered_action_buttons()
			{	
				const vm 		= this

				return vm.action_buttons.map((button) => ({
						...button, 
						type: 'action', 
						tab: 0 
					}))
                    .filter((button) => !vm.user_list.some(k => k.action === button.action))
                    .sort(({name: a}, {name: b}) => a > b)
			},
			filtered_masks()
			{
				const vm 		= this
				let result_list = []

				if(!vm.masks.module)
				{
					result_list = vm.modules.map(k => ({
	                        id: 	k.id,
	                        name: 	k.name,
	                        type: 	'maskModule',
	                        tab: 	1
	                    }))
	                    .filter((button) => !vm.user_list.some(k => k.id === button.id && k.type === button.type))
	                    .sort(({name: nameA}, {name: nameB}) => nameA > nameB)

				} else if (vm.masks.module && vm.masks.modality)
                {
                    let module_id 			= vm.masks.module.id
                    let selected_module 	= vm.modules.find(k => k.id === module_id)

                    let modality_id 		= vm.masks.modality.id
                    let selected_modality 	= vm.modalities.find(k => k.id === modality_id)
                    
                    result_list 			= selected_modality.masks.map(k => ({
                            id: 		k.id_mascara_patologica,
                            name: 		k.nome,
                            type: 		'mask',
                            tab: 		1,
                            modality: 	vm.masks.modality,
                        }))
                        .filter((button) => !vm.user_list.some(k => k.id === button.id && k.type === button.type))
                        .sort(({nome: nameA}, {nome: nameB}) => nameA > nameB)
                    
                    result_list.unshift({
	                        id: 		-1,
	                        name: 		'...',
	                        type: 		'mask',
	                        tab: 		1
	                    })
                    
                } else if (vm.masks.module && !vm.masks.modality)
                {         
                    result_list = vm.modalities.filter(k => k.module_id === vm.masks.module.id)
                        .map(k => ({
                            id: k.id,
                            name: k.name,
                            type: 'maskModality',
                            tab: 1,
                            module: vm.masks.module,
                        }))
                        .filter((button) => !vm.user_list.some(k => k.id === button.id && k.type === button.type))
                        .sort(({nome: nameA}, {nome: nameB}) => nameA > nameB)
                    
                    result_list.unshift({
	                        id: -1,
	                        name: '...',
	                        type: 'maskModality',
	                        tab: 1
	                    })

                }

                if(vm.search)
                {
                	const search = helpers.RemoveAcento(vm.search.toLowerCase())

                	result_list  = result_list.filter(k => {
                		return helpers.RemoveAcento(k.name.toLowerCase()).indexOf(search) > -1
                	})
                }            	

                return result_list
			},
			filtered_schedules()
			{
				const vm 		= this
				let result_list = []

				if(!vm.schedules.module)
				{
                	result_list = vm.modules
                        .map(k => ({
                            id: 		k.id,
                            name: 		k.name,
                            type: 		'scheduleModule',
                            tab: 		2
                        }))
                        .filter((button) => !vm.user_list.some(k => k.id === button.id && k.type === button.type))
                        .sort(({name: nameA}, {name: nameB}) => nameA > nameB)

				} else if (vm.schedules.module && !vm.schedules.modality)
				{              
                    result_list = vm.modalities.filter(k => k.module_id === vm.schedules.module.id)
        				.map(k => ({
                            id: 		k.id,
                            name: 		k.name,
                            type: 		'scheduleModality',
                            tab: 		2,
                            module: 	vm.schedules.module,
                        }))
                        .filter((button) => !vm.user_list.some(k => k.id === button.id && k.type === button.type))
                        .sort(({nome: nameA}, {nome: nameB}) => nameA > nameB)

                    result_list.unshift({
                        id: 		-1,
                        name: 		'...',
                        type: 		'scheduleModality',
                        tab: 		2
                    })
                }

                if(vm.search)
                {
                	const search = helpers.RemoveAcento(vm.search.toLowerCase())

                	result_list  = result_list.filter(k => {
                		return helpers.RemoveAcento(k.name.toLowerCase()).indexOf(search) > -1
                	})
                }              	

                return result_list
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetFlowButtons()
			{
				this.StartLoading()

				return window.api.call('post', '/get-flow-buttons', {
						account_id: 	this.requester_selected_id,
						modality_id: 	this.modality.id,
						module_id: 		this.module.id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.action_buttons 		= data.action_buttons
							this.user_list 				= data.flowchart
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			GetModulesAndModalitiesWithMasks()
			{
				this.StartLoading()

				return window.api.call('post', '/get-modules-and-modalities', {
						account_id: 	this.requester_selected_id,
						with_masks: 	true
					})
					.then(({data}) => {

						if(data.response)
						{
							this.modules 	= _.orderBy(data.modules, 'name', 'asc')
							this.modalities = _.orderBy(data.modalities, 'name', 'asc')
						}	
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			RemSelectedItem(item)
			{
				this.user_list 	= this.user_list.filter( u => {
					return `${u.id}${u.tab}` != `${item.id}${item.tab}`
				})
			},
			AddActionButton(btn)
			{
				this.user_list.push(btn)
			},
			AddMaskButton(mask, add = false)
			{
	            const vm 								 = this
	            const { tab: tab_item, type: item_type } = mask

	            if (tab_item === 1 || tab_item === 2)
	            {
	                switch (item_type) 
	                {
	                    case 'maskModule':

	                        vm.masks.module 	= mask

	                    break
	                    case 'maskModality':

	                        if (mask.id === -1)
	                        {
	                            vm.masks.module 	= null

	                        }else{

	                        	if(add)
	                        	{
	                        		this.user_list.push(mask)

	                        	}else{

	                            	vm.masks.modality  	= mask
	                        	}

	                        }

                        break
	                    case 'mask':

	                        if (mask.id === -1)
	                        {
	                            vm.masks.modality 	= null

	                        }else{

	                        	this.user_list.push(mask)
	                        }

                        break
	                }
	            }
			},
			AddScheduleButton(schedule)
			{
	            const vm 									= this
	            const { tab: tab_item, type: item_type } 	= schedule

	            if (tab_item === 1 || tab_item === 2)
	            {
	                switch (item_type) 
	                {
	                    case 'scheduleModule':

	                        vm.schedules.module 	= schedule

	                    break
	                    case 'scheduleModality':

	                        if (schedule.id === -1)
	                        {
	                            vm.schedules.module 	= null

	                        }else{

	                            this.user_list.push(schedule)
	                        }

                        break
	                }
	            }
			},
			Next()
			{
				this.$emit('next', this.user_list)
			}
		},
		mounted()
		{
			Promise.all([
					this.GetFlowButtons(),
					this.GetModulesAndModalitiesWithMasks()
				])
				.finally(() => {

				})
		}
	}
</script>

<style lang="css" scoped>
</style>